import _ from 'lodash';
import { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useRecoilState, useRecoilValue } from 'recoil';
import LocationFilterIntersectionEditor from '../../../assignmentPolicy/AssignmentPolicyLocationFilterEditor';
import AssignmentPolicyProductFilterEditor from '../../../assignmentPolicy/AssignmentPolicyProductFilterEditor';
import AssignmentPolicyRuleTypeEditor from '../../../assignmentPolicy/AssignmentPolicyRuleTypeEditor';
import useFormatter from '../../../common/useFormatter';
import InboxZero from '../../../components/InboxZero';
import { Container } from '../../../components/layout/ContainerFlex';
import { ScreenTitle } from '../../../components/layout/ScreenTitle';
import SidebarContainer, {
  SidebarContainerProps,
} from '../../../containers/SidebarContainer';
import {
  updateAllPolicyFilterValues,
  updatePolicyFilterValues,
} from '../../../policyFilters/policyFilter.helper';
import useLoadItemFilterValues from '../../../policyFilters/useLoadItemFilterValues';
import useLoadLocationFilterValues from '../../../policyFilters/useLoadLocationFilterValues';
import { sidebarStateByType } from '../../../store/sidebar.state';
import {
  getLocationFilterConfigCommon,
  getProductFilterConfigCommon,
} from '../../store/assignmentPolicy.default';
import {
  assignmentPolicyRule,
  assignmentPolicySelectedIdentity,
} from '../../store/assignmentPolicy.state';
import AssignmentPolicyComplianceModeEditor from './AssignmentPolicyComplianceModeEditor';

const AssignmentPolicyEditorSidebar: React.FC = () => {
  const { t } = useTranslation('simulation');
  const formatter = useFormatter();

  const filterIdentity = useRecoilValue(assignmentPolicySelectedIdentity);
  const rule = useRecoilValue(assignmentPolicyRule(filterIdentity?.ruleId));
  const [loadItemValues, cancelLoadItemValues] = useLoadItemFilterValues();
  const [loadLocationValues, cancelLoadLocationValues] =
    useLoadLocationFilterValues();

  const [policyEditPanelState, setPolicyEditPanelState] = useRecoilState(
    sidebarStateByType('sidebar-policy-storage-editor'),
  );

  const showProductFilters = !_.isNil(filterIdentity?.productFilterId);
  const showLocationFilters = !_.isNil(filterIdentity?.locationFilterId);
  const showRuleTypeEditor = filterIdentity?.filterType === 'ruleType';
  const showComplianceModeEditor =
    filterIdentity?.filterType === 'items-compliance-mode' ||
    filterIdentity?.filterType === 'locations-compliance-mode';

  const productFiltersConfig = getProductFilterConfigCommon(t, formatter);
  const locationFiltersConfig = getLocationFilterConfigCommon(t);

  useEffect(() => {
    if (showProductFilters) {
      updatePolicyFilterValues({
        filterConfig: productFiltersConfig,
        selectedFilterId: filterIdentity.productFilterId,
        policyFilterUnion: rule.productsMatch,
        load: loadItemValues,
        cancel: cancelLoadItemValues,
      });
    }
    if (showLocationFilters) {
      updateAllPolicyFilterValues({
        filterConfig: locationFiltersConfig,
        selectedFilterId: filterIdentity.locationFilterId,
        policyFilterUnion: rule.locationsMatch,
        load: loadLocationValues,
        cancel: cancelLoadLocationValues,
      });
    }
  }, [filterIdentity]);

  if (
    !showProductFilters &&
    !showLocationFilters &&
    !showRuleTypeEditor &&
    !showComplianceModeEditor
  ) {
    return (
      <InboxZero
        selfCenter
        center
        message={t`No Policy filter group selected`}
        message_helper={t`Click on an Edit button in Policy Rule to change filters`}
      />
    );
  }

  return (
    <Container col hasOverflowY className="pb-20">
      <ScreenTitle
        subtitle={t`Filter Options for`}
        title={`${rule.title}`}
        isSticky
      />
      {showComplianceModeEditor && <AssignmentPolicyComplianceModeEditor />}
      {showProductFilters &&
        _.map(productFiltersConfig, configItem => (
          <AssignmentPolicyProductFilterEditor
            key={`policy-filter-editor-${configItem.type}`}
            filterConfig={configItem}
            startLoadData={loadItemValues}
            cancelLoadData={cancelLoadItemValues}
          />
        ))}

      {locationFiltersConfig &&
        _.map(locationFiltersConfig, configItem => (
          <LocationFilterIntersectionEditor
            key={`policy-filter-editor-${configItem.type}`}
            filterConfig={configItem}
            startLoadData={loadLocationValues}
            cancelLoadData={cancelLoadLocationValues}
          />
        ))}
      {showRuleTypeEditor && <AssignmentPolicyRuleTypeEditor />}
    </Container>
  );
};

export default () => {
  const { t } = useTranslation('simulation');

  const containerProps: SidebarContainerProps = {
    type: 'sidebar-policy-storage-editor',
    title: t`Filter Policy Params`,
    loadingMessage: t`Loading Policy...`,
    size: 'xs',
  };

  return (
    <SidebarContainer key={containerProps.type} {...containerProps}>
      <AssignmentPolicyEditorSidebar />
    </SidebarContainer>
  );
};
