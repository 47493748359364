import { MeasurementSystem } from '@warebee/shared/data-access-layout-manager';
import configureMeasurements, { BestResult } from 'convert-units';
import area from 'convert-units/definitions/area';
import digital from 'convert-units/definitions/digital';
import length from 'convert-units/definitions/length';
import mass from 'convert-units/definitions/mass';
import time from 'convert-units/definitions/time';
import volume from 'convert-units/definitions/volume';
import { addDays } from 'date-fns';
import formatDistanceToNow from 'date-fns/formatDistanceToNow';
import formatDuration from 'date-fns/formatDuration';
import intervalToDuration from 'date-fns/intervalToDuration';
import enGB from 'date-fns/locale/en-GB';
import { TFunction } from 'i18next';
import _ from 'lodash';

export const locale = Intl.NumberFormat().resolvedOptions().locale;

const convertLength = configureMeasurements({ length });
const convertTime = configureMeasurements({ time });
const convertMass = configureMeasurements({ mass });
const convertVolume = configureMeasurements({ volume });
const convertArea = configureMeasurements({ area });
const convertDigital = configureMeasurements({ digital });

const percentFormatter = new Intl.NumberFormat(locale, { style: 'percent' });
const compactFormatter = new Intl.NumberFormat(locale, { notation: 'compact' });

const currencyFormatterOptions = _.memoize(
  (currencyCode: string, cutOffNumber = 0) =>
    new Intl.NumberFormat(locale, {
      style: 'currency',
      currency: currencyCode,
      minimumFractionDigits: cutOffNumber,
      maximumFractionDigits: cutOffNumber,
    }).resolvedOptions(),
  (...args) => _.join(args, '-'),
);

export const getCurrencySymbolSettings = _.memoize((currencyCode: string) => {
  const parts = currencyFormatter(currencyCode).formatToParts(0);
  const symbol = parts.find(p => p.type === 'currency')?.value;
  return {
    symbol,
    prefix: parts?.[0].type === 'currency',
  };
});

const currencyFormatter = _.memoize(
  (currencyCode: string, cutOffNumber = 0) =>
    new Intl.NumberFormat(locale, {
      style: 'currency',
      currency: currencyCode,
      currencyDisplay: 'narrowSymbol',
      minimumFractionDigits: cutOffNumber,
      maximumFractionDigits: cutOffNumber,
    }),
  (...args) => _.join(args, '-'),
);

const getCurrencySymbol = (currency: string): string => {
  const currencySymbols: Record<string, string> = {
    USD: '$',
    GBP: '£',
    EUR: '€',
  };

  // Return the currency symbol if it exists, otherwise default to '$'
  return currencySymbols[currency] ?? '$';
};

export const integerFormatter = new Intl.NumberFormat(locale, {
  style: 'decimal',
  minimumFractionDigits: 0,
  maximumFractionDigits: 0,
});

export function formatCompact(v: number) {
  return compactFormatter.format(v);
}

export function formatPercent(v: number) {
  return percentFormatter.format(v);
}

export function formatCurrency(v: number, code: string, cutOffNumber = 0) {
  return v.toLocaleString(locale, {
    ...currencyFormatterOptions(code, cutOffNumber),
    style: 'decimal',
  });
}

export function formatCurrencyFull(
  v: number,
  currencyCode: string,
  cutOffNumber = 0,
) {
  return currencyFormatter(currencyCode, cutOffNumber).format(v);
}

export function formatInteger(v) {
  return integerFormatter.format(v);
}

export function formatToPrecision(
  v: number,
  precision = 2,
  cutOff = 10,
  cutOffMin = 1,
) {
  const formatter = new Intl.NumberFormat(locale, {
    style: 'decimal',
    maximumFractionDigits: v > cutOff ? 0 : precision,
    maximumSignificantDigits: v < cutOffMin ? precision : undefined,
  });

  return formatter.format(v);
}

export function formatDateTime(d: Date) {
  return d?.toLocaleString(locale) ?? '';
}

export function formatDate(d: Date) {
  return d.toLocaleDateString(locale);
}

export type FormattedValueWithUnit = {
  value: string;
  fullString: string;
  unit: string;
  raw: number;
  unitCode: string;
  prefixUnit?: boolean;
};

function getNull(): FormattedValueWithUnit {
  return {
    value: '-',
    fullString: '-',
    unit: '',
    raw: NaN,
    unitCode: '',
  };
}

function getNotApplicable(t: TFunction<'measures'>): FormattedValueWithUnit {
  return {
    value: t(`N/A`, { ns: 'measures' }),
    fullString: t(`N/A`, { ns: 'measures' }),
    unit: '',
    raw: NaN,
    unitCode: '',
  };
}
type UnitPosition = 'prefix' | 'postfix';

function getFormatted(
  result: BestResult<string>,
  original: number,
  t: TFunction<'measures'>,
  formatter: (v: number) => string = formatInteger,
  unitPos: UnitPosition = 'postfix',
): FormattedValueWithUnit {
  const value = result?.val ?? 0;
  const formattedValue = formatter(result?.val ?? 0);
  const formattedUnit = value === 0 ? '' : t(result?.unit, { ns: 'measures' });
  return {
    value: formattedValue,
    unit: formattedUnit,
    raw: original,
    unitCode: result?.unit,
    fullString:
      unitPos === 'postfix'
        ? `${formattedValue} ${value === 0 ? '' : formattedUnit}`
        : `${formattedUnit} ${formattedValue}`,
  };
}

function formatDistanceBestMetric(
  value: number,
  t: TFunction<'measures'>,
  cutOffNumber = 1,
  fractionDigits = 1,
  shouldRound = true,
): FormattedValueWithUnit {
  let distanceBest: BestResult<string>;
  if (value >= 0.0001 && value < 0.1) {
    distanceBest = {
      plural: 'Millimetres',
      singular: 'Millimeter',
      unit: 'mm',
      val: value * 10,
    };
  } else {
    distanceBest = convertLength(value)
      .from('cm')
      .toBest({
        cutOffNumber: value > cutOffNumber ? cutOffNumber : 1,
        exclude: ['dm', 'nm', 'μm'],
      });
  }
  return getFormattedWithOption(
    distanceBest,
    value,
    t,
    fractionDigits,
    shouldRound,
  );
}

function formatDistanceBestImperial(
  value: number,
  t: TFunction<'measures'>,
  cutOffNumber = 1,
  fractionDigits = 1,
  shouldRound = true,
): FormattedValueWithUnit {
  let distanceBest: BestResult<string>;
  if (value < 12) {
    distanceBest = {
      plural: 'Inches',
      singular: 'Inch',
      unit: 'in',
      val: value,
    };
  } else {
    distanceBest = convertLength(value)
      .from('in')
      .toBest({
        cutOffNumber: cutOffNumber,
        exclude: ['mil', 'ft-us', 'fathom', 'nMi'],
      });
  }

  return getFormattedWithOption(
    distanceBest,
    value,
    t,
    fractionDigits,
    shouldRound,
  );
}

function getFormattedWithOption(
  result: BestResult<string>,
  original: number,
  t: TFunction<'measures'>,
  fractionDigits: number,
  shouldRound: boolean,
  unitPos: UnitPosition = 'postfix',
): FormattedValueWithUnit {
  const value = result?.val ?? 0;
  const formattedValue = shouldRound
    ? formatInteger(value)
    : value.toFixed(fractionDigits).replace(/\.?0+$/, '');
  const formattedUnit = value === 0 ? '' : t(result?.unit, { ns: 'measures' });
  return {
    value: formattedValue,
    unit: formattedUnit,
    raw: original,
    unitCode: result?.unit,
    fullString:
      unitPos === 'postfix'
        ? `${formattedValue} ${value === 0 ? '' : formattedUnit}`
        : `${formattedUnit} ${formattedValue}`,
  };
}

function formatWeightBestMetric(
  value: number,
  t: TFunction<'measures'>,
  cutOffNumber = 1,
): FormattedValueWithUnit {
  const weightBest = convertMass(value)
    .from('kg')
    .toBest({ cutOffNumber, exclude: ['mcg', 'mg', 'mt'] });
  return getFormatted(weightBest, value, t);
}

function formatWeightBestImperial(
  value: number,
  t: TFunction<'measures'>,
  cutOffNumber = 1,
): FormattedValueWithUnit {
  const weightBest = convertMass(value)
    .from('lb')
    .toBest({ cutOffNumber, exclude: ['t', 'st'] });

  return getFormatted(weightBest, value, t);
}

function formatVolumeBestMetric(
  value: number,
  t: TFunction<'measures'>,
  cutOffNumber = 10,
): FormattedValueWithUnit {
  const volumeBest = convertVolume(value)
    .from('cm3')
    .toBest({
      cutOffNumber: value > cutOffNumber ? cutOffNumber : 1,
      exclude: [
        // 'mm3',
        'ml',
        'l',
        'kl',
        'Ml',
        'Gl',
        'km3',
        'cl',
        'dl',
        'krm',
        'tsk',
        'msk',
        'kkp',
        'glas',
        'kanna',
        'dm3',
      ],
    });
  return getFormatted(volumeBest, value, t);
}

function formatVolumeBestImperial(
  value: number,
  t: TFunction<'measures'>,
  cutOffNumber = 1,
): FormattedValueWithUnit {
  const volumeBest = convertVolume(value)
    .from('in3')
    .toBest({
      cutOffNumber: value > cutOffNumber ? cutOffNumber : 1,
      exclude: ['tsp', 'Tbs', 'fl-oz', 'cup', 'pnt', 'qt', 'gal', 'yd3'],
    });

  return getFormatted(volumeBest, value, t);
}

function formatAreaBestMetric(
  value: number,
  t: TFunction<'measures'>,
  cutOffNumber = 10,
): FormattedValueWithUnit {
  const areaBest = convertArea(value)
    .from('cm2')
    .toBest({
      cutOffNumber: value > cutOffNumber ? cutOffNumber : 1,
      exclude: ['mi2', 'nm2', 'μm2'],
    });
  return getFormatted(areaBest, value, t);
}

function formatAreaBestImperial(
  value: number,
  t: TFunction<'measures'>,
  cutOffNumber = 1,
): FormattedValueWithUnit {
  const areaBest = convertArea(value)
    .from('in2')
    .toBest({
      cutOffNumber: value > cutOffNumber ? cutOffNumber : 1,
      exclude: ['yd2', 'ac'],
    });

  return getFormatted(areaBest, value, t);
}

export function formatTimeBest(
  value: number,
  t: TFunction<'measures'>,
  cutOffNumber = 10,
): FormattedValueWithUnit {
  const timeBest = convertTime(value)
    .from('s')
    .toBest({
      cutOffNumber,
      exclude: ['mu', 'ns', 'd', 'month', 'week', 'year'],
    });
  return getFormatted(timeBest, value, t);
}

export function formatCurrencyBest(
  value: number,
  currencyCode: string,
  cutOffNumber = 0,
): FormattedValueWithUnit {
  if (_.isNil(value) || _.isNaN(value)) return getNull();
  const symbolSettings = getCurrencySymbolSettings(currencyCode);

  return {
    value: formatCurrency(value, currencyCode, cutOffNumber),
    unit: symbolSettings.symbol,
    raw: value,
    unitCode: currencyCode,
    fullString: formatCurrencyFull(value, currencyCode, cutOffNumber),
    prefixUnit: symbolSettings.prefix,
  };
}

export function formatShare(
  value: number,
  t: TFunction<'measures'>,
): FormattedValueWithUnit {
  if (_.isNil(value) || _.isNaN(value)) return getNotApplicable(t);

  const valueString = formatToPrecision(value * 100, 2, 5, 5);
  return {
    value: valueString,
    unit: '%',
    raw: value,
    unitCode: '%',
    fullString: `${valueString}%`,
  };
}

export function formatDistanceBest(
  value: number,
  system: MeasurementSystem,
  t: TFunction<'measures'>,
  cutOffNumber = 1,
  fractionDigits = 1,
  shouldRound = true,
) {
  if (_.isNil(value) || _.isNaN(value)) return getNotApplicable(t);

  switch (system) {
    case MeasurementSystem.METRIC:
      return formatDistanceBestMetric(
        value,
        t,
        cutOffNumber,
        fractionDigits,
        shouldRound,
      );
    case MeasurementSystem.IMPERIAL:
      return formatDistanceBestImperial(
        value,
        t,
        cutOffNumber,
        fractionDigits,
        shouldRound,
      );
  }
}

export function formatWeightBest(
  value: number,
  system: MeasurementSystem,
  t: TFunction<'measures'>,
  cutOffNumber?: number,
) {
  if (_.isNil(value) || _.isNaN(value)) return getNotApplicable(t);
  switch (system) {
    case MeasurementSystem.METRIC:
      return formatWeightBestMetric(value, t, cutOffNumber);
    case MeasurementSystem.IMPERIAL:
      return formatWeightBestImperial(value, t, cutOffNumber);
  }
}

export function formatVolumeBest(
  value: number,
  system: MeasurementSystem,
  t: TFunction<'measures'>,
) {
  if (_.isNil(value) || _.isNaN(value)) return getNotApplicable(t);
  switch (system) {
    case MeasurementSystem.METRIC:
      return formatVolumeBestMetric(value, t);
    case MeasurementSystem.IMPERIAL:
      return formatVolumeBestImperial(value, t);
  }
}

export function formatAreaBest(
  value: number,
  system: MeasurementSystem,
  t: TFunction<'measures'>,
) {
  if (_.isNil(value) || _.isNaN(value)) return getNotApplicable(t);
  switch (system) {
    case MeasurementSystem.METRIC:
      return formatAreaBestMetric(value, t);
    case MeasurementSystem.IMPERIAL:
      return formatAreaBestImperial(value, t);
  }
}

export function formatCO2Best(value: number, t: TFunction<'measures'>) {
  if (_.isNil(value) || _.isNaN(value)) return getNotApplicable(t);
  const unit = t('kgCO2', { ns: 'measures' });
  return {
    value: formatInteger(value),
    unit,
    raw: value,
    unitCode: 'kgCO2',
    fullString: `${formatInteger(value)} ${unit}`,
  };
}

export function formatTimespanAs24String(
  seconds: number,
): FormattedValueWithUnit {
  const value = new Date(seconds * 1000).toISOString().substr(11, 8);
  return {
    raw: seconds,
    value,
    fullString: value,
    unit: '',
    unitCode: '',
  };
}

export function formatTimespan(
  msEnd: number,
  msStart?: number,
  full?: boolean,
): string {
  const duration = intervalToDuration({ start: msStart ?? 0, end: msEnd });

  // Convert days to hours for total hours calculation
  const totalHours = (duration.days ?? 0) * 24 + (duration.hours ?? 0);
  const totalMinutes = totalHours * 60 + (duration.minutes ?? 0);

  let formatArray: Array<'days' | 'hours' | 'minutes' | 'seconds'>;

  if (totalHours >= 24) {
    // For durations longer than a day, use days and hours
    formatArray = ['days', 'hours'];
  } else if (totalMinutes >= 60) {
    // For durations of an hour or more but less than a day, use hours and minutes
    formatArray = ['hours', 'minutes'];
  } else if (totalMinutes >= 1) {
    // For durations of a minute or more but less than an hour, use minutes only
    formatArray = ['minutes'];
  } else {
    // For durations less than a minute, use seconds only
    formatArray = ['seconds'];
  }

  return formatDuration(duration, {
    locale: enGB,
    format: full ? null : formatArray,
    zero: false,
  });
}

export function formatShortenedTimespan(msEnd: number, msStart?: number) {
  const duration = intervalToDuration({ start: msStart ?? 0, end: msEnd ?? 0 });

  const durationHours = {
    ...duration,
    hours: (duration.days ?? 0) * 24 + (duration.hours ?? 0), // Assuming intervals are not more than a week
  };

  // Custom formatting to 'hr', 'min', 'sec'
  const formatted = [
    durationHours.hours ? `${durationHours.hours} hr` : '',
    durationHours.minutes ? `${durationHours.minutes} min` : '',
    durationHours.seconds ? `${durationHours.seconds} sec` : '',
  ]
    .filter(Boolean)
    .join(' ');

  return formatted || '0sec';
}

export function formatDurationHours(msEnd: number, msStart?: number) {
  const duration = intervalToDuration({ start: msStart ?? 0, end: msEnd ?? 0 });

  return formatDuration(duration, {
    delimiter: '-',
    locale: enGB,
    format: ['days', 'hours', 'minutes'],
    zero: false,
  });
}

export function formatDurationHoursShort(
  t: TFunction<'measures'>,
  msEnd: number,
  msStart?: number,
) {
  if (_.isNil(msEnd) || Number.isNaN(msEnd)) return '';
  const durationRaw = (msEnd ?? 0) - (msStart ?? 0);
  if (durationRaw === 0) return '0';
  const duration = intervalToDuration({ start: msStart ?? 0, end: msEnd ?? 0 });
  const hours =
    ((duration.weeks ?? 0) * 7 + duration.days) * 24 + duration.hours;
  const housePart = hours > 0 ? t(`{{hours}}h`, { ns: 'measures', hours }) : '';
  const minutesPart =
    duration.minutes > 0
      ? t(`{{mins}}m`, { ns: 'measures', mins: duration.minutes })
      : '';

  const combined = _.join(
    [housePart, minutesPart].filter(s => !_.isEmpty(s)),
    ':',
  );
  return _.isEmpty(combined) ? t(`< 1m`, { ns: 'measures' }) : combined;
}

export function formatRemaining(msEnd: number) {
  const duration = intervalToDuration({ start: new Date(), end: msEnd ?? 0 });

  const durationHours: Duration = {
    ...duration,
    hours: (duration.days ?? 0) * 24 + (duration.hours ?? 0), //INFO: we do not expect intervals more than  week
  };
  return formatDuration(durationHours, {
    locale: enGB,
    format: ['days'],
    zero: false,
  });
}

export function formatTimeAgo(
  date: Date,
  options?: { includeSeconds?: boolean; includeMinutes?: boolean },
) {
  const { includeSeconds = false, includeMinutes = false } = options || {};
  return formatDistanceToNow(date, {
    addSuffix: true,
    includeSeconds: includeSeconds,
    ...(includeMinutes && { unit: 'minute' }),
  });
}

export function formatTimeAgoShort(dateStart: Date) {
  return formatDistanceToNow(dateStart, {
    locale: enGB,
    addSuffix: false,
    includeSeconds: false,
  });
}

export function formatRemainingDateTime(dateStart: Date) {
  return formatDistanceToNow(dateStart, {
    locale: enGB,
    addSuffix: false,
    includeSeconds: false,
  });
}

export function formatTime(d: Date) {
  return d.toLocaleTimeString([], {
    timeStyle: 'short',
  });
}
export function formatHours(d: Date) {
  return d.toLocaleTimeString([], {
    // timeStyle: 'short',
    hour: 'numeric',
  });
}

export function getWeekdayShort(weekday: number) {
  const sunday = new Date(3 * 3600 * 1000 * 24);
  const d = addDays(sunday, weekday);
  return d.toLocaleString(locale, { weekday: 'short' });
}

export function formatDateTimeShort(v: Date | number): string {
  if (_.isNil(v)) return '';
  const d = _.isNumber(v) ? new Date(v) : v;
  return (
    d?.toLocaleString(locale, {
      dateStyle: 'medium',
      timeStyle: 'short',
    }) ?? ''
  );
}

export function formatDigital(
  value: number,
  t: TFunction<'measures'>,
  cutOffNumber = 100,
): FormattedValueWithUnit {
  const volumeBest = convertDigital(value).from('B').toBest({
    cutOffNumber,
  });
  return getFormatted(volumeBest, value, t);
}
