export const codesnippet = {
  render: 'CodeSnippet',
  attributes: {
    type: {
      type: String,
      default: 'sql',
      matches: ['sql', 'json'],
    },
    title: {
      type: String,
    },
    className: { type: String },
    children: { type: String, required: true },
    code: { type: String },
  },
};
