import { AnalyzeResultEventTypeSummary } from '@warebee/shared/engine-model';
import _ from 'lodash';
import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useRecoilValue } from 'recoil';
import { getBestContrastColor } from '../../common/color.helper';
import { formatPercent } from '../../common/formatHelper';
import useFormatter from '../../common/useFormatter';
import ButtonSwitchMulti from '../../components/actions/ButtonSwitchMulti';
import { HelperLearnMore } from '../../components/layout/HelperLearnMore';
import { Stat } from '../../components/stats/Stat';
import { StatGroup } from '../../components/stats/StatGroup';
import StatItem from '../../components/stats/StatItem';
import { StatListItem } from '../../components/stats/StatListItem';
import Callout from '../../helpContext/Callout';
import { warehouseSelected } from '../../store/warehouse.state';
import {
  EventGroup,
  EventGroupGlobalMap,
  EventGroupKeys,
  EventGroupMap,
  KPI,
  eventGroupGlobalOrder,
  getEventGroupColor,
  getEventGroupGlobalTitle,
  getEventGroupTitle,
  getKPITitles,
} from '../store/simulation.helper';
import ContainerChart from './ContainerChart';
import {
  EventGroupGlobalValues,
  EventGroupValues,
} from './OptimisationEventsComparePanel';
import SimulationPieChart, { PieData } from './SimulationPieChart';

export type EventsDetailTotal = Partial<Record<KPI, number>>;

export type EventsDetailsPanelBaseProps = {
  title?: string;
  subTitle?: string;
  totalTitle?: string;
  showTotalStats?: boolean;
  showLegendStats?: boolean;
  showKpiSwitch?: boolean;
  cardView?: boolean;
  dashboardSimulationView?: boolean;
  hasInsight?: boolean;
};

export type AnalyzeEventsDetailsPanelProps = EventsDetailsPanelBaseProps & {
  events: AnalyzeResultEventTypeSummary[];
  eventsTotal: EventsDetailTotal;
};

const AnalyzeEventsDetailsPanel: React.FC<
  AnalyzeEventsDetailsPanelProps
> = props => {
  const { t } = useTranslation('simulation');
  const wh = useRecoilValue(warehouseSelected);
  const systemCurrency =
    wh.currency === 'USD'
      ? `$`
      : wh.currency === 'GBP'
        ? `£`
        : wh.currency === 'EUR'
          ? `€`
          : `$`;

  const [kpi, setKpi] = useState<KPI>('cost');
  const formatter = useFormatter();

  const KPIs: KPI[] = ['cost', 'duration', 'co2'];

  function getFormatter(kpi: KPI) {
    switch (kpi) {
      case 'cost':
        return formatter.formatCurrency;
      case 'duration':
        return formatter.formatTimeSpan;
      case 'co2':
        return formatter.formatCO2;

      default:
        return formatter.formatCurrency;
    }
  }

  function getEventKPIValue(event: AnalyzeResultEventTypeSummary, kpi: KPI) {
    switch (kpi) {
      case 'cost':
        return event.totalCost;
      case 'duration':
        return event.totalDuration;
      case 'co2':
        return 0;
      default:
        return 0;
    }
  }

  const kpiTitle = getKPITitles(kpi, t);

  const eventsGlobalGroups: EventGroupGlobalValues = _.reduce(
    props.events,
    (acc, event) => ({
      ...acc,
      [EventGroupGlobalMap[event.eventType]]:
        (acc[EventGroupGlobalMap[event.eventType]] ?? 0) +
        getEventKPIValue(event, kpi),
    }),
    {} as EventGroupGlobalValues,
  );
  const eventsTotal = _.sum(_.values(eventsGlobalGroups));
  const eventsGroups = _.reduce(
    props.events,
    (acc, event) => {
      return {
        ...acc,
        [EventGroupMap[event.eventType]]:
          (acc[EventGroupMap[event.eventType]] ?? 0) +
          (getEventKPIValue(event, kpi) ?? 0),
      };
    },
    {} as EventGroupValues,
  );

  const totalCost = formatter.formatCurrency(props.eventsTotal.cost);
  const totalDuration = formatter.formatTimeSpan(props.eventsTotal.duration);

  const formatFn = getFormatter(kpi);

  const kpiSwitch = props.showKpiSwitch ? (
    <ButtonSwitchMulti
      autoSize
      // className="text-xs"
      options={[
        {
          label: t`Cost`,
          icon: systemCurrency,
        },
        {
          label: t`Time`,
          icon: 'h',
        },
        // {
        //   label: t`CO2`,
        //   icon: 'CO2',
        // },
      ]}
      selectedIndex={KPIs.indexOf(kpi)}
      onClick={index => {
        setKpi(KPIs[index]);
      }}
    />
  ) : null;

  return (
    <StatGroup
      title={props.title}
      subTitle={props.subTitle ?? t`Stats`}
      hasHelper
      cardView={props.cardView}
      helpNavTo={'simulation/analyse/simulation-analyse'}
    >
      {props.showTotalStats && (
        <Stat
          dashboardSimulationView={props.dashboardSimulationView}
          cardView={props.cardView}
          hasHelper
          isPreview
          title={props.totalTitle}
          inPanelMode
        >
          <StatListItem
            title={t`Cost`}
            value={totalCost.value}
            unitOfMeasure={totalCost.unit}
            isUnitPrefix={totalCost.prefixUnit}
          />
          <StatListItem
            title={t`Time`}
            value={totalDuration.value}
            unitOfMeasure={totalDuration.unit}
          />
        </Stat>
      )}
      <Stat
        dashboardSimulationView={props.dashboardSimulationView}
        cardView={props.cardView}
        className={'w-full'}
        hasHelper
        isPreview
        title={kpiTitle}
        inPanelMode
        switchUnitOfMeasure={kpiSwitch}
      >
        <ContainerChart containerMaxHeight={200}>
          <div className="absolute top-0 isolate flex h-full w-full flex-1 flex-col items-center justify-center text-center ltr:left-0 rtl:right-0">
            {_.map(eventGroupGlobalOrder, (key, index) => {
              const eventGroupValue = eventsGlobalGroups[key];
              const value =
                eventGroupValue > 0 ? eventGroupValue / eventsTotal : 0;
              return (
                <React.Fragment key={`global-event-group-${key}`}>
                  {index > 0 && (
                    <hr className="border-menu-300 my-2 w-28 border border-opacity-20 xl:w-32" />
                  )}
                  <div className="">
                    <div className="text-minimal lg:text-xxs uppercase">
                      {getEventGroupGlobalTitle(key, t)}
                    </div>
                    <div className="text-xl lg:text-2xl">
                      {formatPercent(value)}
                    </div>
                  </div>
                </React.Fragment>
              );
            })}
          </div>
          <SimulationPieChart
            data={_(EventGroupKeys)
              .filter(key => eventsGroups[key] > 0)
              .map((key: EventGroup) => {
                const value = eventsGroups[key];
                const dataItem: PieData = {
                  id: key,
                  label: getEventGroupTitle(key, t),
                  color: [
                    getEventGroupColor(key),
                    getBestContrastColor(getEventGroupColor(key)),
                  ],
                  value,
                  formatted: _.isNil(value) ? null : formatFn(value),
                };
                return dataItem;
              })
              .value()}
          />
        </ContainerChart>
        {props.showLegendStats &&
          _(EventGroupKeys)
            .filter(key => eventsGroups[key] > 0)
            .map((key: EventGroup) => (
              <StatItem
                key={`event-group-${key}`}
                title={getEventGroupTitle(key, t)}
                value={formatFn(eventsGroups[key])}
                legendColor={getEventGroupColor(key)}
              />
            ))
            .value()}

        {!props.cardView && props.hasInsight && (
          <Callout panelMode type="suggestion">
            <div>
              <span className="text-menu-text/60 ltr:mr-1 rtl:ml-1">{t`Insight:`}</span>
              {t`The travelling percent in this warehouse is very low relative to picking`}
            </div>
            <HelperLearnMore title={t`Learn More...`} />
          </Callout>
        )}
      </Stat>
    </StatGroup>
  );
};

export default AnalyzeEventsDetailsPanel;
