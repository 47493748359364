import {
  ImportJobImportType,
  ImportJobMappingSettings,
  ImportJobSourceSettings,
  MappingErrorFragment,
  UpdateSimulationInput,
} from '@warebee/frontend/data-access-api-graphql';
import {
  MappingSchema,
  MappingSchemaField,
  MappingValueResolver,
} from '@warebee/shared/import-converter';
import { ParseError } from 'papaparse';
import { HTMLAttributes } from 'react';
import { AsyncLoadStatus, WizardStepConfig } from '../../common/types';
import { HelpIndex } from '../../helpContext/help.default';
import { ImportRootProps } from '../ImportRoot';

export type DatasetType =
  | 'layout'
  | 'convertedLayout'
  | 'assignment'
  | 'orders'
  | 'items'
  | 'assignmentPolicy'
  | 'activityFeed'
  | 'dataset';

export const ImportTypeToJobTypeMap: Record<DatasetType, ImportJobImportType> =
  {
    layout: ImportJobImportType.LAYOUT,
    assignment: ImportJobImportType.ASSIGNMENT,
    orders: ImportJobImportType.ORDER_SET,
    items: ImportJobImportType.ITEM_SET,
    assignmentPolicy: ImportJobImportType.ASSIGNMENT_POLICY,
    activityFeed: ImportJobImportType.ACTIVITY_FEED,
    convertedLayout: ImportJobImportType.CONVERTED_LAYOUT,
    dataset: ImportJobImportType.GENERIC,
  };

export type ImportMenuItemId =
  | 'import-getting-started'
  | 'import-select-csv'
  | 'import-transform'
  | 'import-map-fields'
  | 'import-upload-data';

export type AutoImportStage =
  | 'initializing'
  | 'pipeline-loaded'
  | 'create-job'
  | 'upload-file'
  | 'process-job';

export type ImportParsingSettings = {
  hasHeader: boolean;
};

export type ParsedFilePreview = {
  fields: string[];
  data: Record<string, string>[];
  errors?: string[];
};

export type ParsedFile = {
  file: File;
  headerRow: string[];
  preview: string[][];
  errors: ParseError[];
  rowCount: number;
  //
  previewObject: Record<string, string>[];
  transformedPreview?: Record<string, string>[];
};

export type ImportStepContainerProps = ImportRootProps & {};

export type OperationProgress = {
  progress: number;
};

export type ImportMappingPanelProps = {
  canUpload: boolean;
  onUploadClick: () => void;
};

export type ImportMenuItem = WizardStepConfig<ImportMenuItemId>;

export type MappingFieldSettings<T = object> = MappingSchemaField<T> & {
  title: string;
  description?: string;
  contextHelpId?: HelpIndex;
  /**
   * Set of alternative field names for initial auto-mapping
   * Values should be lowercased
   */
  aliases?: string[];
  valueResolver?: MappingValueResolver<T[keyof T]>;
  columnName?: string;
  example?: string;
  queryHelper?: string[];
  uniqErrorCount?: number;
  totalErrorCount?: number;
  rowNumber?: number;
  mappingType?: 'column' | 'default';
};

export type MappingFieldSettingKey = keyof MappingFieldSettings<Object>;
export const mappingFieldSearchable = ['title'] as const;
export type MappingFieldSearchable = (typeof mappingFieldSearchable)[number];

export type MappingSettings<T = object> = Omit<
  ImportJobMappingSettings,
  'mapping'
> & {
  fields: MappingFieldSettings<T>[];
};

export type MappingSettingsMutablePart<T = object> = Omit<
  ImportJobMappingSettings,
  'mapping'
> & {
  fields: Pick<MappingFieldSettings<T>, 'name' | 'aliases' | 'measureValue'>[];
};

export type ImportSettingsMap = Record<
  string,
  Partial<Record<DatasetType, MappingSettings<object>>>
>;

export type ImportSettingsMutableMap = Record<
  string,
  Partial<Record<DatasetType, MappingSettingsMutablePart<object>>>
>;

export type DatasetConfiguration = {
  dataType: DatasetType;
  jobType: ImportJobImportType;
  title: string;
  description?: string;
  icon?: React.FC<HTMLAttributes<Element>>;
  actionTitleShowData?: string;
  actionName: string;
  actionNameLink?: string;
  filenameTemplate: string;
  importTemplateVersion?: string;
  mappingSettingsDefault: MappingSettings<object>;
  schema: MappingSchema<object>;
  getTargetPath?: (wh: string, id: string) => string;
  getDataTablePath?: (wh: string, id: string) => string;
  getDashboardPath?: (wh: string) => string;
  getIntegrationPath?: (wh: string) => string;
  getImportPath?: (wh: string) => string;
  getSimulationChanges?: (id: string) => Partial<UpdateSimulationInput>;
};

export type UploadChunkStatus = UploadStatus & {
  id: string;
  etag?: string;
};

export type UploadStatus = {
  jobId: string;
  status: AsyncLoadStatus;
  loaded?: number;
  total?: number;
  errors?: any[];
};

export type ValidationError = MappingErrorFragment & {
  fieldTitle: string;
  csvColumn: string;
  canResolve: boolean;
};

export type MappingPreviewViewAs = 'data' | 'errors-grouped' | 'errors-all';

export type ImportSourceSettings = Pick<
  ImportJobSourceSettings,
  'format' | 'compression'
>;
