import { ImportPipelineConnectorType } from '@warebee/frontend/data-access-api-graphql';
import classNames from 'classnames';
import _ from 'lodash';
import { nanoid } from 'nanoid';
import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useRecoilState, useRecoilValue, useSetRecoilState } from 'recoil';
import { formatInteger } from '../../common/formatHelper';
import { cloneWithoutTypename } from '../../common/utils';
import LoadingIndicator from '../../components/LoadingIndicator';
import * as Icon from '../../components/icons';
import { Container } from '../../components/layout/ContainerFlex';
import { ScreenTitle } from '../../components/layout/ScreenTitle';
import TitleSection from '../../components/layout/TitleSection';
import { Stat } from '../../components/stats/Stat';
import { StatGroup } from '../../components/stats/StatGroup';
import {
  warehouseCanUpdate,
  warehouseSelected,
} from '../../store/warehouse.state';
import { WarehouseExtraSettings } from '../../store/warehouse.types';
import useUpdateWarehouseExtraSettings from '../../warehouse/hooks/useUpdateWarehouseExtraSettings';
import PipelineFileUploader from '../ImportPipelineFileUploader';
import useUpdateImportPipeline from '../hooks/useUpdateImportPipeline';
import {
  connectorNewDatasetKey,
  connectorNewSFTPKey,
} from '../store/importPipeline.helper';
import {
  importPipelineIsActiveEditor,
  importPipelineSelected,
  importPipelineSelectedConnectorId,
  importPipelineSelectedId,
  importPipelineUpdateStatus,
  importPipelinesDataTableState,
} from '../store/importPipelines.state';
import ImportPipelineSettingsPanel from './ImportPipelineSettingsPanel';

const ImportPipelineViewPanel: React.FC = () => {
  const { t } = useTranslation('dataset');

  const pipeline = useRecoilValue(importPipelineSelected);
  const wh = useRecoilValue(warehouseSelected);
  const whExtraSettings: WarehouseExtraSettings = wh?.extraSettings;

  const setSelectedId = useSetRecoilState(importPipelineSelectedId);
  const setConnectorId = useSetRecoilState(importPipelineSelectedConnectorId);
  const [tableState, setTableState] = useRecoilState(
    importPipelinesDataTableState,
  );
  const updateStatus = useRecoilValue(importPipelineUpdateStatus);
  const setShowEditor = useSetRecoilState(importPipelineIsActiveEditor);

  const updatePipeline = useUpdateImportPipeline();

  const [query, setQuery] = useState(
    pipeline?.settings?.mappingSettings?.transformation?.query,
  );
  const [updateWhExtraSettings] = useUpdateWarehouseExtraSettings();

  const canUpdate = useRecoilValue(warehouseCanUpdate);
  const connectorId = useRecoilValue(importPipelineSelectedConnectorId);

  const [genLoading, setGenLoading] = useState(false);
  const [token, setToken] = useState<string>(null);

  const [isLoading, setIsLoading] = useState(false);
  const [error, setError] = useState<string>(null);

  const isDefaultPipeline =
    pipeline &&
    pipeline?.id === whExtraSettings.defaultPipelines?.[pipeline?.importType];

  function addSftpConnector() {
    setConnectorId(connectorNewSFTPKey);
    setShowEditor(true);
  }
  function addDatasetConnector() {
    setConnectorId(connectorNewDatasetKey);
    setShowEditor(true);
  }

  function removeConnectorById(id: string) {
    const connectors = _(pipeline.settings?.integrationSettings?.connectors)
      .filter(c => c.id !== id)
      .map(cloneWithoutTypename)
      .value();

    updatePipeline({
      importPipelineId: pipeline.id,
      settings: {
        ...pipeline.settings,
        integrationSettings: {
          connectors,
        },
      },
    });
  }

  function removeAllConnectorsByType(type: ImportPipelineConnectorType) {
    const connectors = _(pipeline.settings?.integrationSettings?.connectors)
      .filter(c => c.type !== type)
      .map(cloneWithoutTypename)
      .value();

    updatePipeline({
      importPipelineId: pipeline.id,
      settings: {
        ...pipeline.settings,
        integrationSettings: {
          connectors,
        },
      },
    });
  }

  function addConnector(type: ImportPipelineConnectorType) {
    const connectors = _.map(
      pipeline.settings?.integrationSettings?.connectors,
      cloneWithoutTypename,
    );
    connectors.push({
      id: nanoid(10),
      type,
      enabled: true,
    });

    updatePipeline({
      importPipelineId: pipeline.id,
      settings: {
        ...pipeline.settings,
        integrationSettings: {
          connectors,
        },
      },
    });
  }

  function setDefaultPipelineSettings(id: string) {
    updateWhExtraSettings({
      patch: {
        defaultPipelines: {
          ...(whExtraSettings.defaultPipelines ?? {}),
          [pipeline.importType]: id,
        },
      },
    });
  }

  const connectors = pipeline?.settings?.integrationSettings?.connectors;

  // Connector: S3 (Push)
  const sftpConnectors = _.filter(
    connectors,
    c => c.type === ImportPipelineConnectorType.SFTP_PULL,
  );
  const hasSftpConnector = !_.isEmpty(sftpConnectors);

  const datasetConnectors = _.filter(
    connectors,
    c => c.type === ImportPipelineConnectorType.DATASET_CHANGED,
  );
  const hasDatasetConnector = !_.isEmpty(datasetConnectors);

  // Connector: S3 (Push)
  const s3Connectors = _.filter(
    connectors,
    c => c.type === ImportPipelineConnectorType.S3_PUSH,
  );
  const hasS3Connector = !_.isEmpty(s3Connectors);

  // Connector: Api (Push)
  const apiConnectors = _.filter(
    connectors,
    c => c.type === ImportPipelineConnectorType.API_PUSH,
  );
  const hasApiConnector = !_.isEmpty(apiConnectors);

  // Connector: Email (Push)
  const emailConnectors = _.filter(
    connectors,
    c => c.type === ImportPipelineConnectorType.EMAIL_PUSH,
  );
  const hasEmailConnector = !_.isEmpty(emailConnectors);

  // const styleApiText = classNames(
  //   'font-mono',
  //   // '!bg-app-panel-light/75',
  //   'text-menu-text',
  //   'text-start',
  //   'break-all',
  //   'select-text',
  //   // 'py-4 px-2',
  //   'w-full',
  //   'shadow',
  //   'rounded',
  // );

  if (_.isNil(pipeline)) {
    return <LoadingIndicator selfCenter message={t`Loading...`} />;
  }

  return (
    <Container
      key={`import-pipeline-view-panel-${pipeline.id}`}
      data-component="ImportPipelineViewPanel"
      col
      hasOverflowY
    >
      <ScreenTitle
        title={pipeline.title}
        subtitle={t`Pipeline`}
        isSticky
        helpNavTo={'importer/importer'}
        icon={Icon.ImportTransform}
      />

      <StatGroup horizontal className="!m-0" classNameStatGroupItems="!p-0.5">
        <Stat
          hasHelper
          isPreview
          title={t`Job Stats`}
          value={formatInteger(pipeline.jobs?.totalCount)}
          isFullWidth
          inPanelMode
        />
      </StatGroup>
      <TitleSection
        id={`pipeline-setting-file-upload`}
        title={t`Pipeline Uploader`}
        inPanelView
        className={classNames('z-400 top-12 xl:top-20')}
        hasScreenTitle
      >
        <PipelineFileUploader pipelineId={pipeline.id} />
      </TitleSection>
      <ImportPipelineSettingsPanel />
    </Container>
  );
};
export default ImportPipelineViewPanel;
