import { useApolloClient } from '@apollo/client';
import {
  findFilterValuesExpr,
  FindFilterValuesRequest,
} from '@warebee/shared/athena-db-model';
import { SelectQueryBuilder } from 'kysely';
import _ from 'lodash';
import { nanoid } from 'nanoid';
import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useRecoilCallback } from 'recoil';
import { POLICY_FILTER_VALUES_PAGE_SIZE } from '../common/constants';
import { AsyncLoadStatus } from '../common/types';
import { getActualityFilterDescSet } from '../feed/store/actuality.hq.helper';
import { executeDatasetQuery } from '../feed/store/feed.helper';
import {
  policyFilterDataAtom,
  policyFiltersSearchValues,
} from '../simulation/store/policyFilter.state';
import { errorAppender } from '../store/error.state';
import { warehouseSelectedId } from '../store/warehouse.state';
import {
  getPolicyFilterDataKeysByEditorType,
  getPolicyFilterKey,
} from './policyFilter.helper';
import { LoadAllPolicyFilterValuesParams } from './policyFilter.types';

export type LoadGenericFilterValuesParams<FT extends string> =
  LoadAllPolicyFilterValuesParams<FT> & {
    builder: SelectQueryBuilder<any, any, any>;
  };

function needUpdate<FT extends string>(
  t: FT,
  params: LoadGenericFilterValuesParams<FT>,
): boolean {
  switch (params.affect) {
    case 'self':
      return t === params.field;
    case 'others':
      return t !== params.field;
    case 'all':
      return true;
  }
}

function getFilterKeys<FT extends string>(
  params: LoadGenericFilterValuesParams<FT>,
): { key: string; type: FT }[] {
  return _(params.filterConfigs)
    .filter(cfg => needUpdate(cfg.type, params))
    .map(cfg => {
      const keyParts = getPolicyFilterDataKeysByEditorType(cfg.editorType);
      return _.map(keyParts, key => ({
        key: getPolicyFilterKey(cfg.type, key),
        type: cfg.type,
      }));
    })
    .flatten()
    .value();
}

function useLoadGenericFilterValues<FT extends string>() {
  const client = useApolloClient();
  const { t } = useTranslation('errors');
  const errorTitle = t`Cannot load Actuality filter values.`;

  const [observable, setObservable] = useState<ZenObservable.Subscription>();

  const initLoading = useRecoilCallback(
    ({ snapshot, set }) =>
      async (params: LoadGenericFilterValuesParams<FT>) => {
        const current = await snapshot.getPromise(policyFilterDataAtom);
        const keysToUpdate = getFilterKeys(params);

        const filterData = _.reduce(
          keysToUpdate,
          (acc, pair) => {
            return {
              ...acc,
              [pair.key]: {
                ...(acc?.[pair.key] ?? {}),
                status: AsyncLoadStatus.Loading,
              },
            };
          },
          current,
        );

        set(policyFilterDataAtom, filterData);
      },
  );

  const loadFilterValues = useRecoilCallback(
    ({ snapshot, set }) =>
      async (params: LoadGenericFilterValuesParams<FT>) => {
        const warehouseId = await snapshot.getPromise(warehouseSelectedId);

        const searchValues = await snapshot.getPromise(
          policyFiltersSearchValues,
        );

        const current = await snapshot.getPromise(policyFilterDataAtom);

        function handleError(details, stack) {
          set(errorAppender, {
            id: nanoid(),
            title: errorTitle,
            details: details,
            callStack: stack,
          });
          const keysToUpdate = getFilterKeys(params);

          const filterData = _.reduce(
            keysToUpdate,
            (acc, pair) => {
              return {
                ...acc,
                [pair.key]: {
                  totalCount: 0,
                  content: [],
                  status: AsyncLoadStatus.Error,
                },
              };
            },
            current,
          );

          set(policyFilterDataAtom, filterData);
          return;
        }

        const keysToUpdate = getFilterKeys(params);
        const filterInputs: FindFilterValuesRequest<FT>[] = _.map(
          keysToUpdate,
          pair => {
            const filterInput: FindFilterValuesRequest<FT> = {
              filterType: pair.type,
              query: searchValues[pair.key],
              page: {
                limit: params.limit || POLICY_FILTER_VALUES_PAGE_SIZE,
                skip: params.skip || 0,
              },
            };
            return filterInput;
          },
        );

        const [filterBuilder1, expr1] = findFilterValuesExpr(
          params.builder,
          getActualityFilterDescSet(),
          filterInputs as any,
          //getActualityFilterValuesRequest(),
        );

        const compiledQueryTotal = filterBuilder1
          .select(expr1.as('result'))
          .compile();

        const queryResult = await executeDatasetQuery({
          warehouseId,
          compiledQuery: compiledQueryTotal,
          comment: '[filters] Generic Filter Values',
        });

        const filterValues = JSON.parse(_.head(queryResult)['result']);

        const filtersData = _.reduce(
          keysToUpdate,
          (acc, pair, index) => {
            const filterData = filterValues[index];
            const values = filterData?.content;
            return {
              ...acc,
              [pair.key]: {
                totalCount: filterData.totalCount,
                status: AsyncLoadStatus.Ok,
                content: params.isAppend
                  ? [...(acc?.[pair.key]?.content ?? []), ...values]
                  : values,
              },
            };
          },
          current,
        );

        set(policyFilterDataAtom, filtersData);

        // const queryObservable = query.subscribe(
        //   ({ data, errors }) => {
        //     if (errors) {
        //       console.error(errors);
        //       handleError(null, errors.map(e => e.message).join('. '));
        //       return;
        //     }

        //     const filterValues = data.layout?.locationFilterValues;

        //   },
        //   error => {
        //     console.error(error);
        //     handleError(error.message || error, error.stack || null);
        //   },
        // );
        // setObservable(queryObservable);
      },
  );

  async function call<FT extends string>(
    params: LoadGenericFilterValuesParams<FT>,
  ) {
    await initLoading(params as any);
    await loadFilterValues(params as any);
  }

  function cancel() {
    observable?.unsubscribe();
  }

  return [call, cancel] as const;
}

export default useLoadGenericFilterValues;
