import _ from 'lodash';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { useRecoilState, useRecoilValue } from 'recoil';
import { Container } from '../../components/layout/ContainerFlex';
import { SidebarMenuItem } from '../../components/nav/SidebarMenuItem';
import SidebarContainer, {
  SidebarContainerProps,
} from '../../containers/SidebarContainer';
import { importMenuItemsDefault } from '../store/import.default';
import {
  importMenuEnabledItemsIds,
  importSelectedStep,
} from '../store/import.state';

type SidebarImporterMenuWizardProps = {
  isMinimised: boolean;
};

const SidebarImportMenuWizard: React.FC<
  SidebarImporterMenuWizardProps
> = props => {
  const { t } = useTranslation('app');
  const enabledMenuItems = useRecoilValue(importMenuEnabledItemsIds);
  const [activeMenuId, setActiveMenuId] = useRecoilState(importSelectedStep);

  const menuItems = _.map(importMenuItemsDefault(t), i => ({
    ...i,
    disabled: !enabledMenuItems.has(i.id),
  }));

  return (
    <Container col hasOverflowY>
      <ul
        data-component="SidebarImporterMenuWizard"
        className={`relative flex flex-col px-1 text-start`}
      >
        {menuItems.map(item => {
          return (
            <SidebarMenuItem
              hasWizardArrows={
                !item.treeLineStart || !item.treeLineEnd || item.hasWizardArrows
              }
              isMinimised={props.isMinimised}
              key={item.id}
              menuLevel={item.menuLevel.toString()}
              title={item.title}
              titleMinimised={item.titleMinimized}
              icon={props.isMinimised && item.icon}
              active={item.id === activeMenuId}
              treeLine={item.treeLine}
              treeLineStart={item.treeLineStart}
              treeLineEnd={item.treeLineEnd}
              hasMenuItems={item.hasMenuItems}
              //inProgress={status?.inProgress}
              stepCounter={item.stepCounter}
              disabled={item?.disabled}
              isHidden={item?.isHidden}
              //locked={status?.isLocked}
              onClick={() => setActiveMenuId(item.id)}
            />
          );
        })}
      </ul>
    </Container>
  );
};

export default () => {
  const { t } = useTranslation('importer');

  const containerProps: SidebarContainerProps = {
    type: 'sidebar-importer-menu',
    title: t('Import Wizard Menu'),
    size: 'xs',
  };
  return (
    <SidebarContainer
      key={containerProps.type}
      {...containerProps}
      widthAutoMinimised
      withMenuIcons={<SidebarImportMenuWizard isMinimised={true} />}
    >
      <SidebarImportMenuWizard isMinimised={false} />
    </SidebarContainer>
  );
};
