import classNames from 'classnames';
import _ from 'lodash';
import React, { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import { useRecoilState, useRecoilValue, useSetRecoilState } from 'recoil';
import { assignmentFilterState } from '../../assignment/store/assignment.state';
import { AsyncLoadStatus } from '../../common/types';
import useChangeSidebarState from '../../common/useChangeSidebarState';
import ErrorIndicator from '../../components/ErrorIndicator';
import LoadingIndicator from '../../components/LoadingIndicator';
import ButtonSwitchMulti from '../../components/actions/ButtonSwitchMulti';
import ButtonToolbar from '../../components/actions/ButtonToolbar';
import DropdownSelector from '../../components/actions/DropdownSelector';
import * as Icon from '../../components/icons';
import { DatasetNavigation } from '../../components/importer/DatasetActionHelpers';
import { ScreenTitle } from '../../components/layout/ScreenTitle';
import Tag from '../../helpContext/Tag';
import { importTriggeredBySim } from '../../store/global.state';
import {
  warehouseAllAssignmentsMeta,
  warehouseCanUpdate,
  warehouseSelectedId,
} from '../../store/warehouse.state';
import { useLoadAllAssignmentsMeta } from '../../warehouse/hooks/useLoadAllAssignmentsMeta';
import {
  HelperWidgetImportInitialSetup,
  HelperWidgetImportLocked,
  HelperWidgetImportSelector,
  HelperWidgetNextStep,
} from '../helper/HelperWidgets';
import useUpdateSimulation from '../hooks/useUpdateSimulation';
import {
  simulationCurrent,
  simulationIsEditable,
  simulationShowDatasetAsTable,
} from '../store/simulation.state';
import { selectedViewModeAtom } from '../store/simulation.wizard.state';

const SimulationDatasetPanelAssignment: React.FC = () => {
  const whId = useRecoilValue(warehouseSelectedId);
  const canUpdate = useRecoilValue(warehouseCanUpdate);
  const canChange = useRecoilValue(simulationIsEditable);
  const [selectedMode] = useRecoilState(selectedViewModeAtom);

  const assignmentsMeta = useRecoilValue(warehouseAllAssignmentsMeta);
  const [simulation, setSimulation] = useRecoilState(simulationCurrent);
  const updateSimulation = useUpdateSimulation();
  const changeSidebar = useChangeSidebarState('sidebar-simulation-issues');
  const setImportTriggerSimId = useSetRecoilState(importTriggeredBySim);

  const [assignmentFilter, setAssignmentFilter] = useRecoilState(
    assignmentFilterState,
  );

  const [showAsTable, setShowAsTable] = useRecoilState(
    simulationShowDatasetAsTable,
  );
  const loadAssignmentList = useLoadAllAssignmentsMeta();
  const { t } = useTranslation('simulation');
  const navigate = useNavigate();

  useEffect(() => {
    loadAssignmentList(whId);
  }, []);

  const changeAssignment = (assignmentId: string, title: string) => {
    setSimulation({
      ...simulation,
      assignment: { id: assignmentId, title },
    });
    changeSidebar({ isCollapsed: false });
    updateSimulation({ assignmentId });
  };
  const proceedWithImport = () => {
    setImportTriggerSimId(simulation.id);
    navigate(`/wh/i/${whId}/import/assignment`);
  };
  const assignments = assignmentsMeta.items;

  const assignmentMap = _.keyBy(assignments, a => a.id);
  const isLoading =
    assignmentsMeta.status === AsyncLoadStatus.None ||
    assignmentsMeta.status === AsyncLoadStatus.Loading;

  const filteredAssignments = assignments.filter(
    assignment =>
      assignmentFilter === 'all' || assignment.status === 'IMPORTED',
  );

  const hasNonImportedAssignments = assignments.some(
    assignment => assignment.status !== 'IMPORTED',
  );

  const datasetSelectorMenu = () => {
    return (
      <div className="flex flex-col">
        <div className="max-w-1/2 px-4">
          {canChange && hasNonImportedAssignments && (
            <ButtonSwitchMulti
              buttonType="minimal"
              autoSize
              options={[{ label: t('Imported') }, { label: t('All') }]}
              selectedIndex={assignmentFilter === 'imported' ? 0 : 1}
              onClick={index =>
                setAssignmentFilter(index === 0 ? 'imported' : 'all')
              }
            />
          )}
        </div>
        <div
          className={classNames(
            'group',
            'flex items-center',
            'py-1 lg:py-2',
            canChange ? 'px-4' : 'px-1',
          )}
        >
          <DropdownSelector
            value={
              simulation?.assignment?.id ||
              t`No Assignment Selected, Click to Select`
            }
            // values={_.keys(assignmentMap)}
            values={_.keys(_.keyBy(filteredAssignments, a => a.id))}
            renderValue={v => {
              if (!v || !assignmentMap[v]) {
                return t`No Assignment Selected, Click to Select`;
              }

              const status = assignmentMap[v]?.status;
              let tag;
              switch (status) {
                case 'IMPORTED':
                  tag = null;
                  break;

                case 'COMPUTED':
                  tag = (
                    <Tag classType="border border-state-computed uppercase text-xxs !mx-0 mb-0.5">{t`Allocated`}</Tag>
                  );
                  break;
                case 'REASSIGNED':
                  tag = (
                    <Tag classType="border border-state-reassigned uppercase text-xxs !mx-0 mb-0.5">{t`Optimised`}</Tag>
                  );
                  break;
                default:
                  tag = null;
              }

              return (
                <div className="flex flex-col items-start">
                  {tag}
                  <div>{assignmentMap[v]?.title || v}</div>
                </div>
              );
            }}
            valueHelper={v =>
              assignmentMap[v]?.updatedAt
                ? new Date(assignmentMap[v]?.updatedAt).toLocaleString('en-GB')
                : ''
            }
            filterValue={(v, searchString) =>
              `${assignmentMap[v]?.status} ${assignmentMap[v]?.title || v}`
                .toLowerCase()
                .indexOf(searchString.toLowerCase()) >= 0
            }
            hasSubAction
            subAction={t`Import Assignment`}
            DropAlignRight
            widthFull
            valuePrimary
            multiline
            light
            onChange={id => changeAssignment(id, assignmentMap[id].title)}
            onSubActionClick={proceedWithImport}
            disabled={!canChange || !canUpdate}
            border={canChange || canUpdate}
            hasSearch
            hasSearchLabel={t`Stock Assignment`}
            isLoading={isLoading}
          />

          {simulation?.assignment?.id && (
            <ButtonToolbar
              titleTrace={`Assignment Datatable`}
              className={classNames(
                'ltr:ml-2 rtl:mr-2',
                showAsTable === 'assignment' ? '' : 'hidden group-hover:block',
              )}
              label={t`Show Data`}
              isBoxed
              isSelected={showAsTable === 'assignment'}
              onChange={() =>
                setShowAsTable(
                  showAsTable === 'assignment' ? null : 'assignment',
                )
              }
            >
              {showAsTable === 'assignment' ? (
                <Icon.ShowTable
                  className={classNames('h-9 w-9 lg:h-10 lg:w-10', 'p-0.5')}
                />
              ) : (
                <Icon.HideTable
                  className={classNames('h-9 w-9 lg:h-10 lg:w-10', 'p-0.5')}
                />
              )}
            </ButtonToolbar>
          )}
        </div>
        <DatasetNavigation
          dataType={'assignment'}
          datasetId={simulation?.assignment?.id}
        />
        {/* <nav className={cn('grid grid-cols-2 content-center gap-2 px-2')}>
          <ImportToSimulationLink
            className="justify-self-start"
            dataType={'assignment'}
          />

          <ToggleDatasetTableLink
            className="justify-self-end"
            dataType={'assignment'}
            datasetId={simulation?.assignment?.id}
          />
        </nav> */}
      </div>
    );
  };

  return (
    <>
      <ScreenTitle
        title={t`Assignment`}
        subtitle={t`Simulation Dataset`}
        isSticky
        helpNavTo={'simulation/dataset/simulation-dataset-assignment'}
        icon={Icon.DataAssignment}
      />

      {isLoading ? (
        <LoadingIndicator message={t`Loading assignments`} selfCenter />
      ) : assignmentsMeta.status === AsyncLoadStatus.Error ? (
        <ErrorIndicator selfCenter message={t`Cannot load assignments`} />
      ) : !_.isEmpty(assignments) ? (
        !canChange ? (
          <HelperWidgetImportLocked title={t`Assignment`}>
            {datasetSelectorMenu()}
          </HelperWidgetImportLocked>
        ) : (
          <>
            <HelperWidgetImportSelector title={t`Assignments`} />
            {datasetSelectorMenu()}
          </>
        )
      ) : (
        <HelperWidgetImportInitialSetup
          dataType="assignment"
          title={t`Import Assignment (csv)`}
        />
      )}

      {simulation?.assignment?.id ? (
        <HelperWidgetNextStep title={t`Order Set import`} hasDataIssues />
      ) : null}
    </>
  );
};

export default SimulationDatasetPanelAssignment;
