import { ImportPipelineConnectorType } from '@warebee/frontend/data-access-api-graphql';
import classNames from 'classnames';
import _ from 'lodash';
import { nanoid } from 'nanoid';
import React, { Fragment, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useRecoilState, useRecoilValue, useSetRecoilState } from 'recoil';
import { cloneWithoutTypename } from '../../../common/utils';
import { Button } from '../../../components/actions/Button';
import ModalDialog from '../../../components/actions/ModalDialog';
import * as Icon from '../../../components/icons';
import InboxZero from '../../../components/InboxZero';
import KeyValueItem from '../../../components/KeyValueItem';
import TitleSection from '../../../components/layout/TitleSection';
import Callout from '../../../helpContext/Callout';
import {
  warehouseCanUpdate,
  warehouseSelected,
} from '../../../store/warehouse.state';
import useUpdateWarehouseExtraSettings from '../../../warehouse/hooks/useUpdateWarehouseExtraSettings';
import useUpdateImportPipeline from '../../hooks/useUpdateImportPipeline';
import { connectorNewDatasetKey } from '../../store/importPipeline.helper';
import {
  importPipelineIsActiveEditor,
  importPipelinesDataTableState,
  importPipelineSelected,
  importPipelineSelectedConnectorId,
  importPipelineSelectedId,
  importPipelineUpdateStatus,
} from '../../store/importPipelines.state';

export const PipelineConnectorS3: React.FC = () => {
  const { t } = useTranslation('dataset');
  const wh = useRecoilValue(warehouseSelected);
  const pipeline = useRecoilValue(importPipelineSelected);
  // const [isLoading, setIsLoading] = useState(false);

  const setSelectedId = useSetRecoilState(importPipelineSelectedId);
  const setConnectorId = useSetRecoilState(importPipelineSelectedConnectorId);
  const [tableState, setTableState] = useRecoilState(
    importPipelinesDataTableState,
  );
  const updateStatus = useRecoilValue(importPipelineUpdateStatus);
  const setShowEditor = useSetRecoilState(importPipelineIsActiveEditor);

  const updatePipeline = useUpdateImportPipeline();

  const [query, setQuery] = useState(
    pipeline?.settings?.mappingSettings?.transformation?.query,
  );
  const [updateWhExtraSettings] = useUpdateWarehouseExtraSettings();

  const canUpdate = useRecoilValue(warehouseCanUpdate);
  const connectorId = useRecoilValue(importPipelineSelectedConnectorId);

  const [genLoading, setGenLoading] = useState(false);
  const [token, setToken] = useState<string>(null);

  const [isLoading, setIsLoading] = useState(false);
  const [error, setError] = useState<string>(null);

  function addDatasetConnector() {
    setConnectorId(connectorNewDatasetKey);
    setShowEditor(true);
  }

  function removeConnectorById(id: string) {
    const connectors = _(pipeline.settings?.integrationSettings?.connectors)
      .filter(c => c.id !== id)
      .map(cloneWithoutTypename)
      .value();

    updatePipeline({
      importPipelineId: pipeline.id,
      settings: {
        ...pipeline.settings,
        integrationSettings: {
          connectors,
        },
      },
    });
  }
  const [isDialogOpen, setIsDialogOpen] = useState(false);

  function removeAllConnectorsByType(type: ImportPipelineConnectorType) {
    const connectors = _(pipeline.settings?.integrationSettings?.connectors)
      .filter(c => c.type !== type)
      .map(cloneWithoutTypename)
      .value();

    updatePipeline({
      importPipelineId: pipeline.id,
      settings: {
        ...pipeline.settings,
        integrationSettings: {
          connectors,
        },
      },
    });
  }

  function addConnector(type: ImportPipelineConnectorType) {
    const connectors = _.map(
      pipeline.settings?.integrationSettings?.connectors,
      cloneWithoutTypename,
    );
    connectors.push({
      id: nanoid(10),
      type,
      enabled: true,
    });

    updatePipeline({
      importPipelineId: pipeline.id,
      settings: {
        ...pipeline.settings,
        integrationSettings: {
          connectors,
        },
      },
    });
  }

  const connectors = pipeline?.settings?.integrationSettings?.connectors;

  // Connector: S3 (Push)
  const s3Connectors = _.filter(
    connectors,
    c => c.type === ImportPipelineConnectorType.S3_PUSH,
  );
  const hasS3Connector = !_.isEmpty(s3Connectors);

  const styleApiText = classNames(
    'font-mono',
    'text-menu-text',
    'text-start',
    'break-all',
    'select-text',
    'w-full',
    'shadow',
    'rounded',
  );

  return (
    <>
      <TitleSection
        id={`pipeline-setting-group-s3`}
        title={t`Connector: S3 (Push)`}
        inPanelView
        // className={classNames('z-400 top-12 xl:top-20')}
        classNameInner="p-4 xl:p-6 pb-10 space-y-8"
        hasScreenTitle
        collapsible
        hasAction={
          <>
            {!hasS3Connector ? (
              <Button
                label={t`Add`}
                className={classNames('rounded ltr:ml-4 rtl:mr-4')}
                buttonSize="xs"
                buttonType="primary"
                hasIconAfter={
                  <Icon.CirclePlus
                    className={classNames('h-5 w-5 fill-current')}
                  />
                }
                onPress={() =>
                  addConnector(ImportPipelineConnectorType.S3_PUSH)
                }
              />
            ) : (
              <Icon.CircleOk className={classNames('h-6 w-6 fill-current')} />
            )}
          </>
        }
      >
        {/* <PolicyWell
          fullHeight
          className={classNames('flex-1')}
          classNameChildren={classNames('space-y-4')}
        > */}
        {_.isEmpty(s3Connectors) && <InboxZero message={t`No Connector`} />}
        {_.map(s3Connectors, c => {
          const connectorState = _.find(
            pipeline.connectorsState,
            cs => cs.id === c.id,
          );
          return (
            <Fragment key={c.id}>
              <KeyValueItem
                className={styleApiText}
                keyName={t`AWS_ACCESS_KEY_ID`}
                keyValue={connectorState?.s3Push?.awsCredentials?.accessKeyId}
                isDisabled={true}
                isCopyableValueOnly
              />
              <KeyValueItem
                className={styleApiText}
                keyName={t`AWS_SECRET_ACCESS_KEY`}
                keyValue={
                  connectorState?.s3Push?.awsCredentials?.secretAccessKey
                }
                isDisabled={true}
                isCopyableValueOnly
              />
              <KeyValueItem
                className={styleApiText}
                keyName={t`Url`}
                keyValue={`${connectorState?.s3Push?.s3Url}/`}
                isDisabled={true}
                isCopyableValueOnly
              />
              <KeyValueItem
                className={styleApiText}
                keyName={t`Pipeline ID`}
                keyValue={pipeline.id}
                isDisabled={true}
                isCopyableValueOnly
              />

              <Callout type="system">
                <div>{t`S3 Api command to upload a file to the S3 bucket`}</div>
                <div
                  className={classNames(
                    styleApiText,
                    'mt-4',
                    'text-sm',
                    // 'space-y-2',
                  )}
                >
                  <KeyValueItem
                    className={styleApiText}
                    keyName={t`Bash Example Test`}
                    keyValue={`export AWS_ACCESS_KEY_ID=${connectorState?.s3Push?.awsCredentials?.accessKeyId}
                      \nexport AWS_SECRET_ACCESS_KEY=${connectorState?.s3Push?.awsCredentials?.secretAccessKey}
                      \naws s3 cp file.csv ${connectorState?.s3Push?.s3Url}/
                      `}
                    isDisabled={true}
                    isCopyable
                  />
                </div>
              </Callout>
            </Fragment>
          );
        })}

        {hasS3Connector && (
          <Button
            label={t`Delete S3 Connection`}
            className={classNames('rounded')}
            buttonSize="xs"
            buttonType="delete"
            full
            hasIconAfter={
              <Icon.CircleX className={classNames('h-5 w-5 fill-current')} />
            }
            onPress={() => setIsDialogOpen(true)}
          />
        )}

        <ModalDialog
          title={t(
            `Are you sure you want to delete the S3 connector
          This action cannot be undone!`,
          )}
          okButtonTitle={t`Delete S3`}
          okButtonType={'delete'}
          isOpen={isDialogOpen}
          setIsOpen={setIsDialogOpen}
          hasCancelButton
          onConfirm={async () =>
            removeAllConnectorsByType(ImportPipelineConnectorType.S3_PUSH)
          }
        />
      </TitleSection>
    </>
  );
};
