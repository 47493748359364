import _ from 'lodash';
import React, { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useRecoilValue } from 'recoil';
import { formatCurrencyBest } from '../common/formatHelper';
import { AsyncLoadStatus } from '../common/types';
import ErrorIndicator from '../components/ErrorIndicator';
import InboxZero from '../components/InboxZero';
import LoadingIndicator from '../components/LoadingIndicator';
import { AppBillingInvoiceLink } from './AppBillingInvoiceLink';
import useLoadSubscription from './hooks/useLoadSubscription';
import { convertStripeAmount } from './store/appBilling.helper';
import {
  appBillingSubscription,
  appBillingSubscriptionError,
  appBillingSubscriptionLoadStatus,
} from './store/appBilling.state';

export type AppBillingSubscriptionStatusProps = {
  subscriptionId: string;
};

export const AppBillingSubscriptionStatus: React.FC<
  AppBillingSubscriptionStatusProps
> = props => {
  const { t } = useTranslation('app');

  const subscription = useRecoilValue(appBillingSubscription);
  const subscriptionError = useRecoilValue(appBillingSubscriptionError);
  const loadStatus = useRecoilValue(appBillingSubscriptionLoadStatus);
  const [loadSubscription, cleanupSubscription] = useLoadSubscription();

  //subscription.latestInvoice
  useEffect(() => {
    if (!_.isEmpty(props.subscriptionId)) {
      loadSubscription(props.subscriptionId);
    }
    return () => {
      cleanupSubscription();
    };
  }, [props.subscriptionId]);

  if (
    loadStatus === AsyncLoadStatus.Loading ||
    loadStatus === AsyncLoadStatus.None
  ) {
    return <LoadingIndicator message={t`Loading subscription`} />;
  }

  if (_.isNil(props.subscriptionId)) {
    return <ErrorIndicator message={t`Subscription id is not provided`} />;
  }

  if (loadStatus === AsyncLoadStatus.Error) {
    return <ErrorIndicator message={subscriptionError ?? t`Error`} />;
  }

  if (loadStatus === AsyncLoadStatus.Ok && _.isNil(subscription)) {
    return <InboxZero message={t`Subscription not found`} />;
  }

  const subscriptionPrice = formatCurrencyBest(
    convertStripeAmount(subscription.latestInvoice?.total ?? 0),
    subscription?.latestInvoice?.currency ?? 'GBP',
  );

  return (
    <AppBillingInvoiceLink
      id={subscription.id}
      pdfUrl={subscription.latestInvoice?.invoicePdf}
      receiptUrl={subscription.latestInvoice?.receiptUrl}
      status={subscription.latestInvoice?.status}
      title={subscription.latestInvoice?.title}
      description={subscription.latestInvoice?.description}
      date={new Date(subscription.createdAt)}
      total={subscriptionPrice}
    />
  );
};

export default AppBillingSubscriptionStatus;
