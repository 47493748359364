import { TFunction } from 'i18next';
import * as Icon from '../../components/icons';
import { DatasetAutomationMenuItem } from './datasetAutomation.types';

export const datasetAutomationMenuItems: (
  t: TFunction<'app'>,
) => DatasetAutomationMenuItem[] = t => [
  {
    id: 'dataset-automation-menu-import-jobs',
    title: t('Import Jobs', { ns: 'app' }),
    titleMinimized: t('Jobs', { ns: 'app' }),
    disabled: false,
    icon: Icon.CloudUploadCsv,
    menuLevel: 1,
  },
  {
    id: 'dataset-automation-menu-import-pipelines',
    title: t('Import Pipelines', { ns: 'app' }),
    titleMinimized: t('Pipelines', { ns: 'app' }),
    disabled: false,
    icon: Icon.LinkArrow,
    menuLevel: 1,
    sidebar: {
      'sidebar-dataset-pipeline': { isHidden: true, isCollapsed: false },
      'sidebar-dataset-pipeline-editor': { isHidden: true, isCollapsed: false },
    },
  },
];
