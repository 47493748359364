import {
  Action,
  MeasurementSystem,
  useCreateWarehouseMutation,
  useRemoveWarehouseMutation,
  useUpdateWarehouseMutation,
} from '@warebee/frontend/data-access-api-graphql';
import { CreateWarehouseInput } from '@warebee/shared/data-access-api-dto';
import _ from 'lodash';
import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate, useParams } from 'react-router-dom';
import { useRecoilState, useRecoilValue } from 'recoil';
import { NoMatch404 } from '../NoMatch404';
import useLoadWarehouseBillingState from '../appBilling/hooks/useLoadWarehouseBillingState';
import { countriesCodesAll, countryNamesIntl } from '../assets/countries';
import { currencyByCode, currencyNamesIntl } from '../assets/currency';
import { WAREHOUSE_NEW_ID } from '../common/constants';
import { AsyncLoadStatus } from '../common/types';
import { cn } from '../common/utils';
import { AppFooter } from '../components/AppFooter';
import { AppHeader } from '../components/AppHeader';
import FormError from '../components/FormError';
import LoadingIndicator from '../components/LoadingIndicator';
import { Button } from '../components/actions/Button';
import DropdownSelector from '../components/actions/DropdownSelector';
import ModalDialog from '../components/actions/ModalDialog';
import { Alert } from '../components/alerts/Alerts';
import * as Icon from '../components/icons';
import { InputGroupList } from '../components/inputs/InputGroupList';
import InputText from '../components/inputs/InputText';
import { ContainerAppView } from '../components/layout/ContainerAppView';
import { Container, ContainerForm } from '../components/layout/ContainerFlex';
import { ScreenTitle } from '../components/layout/ScreenTitle';
import { Spacer } from '../components/layout/Spacer';
import { ActionBar } from '../components/nav/ActionBar';
import {
  warehouseList,
  warehouseListLoadStatus,
} from '../store/warehouse.state';

export type WarehouseSettingsParams = {
  warehouseId: string;
};

const WarehouseSettings: React.FC = () => {
  const { t: tCurrency } = useTranslation('currency');
  const { t } = useTranslation('app');

  const { warehouseId = WAREHOUSE_NEW_ID } =
    useParams<WarehouseSettingsParams>();
  const [whList, setWhList] = useRecoilState(warehouseList);
  const whListStatus = useRecoilValue(warehouseListLoadStatus);
  const [createWarehouse, { loading: loadingCreate }] =
    useCreateWarehouseMutation();
  const [updateWarehouse, { loading: loadingUpdate }] =
    useUpdateWarehouseMutation();
  const [removeWarehouse, { loading: loadingRemove }] =
    useRemoveWarehouseMutation();
  const loadWhBillingState = useLoadWarehouseBillingState();

  const navigate = useNavigate();
  const defaultInputData: CreateWarehouseInput = {
    title: '',
    address: '',
    companyName: '',
    description: '',
    measurementSystem: MeasurementSystem.METRIC,
    country: 'GB',
    currency: 'GBP',
  };
  const isNewWarehouseSetup =
    warehouseId?.toLowerCase() === WAREHOUSE_NEW_ID.toLowerCase();
  const wh = _.find(whList, wh => wh.id === warehouseId);
  const [inputData, setInputData] = useState<CreateWarehouseInput>(
    isNewWarehouseSetup ? defaultInputData : wh,
  );
  const [errors, setErrors] = useState({
    title: null,
  });
  const [isDialogOpen, setIsDialogOpen] = useState(false);

  useEffect(() => {
    const wh = _.find(whList, wh => wh.id === warehouseId);
    if (!_.isNil(wh)) {
      setInputData(_.cloneDeep(wh));
    }
  }, [whList]);

  const canUpdate = _.includes(wh?.permissions?.allowed, Action.UPDATE);
  const canDelete = _.includes(wh?.permissions?.allowed, Action.DELETE);

  if (!isNewWarehouseSetup && _.isNil(wh)) {
    return <NoMatch404 title={t`Warehouse not found`} />;
  }

  async function callCreate() {
    if (_.isEmpty(inputData.title)) {
      setErrors({ title: t`Warehouse name cannot be empty` });
      return;
    }

    try {
      const response = await createWarehouse({
        variables: {
          input: inputData,
        },
      });

      if (response.errors) {
        // response.errors.forEach((e: any) => {
        //   if (
        //     e.extensions &&
        //     e.extensions.code === 'BAD_USER_INPUT' &&
        //     e.extensions.errors
        //   ) {
        //     setFieldErrors(e.extensions.errors as ValidationError[]);
        //   }
        // });
      } else {
        const wh = response.data.createWarehouse;
        setWhList([...whList, wh]);
        navigate(`/wh/i/${wh.id}`);
        loadWhBillingState([wh.id]);
      }
    } catch (ex) {
      console.log(ex);
      throw ex;
    }
  }

  async function callUpdate() {
    if (_.isEmpty(inputData.title)) {
      setErrors({ title: t`Warehouse name cannot be empty` });
      return;
    }

    try {
      const response = await updateWarehouse({
        variables: {
          input: {
            warehouseId: warehouseId,
            title: inputData.title,
            description: inputData.description,
            companyName: inputData.companyName,
            address: inputData.address,
          },
        },
      });

      if (response.errors) {
        // response.errors.forEach((e: any) => {
        //   if (
        //     e.extensions &&
        //     e.extensions.code === 'BAD_USER_INPUT' &&
        //     e.extensions.errors
        //   ) {
        //     setFieldErrors(e.extensions.errors as ValidationError[]);
        //   }
        // });
      } else {
        const wh = response.data.updateWarehouse;
        setWhList([..._.filter(whList, w => w.id !== wh.id), wh]);
        navigate(`/wh/i/${wh.id}`);
      }
    } catch (ex) {
      console.log(ex);
      throw ex;
    }
  }

  async function callDelete() {
    try {
      const response = await removeWarehouse({
        variables: {
          id: warehouseId,
        },
      });

      if (response.errors) {
        // response.errors.forEach((e: any) => {
        //   if (
        //     e.extensions &&
        //     e.extensions.code === 'BAD_USER_INPUT' &&
        //     e.extensions.errors
        //   ) {
        //     setFieldErrors(e.extensions.errors as ValidationError[]);
        //   }
        // });
      } else {
        setWhList(_.filter(whList, w => w.id !== wh.id));
        navigate(`/`);
      }
    } catch (ex) {
      console.log(ex);
      throw ex;
    }
  }

  const measureSystemOptions = [
    MeasurementSystem.METRIC,
    MeasurementSystem.IMPERIAL,
  ];

  const currencyOptions = _.keys(currencyByCode(tCurrency));

  return (
    <>
      <AppHeader hasMenuGlobal hasHelp hasUser />
      <ContainerAppView>
        {inputData && whListStatus === AsyncLoadStatus.Ok ? (
          <ContainerForm fullWidth>
            <ScreenTitle
              hasPadding
              isHero
              title={isNewWarehouseSetup ? t`New Warehouse` : inputData.title}
              subtitle={isNewWarehouseSetup ? t`Getting Started` : t`Editing`}
              isSticky
            />
            <Container col hasOverflowY className="space-y-10 sm:p-6 lg:p-10">
              <InputGroupList hasPadding hasSpacing>
                <fieldset>
                  <InputText
                    placeholder={t`Your Warehouse Name`}
                    label={t`Warehouse Name`}
                    required
                    name="title"
                    value={inputData.title}
                    onChange={v => {
                      setErrors({ ...errors, title: null });
                      setInputData({ ...inputData, title: v });
                    }}
                    isDisabled={!isNewWarehouseSetup && !canUpdate}
                  />
                  <FormError errors={errors} name="title" />
                </fieldset>
                <fieldset>
                  <InputText
                    placeholder={t`Company Name`}
                    label={t`Company Name`}
                    name="companyName"
                    value={inputData.companyName}
                    onChange={v =>
                      setInputData({ ...inputData, companyName: v })
                    }
                    isDisabled={!isNewWarehouseSetup && !canUpdate}
                  />
                  <FormError errors={errors} name="companyName" />
                </fieldset>
                <fieldset>
                  <InputText
                    placeholder={t`Address`}
                    label={t`Address`}
                    name="address"
                    value={inputData.address}
                    onChange={v => setInputData({ ...inputData, address: v })}
                    isDisabled={!isNewWarehouseSetup && !canUpdate}
                  />
                  <FormError errors={errors} name="address" />
                </fieldset>
              </InputGroupList>

              {/* {isNew ? ( */}
              <div
                className={cn(
                  'm-3 mb-4',
                  'flex flex-col',
                  'bg-menu/50 backdrop-saturate-110 backdrop-blur-lg backdrop-filter',
                  'p-4',
                )}
              >
                {!isNewWarehouseSetup && (
                  <Alert
                    hasStatusLocked
                    message={t`Once the warehouse has data, the following settings can no longer be changed!`}
                    className="mb-4"
                  ></Alert>
                )}

                <InputGroupList
                  hasPadding
                  hasSpacing
                  className={cn('flex flex-col')}
                >
                  <DropdownSelector
                    // headerMode={isNew}
                    formMode
                    DropAlignRight
                    label={t`Country`}
                    values={_.keys(countriesCodesAll)}
                    renderValue={v =>
                      _.isNil(v) ? '' : countryNamesIntl.of(v)
                    }
                    value={inputData.country}
                    onChange={v => setInputData({ ...inputData, country: v })}
                    disabled={!isNewWarehouseSetup}
                    hasSearch
                    filterValue={(v, search) =>
                      countryNamesIntl
                        .of(v)
                        ?.toLowerCase()
                        ?.indexOf(search.toLocaleLowerCase()) >= 0
                    }
                  />
                  <DropdownSelector
                    // headerMode={isNew}
                    formMode
                    DropAlignRight
                    label={t`Measurement System`}
                    values={measureSystemOptions}
                    value={inputData.measurementSystem}
                    onChange={v =>
                      setInputData({ ...inputData, measurementSystem: v })
                    }
                    disabled={!isNewWarehouseSetup}
                  />
                  <DropdownSelector
                    formMode
                    DropAlignRight
                    label={t`Currency`}
                    values={currencyOptions}
                    value={inputData.currency}
                    onChange={v => setInputData({ ...inputData, currency: v })}
                    disabled={!isNewWarehouseSetup}
                    renderValue={v => (v ? currencyNamesIntl.of(v) : '')}
                  />
                </InputGroupList>
              </div>

              {canDelete && (
                <div
                  className={cn(
                    'm-3 mb-4 mt-8',
                    'flex flex-col space-y-4 xl:flex-row',
                    'bg-menu/50 backdrop-saturate-110 backdrop-blur-lg backdrop-filter',
                    'p-4',
                  )}
                >
                  <div className="text-menu-active flex-1">
                    {t`Delete warehouse and all it's data. This action cannot be undone!`}
                  </div>
                  {/* <Alert
                    hasStatusHelper
                    icon={Icon.TriangleX}
                    message={t`Delete warehouse and all it's data. This action cannot be undone!`}
                    className="z-alert m-3 flex items-center p-4"
                  > */}
                  <Spacer flexspace />
                  <Button
                    className="self-end"
                    label={
                      loadingCreate
                        ? t`Deleting Warehouse...`
                        : t`Delete Warehouse`
                    }
                    buttonType="delete"
                    buttonSize="sm"
                    onPress={() => setIsDialogOpen(true)}
                    isDisabled={loadingRemove || loadingUpdate}
                    isLoading={loadingRemove || loadingUpdate}
                    hasIconAfter={
                      <Icon.CircleX className={`h-5 w-5 fill-current`} />
                    }
                  />
                  {/* </Alert> */}
                </div>
              )}
            </Container>

            <ActionBar>
              {whList?.length > 0 && (
                <Button
                  label={t`Cancel`}
                  buttonType="primary"
                  onPress={() => {
                    navigate(`/`);
                  }}
                />
              )}

              <Spacer flexspace />

              {canUpdate && (
                <Button
                  label={
                    loadingCreate
                      ? t`Updating Warehouse...`
                      : t`Update Warehouse`
                  }
                  buttonType="primary"
                  onPress={callUpdate}
                  isDisabled={loadingUpdate || loadingRemove}
                  isLoading={loadingUpdate || loadingRemove}
                />
              )}

              {isNewWarehouseSetup && (
                <Button
                  label={
                    loadingCreate
                      ? t`Creating Warehouse...`
                      : t`Create Warehouse`
                  }
                  buttonType="primary"
                  onPress={callCreate}
                  isDisabled={loadingCreate}
                  isLoading={loadingCreate}
                />
              )}
            </ActionBar>
          </ContainerForm>
        ) : (
          <LoadingIndicator />
        )}
      </ContainerAppView>
      <AppFooter />

      <ModalDialog
        title={t(
          `Are you sure you want to delete warehouse {{whTitle}} and all it's data?
          This action cannot be undone!`,
          { whTitle: inputData?.title },
        )}
        okButtonTitle={t`Delete Warehouse`}
        okButtonType={'delete'}
        isOpen={isDialogOpen}
        setIsOpen={setIsDialogOpen}
        hasCancelButton
        onConfirm={callDelete}
      />
    </>
  );
};

export default WarehouseSettings;
