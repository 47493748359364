import { SelectQueryBuilder } from 'kysely';
import _ from 'lodash';
import { atom, selector, selectorFamily } from 'recoil';
import { actualityHqBuilderBase } from '../../feed/store/actuality.hq.state';
import { actualityExtraSettings } from '../../feed/store/actuality.state';
import { ActualityHqDataColumn } from '../../feed/store/datasetQueries/actualityHqDataRows';
import { PolicyFilterIntersection } from '../../policyFilters/policyFilter.types';
import {
  FilterPreset,
  FilterPresetDataType,
  FilterPresets,
  FilterPresetSelectedIdentity,
} from './filterPreset.types';

const getKey = (postfix: string) => `warebee-filter-set-${postfix}`;

export const filterPresetTypeSelected = atom<FilterPresetDataType>({
  key: getKey('type-selected'),
  default: 'actualityHq',
});

export const filterPresetsAll = selector<FilterPresets<string>>({
  key: getKey('all'),
  get: ({ get }) => {
    return get(actualityExtraSettings)?.filterPreset ?? [];
  },
  set: ({ get, set }, value: FilterPresets<string>) => {
    set(actualityExtraSettings, current => ({
      ...current,
      filterPreset: value as FilterPresets<ActualityHqDataColumn>,
    }));
  },
});

export const filterPresetSelectedIdentity = atom<FilterPresetSelectedIdentity>({
  key: getKey('selected-identity'),
  default: null,
});

export const filterPresetSelected = selector<FilterPreset<string>>({
  key: getKey('selected-id'),
  get: ({ get }) => {
    const selected = get(filterPresetSelectedIdentity);
    if (_.isNil(selected?.presetId)) {
      return null;
    }
    const all = get(filterPresetsAll);
    return _.find(all, p => p.id === selected?.presetId);
  },
});

export const filterPresetById = selectorFamily<FilterPreset<string>, string>({
  key: getKey('rule-by-id'),
  get:
    presetId =>
    ({ get }) => {
      if (_.isNil(presetId)) return null;
      const all = get(filterPresetsAll);
      return _.find(all, p => p.id === presetId);
    },
  set:
    ruleId =>
    ({ get, set }, value: FilterPreset<string>) => {
      set(filterPresetsAll, current =>
        current.map(rule => (rule.id === ruleId ? value : rule)),
      );
    },
});

export const filterPresetFilterIntersection = selectorFamily<
  PolicyFilterIntersection<string>,
  FilterPresetSelectedIdentity
>({
  key: getKey('filter-intersection-by-id'),
  get:
    identity =>
    ({ get }) => {
      if (_.isNil(identity?.filterId) || _.isNil(identity?.presetId))
        return null;

      const preset = get(filterPresetById(identity.presetId));
      if (!preset) return null;

      const allFilters = preset?.filterUnion?.anyOf;

      const filterIntersection = _.find(
        allFilters,
        fs => fs.id === identity.filterId,
      );
      return filterIntersection;
    },
  set:
    identity =>
    ({ get, set }, value: PolicyFilterIntersection<string>) => {
      if (_.isNil(identity?.filterId) || _.isNil(identity?.presetId)) {
        throw new Error('Invalid parameters. Filter identity is invalid');
      }

      const preset = get(filterPresetById(identity.presetId));
      if (!preset) return null;

      set(filterPresetById(identity.presetId), {
        ...preset,
        filterUnion: {
          ...(preset?.filterUnion ?? {}),
          anyOf: _.map(preset?.filterUnion?.anyOf, fs =>
            fs.id === identity.filterId ? value : fs,
          ),
        },
      });
    },
});

export const filterPresetEffectiveQueryBuilder = selector<{
  b: SelectQueryBuilder<any, any, any>;
}>({
  key: getKey('effective-query-builder'),
  get: ({ get }) => {
    return get(actualityHqBuilderBase);
  },
});
