import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useRecoilState, useRecoilValue } from 'recoil';
import { Button } from '../../components/actions/Button';
import SectionOptional from '../../components/layout/SectionOptional';
import { ActionBar } from '../../components/nav/ActionBar';
import { IconArrowDir } from '../../components/nav/IconArrowDirectional';
import SidebarContainer, {
  SidebarContainerProps,
} from '../../containers/SidebarContainer';
import { warehouseIsDemo } from '../../store/warehouse.state';
import useRunAllocationAnalyze from '../../warehouse/hooks/useRunAllocationAnalyze';
import useRunAnalyze from '../../warehouse/hooks/useRunAnalyze';
import { allocationRunSummary } from '../store/allocation/allocation.state';
import {
  simulationCurrent,
  simulationIsEditable,
  simulationManualLock,
  simulationWithAllocation,
} from '../store/simulation.state';
import AllocationPolicyPanel from './allocationPolicy/AllocationPolicyPanel';

const AllocationPolicySidebar: React.FC = () => {
  const { t } = useTranslation('simulation');
  // const selectStep = useSelectSimulationWizardStep();
  const isDemo = useRecoilValue(warehouseIsDemo);
  const canUpdate = useRecoilValue(simulationIsEditable);
  const isSimulationManuallyLocked = useRecoilValue(simulationManualLock);
  const simulation = useRecoilValue(simulationCurrent);
  const [isStarting, setIsStarting] = useState(false);
  const [withAllocate, setWithAllocate] = useRecoilState(
    simulationWithAllocation,
  );

  const allocationRun = useRecoilValue(allocationRunSummary);
  const runAnalyze = useRunAnalyze();
  const runAllocationAnalyze = useRunAllocationAnalyze();

  return (
    <>
      <AllocationPolicyPanel />
      {/* <ActionBarNextStep /> */}
      {canUpdate && (
        <ActionBar sticky stickyBottom>
          <>
            <SectionOptional
              id={'analyze-with-allocate'}
              offLabel={`Off`}
              onLabel={`With Allocate`}
              value={withAllocate}
              onChange={enabled => setWithAllocate(enabled)}
            />

            <Button
              full
              label={
                isDemo
                  ? t`Start Analyse`
                  : !isSimulationManuallyLocked
                    ? t`Re-Analyse`
                    : t`Analyse`
              }
              buttonType="primary"
              hasIconAfter
              buttonIcon={<IconArrowDir />}
              isLoading={isStarting}
              isDisabled={isStarting || isDemo}
              onPress={async () => {
                setIsStarting(true);
                await runAnalyze(simulation.id);
                withAllocate &&
                  allocationRun?.id &&
                  runAllocationAnalyze(allocationRun?.id);
                setIsStarting(false);
              }}
            />
          </>
        </ActionBar>
      )}
    </>
  );
};

export default () => {
  const { t } = useTranslation('simulation');

  const containerProps: SidebarContainerProps = {
    type: 'sidebar-policy-allocation',
    title: t`Allocation Policy`,
    loadingMessage: t`Loading Policy`,
    size: 'md',
  };

  return (
    <SidebarContainer key={containerProps.type} {...containerProps}>
      <AllocationPolicySidebar />
    </SidebarContainer>
  );
};
