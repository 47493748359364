import _ from 'lodash';
import React, { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useRecoilValue } from 'recoil';
import useFormatter from '../common/useFormatter';
import InboxZero from '../components/InboxZero';
import { Container } from '../components/layout/ContainerFlex';
import { ScreenTitle } from '../components/layout/ScreenTitle';
import { updateAllPolicyFilterValues } from '../policyFilters/policyFilter.helper';
import useLoadLocationFilterValues from '../policyFilters/useLoadLocationFilterValues';
import { getLocationFilterConfigCommon } from '../simulation/store/assignmentPolicy.default';
import {
  waypointPolicyRule,
  waypointPolicySelectedIdentity,
} from '../simulation/store/waypointPolicy/waypointPolicy.state';
import WaypointPolicyLocationFilterEditor from './WaypointPolicyLocationFilterEditor';

const WaypointPolicyEditorPanel: React.FC = () => {
  const { t } = useTranslation('simulation');
  const formatter = useFormatter();
  const selectedIdentity = useRecoilValue(waypointPolicySelectedIdentity);
  const rule = useRecoilValue(waypointPolicyRule(selectedIdentity?.ruleId));
  const [loadLocationValues, cancelLoadLocationValues] =
    useLoadLocationFilterValues();

  const locationFiltersConfig = getLocationFilterConfigCommon(t);

  useEffect(() => {
    if (
      _.isNil(selectedIdentity.ruleId) ||
      _.isNil(selectedIdentity.groupType) ||
      _.isNil(selectedIdentity.locationFilterId)
    )
      return;

    const policyFilterUnion =
      selectedIdentity.groupType === 'start'
        ? rule.startPoints?.locationsMatch
        : rule.endPoints?.locationsMatch;

    updateAllPolicyFilterValues({
      filterConfig: locationFiltersConfig,
      selectedFilterId: selectedIdentity.locationFilterId,
      policyFilterUnion: policyFilterUnion,
      load: loadLocationValues,
      cancel: cancelLoadLocationValues,
    });
  }, [selectedIdentity]);

  if (!selectedIdentity || !rule) {
    return (
      <InboxZero
        selfCenter
        center
        message={t`No Policy filter group selected`}
        message_helper={t`Click on an Edit button in Policy Rule to change filters`}
      />
    );
  }

  return (
    <Container col hasOverflowY>
      <ScreenTitle
        subtitle={t`Filter Options for`}
        title={`${rule.title}`}
        isSticky
      />

      {_.map(locationFiltersConfig, configItem => (
        <WaypointPolicyLocationFilterEditor
          key={`policy-filter-editor-${configItem.type}`}
          filterConfig={configItem}
          startLoadData={loadLocationValues}
          cancelLoadData={cancelLoadLocationValues}
        />
      ))}
    </Container>
  );
};
export default WaypointPolicyEditorPanel;
