import {
  AssignmentComplianceItemFilter,
  AssignmentComplianceItemSortBy,
  LoadAssignmentComplianceItemsDocument,
  LoadAssignmentComplianceItemsQuery,
  LoadAssignmentComplianceItemsQueryVariables,
} from '@warebee/frontend/data-access-api-graphql';
import { nanoid } from 'nanoid';
import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useRecoilCallback } from 'recoil';
import { secureClient } from '../../GraphQLClient';
import { DATASET_VIEW_PAGE_SIZE } from '../../common/constants';
import { AsyncLoadStatus } from '../../common/types';
import { errorAppender } from '../../store/error.state';
import {
  assignmentComplianceItemsDataAtom,
  assignmentComplianceItemsDataLoadStatus,
  getComplianceKey,
} from '../store/assignmentCompliance.state';

export type LoadAssignmentComplianceItemsParams = {
  simulationId: string;
  assignmentId: string;
  limit?: number;
  skip?: number;
  isAppend?: boolean;
  filter?: AssignmentComplianceItemFilter;
  sortBy?: AssignmentComplianceItemSortBy[];
};

function useLoadAssignmentComplianceItems() {
  const { t } = useTranslation('errors');
  const errorTitle = t`Cannot load assignment compliance results`;
  const [observable, setObservable] = useState<ZenObservable.Subscription>();

  const initLoading = useRecoilCallback(
    ({ set }) =>
      async (params: LoadAssignmentComplianceItemsParams) => {
        set(
          assignmentComplianceItemsDataLoadStatus(params.assignmentId),
          AsyncLoadStatus.Loading,
        );
      },
  );

  const callLoad = useRecoilCallback(
    ({ snapshot, set }) =>
      async (params: LoadAssignmentComplianceItemsParams) => {
        const complianceKey = getComplianceKey(
          params.simulationId,
          params.assignmentId,
        );
        const current = await snapshot.getPromise(
          assignmentComplianceItemsDataAtom(complianceKey),
        );

        function handleError(details, stack) {
          set(errorAppender, {
            id: nanoid(),
            title: errorTitle,
            details: details,
            callStack: stack,
          });
          set(
            assignmentComplianceItemsDataLoadStatus(params.assignmentId),
            AsyncLoadStatus.Error,
          );
          return;
        }

        const query = secureClient.watchQuery<
          LoadAssignmentComplianceItemsQuery,
          LoadAssignmentComplianceItemsQueryVariables
        >({
          query: LoadAssignmentComplianceItemsDocument,
          variables: {
            simulationId: params.simulationId,
            assignmentId: params.assignmentId,
            page: {
              limit: params.limit || DATASET_VIEW_PAGE_SIZE,
              skip: params.skip || 0,
            },
            filter: params.filter,
            sortBy: params.sortBy,
          },
        });

        const queryObservable = query.subscribe(
          result => {
            const { data, errors } = result;
            if (errors) {
              console.error(errors);
              handleError(null, errors.map(e => e.message).join('. '));
              return;
            }

            const locationData = data?.simulation?.assignmentCompliance?.items;
            let content = locationData?.content ?? [];

            if (params.isAppend) {
              content = [...current.content, ...content];
            }

            set(assignmentComplianceItemsDataAtom(complianceKey), {
              totalCount: locationData?.totalCount ?? 0,
              content,
            });
            set(
              assignmentComplianceItemsDataLoadStatus(params.assignmentId),
              AsyncLoadStatus.Ok,
            );
          },
          error => {
            console.error(error);
            handleError(error.message || error, error.stack || null);
          },
        );

        setObservable(queryObservable);
      },
  );

  async function call(params: LoadAssignmentComplianceItemsParams) {
    await initLoading(params);
    await callLoad(params);
  }

  function cancel() {
    observable?.unsubscribe();
  }

  return [call, cancel] as const;
}

export default useLoadAssignmentComplianceItems;
