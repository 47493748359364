import { KyselyFilterDescSet } from '@warebee/shared/athena-db-model';
import _ from 'lodash';
import { getFilterDescriptorDataType } from '../../policyFilters/policyFilter.helper';
import { actualityHqFieldTypesBase } from './actuality.hq.default';
import { ActualityHqDataColumn } from './datasetQueries/actualityHqDataRows';

export function getActualityFilterDescSet(): KyselyFilterDescSet<
  ActualityHqDataColumn,
  any,
  any
> {
  return _.transform(
    actualityHqFieldTypesBase,
    (acc, type, filterKey) => {
      acc[filterKey] = [
        getFilterDescriptorDataType(type),
        eb => eb.ref(filterKey),
      ];
    },
    {} as KyselyFilterDescSet<ActualityHqDataColumn, any, any>,
  );
}
