import _ from 'lodash';
import React, { useId } from 'react';
import {
  PanelFixedHeight,
  panelFixedHeights,
} from '../../../common/component.types';
import { cn } from '../../../common/utils';
import * as Icon from '../../icons';
import Loader from '../../loaders/Loader';

export type PanelHeaderProps = {
  className?: string;
  children?: React.ReactNode;
  filterPlaceholder?: React.ReactNode;
  icon?: string;
  title?: string | React.ReactNode;
  subtitle?: string;
  borderTop?: boolean;
  collapsible?: boolean;
  adjustable?: boolean;
  closable?: boolean;
  transparent?: boolean;
  isLoading?: boolean;
  fixedHeight?: PanelFixedHeight;
  onCloseClick?: () => void;
  onClick?: () => void;
  onHeightChange?: (v: PanelFixedHeight) => void;
};

export const PanelHeader = ({
  className,
  children,
  title,
  subtitle,
  icon,
  borderTop,
  collapsible,
  adjustable,
  closable,
  transparent,
  filterPlaceholder,
  isLoading,
  onCloseClick,
  onClick,
  ...props
}: PanelHeaderProps) => {
  const id = useId();
  borderTop = false;

  function toggleHeight(offset: 1 | -1) {
    const l = panelFixedHeights.length;
    const currentIndex = _.indexOf(panelFixedHeights, props.fixedHeight);
    const targetIndex = (currentIndex + offset + l) % l;
    const targetHeight = panelFixedHeights[targetIndex];
    // console.log('Panel', currentIndex, targetIndex);
    if (_.isFunction(props.onHeightChange)) {
      props.onHeightChange(targetHeight);
    }
  }

  const styleIconButton = cn(
    'h-5 w-5',
    'fill-current',
    'opacity-60',
    'bg-menu/5',
    'hover:bg-menu-hover hover:text-menu-text',
    'text-menu-text',
    'p-1 mx-0.5',
    'rounded-full',
  );

  return (
    <header
      data-component="PanelHeader"
      id={id}
      className={cn(
        'z-1000 sticky top-0',
        'flex items-center',
        'p-header',
        'h-header',
        'flex-shrink-0',
        'ltr:pl-2 rtl:pr-2',
        'text-xs uppercase',
        transparent
          ? 'group uppercase'
          : 'bg-sidebar-header/90 text-menu-text backdrop-saturate-110 backdrop-blur-lg backdrop-filter',
        {
          'hover:bg-sidebar-header/75 cursor-pointer transition delay-300 duration-300 ease-in-out':
            collapsible,
        },
        className,
      )}
    >
      {borderTop && <hr className="border-app-panel-dark mb-2 mt-1" />}

      {isLoading && <Loader size={20} type={'loading-original'} />}

      {title && (
        <h6
          data-component="PanelHeaderTitleCollapsible"
          className={cn(
            'flex items-center',
            {
              truncate: typeof title === 'string',
            },
            {
              'h-full flex-1': !subtitle,
            },
          )}
        >
          {((collapsible || adjustable) && (
            <>
              <button
                type="button"
                className={cn('flex items-center ltr:ml-1 rtl:mr-1')}
                aria-label={`Maximize`}
                onClick={() => toggleHeight(1)}
              >
                <Icon.ChevronUp className={cn(styleIconButton)} />
              </button>

              <button
                type="button"
                className={cn('flex items-center ltr:ml-1 rtl:mr-1')}
                aria-label={`Minimize`}
                onClick={() => toggleHeight(-1)}
              >
                <Icon.ChevronDown className={cn(styleIconButton)} />
              </button>

              <button
                type="button"
                className={cn('flex items-center ltr:ml-1 rtl:mr-1')}
                aria-label={'Button Collapsible'}
                onClick={onClick}
              >
                {icon}
                {adjustable ? null : (
                  <Icon.ChevronDualCloseVertical
                    className={cn(styleIconButton)}
                  />
                )}
              </button>
              <div
                className={cn(
                  'text-menu-title flex-1',
                  {
                    truncate: typeof title === 'string',
                  },
                  {
                    flex: typeof title !== 'string',
                  },
                )}
              >
                {title}
              </div>
            </>
          )) || (
            <span
              data-component="PanelHeaderTitle"
              className={cn(
                'ltr:pl-2 ltr:pr-1 rtl:pl-1 rtl:pr-2',
                'text-menu-text flex-1',
                {
                  truncate: typeof title === 'string',
                  'flex items-center': filterPlaceholder,
                },
              )}
            >
              {title}
              {filterPlaceholder && (
                <span className="ml-4">{filterPlaceholder}</span>
              )}
            </span>
          )}
        </h6>
      )}

      {subtitle && (
        <div className={cn('text-menu-text/60', 'flex items-center', 'px-1')}>
          <span className={cn('text-xxs flex-1')}>{subtitle}</span>
        </div>
      )}

      {children}

      {closable && (
        <button
          type="button"
          className={cn('ltr:ml-4 rtl:mr-4', 'flex items-center')}
          aria-label={`Close`}
          onClick={onCloseClick}
        >
          <Icon.Close className={cn(styleIconButton)} />
        </button>
      )}
    </header>
  );
};
