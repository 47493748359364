import {
  ImportJobsConnectionFragment,
  SortDirection,
} from '@warebee/frontend/data-access-api-graphql';
import { atom, selector } from 'recoil';
import { persistAtom } from '../../common/recoil/persistAtom';
import { AsyncLoadStatus, DataTableState } from '../../common/types';
import { ImportJobsDataColumn } from './importJobs.types';

const getKey = (postfix: string) => `warebee-import-jobs-${postfix}`;

export const importJobsData = atom<ImportJobsConnectionFragment>({
  key: getKey('data'),
  default: null,
});

export const importJobsDataStatus = atom<AsyncLoadStatus>({
  key: getKey('data-status'),
  default: AsyncLoadStatus.None,
});

export const importJobsDataTableState = atom<
  DataTableState<ImportJobsDataColumn>
>({
  key: getKey('data-table-state'),
  default: {
    searchValues: {},
    sortValues: {
      createdAt: SortDirection.ASC,
    },
  },
});

export const importPipelineTabId = [
  'tab-pipeline-settings-properties',
  'tab-pipeline-settings-import',
  'tab-pipeline-settings-export',
  'tab-pipeline-settings-integration',
  'tab-pipeline-settings-extras',
] as const;

export type ImportPipelineTabId = (typeof importPipelineTabId)[number];

const importPipelineSelectedTabIdAtom = persistAtom<ImportPipelineTabId>({
  key: getKey('pipeline-settings-tab-key-atom'),
  default: 'tab-pipeline-settings-properties',
});

export const importPipelineSelectedTabId = selector<ImportPipelineTabId>({
  key: getKey('feed-tab-key'),
  get: ({ get }) => get(importPipelineSelectedTabIdAtom),
  set: ({ get, set }, value: ImportPipelineTabId) => {
    // console.log(
    //   'SELECTOR ImportPipelineTabId:',
    //   get(importPipelineSelectedTabIdAtom),
    //   value,
    // );
    set(importPipelineSelectedTabIdAtom, current => {
      // console.log('SELECTOR ImportPipelineTabId CURRENT:', current);
      return value;
    });
  },
});
