import {
  QueryDatasetDocument,
  useRunExportJobMutation,
} from '@warebee/frontend/data-access-api-graphql';
import {
  DatasetQueryConverterConfig,
  DatasetQueryExportJobParams,
  getDatasetQueryDataRows,
} from '@warebee/shared/export-converter';
import { format } from 'date-fns';
import _ from 'lodash';
import { nanoid } from 'nanoid';
import { useTranslation } from 'react-i18next';
import { useRecoilCallback } from 'recoil';
import { AsyncLoadStatus } from '../../common/types';
import { errorAppender } from '../../store/error.state';
import { warehouseSelectedId } from '../../store/warehouse.state';
import useActualityHeatmapDataTableConfig from '../heatmapTable/useActualityHeatmapDataTableConfig';
import {
  actualityHeatmapTableData,
  actualityHeatmapTableDataLoadStatus,
  actualityHeatmapTableDataState,
  actualityHeatmapTableTotalCount,
  actualityMetricDescriptor,
} from '../store/actuality.heatmap.state';
import {
  ActualityHeatmapDataQueryParams,
  ActualityHeatmapDataRow,
  getActualityHeatmapDataQuery,
  getActualityHeatmapTotalQuery,
} from '../store/datasetQueries/actualityHeatmapData';
import { executeDatasetQuery } from '../store/feed.helper';
import { feedItemsWithRank } from '../store/feed.state';

export type LoadActualityHeatmapTableParams =
  ActualityHeatmapDataQueryParams & {
    isAppend?: boolean;
  };

export type ExportActualityHeatmapTableParams = ActualityHeatmapDataQueryParams;

function useLoadActualityHeatmapTableData() {
  const { t } = useTranslation('errors');
  const errorTitle = t`Cannot load Actuality Heatmap Data`;
  const columnsConfig = useActualityHeatmapDataTableConfig();
  const [runExportCSV] = useRunExportJobMutation();

  const resetCallback = useRecoilCallback(({ reset }) => async () => {
    reset(actualityHeatmapTableDataLoadStatus);
    reset(actualityHeatmapTableData);
    reset(actualityHeatmapTableDataState);
    reset(actualityHeatmapTableTotalCount);
  });

  const getConverterConfig = useRecoilCallback(
    ({ snapshot }) =>
      async (
        params: LoadActualityHeatmapTableParams,
      ): Promise<DatasetQueryConverterConfig<ActualityHeatmapDataRow>> => {
        const metric = await snapshot.getPromise(actualityMetricDescriptor);
        const itemsWithRanks = await snapshot.getPromise(feedItemsWithRank);
        let dictionaries: Record<string, Record<string, string>> = null;
        let dictionariesDefault: Record<string, string> = null;

        if (metric.type === 'abc') {
          const rankMap: Record<string, string> = _.transform(
            itemsWithRanks,
            (acc, item) => {
              acc[item.skuKey] = item.category;
            },
            {},
          );

          dictionaries = { dimensionValue: rankMap };
          dictionariesDefault = {
            dimensionValue: 'D',
          };
        }
        return {
          columnsConfig,
          dictionaries,
          dictionariesDefault,
        };
      },
  );

  const initLoading = useRecoilCallback(
    ({ set, reset }) =>
      async (params: LoadActualityHeatmapTableParams) => {
        set(actualityHeatmapTableDataLoadStatus, AsyncLoadStatus.Loading);
        if (!params.isAppend) {
          reset(actualityHeatmapTableData);
          reset(actualityHeatmapTableTotalCount);
        }
      },
  );

  const loadDataCallback = useRecoilCallback(
    ({ snapshot, set }) =>
      async (params: LoadActualityHeatmapTableParams) => {
        const warehouseId = await snapshot.getPromise(warehouseSelectedId);
        const current = await snapshot.getPromise(actualityHeatmapTableData);
        const converterConfig = await getConverterConfig(params);

        let totalCount = await snapshot.getPromise(
          actualityHeatmapTableTotalCount,
        );

        function handleError(details, stack) {
          set(errorAppender, {
            id: nanoid(),
            title: errorTitle,
            details: details,
            callStack: stack,
          });
          set(actualityHeatmapTableDataLoadStatus, AsyncLoadStatus.Error);
        }

        if (!params.isAppend) {
          const compiledQueryTotal =
            getActualityHeatmapTotalQuery(params).compile();

          const totalResult = await executeDatasetQuery({
            warehouseId,
            compiledQuery: compiledQueryTotal,
            comment: '[actuality] Heatmap total rows',
          });
          totalCount = _.size(totalResult);
        }

        let rows = [];
        if (totalCount > 0) {
          const compiledQuery = getActualityHeatmapDataQuery(params).compile();

          const rowsRaw = await executeDatasetQuery({
            warehouseId,
            compiledQuery,

            comment: '[actuality] Heatmap table data',
          });
          rows = getDatasetQueryDataRows(rowsRaw, converterConfig);
        }

        if (params.isAppend) {
          rows = [...current, ...rows];
        }
        set(actualityHeatmapTableData, rows);
        set(actualityHeatmapTableTotalCount, totalCount);
        set(actualityHeatmapTableDataLoadStatus, AsyncLoadStatus.Ok);
      },
  );

  const exportCallback = useRecoilCallback(
    ({ snapshot }) =>
      async (params: ExportActualityHeatmapTableParams) => {
        const warehouseId = await snapshot.getPromise(warehouseSelectedId);
        const exportParams = { ...params, page: null };
        const query = getActualityHeatmapDataQuery(exportParams).compile();
        const converterConfig = await getConverterConfig(params);

        const now = new Date();
        const timestamp = format(now, 'yyyy-MM-dd-HHmm');
        const shortWarehouseId = warehouseId.split('-')[0];
        const shortWarehouseName = warehouseId.split('-')[0];

        const variables: DatasetQueryExportJobParams<ActualityHeatmapDataRow> =
          {
            converterId: 'DATASET_QUERY',
            query: QueryDatasetDocument.loc.source.body,
            config: converterConfig,
            variables: {
              warehouseId: warehouseId,
              query: query.sql,
              params: query.parameters,
            },
            filename: `actuality-heatmap-${timestamp}-${shortWarehouseId}.csv`,
          };

        const { data, errors } = await runExportCSV({
          variables,
        });

        return {
          errors: errors,
          job: data.createExportJob,
        };
      },
  );

  async function call(params: LoadActualityHeatmapTableParams) {
    await initLoading(params);
    await loadDataCallback(params);
  }

  async function reset() {
    await resetCallback();
  }

  async function exportCSV(params: ExportActualityHeatmapTableParams) {
    return await exportCallback(params);
  }

  return [call, reset, exportCSV] as const;
}

export default useLoadActualityHeatmapTableData;
