import _ from 'lodash';
import { useRecoilState, useRecoilValue } from 'recoil';
import DropdownSelector from '../components/actions/DropdownSelector';
import * as Icon from '../components/icons';
import {
  filterPresetsAll,
  filterPresetSelectedIdentity,
} from '../filterPreset/store/filterPreset.state';

const ActualityPresetSelect: React.FC = () => {
  const availablePresets = useRecoilValue(filterPresetsAll);
  const [selectedIdentity, setSelectedIdentity] = useRecoilState(
    filterPresetSelectedIdentity,
  );
  const presetsMap = _.keyBy(availablePresets, 'id');
  const iconStyle = 'h-5 w-5 mx-1 active:bg-menu-active';
  const iconValueStyle = 'h-4 w-4 ltr:mr-2 rtl:ml-2';

  return (
    <DropdownSelector
      value={selectedIdentity?.presetId}
      values={[null, ..._.keys(presetsMap)]}
      renderValue={v => (v ? presetsMap[v]?.title : `—`)}
      onChange={v => setSelectedIdentity({ presetId: v })}
      // buttonTransparent
      // panelMode
      dark
      w_lg
      iconValue={Icon.FilterMenu}
      // iconSelection={Icon.Show}
      // iconMenu={Icon.CircleMenu}
      iconSelectionClass={iconStyle}
      iconClass={iconValueStyle}
      // iconDropMenu={Icon.MenuDotsHorizontal}
      // iconSelectionClass={iconStyle}
      className="bg-app-header h-6 rounded-md ltr:mr-2 rtl:ml-2"
      // classNameValue="text-xs"
    />
  );
};

export default ActualityPresetSelect;
