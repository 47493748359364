import {
  ImportPipelineConnectorSettingsFragment,
  useLoadDatasetObjectsQuery,
  useUpdateImportPipelineMutation,
} from '@warebee/frontend/data-access-api-graphql';
import { t } from 'i18next';
import _ from 'lodash';
import React, { useEffect, useState } from 'react';
import { useRecoilValue, useSetRecoilState } from 'recoil';
import { formatDateTime } from '../../../common/formatHelper';
import ErrorIndicator from '../../../components/ErrorIndicator';
import InboxZero from '../../../components/InboxZero';
import { Button } from '../../../components/actions/Button';
import DropdownSelector from '../../../components/actions/DropdownSelector';
import * as Icon from '../../../components/icons';
import { Container } from '../../../components/layout/ContainerFlex';
import { ScreenTitle } from '../../../components/layout/ScreenTitle';
import { Spacer } from '../../../components/layout/Spacer';
import { ActionBar } from '../../../components/nav/ActionBar';
import { Table } from '../../../components/table/Table';
import { TableBody } from '../../../components/table/TableBody';
import { TableHead } from '../../../components/table/TableHead';
import { TableRow } from '../../../components/table/TableRow';
import { TableTd } from '../../../components/table/TableTd';
import { TableTh } from '../../../components/table/TableTh';
import { warehouseSelectedId } from '../../../store/warehouse.state';
import useUpdateImportPipeline from '../../hooks/useUpdateImportPipeline';
import {
  importPipelineIsActiveEditor,
  importPipelineSelected,
  importPipelineSelectedConnectorId,
} from '../../store/importPipelines.state';

type DatasetConnectorEditorProps = {
  connector: ImportPipelineConnectorSettingsFragment;
  isNew: boolean;
  onChange: () => Promise<void>;
};

const DatasetConnectorEditor: React.FC<DatasetConnectorEditorProps> = props => {
  const pipeline = useRecoilValue(importPipelineSelected);
  const warehouseId = useRecoilValue(warehouseSelectedId);
  const [callUpdatePipeline] = useUpdateImportPipelineMutation();
  const setShowEditor = useSetRecoilState(importPipelineIsActiveEditor);
  const setSelectedConnectorId = useSetRecoilState(
    importPipelineSelectedConnectorId,
  );

  const [connector, setConnector] =
    useState<ImportPipelineConnectorSettingsFragment>(props.connector);

  const [isChanged, setIsChanged] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [error, setError] = useState<string[]>(null);
  const updatePipeline = useUpdateImportPipeline();

  const [datasetId, setDatasetId] = useState<string>();

  useEffect(() => {
    setConnector(props.connector);
    setIsChanged(false);
  }, [props.connector]);

  const { data: loadDatasetResult, loading: isDatasetLoading } =
    useLoadDatasetObjectsQuery({
      variables: {
        warehouseId,
      },
    });

  const datasets = _(loadDatasetResult?.warehouse?.datasetObjects?.content)
    .keyBy(j => j.id)
    .value();

  function addSource(id: string) {
    setConnector({
      ...connector,
      datasetChanged: {
        sources: [
          ...connector.datasetChanged?.sources,
          {
            id,
          },
        ],
      },
    });
  }
  function removeSource(id: string) {
    setConnector({
      ...connector,
      datasetChanged: {
        sources: _.filter(connector.datasetChanged?.sources, s => s.id !== id),
      },
    });
  }

  async function applyChanges() {
    setIsLoading(true);
    const connectors = _.map(
      pipeline.settings?.integrationSettings?.connectors,
      c => (c.id === props.connector?.id ? connector : c),
    );

    if (props.isNew) {
      connectors.push(connector);
    }

    try {
      updatePipeline({
        importPipelineId: pipeline.id,
        settings: {
          ...pipeline.settings,
          integrationSettings: {
            ...(pipeline?.settings?.integrationSettings ?? {}),
            connectors,
          },
        },
      });

      props.onChange();
    } catch (ex) {
      console.error(ex);
      setError(ex.message);
    } finally {
      setIsLoading(false);
    }
  }

  return (
    <Container col hasOverflowY>
      <ScreenTitle
        subtitle={t`Connector properties`}
        title={t`Dataset changed`}
        isSticky
      />

      <ActionBar className="items-center space-x-2 p-2">
        <DropdownSelector
          label={t`Dataset:`}
          value={datasetId}
          values={_.keys(datasets)}
          renderValue={v =>
            _.isNil(v) ? t`No Dataset selected` : (datasets[v]?.title ?? '')
          }
          valueHelper={v =>
            v && datasets[v]
              ? formatDateTime(new Date(datasets[v].createdAt))
              : ''
          }
          onChange={v => setDatasetId(v)}
          // panelModeMultiline
          DropAlignRight
          widthFull
          panelMode
          hasSearch
          hasSearchLabel={t`Datasets`}
        />
        <Button
          label={t`Add`}
          buttonType="primary"
          buttonSize="xxs"
          className="rounded"
          hasIconBefore
          buttonIcon={<Icon.CirclePlus className={`h-4 w-4 fill-current`} />}
          onPress={() => {
            addSource(datasetId);
          }}
        />
      </ActionBar>
      {!_.isNil(error) && <ErrorIndicator message={error} />}

      <Table isSticky isSortable isHoverable>
        <TableHead>
          <TableRow>
            <TableTh isWide value={t`Type`} selectable={true} />
            <TableTh isWide value={t`Title`} selectable={true} />
            <TableTh />
          </TableRow>
        </TableHead>

        <TableBody>
          {_.isEmpty(connector.datasetChanged?.sources) ? (
            <TableRow>
              <TableTd colSpan={3}>
                <InboxZero selfCenter hasIcon message={t`No Items`}></InboxZero>
              </TableTd>
            </TableRow>
          ) : (
            _.map(connector.datasetChanged?.sources, source => {
              const ds = _.find(datasets, ds => ds.id === source.id);
              return (
                <TableRow key={`row-${source.id}`} rowLineList rowHover>
                  <TableTd>{ds.objectType ?? ''}</TableTd>
                  <TableTd>{ds.title ?? source.id}</TableTd>
                  <TableTd>
                    <Button
                      label={t`Delete`}
                      buttonSize="xs"
                      buttonType={'delete'}
                      hasIconAfter
                      buttonIcon={
                        <Icon.CircleX className={`h-5 w-5 fill-current`} />
                      }
                      onPress={() => removeSource(source.id)}
                    />
                  </TableTd>
                </TableRow>
              );
            })
          )}
        </TableBody>
      </Table>

      <ActionBar stickyBottom>
        <Button
          label={t`Cancel`}
          buttonType="secondary"
          onPress={() => {
            setShowEditor(false);
            setSelectedConnectorId(null);
          }}
          isDisabled={isLoading}
          isLoading={isLoading}
        />
        <Spacer flexspace />
        <Button
          label={props.isNew ? t`Create` : t`Update`}
          buttonType="primary"
          onPress={applyChanges}
          isDisabled={isLoading}
        />
      </ActionBar>
    </Container>
  );
};

export default DatasetConnectorEditor;
