import classNames from 'classnames';
import _ from 'lodash';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { useRecoilState } from 'recoil';
import Tabs, { TabItem } from '../../components/actions/Tabs';
import * as Icon from '../../components/icons';
import useUserPermissionFeatures from '../../permissions/roles/hooks/useUserPermissionFeatures';
import {
  importPipelineSelectedTabId,
  ImportPipelineTabId,
} from '../store/importJobs.state';
import { PipelineSectionConnectors } from './sections/PipelineSectionConnectors';
import { PipelineSectionDeletePipeline } from './sections/PipelineSectionDeletePipeline';
import { PipelineSectionProperties } from './sections/PipelineSectionProperties';
import { PipelineSectionSettings } from './sections/PipelineSectionSettings';

const ImportPipelineSettingsPanel: React.FC = () => {
  const { t } = useTranslation('feed');
  const [selectedTab, setSelectedTab] = useRecoilState(
    importPipelineSelectedTabId,
  );
  const visibleFeatures = useUserPermissionFeatures();

  const tabIconStyle = classNames(
    'fill-current w-7 lg:w-8 xl:w-9 2xl:w-10 h-auto',
  );

  const tabItems: TabItem<ImportPipelineTabId>[] = [
    {
      id: 'tab-pipeline-settings-properties',
      title: t`Properties`,
      icon: <Icon.Settings3 className={tabIconStyle} />,
      content: (
        <>
          <PipelineSectionProperties />
          <PipelineSectionSettings />
        </>
      ),
    },
    {
      id: 'tab-pipeline-settings-import',
      title: t`Import Connectors`,
      icon: <Icon.ArrowBottom className={tabIconStyle} />,
      content: (
        <>
          <PipelineSectionConnectors />
        </>
      ),
    },
    {
      id: 'tab-pipeline-settings-export',
      title: t`Export Connectors`,
      icon: <Icon.ArrowTop className={tabIconStyle} />,
      content: (
        <>
          <PipelineSectionConnectors />
        </>
      ),
    },
    // {
    //   id: 'tab-pipeline-settings-integration',
    //   title: t`Integrations`,
    //   icon: <Icon.ArrowsTwoWay className={tabIconStyle} />,
    //   content: (
    //     <>
    //       <PipelineSectionIntegration />
    //     </>
    //   ),
    // },
    {
      id: 'tab-pipeline-settings-extras',
      title: t`Extras...`,
      icon: <Icon.MenuDotsHorizontal className={tabIconStyle} />,
      content: (
        <>
          <PipelineSectionDeletePipeline />
        </>
      ),
    },
  ];

  // console.log('tabItems:', tabItems);
  const shouldHaveScroll = selectedTab !== 'tab-pipeline-settings-properties';

  const allowedTabs = tabItems.filter(tab => true);

  // console.log('visibleFeatures:', visibleFeatures);
  // console.log('allowedTabs:', allowedTabs);

  return (
    <Tabs
      componentName="ImportPipelineSettingsPanel"
      items={allowedTabs}
      selectedIndex={_.findIndex(allowedTabs, i => i.id === selectedTab)}
      onChange={index => setSelectedTab(allowedTabs[index].id)}
      classNameTitle="uppercase"
      hasScroll={shouldHaveScroll}
      fullHeight
      hasActionbar={shouldHaveScroll ? false : true}
      tabsWrapperClassNames="bg-app-panel-dark/50"
    />
  );
};

export default ImportPipelineSettingsPanel;
