import React, { PropsWithChildren } from 'react';
import { cn } from '../common/utils';

export type TagProps = PropsWithChildren & {
  className?: string;
  classType?: string;
  leftTag?: boolean;
  rightTag?: boolean;
  fullWidth?: boolean;
  title?: string;
};

const Tag: React.FC<TagProps> = props => {
  return (
    <span
      title={props.title}
      data-component="Tag"
      className={cn(
        'flex items-center',
        // 'flex-col justify-center',
        'select-text',
        'text-xs',
        'px-3 py-1',
        !props.leftTag && !props.rightTag
          ? ['rounded', 'mx-1', 'items-center']
          : '',
        props.leftTag ? 'items-start rounded-l ltr:ml-1 rtl:mr-1' : '',
        props.rightTag ? 'items-start rounded-r ltr:mr-1 rtl:ml-1' : '',
        props.fullWidth ? 'flex-1' : '',
        props.classType ? props.classType : 'bg-app-panel-dark/50',
        props.className,
      )}
    >
      {props.children}
    </span>
  );
};

export default Tag;
