import {
  AnalyzeEventFragment,
  AnalyzePicklistFragment,
  PicklistEventType,
} from '@warebee/frontend/data-access-api-graphql';
import classNames from 'classnames';
import _ from 'lodash';
import React, { useEffect, useRef } from 'react';
import { useTranslation } from 'react-i18next';
import {
  RecoilState,
  useRecoilState,
  useRecoilValue,
  useSetRecoilState,
} from 'recoil';
import { ANALYZE_WAYPOINT_HIGHLIGHT_TIMEOUT } from '../../../common/constants';
import { AsyncLoadStatus } from '../../../common/types';
import useFormatter from '../../../common/useFormatter';
import ErrorIndicator from '../../../components/ErrorIndicator';
import InboxZero from '../../../components/InboxZero';
import LoadingIndicator from '../../../components/LoadingIndicator';
import EventSection from '../../../containers/EventSection';
import { PicklistRoute } from '../../../layout/features/features.types';
import { viewerSelectedPlaneId } from '../../../layout/viewer/store/viewer.state';
import { AgentCategoryId } from '../../../resourcePolicy/agentData/agent.types';
import {
  getAgentIcon,
  getAgentTypeTitle,
} from '../../../resourcePolicy/agentData/agentDataHelper';
import { resourcePolicy } from '../../store/resourcePolicy.state';
import { createRouteForEvent } from '../../store/simulation.helper';
import {
  simulationLayoutSelectedWaypoint,
  simulationLayoutSelectedWaypointType,
} from '../../store/simulation.layout.state';
import {
  simulationAnalyzePicklistEvents,
  simulationAnalyzePicklistEventsLoadStatus,
} from '../../store/simulation.state';
import EventListItemHeader from './EventListItemHeader';
import { PicklistEventDetails } from './PicklistEventDetails';
import { PicklistLineDetails } from './PicklistLineDetails';

export type PicklistEventsProps = {
  picklistSummary: AnalyzePicklistFragment;
  selectedPicklist: RecoilState<string>;
  setRoutePart: RecoilState<PicklistRoute>;
  setRoutePartWithZoom: RecoilState<PicklistRoute>;
  wpType?: 'before' | 'after';
};

export const PicklistEvents: React.FC<PicklistEventsProps> = props => {
  const { t } = useTranslation('simulation');

  const itemsRef = useRef([]);
  const picklist = props.picklistSummary.picklist;
  const setRoutePart = useSetRecoilState(props.setRoutePart);
  const setRoutePartZoom = useSetRecoilState(props.setRoutePartWithZoom);
  const resPolicy = useRecoilValue(resourcePolicy);
  const [selectedPicklist, setSelectedPicklists] = useRecoilState(
    props.selectedPicklist,
  );
  const setAreaId = useSetRecoilState(viewerSelectedPlaneId);
  const [selectedWP, setSelectedWp] = useRecoilState(
    simulationLayoutSelectedWaypoint,
  );
  const selectedWpType = useRecoilValue(simulationLayoutSelectedWaypointType);

  const eventsLoadStatus = useRecoilValue(
    simulationAnalyzePicklistEventsLoadStatus(picklist.id),
  );
  const picklistWithEvents = useRecoilValue(
    simulationAnalyzePicklistEvents(picklist.id),
  );
  const formatter = useFormatter();

  let globalEventVisualIndex = 1;

  const line = parseInt((selectedWP as any)?.label);

  useEffect(() => {
    if (
      eventsLoadStatus !== AsyncLoadStatus.Ok ||
      selectedWpType !== props.wpType
    ) {
      return;
    }

    let timer, timer2;
    if (!Number.isNaN(line)) {
      try {
        timer2 = setTimeout(() => {
          itemsRef.current[line]?.scrollIntoView({
            behavior: 'smooth',
            block: 'center',
          });
          timer = setTimeout(() => {
            setSelectedWp(null);
          }, ANALYZE_WAYPOINT_HIGHLIGHT_TIMEOUT);
        }, 100);
      } catch (ex) {
        //
      }
    }
    return () => {
      timer && clearTimeout(timer);
      timer2 && clearTimeout(timer2);
    };
  }, [line]);

  function zoomToRoutePart(event: AnalyzeEventFragment) {
    setAreaId(picklist.planeId);
    setRoutePartZoom(createRouteForEvent(props.picklistSummary, event));
  }

  function drawEvents(events: AnalyzeEventFragment[]) {
    return events?.map(event => {
      return (
        <PicklistEventDetails
          key={`event-${picklist.id}-${event.sequenceId}`}
          index={globalEventVisualIndex++}
          event={event}
          onClick={event =>
            event.eventType !== PicklistEventType.PRE_HANDLING &&
            event.eventType !== PicklistEventType.POST_HANDLING
              ? zoomToRoutePart(event)
              : null
          }
          onHover={event =>
            event &&
            event.eventType !== PicklistEventType.PRE_HANDLING &&
            event.eventType !== PicklistEventType.POST_HANDLING
              ? setRoutePart(createRouteForEvent(props.picklistSummary, event))
              : setRoutePart(null)
          }
        />
      );
    });
  }

  if (eventsLoadStatus === AsyncLoadStatus.None) {
    return null;
  }

  if (eventsLoadStatus === AsyncLoadStatus.Loading) {
    return <LoadingIndicator selfCenter message={t`Loading order details`} />;
  }

  if (eventsLoadStatus === AsyncLoadStatus.Error) {
    return <ErrorIndicator message={t`Error`} />;
  }
  const events = picklistWithEvents?.events ?? [];

  const totalCost = _.sumBy(events, e => e.cost);
  const cost = formatter.formatCurrency(totalCost);

  const startEvents = _.takeWhile(events, e => _.isNil(e.picklistLine)).filter(
    event => event.duration > 0,
  );

  const startEventsCost = _.sumBy(startEvents, e => e.cost);

  const endEvents = _.takeRightWhile(events, e =>
    _.isNil(e.picklistLine),
  ).filter(event => event.duration > 0);
  const endEventsCost = _.sumBy(endEvents, e => e.cost);

  const eventByLine = _.groupBy(events, e => e.picklistLine);
  const agents = _.map(picklist.agents, agentId =>
    resPolicy.agents.find(a => a.id === agentId),
  );

  const agentString = _.map(
    agents,
    a => `${a.title} (${getAgentTypeTitle(a.resourceTypeId, t)})`,
  ).join(',');

  const agentResourceTypeId = agents[0].resourceTypeId;

  const agentIcon = _.map(agents, a => a.resourceTypeId);

  const isSelected = selectedPicklist === picklist.id;

  const statsStart = [
    {
      title: t`Cost`,
      value: `${
        startEventsCost < 0.01
          ? `< 0.01`
          : startEventsCost < 0.1
            ? `< 0.1`
            : startEventsCost === 0
              ? `—`
              : startEventsCost > 1
                ? startEventsCost.toFixed(0)
                : startEventsCost.toPrecision(1)
      }`,
    },
  ];

  const statsEnd = [
    {
      title: t`Cost`,
      value: `${
        endEventsCost < 0.01
          ? `< 0.01`
          : endEventsCost < 0.1
            ? `< 0.1`
            : endEventsCost === 0
              ? `—`
              : endEventsCost > 1
                ? startEventsCost.toFixed(0)
                : endEventsCost.toPrecision(1)
      }`,
    },
  ];

  return (
    <div
      data-component="EventList"
      key={`picklist-${picklist.id}`}
      className={classNames('bg-app-panel/30 w-full text-sm')}
    >
      {/* START */}
      <EventSection
        id={`picklist-start-${picklist.id}`}
        fullWidth
        inPanelView
        collapsible
        hasAgent
        titleContainer={
          <>
            <EventListItemHeader
              isFirst
              counter={t`Start`}
              title={t`Start`}
              titleAriaLabel={t`Start`}
              counterSize={'lg'}
              stats={statsStart}
            />
            <EventListItemHeader
              isAgent
              icon={getAgentIcon(agentResourceTypeId as AgentCategoryId)}
              title={agentString}
              titleAriaLabel={t`Agent`}
            />
          </>
        }
      >
        {drawEvents(startEvents)}
      </EventSection>

      {_.isEmpty(picklist.lines) ? (
        <InboxZero message={t`No Events found`} />
      ) : (
        ''
      )}

      {_.map(picklist.lines, pickLine => {
        const events = _.filter(
          eventByLine[pickLine.picklistLine],
          event => event.duration > 0,
        ); // [i] Filter out 0 time events
        const isSelected = line === pickLine.picklistLine + 1;
        isSelected && console.log('Events', events);
        return (
          <div
            data-component="picklistLine"
            key={`div-picklist-line-${pickLine.picklistLine}`}
            ref={el => (itemsRef.current[pickLine.picklistLine] = el)}
          >
            <PicklistLineDetails
              key={`picklist-line-${pickLine.picklistLine}`}
              picklistId={picklist.id}
              picklistLine={pickLine}
              events={events}
              isSelected={line === pickLine.picklistLine + 1}
            >
              {drawEvents(events)}
            </PicklistLineDetails>
          </div>
        );
      })}

      <EventSection
        id={`picklist-end-${picklist.id}`}
        fullWidth
        inPanelView
        collapsible
        titleContainer={
          <EventListItemHeader
            isLast
            counter={t`End`}
            title={t`End`}
            titleAriaLabel={t`End`}
            counterSize={'lg'}
            stats={statsEnd}
          />
        }
      >
        {drawEvents(endEvents)}
      </EventSection>
    </div>
  );
};
export default PicklistEvents;
