import { LoadingJobDetails } from '@warebee/frontend/data-access-api-graphql';
import { format } from 'date-fns';
import _ from 'lodash';
import { useTranslation } from 'react-i18next';
import { toDateFromLocaleStringDate } from '../../common/dateTimeHelper';
import useFormatter from '../../common/useFormatter';
import ButtonLocateLocation from '../../components/ButtonLocateLocation';
import { ColumnConfig } from '../../components/DatasetTable';
import { renderUomsWithTotal } from '../panels/allocate/useAllocationSummaryTableConfig';
import ItemTag from '../tags/ItemTag';
import { JobTimelineDataTableRow } from './SimulationJobTimelineDataTable';

function useSimulationJobTimelineTableConfig(): ColumnConfig<JobTimelineDataTableRow>[] {
  const { t } = useTranslation('simulation');
  const formatter = useFormatter();

  const config: ColumnConfig<JobTimelineDataTableRow>[] = [
    {
      field: 'resourceId',
      title: t`Dock`,
      render: (v: any) => <ButtonLocateLocation locationId={v} />,
    },

    {
      field: 'startTime',
      title: t`Start Date`,
      isHeader: true,
      render: (v: string) =>
        format(toDateFromLocaleStringDate(v), 'dd MMM, hh:mm'),
    },
    {
      field: 'job.duration',
      title: t`Duration`,
      render: v => formatter.formatTimespanAs24String(v as any).fullString,
    },
    {
      field: 'job.deadline',
      title: t`Deadline`,
      isHeader: true,
      render: (v: string) =>
        format(toDateFromLocaleStringDate(v), 'dd MMM, hh:mm'),
    },

    {
      field: 'jobDetails.details.picklistCount',
      title: t`Picklists`,
      render: v => formatter.formatInteger(v as any).fullString,
    },
    {
      field: 'jobDetails.details.picklistLineCount',
      title: t`Line Count`,
      render: v => formatter.formatInteger(v as any).fullString,
    },
    {
      field: 'jobDetails.details.orderCount',
      title: t`Order Count`,
      render: v => formatter.formatInteger(v as any).fullString,
    },
    {
      field: 'jobDetails.details.netWeight',
      title: t`Net Weight`,
      render: v => formatter.formatWeight(v as any).fullString,
    },
    {
      field: 'jobDetails.details',
      title: t`UOM`,
      render: (v, row) => {
        const uomQty = _.transform(
          (row.jobDetails?.details as unknown as LoadingJobDetails)
            ?.uomQuantities,
          (acc, uom: any) => (acc[uom.uom] = uom.uomQuantity),
          {},
        );
        return (
          <>
            {renderUomsWithTotal(
              uomQty,
              (row.jobDetails.details as unknown as LoadingJobDetails)
                ?.quantity,
              t,
            )}
          </>
        );
      },
    },
    {
      field: 'jobDetails.details.volume',
      title: t`Volume`,
      render: v => formatter.formatVolume(v as any, 1).fullString,
    },
    {
      field: 'job.id',
      title: t`Job ID`,
      render: (v: any) => <ItemTag title={v} filters={null} />,
    },
  ];

  return config;
}

export default useSimulationJobTimelineTableConfig;
