import { cn } from '../common/utils';
import CopyToClipboardButton from '../components/actions/CopyToClipboardButton';
import Tag from './Tag';

type TagProps = {
  value: string;
  showFull?: boolean;
  canCopy?: boolean;
  startSlice?: number;
  endSlice?: number;
  className?: string;
};

export const TagId = ({
  value,
  showFull = false,
  canCopy = true,
  startSlice = 8,
  endSlice = 8,
  className,
}: TagProps) => {
  if (!value) return null;

  return (
    <Tag className={cn('group relative w-full', className)} title={value}>
      <span className={cn('max-w-ch-20 w-full truncate ltr:mr-1 rtl:ml-1')}>
        {showFull
          ? value
          : `${value.slice(0, startSlice)} - ... - ${value.slice(-endSlice)}`}
      </span>
      {canCopy && (
        <CopyToClipboardButton
          hasIconSmall
          value={value}
          className="absolute -left-1 -top-2 hidden group-hover:inline-flex"
        />
      )}
    </Tag>
  );
};
