import {
  SortDirection,
  StringSearchFilter,
  StringSearchFilterType,
} from '@warebee/frontend/data-access-api-graphql';
import { applyFilterUnion } from '@warebee/shared/athena-db-model';
import { QueryCreator, sql } from 'kysely';
import _ from 'lodash';
import { KeysMatching } from '../../../common/utility.types';
import { FilterPreset } from '../../../filterPreset/store/filterPreset.types';
import { getActualityFilterDescSet } from '../actuality.hq.helper';
import {
  ActualityHqDataColumn,
  ActualityHqDataRow,
} from './actualityHqDataRows';
import {
  DatasetDatabase,
  datasetQueryBuilder,
} from './queryBuilder/datasetQueryBuilder';
import {
  ActivityFeedQueryBuilderParams,
  getActivityFeedFilteredQueryBuilder,
} from './queryBuilder/feedEventsQueryBuilders';

export type ActualityHqQueryBuilderParams = ActivityFeedQueryBuilderParams & {
  layoutId: string;
  itemSetId: string;
  assignmentId: string;
  filterBy?: ActualityHqFilterBy;
  filterPreset?: FilterPreset<ActualityHqDataColumn>;
};

export type ActualityHqSortBy = {
  direction?: SortDirection;
  field: ActualityHqDataColumn;
};

export type ActualityHqFieldWithStringFilter = KeysMatching<
  ActualityHqDataRow,
  string
>;

export type ActualityHqFilterBy = Partial<
  Record<ActualityHqFieldWithStringFilter, StringSearchFilter>
>;

function getItemsFilteredQueryBuilder<T extends DatasetDatabase>(
  params: ActualityHqQueryBuilderParams,
  db: QueryCreator<T>,
) {
  let at = db
    .selectFrom('___item_set_iceberg___')
    .where('datasetObjectId', '=', params.itemSetId as any);
  return at;
}

function getLayoutFilteredQueryBuilder<T extends DatasetDatabase>(
  params: ActualityHqQueryBuilderParams,
  db: QueryCreator<T>,
) {
  let at = db
    .selectFrom('___layout_location___')
    .where('datasetObjectId', '=', params.layoutId as any);
  return at;
}

function getAssignmentFilteredQueryBuilder<T extends DatasetDatabase>(
  params: ActualityHqQueryBuilderParams,
  db: QueryCreator<T>,
) {
  let at = db
    .selectFrom('___assignment___')
    .where('datasetObjectId', '=', params.assignmentId as any);
  return at;
}

export function getHqQueryBuilder(params: ActualityHqQueryBuilderParams) {
  let builder = datasetQueryBuilder
    .with(
      cte => cte('af'),
      db => {
        return getActivityFeedFilteredQueryBuilder(params, db);
      },
    )
    .with(
      cte => cte('is'),
      db => {
        return getItemsFilteredQueryBuilder(params, db).selectAll();
      },
    )
    .with(
      cte => cte('l'),
      db => {
        return getLayoutFilteredQueryBuilder(params, db).selectAll();
      },
    )
    .with(
      cte => cte('a'),
      db => {
        //return getActivityAssignmentFilteredQueryBuilder(params, db);

        return getAssignmentFilteredQueryBuilder(params, db)
          .selectAll()
          .select(({ fn }) => [
            sql<string>`${sql.ref('consignee')} ||'-'|| ${sql.ref('sku')} `.as(
              'skuKey',
            ),
            sql<number>`cast(json_query(${sql.ref('raw_data')}, 'strict $.total_qty') as double)`.as(
              'stock_total_qty',
            ),
          ]);
      },
    )
    .with(
      cte => cte('afa'),
      db => {
        return db
          .selectFrom('af')
          .leftJoin('a', join =>
            join
              .onRef('af.locationId', '=', 'a.locationId')
              .onRef('af.consignee', '=', 'a.consignee')
              .onRef('af.sku', '=', 'a.sku'),
          )
          .selectAll('af')
          .select(eb => [
            eb
              .case()
              .when('a.consignee', 'is', null)
              .then(false)
              .else(true)
              .end()
              .as('pickByAssignment'),
          ]);
      },
    )
    .with(
      cte => cte('hqRaw'),
      db => {
        return (
          db
            .selectFrom('afa as af')
            .leftJoin('is', join =>
              join
                .onRef('af.consignee', '=', 'is.consignee')
                .onRef('af.sku', '=', 'is.sku')
                .onRef('af.uom', '=', 'is.uom'),
            )
            .leftJoin('l', join =>
              join.onRef('af.locationId', '=', 'l.locationId'),
            )
            .selectAll('af')
            .select([
              'is.skuGroup',
              'is.name',
              'is.description',
              'is.subGroup',
              'is.transportClass',
              'is.stockCategory',
              'is.storageClass',
              'is.pollutionClass',
              'is.lowerUom',
              'is.netWeight',
              'is.length',
              'is.width',
              'is.height',
              'is.volume',
              'is.unitsPerLowestUom',
              'is.ean',
              'is.upc',
              'l.locationId as layoutLocationId',
              'l.locationOrder',
              'l.locationStatus',
              'l.locationLevel',
              'l.locationLength',
              'l.locationWidth',
              'l.locationHeight',
              'l.locationWeight',
              'l.locationBayId',
              'l.locationBayTitle',
              'l.locationBayPosition',
              'l.locationDepthPosition',
              'l.locationUsageType',
              'l.locmhtype',
              'l.locationRackingType',
              'l.warehouseArea as layoutWarehouseArea',
              'l.locationSide',
              'l.congestionZone',
              'l.locationBayProjection',
              'l.locationHeightFromFloor',
              'l.locationDepthFromFront',
              'l.locationIndexFromFront',
              'l.bayType',
              'l.aisleId',
              'l.aisleTitle',
              'l.planeId',
              'l.planeTitle',
            ])
            // .select(
            //   sql<string>`${sql.ref('af.consignee')} ||'-'|| ${sql.ref('af.sku')} `.as(
            //     'skuKey',
            //   ),
            // )
            .select(
              sql<string>`${sql.ref('af.locationId')} || '-' || ${sql.ref('af.consignee')} ||'-'|| ${sql.ref('af.sku')} `.as(
                'assignmentItem',
              ),
            )
            .select(
              sql<Date>`
            first_value(to_unixtime(${sql.ref('af.eventEndTime')})) 
          OVER(
            PARTITION BY ${sql.ref('af.locationId')}, ${sql.ref('af.consignee')}, ${sql.ref('af.sku')}
            ORDER BY ${sql.ref('eventEndTime')} DESC
          )`.as('lastEventTime'),
            )
        );
      },
    )
    .with(
      cte => cte('hq'),
      db => {
        let builder = db.selectFrom('hqRaw').selectAll();

        if (params.filterBy) {
          builder = _.reduce(
            params.filterBy,
            (q, value, key) => {
              if (
                value?.type === StringSearchFilterType.CONTAINS &&
                value?.value === 'undefined'
              ) {
                return q.where(key as any, 'is', null);
              }

              if (
                value?.type === StringSearchFilterType.CONTAINS &&
                !_.isEmpty(value?.value)
              ) {
                return q.where(key as any, 'like', `%${value.value}%`);
              }

              return q;
            },
            builder,
          );
        }

        if (!_.isEmpty(params.filterPreset?.filterUnion?.anyOf)) {
          const [filterBuilder, exp] = applyFilterUnion(
            builder,
            getActualityFilterDescSet(),
            params.filterPreset.filterUnion,
          );

          builder = filterBuilder.where(exp);
        }

        return builder;
      },
    );

  return builder;
}
