/* eslint-disable react/jsx-no-useless-fragment */
import _ from 'lodash';
import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { cn } from '../../common/utils';
import { InputSearch } from '../inputs/InputSearch';

export type StatListHeaderProps = {
  children?: React.ReactNode;
  className?: string;
  value?: string;
  title?: string | React.ReactNode;
  isActionable?: boolean;
  onClick?: () => void;
  isSelected?: boolean;
  valueBefore?: boolean;
  valueAfter?: boolean;
  isComparable?: boolean;
  isPrimaryResult?: boolean;
  hasSearch?: boolean;
  searchValue?: string;
  onSearch?: (searchValue: string) => void;
};

export const StatListHeader = ({
  children,
  className,
  value,
  title,
  isActionable,
  onClick,
  isSelected,
  isComparable,
  hasSearch,
  isPrimaryResult,
  searchValue,
  onSearch,
}: StatListHeaderProps) => {
  const { t } = useTranslation('simulation');

  const [id] = useState<string>(_.uniqueId());

  return (
    <div
      id={id}
      data-component="StatListHeader"
      className={cn(
        'group flex-1 text-xs uppercase',
        isPrimaryResult ? 'text-menu' : 'text-menu-text/60',
        className,
      )}
      onClick={() => onClick && onClick()}
    >
      <div
        className={cn(
          'border-menu/80 border-b',
          'mt-0.5',
          'flex items-end',
          'py-1',
        )}
      >
        <div
          className={cn(
            'flex-1 font-bold',
            'ltr:pr-1 rtl:pl-1',
            isActionable
              ? isSelected && 'bg-menu-active text-menu-active-text'
              : '',
          )}
        >
          {hasSearch ? (
            <InputSearch
              titleMode
              headerStatMode
              placeholder={`${title}...`}
              value={searchValue || ''}
              onChange={v => onSearch(v)}
            />
          ) : (
            <>{title}</>
          )}
        </div>
        {value && (
          <>
            <div
              className={cn(
                'truncate text-end font-bold',
                'lg:min-w-20 xl:min-w-24',
                'ltr:pr-1 ltr:xl:pl-2 rtl:pl-1 rtl:xl:pr-2',
                { 'bg-menu-active text-menu-active-text': isActionable },
              )}
            >
              {value}
              {isComparable && (
                <div className={cn('text-menu/80 flex text-xs')}>
                  <div className={cn('flex-1')}>{t`Before`}</div>
                  <div>
                    <span className={cn('text-menu mx-1')}>{` | `}</span>
                    {t`After`}
                  </div>
                </div>
              )}
            </div>
          </>
        )}
      </div>

      {children}
    </div>
  );
};
