import _ from 'lodash';
import { useTranslation } from 'react-i18next';
import ButtonLocateLocation from '../components/ButtonLocateLocation';
import { ColumnConfig } from '../components/DatasetTable';
import { AnalyzeGatesStatsRow } from '../feed/store/datasetQueries/analyzeGatesStatsQuery';
import useEndpointDistributionKPIDescriptors from './useEndpointDistributionKPIDescriptors';

function useAnalyzeResultEndpointDistributionTableConfig(): ColumnConfig<AnalyzeGatesStatsRow>[] {
  const { t } = useTranslation('simulation');

  const kpis = useEndpointDistributionKPIDescriptors();
  const columnsConfig: ColumnConfig<AnalyzeGatesStatsRow>[] = [
    {
      field: 'locationId',
      title: t`Location`,
      render: (locationId: string, row) =>
        locationId ? (
          <ButtonLocateLocation locationId={locationId} />
        ) : (
          t`Default (Endpoint)`
        ),
    },
    {
      field: 'eventType',
      title: t`Type`,
      render: (eventType: string, row) => {
        switch (eventType) {
          case 'PRE_HANDLING':
            return t`Start`;
          case 'TRAVELLING_HORIZONTAL_END':
            return t`End`;
        }
      },
    },
    ..._.map(kpis, f => ({
      field: f.id,
      title: f.title,
      render: (v: number) => f.formatter(v).fullString,
    })),
  ];

  return columnsConfig;
}
export default useAnalyzeResultEndpointDistributionTableConfig;
