import { LayoutLevelLocationFragment } from '@warebee/frontend/data-access-api-graphql';
import _ from 'lodash';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { useRecoilValue, useRecoilValueLoadable } from 'recoil';
import { formatInteger } from '../../common/formatHelper';
import {
  getHeatmapBucketByValue,
  HEATMAP_ZERO_BUCKET_ID,
} from '../../common/heatmap.helper';
import LocationLayer from '../../layout/viewer/layers/LocationLayer';
import { occupancyMetricRange } from '../store/occupancy/assignmentOccupancy.defaults';
import {
  getOccupancyCategory,
  getOccupancyHeatmapBuckets,
} from '../store/occupancy/assignmentOccupancy.helper';
import {
  assignmentOccupancyCategoryFilter,
  assignmentOccupancyLevelLocation,
  assignmentOccupancyMetricDescriptorSelected,
  assignmentOccupancyShowHeatmap,
} from '../store/occupancy/assignmentOccupancy.state';

const AssignmentOccupancyLocationLayer: React.FC = () => {
  const { t } = useTranslation('simulation');
  const occupancyMetricDescriptor = useRecoilValue(
    assignmentOccupancyMetricDescriptorSelected,
  );
  const levelLocationLoadable = useRecoilValueLoadable(
    assignmentOccupancyLevelLocation,
  );
  const showHeatmap = useRecoilValue(assignmentOccupancyShowHeatmap);
  const categoryFilter = useRecoilValue(assignmentOccupancyCategoryFilter);

  const levelLocation =
    levelLocationLoadable.state === 'hasValue'
      ? levelLocationLoadable.contents
      : null;

  const hasFilter = !_.isEmpty(categoryFilter);
  const filter = (locationFeature: LayoutLevelLocationFragment) => {
    const loc = levelLocation?.[locationFeature.locationId]?.[0];
    if (_.isNil(loc)) return false;

    const cat = getOccupancyCategory(loc?.occupancy);
    return _.has(categoryFilter, cat.key);
  };

  const bucketsMap = _.keyBy(getOccupancyHeatmapBuckets(t), b => b.id);

  let getColor: (loc: LayoutLevelLocationFragment) => [string, string] | null;
  // let getLabel: (loc: LayoutLevelLocationFragment) => string;
  let getGradientColors: (loc: LayoutLevelLocationFragment) => string[];

  function getLabel(locationFeature: LayoutLevelLocationFragment) {
    const loc = levelLocation?.[locationFeature.locationId];
    const itemCount = _.size(loc);
    return itemCount > 1 ? formatInteger(itemCount) : null;
  }

  // if (occupancyMetricDescriptor === 'occupancy-rate') {
  getGradientColors = (loc: LayoutLevelLocationFragment): string[] => {
    const locationRows = levelLocation?.[loc.locationId];
    if (_.isEmpty(locationRows)) {
      return [bucketsMap[HEATMAP_ZERO_BUCKET_ID].color];
    }

    const colors = _(locationRows)
      .map(data => {
        return getHeatmapBucketByValue({
          data,
          buckets: bucketsMap,
          metricDescriptor: occupancyMetricDescriptor,
          rangeDescriptor: occupancyMetricRange,
        });
      })
      .compact()
      // .filter(b => b && heatmapFilter.hiddenBuckets[b.id] !== true)
      .sortBy(b => -b.index)
      .map(p => p.color)
      .uniq()
      .value();

    return _.isEmpty(colors) ? null : colors;
  };
  // }

  // const  getColor(
  //   locationFeature: LayoutLevelLocationFragment,
  // ): [string, string] {
  //   const loc = levelLocation?.[locationFeature.locationId];

  //   if (loc && showHeatmap) {
  //     const cat = getOccupancyCategory(loc?.occupancy);
  //     return [cat.color, cat.textColor];
  //   }
  //   return null;
  // }

  return (
    <LocationLayer
      filter={hasFilter ? filter : null}
      getLabel={getLabel}
      getColor={showHeatmap ? getColor : null}
      getGradientColors={showHeatmap ? getGradientColors : null}
    />
  );
};

export default AssignmentOccupancyLocationLayer;
