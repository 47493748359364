import { ItemFilterFragment } from '@warebee/frontend/data-access-api-graphql';
import _ from 'lodash';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { useRecoilState, useRecoilValue } from 'recoil';
import { FilterFieldConfigBase } from '../common/types';
import {
  LoadAllPolicyFilterValuesFilterParams,
  PolicyFilterIntersection,
} from '../policyFilters/policyFilter.types';
import PolicyFilterValueEditor, {
  PolicyFilterValueEditorProps,
} from '../policyFilters/PolicyFilterValueEditor';
import {
  filterPresetFilterIntersection,
  filterPresetSelectedIdentity,
} from './store/filterPreset.state';

export type FilterPresetFilterEditorProps = {
  filterConfig: FilterFieldConfigBase<string>;
  startLoadData: (
    params: LoadAllPolicyFilterValuesFilterParams<string>,
  ) => Promise<void>;
  cancelLoadData: (fieldId: string) => void;
};

const FilterPresetFilterEditor: React.FC<
  FilterPresetFilterEditorProps
> = props => {
  const { t } = useTranslation('app');
  const { type } = props.filterConfig;
  const { startLoadData, cancelLoadData } = props;

  const editableFilterSetIdentity = useRecoilValue(
    filterPresetSelectedIdentity,
  );
  const [filterIntersection, setFilterIntersection] = useRecoilState(
    filterPresetFilterIntersection(editableFilterSetIdentity),
  );

  if (!editableFilterSetIdentity || !filterIntersection) return null;

  const fieldValue = _.find(filterIntersection?.allOf, f => f.type === type);

  const onSelectValue = (value: ItemFilterFragment) => {
    const others = filterIntersection.allOf.filter(fi => fi.type !== type);
    // If all values vas deselected in filter, we shouldn't add this filter to set
    if (
      !_.isEmpty(value.valueIn) ||
      !_.isNil(value?.range?.from) ||
      !_.isNil(value?.range?.to)
    ) {
      others.push(value);
    }

    const newIntersection: PolicyFilterIntersection<string> = {
      id: filterIntersection.id,
      allOf: others,
    };

    setFilterIntersection(newIntersection);

    startLoadData({
      field: type,
      filterKey: '',
      filterIntersection: newIntersection,
      affect: 'others',
    });
  };

  const editorProps: PolicyFilterValueEditorProps<string> = {
    config: props.filterConfig,
    value: fieldValue,
    onValueChange: onSelectValue,
    startLoadData: params =>
      startLoadData({ ...params, filterIntersection, affect: 'self' }),
    cancelLoadData,
  };

  return <PolicyFilterValueEditor {...editorProps} />;
};
export default FilterPresetFilterEditor;
