import _ from 'lodash';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { useRecoilState } from 'recoil';
import { cn } from '../../../../common/utils';
import ButtonSwitchMulti from '../../../../components/actions/ButtonSwitchMulti';
import { assignmentOccupancyMode } from '../../../store/occupancy/assignmentOccupancy.state';
import { OccupancyMode } from '../../../store/occupancy/assignmentOccupancy.types';

const OccupancyModeSwitch: React.FC = () => {
  const { t } = useTranslation('app');
  const [mode, setMode] = useRecoilState(assignmentOccupancyMode);

  const viewAs: { key: OccupancyMode; label: string }[] = [
    {
      key: 'volume',
      label: t`Volume`,
    },
    {
      key: 'stockUom',
      label: t`Stock UOM`,
    },
    {
      key: 'maxUom',
      label: t`Max UOM`,
    },
  ];

  return (
    <>
      <ButtonSwitchMulti
        autoSize
        className={cn('mx-0.5 w-full xl:mx-3')}
        classNameLabel={cn('text-xs py-0.5 px-2')}
        buttonType="header"
        options={viewAs}
        selectedIndex={_.findIndex(viewAs, o => o.key === mode)}
        onClick={index => setMode(viewAs[index].key)}
      />
    </>
  );
};

export default OccupancyModeSwitch;
