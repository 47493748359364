import { TFunction } from 'i18next';
import {
  FilterFieldConfigBase,
  FilterFieldEditorType,
} from '../../common/types';
import i18n from '../../i18n';
import { ActualityHqDataColumn } from './datasetQueries/actualityHqDataRows';

export function getActualityHqConfigBase(
  t: TFunction<'app'> = i18n.t,
): FilterFieldConfigBase<ActualityHqDataColumn>[] {
  return [
    {
      type: 'eventId',
      title: t('Event Id', { ns: 'simulation' }),
      editorType: FilterFieldEditorType.MultipleCheck,
      disabled: true,
    },
    {
      type: 'eventProcessType',
      title: t('Process Type', { ns: 'simulation' }),
      editorType: FilterFieldEditorType.MultipleCheck,
      group: t('Events', { ns: 'simulation' }),
    },
    {
      type: 'eventType',
      title: t('Event Type', { ns: 'simulation' }),
      editorType: FilterFieldEditorType.MultipleCheck,
    },
    {
      type: 'consignee',
      title: t('Consignee', { ns: 'simulation' }),
      editorType: FilterFieldEditorType.MultipleCheck,
    },
    {
      type: 'sku',
      title: t('Sku', { ns: 'simulation' }),
      editorType: FilterFieldEditorType.MultipleCheck,
    },
    {
      type: 'uom',
      title: t('Uom', { ns: 'simulation' }),
      editorType: FilterFieldEditorType.MultipleCheck,
    },
    {
      type: 'quantity',
      title: t('Quantity', { ns: 'simulation' }),
      editorType: FilterFieldEditorType.MultipleCheck,
    },
    {
      type: 'scanCode',
      title: t('Scan Code', { ns: 'simulation' }),
      editorType: FilterFieldEditorType.MultipleCheck,
    },
    {
      type: 'warehouseAreaSource',
      title: t('Warehouse Area Source', { ns: 'simulation' }),
      editorType: FilterFieldEditorType.MultipleCheck,
      disabled: true,
    },
    {
      type: 'locationIdSource',
      title: t('Location Id Source', { ns: 'simulation' }),
      editorType: FilterFieldEditorType.MultipleCheck,
      disabled: true,
    },
    {
      type: 'warehouseArea',
      title: t('Warehouse Area', { ns: 'simulation' }),
      editorType: FilterFieldEditorType.MultipleCheck,
      disabled: true,
    },
    {
      type: 'locationId',
      title: t('Location Id', { ns: 'simulation' }),
      editorType: FilterFieldEditorType.MultipleCheck,
    },
    {
      type: 'agentType',
      title: t('Agent Type', { ns: 'simulation' }),
      editorType: FilterFieldEditorType.MultipleCheck,
    },
    {
      type: 'agentId',
      title: t('Agent Id', { ns: 'simulation' }),
      editorType: FilterFieldEditorType.MultipleCheck,
    },
    {
      type: 'agentUser',
      title: t('Agent User', { ns: 'simulation' }),
      editorType: FilterFieldEditorType.MultipleCheck,
    },
    {
      type: 'agentEnergy',
      title: t('Agent Energy', { ns: 'simulation' }),
      editorType: FilterFieldEditorType.MultipleCheck,
      disabled: true,
    },
    {
      type: 'agentSignalStrength',
      title: t('Agent Signal Strength', { ns: 'simulation' }),
      editorType: FilterFieldEditorType.MultipleCheck,
      disabled: true,
    },
    {
      type: 'eventStartTime',
      title: t('Event Start Time', { ns: 'simulation' }),
      editorType: FilterFieldEditorType.MultipleCheck,
      disabled: true,
    },
    {
      type: 'jobId',
      title: t('Job Id', { ns: 'simulation' }),
      editorType: FilterFieldEditorType.MultipleCheck,
    },
    {
      type: 'jobLine',
      title: t('Job Line', { ns: 'simulation' }),
      editorType: FilterFieldEditorType.MultipleCheck,
    },
    {
      type: 'jobGroupId',
      title: t('Job Group Id', { ns: 'simulation' }),
      editorType: FilterFieldEditorType.MultipleCheck,
    },
    {
      type: 'datasetObjectId',
      title: t('Dataset Object Id', { ns: 'simulation' }),
      editorType: FilterFieldEditorType.MultipleCheck,
      disabled: true,
    },
    {
      type: 'eventEndTime',
      title: t('Event End Time', { ns: 'simulation' }),
      editorType: FilterFieldEditorType.MultipleCheck,
    },
    {
      type: 'importJobId',
      title: t('Import Job Id', { ns: 'simulation' }),
      editorType: FilterFieldEditorType.MultipleCheck,
      disabled: true,
    },
    {
      type: 'skuKey',
      title: t('Sku Key', { ns: 'simulation' }),
      editorType: FilterFieldEditorType.MultipleCheck,
      disabled: true,
    },
    {
      type: 'jobKey',
      title: t('Job Key', { ns: 'simulation' }),
      editorType: FilterFieldEditorType.MultipleCheck,
      disabled: true,
    },
    {
      type: 'pickByAssignment',
      title: t('Pick By Assignment', { ns: 'simulation' }),
      editorType: FilterFieldEditorType.MultipleCheck,
    },
    {
      type: 'skuGroup',
      title: t('Sku Group', { ns: 'simulation' }),
      editorType: FilterFieldEditorType.MultipleCheck,
    },
    // {
    //   type: 'name',
    //   title: t('Name', { ns: 'simulation' }),
    //   editorType: FilterFieldEditorType.MultipleCheck,
    // },
    // {
    //   type: 'description',
    //   title: t('Description', { ns: 'simulation' }),
    //   editorType: FilterFieldEditorType.MultipleCheck,
    // },
    {
      type: 'subGroup',
      title: t('Sub Group', { ns: 'simulation' }),
      editorType: FilterFieldEditorType.MultipleCheck,
    },
    {
      type: 'transportClass',
      title: t('Transport Class', { ns: 'simulation' }),
      editorType: FilterFieldEditorType.MultipleCheck,
    },
    {
      type: 'stockCategory',
      title: t('Stock Category', { ns: 'simulation' }),
      editorType: FilterFieldEditorType.MultipleCheck,
    },
    {
      type: 'storageClass',
      title: t('Storage Class', { ns: 'simulation' }),
      editorType: FilterFieldEditorType.MultipleCheck,
    },
    {
      type: 'pollutionClass',
      title: t('Pollution Class', { ns: 'simulation' }),
      editorType: FilterFieldEditorType.MultipleCheck,
    },
    {
      type: 'lowerUom',
      title: t('Lower Uom', { ns: 'simulation' }),
      editorType: FilterFieldEditorType.MultipleCheck,
    },
    {
      type: 'netWeight',
      title: t('Net Weight', { ns: 'simulation' }),
      editorType: FilterFieldEditorType.MultipleCheck,
    },
    {
      type: 'length',
      title: t('Length', { ns: 'simulation' }),
      editorType: FilterFieldEditorType.MultipleCheck,
    },
    {
      type: 'width',
      title: t('Width', { ns: 'simulation' }),
      editorType: FilterFieldEditorType.MultipleCheck,
    },
    {
      type: 'height',
      title: t('Height', { ns: 'simulation' }),
      editorType: FilterFieldEditorType.MultipleCheck,
    },
    {
      type: 'volume',
      title: t('Volume', { ns: 'simulation' }),
      editorType: FilterFieldEditorType.MultipleCheck,
    },
    {
      type: 'unitsPerLowestUom',
      title: t('Units Per Lowest Uom', { ns: 'simulation' }),
      editorType: FilterFieldEditorType.MultipleCheck,
    },
    {
      type: 'ean',
      title: t('EAN', { ns: 'simulation' }),
      editorType: FilterFieldEditorType.MultipleCheck,
    },
    {
      type: 'upc',
      title: t('UPC', { ns: 'simulation' }),
      editorType: FilterFieldEditorType.MultipleCheck,
    },
    // {
    //   type: 'layoutLocationId',
    //   title: t('Layout Location Id', { ns: 'simulation' }),
    //   editorType: FilterFieldEditorType.MultipleCheck,
    // },
    {
      type: 'locationOrder',
      title: t('Location Order', { ns: 'simulation' }),
      editorType: FilterFieldEditorType.MultipleCheck,
    },
    {
      type: 'locationStatus',
      title: t('Location Status', { ns: 'simulation' }),
      editorType: FilterFieldEditorType.MultipleCheck,
    },
    {
      type: 'locationLevel',
      title: t('Location Level', { ns: 'simulation' }),
      editorType: FilterFieldEditorType.MultipleCheck,
    },
    {
      type: 'locationLength',
      title: t('Location Length', { ns: 'simulation' }),
      editorType: FilterFieldEditorType.MultipleCheck,
    },
    {
      type: 'locationWidth',
      title: t('Location Width', { ns: 'simulation' }),
      editorType: FilterFieldEditorType.MultipleCheck,
    },
    {
      type: 'locationHeight',
      title: t('Location Height', { ns: 'simulation' }),
      editorType: FilterFieldEditorType.MultipleCheck,
    },
    {
      type: 'locationWeight',
      title: t('Location Weight', { ns: 'simulation' }),
      editorType: FilterFieldEditorType.MultipleCheck,
    },
    {
      type: 'locationBayId',
      title: t('Location Bay Id', { ns: 'simulation' }),
      editorType: FilterFieldEditorType.MultipleCheck,
    },
    // {
    //   type: 'locationBayTitle',
    //   title: t('Location Bay Title', { ns: 'simulation' }),
    //   editorType: FilterFieldEditorType.MultipleCheck,
    // },
    {
      type: 'locationBayPosition',
      title: t('Location Bay Position', { ns: 'simulation' }),
      editorType: FilterFieldEditorType.MultipleCheck,
    },
    {
      type: 'locationDepthPosition',
      title: t('Location Depth Position', { ns: 'simulation' }),
      editorType: FilterFieldEditorType.MultipleCheck,
    },
    {
      type: 'locationUsageType',
      title: t('Location Usage Type', { ns: 'simulation' }),
      editorType: FilterFieldEditorType.MultipleCheck,
    },
    {
      type: 'locmhtype',
      title: t('MHE Type', { ns: 'simulation' }),
      editorType: FilterFieldEditorType.MultipleCheck,
    },
    {
      type: 'locationRackingType',
      title: t('Location Racking Type', { ns: 'simulation' }),
      editorType: FilterFieldEditorType.MultipleCheck,
    },
    {
      type: 'layoutWarehouseArea',
      title: t('Warehouse Area', { ns: 'simulation' }),
      editorType: FilterFieldEditorType.MultipleCheck,
    },
    {
      type: 'locationSide',
      title: t('Location Side', { ns: 'simulation' }),
      editorType: FilterFieldEditorType.MultipleCheck,
    },
    {
      type: 'congestionZone',
      title: t('Congestion Zone', { ns: 'simulation' }),
      editorType: FilterFieldEditorType.MultipleCheck,
    },
    {
      type: 'locationBayProjection',
      title: t('Location Bay Projection', { ns: 'simulation' }),
      editorType: FilterFieldEditorType.MultipleCheck,
    },
    {
      type: 'locationHeightFromFloor',
      title: t('Location Height From Floor', { ns: 'simulation' }),
      editorType: FilterFieldEditorType.MultipleCheck,
    },
    {
      type: 'locationDepthFromFront',
      title: t('Location Depth From Front', { ns: 'simulation' }),
      editorType: FilterFieldEditorType.MultipleCheck,
    },
    {
      type: 'locationIndexFromFront',
      title: t('Location Index From Front', { ns: 'simulation' }),
      editorType: FilterFieldEditorType.MultipleCheck,
    },
    {
      type: 'bayType',
      title: t('Bay Type', { ns: 'simulation' }),
      editorType: FilterFieldEditorType.MultipleCheck,
    },
    {
      type: 'aisleId',
      title: t('Aisle Id', { ns: 'simulation' }),
      editorType: FilterFieldEditorType.MultipleCheck,
    },
    {
      type: 'aisleTitle',
      title: t('Aisle Title', { ns: 'simulation' }),
      editorType: FilterFieldEditorType.MultipleCheck,
    },
    {
      type: 'planeId',
      title: t('Plane Id', { ns: 'simulation' }),
      editorType: FilterFieldEditorType.MultipleCheck,
    },
    {
      type: 'planeTitle',
      title: t('Plane Title', { ns: 'simulation' }),
      editorType: FilterFieldEditorType.MultipleCheck,
    },
    // {
    //   type: 'assignmentItem',
    //   title: t('Assignment Item', { ns: 'simulation' }),
    //   editorType: FilterFieldEditorType.MultipleCheck,
    // },
    {
      type: 'lastEventTime',
      title: t('Last Event Time', { ns: 'simulation' }),
      editorType: FilterFieldEditorType.MultipleCheck,
    },
  ];
}
