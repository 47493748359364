import _ from 'lodash';
import React from 'react';
import { FormattedValueWithUnit } from '../common/formatHelper';
import DropdownSelector from '../components/actions/DropdownSelector';
import { AnalyzeGatesStatsRow } from '../feed/store/datasetQueries/analyzeGatesStatsQuery';
import useEndpointDistributionKPIDescriptors from './useEndpointDistributionKPIDescriptors';

export type EndpointDistributionKPI = keyof Pick<
  AnalyzeGatesStatsRow,
  'jobsCount' | 'cost' | 'duration' | 'weight' | 'volume'
>;

export type EndpointDistributionKPIDescriptor = {
  id: EndpointDistributionKPI;
  title: string;
  formatter: (v: number) => FormattedValueWithUnit;
};

export type AnalyzeResultEndpointDistributionKPISelectorProps = {
  selected: EndpointDistributionKPI;
  setSelected: (value: EndpointDistributionKPI) => void;
};

const AnalyzeResultEndpointDistributionKPISelector: React.FC<
  AnalyzeResultEndpointDistributionKPISelectorProps
> = props => {
  const kpiDescriptors = useEndpointDistributionKPIDescriptors();

  return (
    <DropdownSelector
      value={props.selected}
      values={_.keys(kpiDescriptors) as EndpointDistributionKPI[]}
      renderValue={v => kpiDescriptors[v]?.title}
      onChange={v => props.setSelected(v)}
      // buttonTransparent
      // panelMode
      dark
      w_sm
      className="mx-1 bg-opacity-70"
      classNameValue="text-sm"
    />
  );
};

export default AnalyzeResultEndpointDistributionKPISelector;
