import classNames from 'classnames';
import React, { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useRecoilState, useRecoilValue, useResetRecoilState } from 'recoil';
import DropdownSelector from '../../components/actions/DropdownSelector';
import InboxZero from '../../components/InboxZero';
import { ActionBar } from '../../components/nav/ActionBar';
import { StatGroup } from '../../components/stats/StatGroup';
import { FeedPicklistDetails } from '../components/FeedPicklistDetails';
import {
  actualityAgentsAnalyzedJobs,
  actualityJobTimingMode,
} from '../store/actuality.jobs.state';
import { actualityJobTimingModes } from '../store/feed.default';
import { feedSelectedPickListId } from '../store/feed.state';

const FeedPicklistPanel: React.FC = () => {
  const { t } = useTranslation('app');
  const agentsJobs = useRecoilValue(actualityAgentsAnalyzedJobs);
  const resetSelectedPicklist = useResetRecoilState(feedSelectedPickListId);
  const [timingMode, setTimingMode] = useRecoilState(actualityJobTimingMode);

  useEffect(() => {
    return () => {
      resetSelectedPicklist();
    };
  }, []);

  return (
    <div
      data-component="FeedPicklistPanel"
      className={classNames('h-full overflow-y-auto')}
    >
      <ActionBar
        transparent
        className={classNames(
          'p-2',
          'bg-app-panel-light/40',
          'saturate-110 backdrop-blur',
          'sticky top-0',
          'z-header_screen',
        )}
      >
        <DropdownSelector
          className="text-xs"
          value={timingMode}
          values={[...actualityJobTimingModes]}
          renderValue={v => `${t`Time View: `}${v}`}
          onChange={selectedMode => setTimingMode(selectedMode)}
          valuePrimary
          light
          widthFull
        />
      </ActionBar>

      <StatGroup helpNavTo={'live/feed'}>
        {agentsJobs?.length > 0 ? (
          agentsJobs.map((pl, index) => (
            <FeedPicklistDetails key={pl.jobKey} index={index} job={pl} />
          ))
        ) : (
          <InboxZero selfCenter message={t`No Events found`} />
        )}
      </StatGroup>
    </div>
  );
};

export default FeedPicklistPanel;
