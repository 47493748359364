import classNames from 'classnames';
import _ from 'lodash';
import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useRecoilValue } from 'recoil';
import { ActionBar } from '../../components/nav/ActionBar';
import { DatasetType } from '../../import/store/import.types';
import ImportPipelineFileUploaderByType from '../../importJobs/ImportPipelineFileUploaderByType';
import { warehouseIsDemo } from '../../store/warehouse.state';

export type ImportHelperProps = {
  children?: React.ReactNode;
  className?: string;
  headerMode?: boolean;
  dataType?: DatasetType[];
};

export const ImportHelper = ({
  children,
  className,
  headerMode,
  dataType,
}: ImportHelperProps) => {
  const [id] = useState<string>(_.uniqueId());
  const { t } = useTranslation('app');
  const isDemo = useRecoilValue(warehouseIsDemo);

  const renderButtons = () => (
    <>
      {dataType?.includes('assignment') && (
        <div className="flex w-full flex-1 flex-col">
          <ImportPipelineFileUploaderByType
            dataType={'assignment'}
            viewMode={'header'}
          />
        </div>
      )}

      {dataType?.includes('items') && (
        <div className="flex w-full flex-1 flex-col">
          <ImportPipelineFileUploaderByType
            dataType={'items'}
            viewMode={'header'}
          />
        </div>
      )}

      {dataType?.includes('orders') && (
        <div className="flex w-full flex-1 flex-col">
          <ImportPipelineFileUploaderByType
            dataType={'orders'}
            viewMode={'header'}
          />
        </div>
      )}

      {dataType?.includes('activityFeed') && (
        <div className="flex w-full flex-1 flex-col">
          <ImportPipelineFileUploaderByType
            dataType={'activityFeed'}
            viewMode={'header'}
          />
        </div>
      )}
      {dataType?.includes('dataset') && (
        <div className="flex w-full flex-1 flex-col">
          <ImportPipelineFileUploaderByType
            dataType={'dataset'}
            viewMode={'header'}
          />
        </div>
      )}
      {dataType?.includes('assignmentPolicy') && (
        <div className="flex w-full flex-1 flex-col">
          <ImportPipelineFileUploaderByType
            dataType={'assignmentPolicy'}
            viewMode={'header'}
          />
        </div>
      )}
      {children}
    </>
  );

  if (headerMode) {
    return (
      <ActionBar className="p-2">
        <header
          id={id}
          data-component="ImportHelper"
          className={classNames(
            'flex flex-1 flex-col items-center md:flex-row',
            'p-4',
            'space-x-1 md:space-x-4',
            'text-xs md:text-sm',
            { hidden: isDemo },
            className,
          )}
        >
          {renderButtons()}
        </header>
      </ActionBar>
    );
  } else {
    return renderButtons();
  }
};
