import { LayoutDataRow } from '@warebee/shared/export-converter';
import _ from 'lodash';
import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useRecoilValueLoadable } from 'recoil';
import { DatasetDataViewProps } from '../common/types';
import DatasetTable from '../components/DatasetTable';
import useWarehouseFilesTableConfig from './hooks/useWarehouseFilesTableConfig';
import { warehouseFilesData } from './store/warehouseFiles.state';

const WarehouseFilesDataTable: React.FC<
  DatasetDataViewProps<LayoutDataRow>
> = props => {
  const { t } = useTranslation('dataset');
  const dataLoadable = useRecoilValueLoadable(warehouseFilesData);
  const columnsConfig = useWarehouseFilesTableConfig();

  const [tableState, setTableState] = useState<{
    searchValues: Record<string, string>;
    sortValues: Record<string, 'asc' | 'desc'>;
  }>({
    searchValues: {},
    sortValues: {},
  });

  const data = dataLoadable.state === 'hasValue' ? dataLoadable.contents : [];
  const isLoading = dataLoadable.state === 'loading';

  const totalCount = data?.length || 0;
  return (
    <DatasetTable
      hasCounter
      hideScreenTitle={props.hideScreenTitle}
      subtitle={t`Files`}
      title={props.title}
      isSticky
      id={props?.id}
      columnsConfig={columnsConfig}
      keyFields={['warehouseId', 'name']}
      data={data}
      onSearch={searchValues => {
        setTableState({ ...tableState, searchValues });
      }}
      onSort={sortValues => {
        setTableState({
          ...tableState,
          sortValues: sortValues as Record<string, 'asc' | 'desc'>,
        });
      }}
      searchValues={tableState.searchValues}
      sortBy={tableState.sortValues}
      totalCount={totalCount}
      // searchValues={{}}
      isLoading={isLoading}
      onLoadNext={_.noop}
    />
  );
};

export default WarehouseFilesDataTable;
