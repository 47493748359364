import { ImportPipelineConnectorType } from '@warebee/frontend/data-access-api-graphql';
import classNames from 'classnames';
import _ from 'lodash';
import { nanoid } from 'nanoid';
import React, { Fragment, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useRecoilState, useRecoilValue, useSetRecoilState } from 'recoil';
import { cloneWithoutTypename } from '../../../common/utils';
import { Button } from '../../../components/actions/Button';
import CopyToClipboardButton from '../../../components/actions/CopyToClipboardButton';
import ModalDialog from '../../../components/actions/ModalDialog';
import * as Icon from '../../../components/icons';
import InboxZero from '../../../components/InboxZero';
import KeyValueItem from '../../../components/KeyValueItem';
import TitleSection from '../../../components/layout/TitleSection';
import { ActionBar } from '../../../components/nav/ActionBar';
import Callout from '../../../helpContext/Callout';
import {
  warehouseCanUpdate,
  warehouseSelected,
} from '../../../store/warehouse.state';
import useUpdateWarehouseExtraSettings from '../../../warehouse/hooks/useUpdateWarehouseExtraSettings';
import useUpdateImportPipeline from '../../hooks/useUpdateImportPipeline';
import {
  connectorNewDatasetKey,
  connectorNewSFTPKey,
} from '../../store/importPipeline.helper';
import {
  importPipelineIsActiveEditor,
  importPipelinesDataTableState,
  importPipelineSelected,
  importPipelineSelectedConnectorId,
  importPipelineSelectedId,
  importPipelineUpdateStatus,
} from '../../store/importPipelines.state';

export const PipelineConnectorApi: React.FC = () => {
  const { t } = useTranslation('dataset');
  const wh = useRecoilValue(warehouseSelected);
  const pipeline = useRecoilValue(importPipelineSelected);
  // const [isLoading, setIsLoading] = useState(false);

  const setSelectedId = useSetRecoilState(importPipelineSelectedId);
  const setConnectorId = useSetRecoilState(importPipelineSelectedConnectorId);
  const [tableState, setTableState] = useRecoilState(
    importPipelinesDataTableState,
  );
  const updateStatus = useRecoilValue(importPipelineUpdateStatus);
  const setShowEditor = useSetRecoilState(importPipelineIsActiveEditor);

  const updatePipeline = useUpdateImportPipeline();

  const [query, setQuery] = useState(
    pipeline?.settings?.mappingSettings?.transformation?.query,
  );
  const [updateWhExtraSettings] = useUpdateWarehouseExtraSettings();

  const canUpdate = useRecoilValue(warehouseCanUpdate);
  const connectorId = useRecoilValue(importPipelineSelectedConnectorId);

  const [genLoading, setGenLoading] = useState(false);
  const [token, setToken] = useState<string>(null);

  const [isLoading, setIsLoading] = useState(false);
  const [error, setError] = useState<string>(null);

  function addSftpConnector() {
    setConnectorId(connectorNewSFTPKey);
    setShowEditor(true);
  }
  function addDatasetConnector() {
    setConnectorId(connectorNewDatasetKey);
    setShowEditor(true);
  }

  function removeConnectorById(id: string) {
    const connectors = _(pipeline.settings?.integrationSettings?.connectors)
      .filter(c => c.id !== id)
      .map(cloneWithoutTypename)
      .value();

    updatePipeline({
      importPipelineId: pipeline.id,
      settings: {
        ...pipeline.settings,
        integrationSettings: {
          connectors,
        },
      },
    });
  }

  const [isDialogOpen, setIsDialogOpen] = useState(false);

  function removeAllConnectorsByType(type: ImportPipelineConnectorType) {
    const connectors = _(pipeline.settings?.integrationSettings?.connectors)
      .filter(c => c.type !== type)
      .map(cloneWithoutTypename)
      .value();

    updatePipeline({
      importPipelineId: pipeline.id,
      settings: {
        ...pipeline.settings,
        integrationSettings: {
          connectors,
        },
      },
    });
  }

  function addConnector(type: ImportPipelineConnectorType) {
    const connectors = _.map(
      pipeline.settings?.integrationSettings?.connectors,
      cloneWithoutTypename,
    );
    connectors.push({
      id: nanoid(10),
      type,
      enabled: true,
    });

    updatePipeline({
      importPipelineId: pipeline.id,
      settings: {
        ...pipeline.settings,
        integrationSettings: {
          connectors,
        },
      },
    });
  }

  const connectors = pipeline?.settings?.integrationSettings?.connectors;

  // Connector: Api (Push)
  const apiConnectors = _.filter(
    connectors,
    c => c.type === ImportPipelineConnectorType.API_PUSH,
  );
  const hasApiConnector = !_.isEmpty(apiConnectors);

  const styleApiText = classNames(
    'font-mono',
    // '!bg-app-panel-light/75',
    'text-menu-text',
    'text-start',
    'break-all',
    'select-text',
    // 'py-4 px-2',
    'w-full',
    'shadow',
    'rounded',
  );

  return (
    <>
      <TitleSection
        id={`pipeline-setting-group-api`}
        title={t`Connector: API (Push)`}
        inPanelView
        // className={classNames('z-400 top-12 xl:top-20')}
        classNameInner="p-4 xl:p-6 pb-10 space-y-8"
        hasScreenTitle
        collapsible
        hasAction={
          <>
            {!hasApiConnector ? (
              <Button
                label={t`Add`}
                className={classNames('rounded ltr:ml-4 rtl:mr-4')}
                buttonSize="xs"
                buttonType="primary"
                hasIconAfter={
                  <Icon.CirclePlus className={`h-5 w-5 fill-current`} />
                }
                onPress={() =>
                  addConnector(ImportPipelineConnectorType.API_PUSH)
                }
              />
            ) : (
              <Icon.CircleOk className={classNames('h-6 w-6 fill-current')} />
            )}
          </>
        }
      >
        {_.isEmpty(apiConnectors) && <InboxZero message={t`No Connector`} />}
        {_.map(apiConnectors, c => {
          const connectorState = _.find(
            pipeline.connectorsState,
            cs => cs.id === c.id,
          );
          return (
            <Fragment key={c.id}>
              <KeyValueItem
                className={styleApiText}
                keyName={t`API Endpoint`}
                keyValue={connectorState.apiPush?.apiUrl}
                isDisabled={true}
                isCopyable
              />
              <CopyToClipboardButton value={connectorState.apiPush?.apiUrl} />
              <KeyValueItem
                className={styleApiText}
                keyName={t`API Key`}
                keyValue={connectorState?.apiPush?.apiAccessKey}
                isDisabled={true}
                isCopyable
              />

              <Callout type="suggestion">
                <div>
                  {t`Curl command to upload a file to the API endpoint`}
                </div>

                <div className={classNames(styleApiText)}>
                  <KeyValueItem
                    className={styleApiText}
                    keyName={t`Curl Test Example`}
                    keyValue={`curl --upload-file file.gz -X POST ${connectorState.apiPush?.apiUrl} -v -H "Authorization: ${connectorState?.apiPush?.apiAccessKey}"`}
                    isDisabled={true}
                    isCopyable
                  />
                </div>
              </Callout>
            </Fragment>
          );
        })}

        {hasApiConnector && (
          <ActionBar className={classNames('mt-10')}>
            <Button
              label={t`Revoke Key (Delete)`}
              className={classNames('rounded')}
              buttonSize="xs"
              buttonType="delete"
              full
              hasIconAfter={
                <Icon.CircleX className={classNames('h-5 w-5 fill-current')} />
              }
              onPress={() => setIsDialogOpen(true)}
            />
          </ActionBar>
        )}

        <ModalDialog
          title={t(
            `Are you sure you want to Revoke Key (Delete) the API connector
          This action cannot be undone!`,
          )}
          okButtonTitle={t`Delete Api`}
          okButtonType={'delete'}
          isOpen={isDialogOpen}
          setIsOpen={setIsDialogOpen}
          hasCancelButton
          onConfirm={async () =>
            removeAllConnectorsByType(ImportPipelineConnectorType.API_PUSH)
          }
        />
      </TitleSection>
    </>
  );
};
