import _ from 'lodash';
import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { formatDateTime } from '../common/formatHelper';
import { cn } from '../common/utils';
import KeyValueItem from './KeyValueItem';

export type ImportFileFooterStatsProps = {
  children?: React.ReactNode;
  className?: string;
  importSource?: string;
  dataSource?: string;
  importTime?: number;
  createdDate?: number;
  updatedDate?: number;
};

export const ImportFileFooterStats = ({
  className,
  children,
  importSource,
  dataSource,
  importTime,
  createdDate,
  updatedDate,
}: ImportFileFooterStatsProps) => {
  const [id] = useState<string>(_.uniqueId());
  const { t } = useTranslation('designer');

  return (
    <div
      data-component="ImportFileFooterStats"
      id={id}
      className={cn(
        'p-2',
        'w-full',
        'text-menu-text/50 text-xxs text-start',
        'divide-menu/50 divide-y',
        'grid grid-cols-1',
        className,
      )}
    >
      {importTime && (
        <KeyValueItem
          keyName={t('{{importSource}} imported in', {
            importSource: importSource || '',
          })}
          keyValue={`${formatDateTime(new Date(importTime))}`}
          isDisabled={true}
          isCopyableValueOnly
          style={{ noStyle: true, inline: true, textSize: 'xs' }}
          truncate
        />
      )}

      {createdDate && (
        <KeyValueItem
          keyName={t`Created`}
          keyValue={`${formatDateTime(new Date(createdDate))}`}
          isDisabled={true}
          isCopyableValueOnly
          style={{ noStyle: true, inline: true, textSize: 'xs' }}
          truncate
        />
      )}

      {updatedDate && (
        <KeyValueItem
          keyName={t`Updated`}
          keyValue={`${formatDateTime(new Date(updatedDate))}`}
          isDisabled={true}
          isCopyableValueOnly
          style={{ noStyle: true, inline: true, textSize: 'xs' }}
          truncate
        />
      )}

      {importSource && (
        <KeyValueItem
          keyName={t`Source`}
          keyValue={importSource}
          isDisabled={true}
          isCopyableValueOnly
          style={{ noStyle: true, inline: true, textSize: 'xs' }}
          truncate
          classNameKeyValue="overflow-hidden text-ellipsis"
        />
      )}

      {dataSource && (
        <KeyValueItem
          keyName={t`From`}
          keyValue={dataSource}
          isDisabled={true}
          isCopyableValueOnly
          style={{ noStyle: true, inline: true, textSize: 'xs' }}
          truncate
          classNameKeyValue="overflow-hidden text-ellipsis"
        />
      )}

      {children}
    </div>
  );
};
