// Aggregate functions
const aggregateFunctions = [
  'AVG',
  'COUNT',
  'MAX',
  'MIN',
  'SUM',
  'ARRAY_AGG',
  'GROUP_CONCAT',
  'LISTAGG',
  'BOOL_AND',
  'BOOL_OR',
  'EVERY',
  'STDDEV',
  'VARIANCE',
].join('|');

// Window functions
const windowFunctions = [
  'LAG',
  'LEAD',
  'FIRST_VALUE',
  'LAST_VALUE',
  'NTH_VALUE',
  'ROW_NUMBER',
  'RANK',
  'DENSE_RANK',
  'PERCENT_RANK',
  'CUME_DIST',
  'NTILE',
].join('|');

// Date/Time functions
const dateTimeFunctions = [
  'NOW',
  'CURRENT_TIMESTAMP',
  'CURRENT_DATE',
  'CURRENT_TIME',
  'FROM_UNIXTIME',
  'UNIX_TIMESTAMP',
  'DATE_FORMAT',
  'STR_TO_DATE',
  'DATE_TRUNC',
  'DATE_ADD',
  'DATE_SUB',
  'DATE_DIFF',
  'DATEDIFF',
  'DATEADD',
  'EXTRACT',
  'TO_DAYS',
  'FROM_DAYS',
  'WEEKDAY',
  'DAYNAME',
  'MONTHNAME',
  'QUARTER',
  'WEEK',
  'YEARWEEK',
  'YEAR',
  'MONTH',
  'DAY',
  'HOUR',
  'MINUTE',
  'SECOND',
  'TIMESTAMPADD',
  'TIMESTAMPDIFF',
  'FROM_ISO8601_TIMESTAMP',
  'FROM_ISO8601_DATE',
  'TO_ISO8601',
  'TO_TIMESTAMP',
  'TO_UNIXTIME',
].join('|');

// String functions
const stringFunctions = [
  'LOWER',
  'UPPER',
  'TRIM',
  'LTRIM',
  'RTRIM',
  'CONCAT',
  'SUBSTRING',
  'REPLACE',
  'REGEXP_REPLACE',
  'LENGTH',
  'STRPOS',
  'POSITION',
  'LOCATE',
  'LEFT',
  'RIGHT',
  'REVERSE',
  'SPLIT_PART',
  'INITCAP',
  'LPAD',
  'RPAD',
  'FORMAT',
  'CHR',
  'CODEPOINT',
  'HAMMING_DISTANCE',
  'LEVENSHTEIN_DISTANCE',
  'SOUNDEX',
].join('|');

// Math functions
const mathFunctions = [
  'ABS',
  'CEIL',
  'CEILING',
  'FLOOR',
  'ROUND',
  'POWER',
  'SQRT',
  'CBRT',
  'EXP',
  'LN',
  'LOG2',
  'LOG10',
  'MOD',
  'RAND',
  'RANDOM',
  'SIGN',
  'COS',
  'SIN',
  'TAN',
  'ACOS',
  'ASIN',
  'ATAN',
  'DEGREES',
  'RADIANS',
  'PI',
  'E',
  'WIDTH_BUCKET',
].join('|');

// Conditional and comparison functions
const conditionalFunctions = [
  'COALESCE',
  'NULLIF',
  'GREATEST',
  'LEAST',
  'IFNULL',
  'NVL',
  'DECODE',
  'IF',
  'TRY',
  'IS_NULL',
  'IS_FINITE',
  'IS_INFINITE',
  'IS_NAN',
].join('|');

// Type conversion functions
const conversionFunctions = [
  'CAST',
  'TRY_CAST',
  'CONVERT',
  'FORMAT',
  'PARSE_PRESTO_DATA_SIZE',
  'TO_BASE64',
  'FROM_BASE64',
  'TO_HEX',
  'FROM_HEX',
  'TO_BIG_ENDIAN_64',
].join('|');

// JSON functions
const jsonFunctions = [
  'JSON_EXTRACT',
  'JSON_EXTRACT_SCALAR',
  'JSON_SIZE',
  'JSON_ARRAY_LENGTH',
  'JSON_FORMAT',
  'JSON_PARSE',
  'TO_JSON',
].join('|');

// URL functions
const urlFunctions = [
  'URL_EXTRACT_FRAGMENT',
  'URL_EXTRACT_HOST',
  'URL_EXTRACT_PATH',
  'URL_EXTRACT_PORT',
  'URL_EXTRACT_PROTOCOL',
  'URL_EXTRACT_QUERY',
  'URL_ENCODE',
  'URL_DECODE',
].join('|');

// Basic keywords
const basicKeywords = [
  'SELECT',
  'FROM',
  'WHERE',
  'INSERT',
  'UPDATE',
  'DELETE',
  'INTO',
  'VALUES',
  'SET',
  'GROUP',
  'BY',
  'ORDER',
  'HAVING',
  'LIMIT',
  'OFFSET',
  'FETCH',
  'NEXT',
  'ROWS',
  'ONLY',
  'IGNORE',
].join('|');

// Data types
const dataTypes = [
  'BIGINT',
  'BINARY',
  'BIT',
  'BLOB',
  'BOOL',
  'BOOLEAN',
  'CHAR',
  'CHARACTER',
  'DATE',
  'DATETIME',
  'DECIMAL',
  'DOUBLE',
  'FLOAT',
  'INT',
  'INTEGER',
  'LONG',
  'NUMERIC',
  'REAL',
  'SMALLINT',
  'TEXT',
  'TIME',
  'TIMESTAMP',
  'TINYINT',
  'VARCHAR',
  'VARBINARY',
  'VARYING',
].join('|');

// Table operations
const tableOperations = [
  'CREATE',
  'ALTER',
  'DROP',
  'TRUNCATE',
  'RENAME',
  'TABLE',
  'VIEW',
  'INDEX',
  'CONSTRAINT',
  'SCHEMA',
  'DATABASE',
  'COLUMN',
  'ADD',
  'MODIFY',
  'CHANGE',
].join('|');

// Joins
const joinTypes = [
  'JOIN',
  'INNER',
  'LEFT',
  'RIGHT',
  'FULL',
  'OUTER',
  'CROSS',
  'NATURAL',
  'USING',
  'ON',
].join('|');

// Transaction control
const transactionControl = [
  'BEGIN',
  'COMMIT',
  'ROLLBACK',
  'TRANSACTION',
  'SAVEPOINT',
  'ISOLATION',
  'LEVEL',
  'READ',
  'WRITE',
  'UNCOMMITTED',
  'COMMITTED',
  'REPEATABLE',
  'SERIALIZABLE',
].join('|');

// Flow control
const flowControl = [
  'CASE',
  'WHEN',
  'THEN',
  'ELSE',
  'END',
  'IF',
  'EXISTS',
  'FOR',
  'WHILE',
  'LOOP',
  'RETURN',
  'CONTINUE',
  'BREAK',
].join('|');

// Window functions
const windowKeywords = [
  'WINDOW',
  'PARTITION',
  'OVER',
  'RANGE',
  'ROWS',
  'GROUPS',
  'BETWEEN',
  'UNBOUNDED',
  'PRECEDING',
  'FOLLOWING',
  'CURRENT',
  'ROW',
  'EXCLUDE',
  'TIES',
  'OTHERS',
].join('|');

// Permissions
const permissionKeywords = [
  'GRANT',
  'REVOKE',
  'PRIVILEGES',
  'PUBLIC',
  'ROLE',
  'USER',
  'WITH',
  'ADMIN',
  'OPTION',
].join('|');

// Null handling
const nullHandling = [
  'NULL',
  'IS',
  'NOT',
  'DISTINCT',
  'FROM',
  'NULLS',
  'FIRST',
  'LAST',
].join('|');

// Set operations
const setOperations = ['UNION', 'ALL', 'INTERSECT', 'EXCEPT', 'MINUS'].join(
  '|',
);

// Other keywords
const otherKeywords = [
  'AS',
  'ASC',
  'DESC',
  'BETWEEN',
  'IN',
  'LIKE',
  'ILIKE',
  'ANY',
  'SOME',
  'DEFAULT',
  'CHECK',
  'PRIMARY',
  'KEY',
  'FOREIGN',
  'REFERENCES',
  'UNIQUE',
  'TEMPORARY',
  'TEMP',
  'CASCADE',
  'RESTRICT',
  'FORCE',
  'WITH',
  'WITHOUT',
  'RECURSIVE',
  'LATERAL',
  'ORDINALITY',
  'SYSTEM',
  'VERSION',
].join('|');

// Combine all keywords
const allKeywords = [
  basicKeywords,
  dataTypes,
  tableOperations,
  joinTypes,
  transactionControl,
  flowControl,
  windowKeywords,
  permissionKeywords,
  nullHandling,
  setOperations,
  otherKeywords,
].join('|');

// Combine all functions
const allFunctions = [
  aggregateFunctions,
  windowFunctions,
  dateTimeFunctions,
  stringFunctions,
  mathFunctions,
  conditionalFunctions,
  conversionFunctions,
  jsonFunctions,
  urlFunctions,
].join('|');

// Arithmetic operators
const arithmeticOperators = ['\\+', '-', '\\*', '/', '%', '\\^'].join('|');

// Comparison operators
const comparisonOperators = [
  '=',
  '!=',
  '<>',
  '>',
  '<',
  '>=',
  '<=',
  '!<',
  '!>',
].join('|');

// Logical operators
const logicalOperators = ['AND', 'OR', 'NOT', 'XOR', '&&', '\\|\\|', '!'].join(
  '|',
);

// Pattern matching operators
const patternOperators = [
  'LIKE',
  'ILIKE',
  'REGEXP',
  'REGEXP_LIKE',
  'REGEXP_REPLACE',
  'RLIKE',
  'SOUNDS\\s+LIKE',
].join('|');

// Special operators
const specialOperators = [
  'IS',
  'IN',
  'BETWEEN',
  'OVERLAPS',
  'DIV',
  'CONTAINS',
  'SIMILAR\\s+TO',
].join('|');

// Combine all operators
const allOperators = [
  arithmeticOperators,
  comparisonOperators,
  logicalOperators,
  patternOperators,
  specialOperators,
].join('|');

export const sql = {
  comment: {
    pattern: /(^|[^\\])(?:\/\*[\s\S]*?\*\/|(?:--|\/\/|#).*)/,
    lookbehind: true,
  },
  templateLiteral: [
    {
      pattern: /{{#dataset\[.*?\]}}|{{#[^}]+\[.*?\]}}/,
      greedy: true,
      alias: 'dataset-variable-id',
    },
    {
      pattern: /{{#[^}]+}}/,
      greedy: true,
      alias: 'dataset-variable',
    },
    {
      pattern: /_{3}[a-zA-Z_]+_{3,}/,
      greedy: true,
      alias: 'input-variable',
    },
  ],
  variable: [
    {
      pattern: /@(["'`])(?:\\[\s\S]|(?!\1)[^\\])+\1/,
      greedy: true,
    },
    /@[\w.$]+/,
  ],
  string: {
    pattern: /(^|[^@\\])(')(?:\\[\s\S]|(?!\2)[^\\]|\2\2)*\2/,
    greedy: true,
    lookbehind: true,
  },
  column: {
    pattern: /(^|[^@\\])(")(?:\\[\s\S]|(?!\2)[^\\]|\2\2)*\2/,
    greedy: true,
    lookbehind: true,
  },
  identifier: {
    pattern: /(^|[^@\\])`(?:\\[\s\S]|[^`\\]|``)*`/,
    greedy: true,
    lookbehind: true,
    inside: {
      punctuation: /^`|`$/,
    },
  },
  function: new RegExp(`\\b(?:${allFunctions})(?=\\s*\\()`, 'i'),
  keyword: new RegExp(`\\b(?:${allKeywords})\\b`, 'i'),
  boolean: /\b(?:FALSE|NULL|TRUE)\b/i,
  number: /\b0x[\da-f]+\b|\b\d+(?:\.\d*)?|\B\.\d+\b/i,
  operator: new RegExp(`(?:^|\\s)(${allOperators})(?=\\s|$)`, 'i'),
  punctuation: /[;[\]()`,.]/,
};
