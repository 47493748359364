import {
  AssignmentOccupancyLocationFilter,
  AssignmentOccupancyLocationStatus,
  AssignmentOccupancySummaryFragment,
  LoadAssignmentOccupancyLocationsDocument,
  LoadAssignmentOccupancyLocationsQuery,
  LoadAssignmentOccupancyLocationsQueryVariables,
} from '@warebee/frontend/data-access-api-graphql';
import {
  getVolumeComplianceTableRows,
  VolumeComplianceDataRow,
} from '@warebee/shared/export-converter';
import { TFunction } from 'i18next';
import _ from 'lodash';
import { TwTheme } from '../../../../Tw';
import {
  HEATMAP_UNDEFINED_BUCKET_ID,
  HEATMAP_ZERO_BUCKET_ID,
  HeatmapBucket,
} from '../../../common/heatmap.helper';
import { secureClient } from '../../../GraphQLClient';
import i18n from '../../../i18n';
import {
  OccupancyCategoryDescriptor,
  OccupancyCategoryDescriptorMap,
  OccupancyMode,
  OccupancySummary,
} from './assignmentOccupancy.types';

const categoryColor = TwTheme.extend.colors.categoryOccupancy;
const categoryTextColor = TwTheme.extend.colors.categoryOccupancyText;

export const getOccupancyCategories = _.memoize(
  (t: TFunction<'simulation'> = i18n.t): OccupancyCategoryDescriptorMap =>
    _.keyBy(
      [
        {
          key: '100',
          tag: '100+',
          title: t('> 100%', { ns: 'simulation' }),
          color: categoryColor[150],
          textColor: categoryTextColor[10],
          fromVolumePercentile: 1,
          toVolumePercentile: null,
        },
        {
          key: '80-100',
          tag: '80-100',
          title: t('80-100%', { ns: 'simulation' }),
          color: categoryColor[100],
          textColor: categoryTextColor[10],
          fromVolumePercentile: 0.8,
          toVolumePercentile: 1,
        },
        {
          key: '60-80',
          tag: '60-80',
          title: t('60-80%', { ns: 'simulation' }),
          color: categoryColor[70],
          textColor: categoryTextColor[10],
          fromVolumePercentile: 0.6,
          toVolumePercentile: 0.8,
        },
        {
          key: '40-60',
          tag: '40-60',
          title: t('40-60%', { ns: 'simulation' }),
          color: categoryColor[50],
          textColor: categoryTextColor[10],
          fromVolumePercentile: 0.4,
          toVolumePercentile: 0.6,
        },
        {
          key: '20-40',
          tag: '20-40',
          title: t('20-40%', { ns: 'simulation' }),
          color: categoryColor[30],
          textColor: categoryTextColor[10],
          fromVolumePercentile: 0.2,
          toVolumePercentile: 0.4,
        },
        {
          key: '0-20',
          tag: '0-20',
          title: t('0-20%', { ns: 'simulation' }),
          color: categoryColor[20],
          textColor: categoryTextColor[10],
          fromVolumePercentile: 0,
          toVolumePercentile: 0.2,
        },
        {
          key: HEATMAP_ZERO_BUCKET_ID,
          tag: 'EMPTY',
          title: t('Empty', { ns: 'simulation' }),
          color: categoryColor['empty'],
          textColor: categoryTextColor[0],
          fromVolumePercentile: null,
          toVolumePercentile: 0,
        },
        {
          key: HEATMAP_UNDEFINED_BUCKET_ID,
          tag: 'UNKNOWN',
          title: t('Unknown', { ns: 'simulation' }),
          color: categoryColor['unknown'],
          textColor: categoryTextColor['Unknown'],
          fromVolumePercentile: null,
          toVolumePercentile: null,
        },
      ],

      'key',
    ) as unknown as OccupancyCategoryDescriptorMap,
);

const categories = _.values(getOccupancyCategories());
export function getOccupancyCategory(
  volumeShare: number,
): OccupancyCategoryDescriptor {
  const targetCategory = _.find(categories, c => {
    if (_.isNil(volumeShare) || _.isNaN(volumeShare))
      return c.fromVolumePercentile === null && c.toVolumePercentile === null;
    return (
      volumeShare > (c.fromVolumePercentile ?? Number.MIN_SAFE_INTEGER) &&
      volumeShare <= (c.toVolumePercentile ?? Number.MAX_SAFE_INTEGER)
    );
  });
  return targetCategory;
}

export function getOccupancyCategoryFull(
  volumeShare: number,
  categories: OccupancyCategoryDescriptorMap,
): OccupancyCategoryDescriptor {
  return categories[getOccupancyCategory(volumeShare)?.key ?? ''];
}

export function getOccupancyHeatmapBuckets(
  t: TFunction<'simulation'>,
): HeatmapBucket[] {
  return _(getOccupancyCategories(t))
    .values()
    .map((category, index) => ({
      id: category.key,
      index: -index,
      color: category.color,
      textColor: category.textColor,
      title: category.tag,
      from: category.fromVolumePercentile,
      to: category.toVolumePercentile,
    }))
    .value();
}

export type LoadOccupancyHeatmapParams = {
  simulationId: string;
  assignmentId: string;
  locationId?: string;
  filter: AssignmentOccupancyLocationFilter;
};

export async function loadOccupancyHeatmap(
  params: LoadOccupancyHeatmapParams,
): Promise<Record<string, VolumeComplianceDataRow[]>> {
  const { simulationId, assignmentId } = params;

  const response = await secureClient.query<
    LoadAssignmentOccupancyLocationsQuery,
    LoadAssignmentOccupancyLocationsQueryVariables
  >({
    query: LoadAssignmentOccupancyLocationsDocument,
    variables: {
      simulationId,
      assignmentId,
      page: {
        limit: null,
        skip: 0,
      },
      filter: params.filter,
    },
  });

  const rows = getVolumeComplianceTableRows(
    response.data?.simulation.assignmentOccupancy?.locations.content,
  );
  const locationMap = _.groupBy(
    rows,
    locCompliance => locCompliance.locationId,
  );
  return locationMap;
}

let prev = 0;
export const categoryOccupancyWidths = _(getOccupancyCategories())
  .map(c => [c.fromVolumePercentile, c.toVolumePercentile])
  .flatten()
  .uniq()
  .compact()
  .filter(v => v > 0)
  .sort()
  .map(v => {
    const diff = v - prev;
    prev = v;
    return Math.round(diff * 100);
  })
  .value();

export function getAssignmentOccupancyStatusMap(
  t: TFunction<'simulation'>,
): Record<AssignmentOccupancyLocationStatus, string> {
  return {
    [AssignmentOccupancyLocationStatus.EMPTY]: t('Empty', { ns: 'simulation' }),
    [AssignmentOccupancyLocationStatus.OCCUPIED]: t('Occupied', {
      ns: 'simulation',
    }),
    [AssignmentOccupancyLocationStatus.PARTIALLY_UNKNOWN_ITEMS_VOLUME]: t(
      'Partial Vol.',
      { ns: 'simulation' },
    ),
    [AssignmentOccupancyLocationStatus.UNKNOWN_ITEMS_VOLUME]: t(
      'Unknown Vol.',
      { ns: 'simulation' },
    ),
    [AssignmentOccupancyLocationStatus.UNKNOWN_LOCATION_VOLUME]: t(
      'Unknown Loc. Vol.',
      { ns: 'simulation' },
    ),
  };
}

export function getOccupancySummary(
  summary: AssignmentOccupancySummaryFragment,
  mode: OccupancyMode,
): OccupancySummary {
  const occupiedVolume = summary?.totalOccupiedVolume;
  const emptyVolume = summary?.stockUomCapacitySummary?.totalEmptyVolume;
  const totalStorageVolume = summary?.totalStorageVolume;

  let unknownVolume: number, utilizedVolume: number;

  switch (mode) {
    case 'volume':
      unknownVolume = summary?.totalUnknownVolume;
      utilizedVolume =
        summary?.totalStorageVolume - unknownVolume - emptyVolume;

      break;
    case 'stockUom':
      unknownVolume = summary?.stockUomCapacitySummary?.totalUnknownVolume;
      utilizedVolume = summary?.stockUomCapacitySummary?.totalCapacityVolume;

      break;
    case 'maxUom':
      unknownVolume = summary?.maxUomCapacitySummary?.totalUnknownVolume;
      utilizedVolume = summary?.maxUomCapacitySummary?.totalCapacityVolume;
      break;
  }

  const assignedVolume =
    summary?.totalStorageVolume - unknownVolume - emptyVolume;

  return {
    occupiedVolume,
    unOccupiedVolume: utilizedVolume - occupiedVolume,
    utilizedVolume,
    unUtilizedVolume: assignedVolume - utilizedVolume,
    assignedVolume,
    unknownVolume,
    emptyVolume,
    totalStorageVolume,
  };
}
