import { sql } from 'kysely';
import {
  analyzedFeedEventsQueryBuilder,
  AnalyzedFeedEventsQueryBuilderParams,
} from './queryBuilder/analyzedFeedEventsQueryBuilder';

export type ActualityAnalyzedEventsSummaryGroupKey = 'eventType' | 'agentId';

export type ActualityAnalyzedEventsSummaryRow<
  T extends ActualityAnalyzedEventsSummaryGroupKey,
> = Record<T, string> & {
  processType: string;
  eventsCount: number;
  cost: number;
  duration: number;
  distance: number;
};

export function getActualityAnalyzedEventsSummary(
  params: AnalyzedFeedEventsQueryBuilderParams,
  groupBy: ActualityAnalyzedEventsSummaryGroupKey,
) {
  return analyzedFeedEventsQueryBuilder(params)
    .selectFrom('ajewpl')
    .select(({ fn }) => [
      groupBy,
      'processType',
      fn.countAll().as('eventsCount'),
      fn.sum('cost').as('cost'),
      fn.sum('duration').as('duration'),
      fn
        .sum(
          sql`cast(json_query(${sql.ref('details')}, 'strict $.distance') as double)`,
        )
        .filterWhere('eventType', 'in', [
          'TRAVELLING_HORIZONTAL_START',
          'TRAVELLING_HORIZONTAL',
          'TRAVELLING_HORIZONTAL_END',
        ])
        .as('distance'),
    ])
    .groupBy([groupBy, 'processType']);
}
