import _ from 'lodash';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { useRecoilValue } from 'recoil';
import { Container } from '../../components/layout/ContainerFlex';
import SidebarContainer, {
  SidebarContainerProps,
} from '../../containers/SidebarContainer';
import ImportPipelineEditPanel from '../../importJobs/panels/ImportPipelineEditPanel';
import {
  importPipelineSelected,
  importPipelineSelectedId,
} from '../../importJobs/store/importPipelines.state';

const DatasetObjectEditorSidebar: React.FC = () => {
  const { t } = useTranslation('dataset');
  const selectedPipelineId = useRecoilValue(importPipelineSelectedId);

  const containerProps: SidebarContainerProps = {
    type: 'sidebar-dataset-pipeline-editor',
    title: t`Pipeline Settings`,
    size: _.isNil(selectedPipelineId) ? 'md' : 'lg',
  };

  return (
    <SidebarContainer {...containerProps}>
      <Container col>
        <ImportPipelineEditPanel />
      </Container>
    </SidebarContainer>
  );
};

export default DatasetObjectEditorSidebar;
