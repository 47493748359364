import { HeatMapSerie, ResponsiveHeatMapCanvas } from '@nivo/heatmap';
import { TwTheme } from 'apps/frontend/src/Tw';
import { addDays, addHours, differenceInDays, format } from 'date-fns';
import _ from 'lodash';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { useRecoilState, useRecoilValue, useSetRecoilState } from 'recoil';
import InboxZero from '../../components/InboxZero';
import FeedActivityAgentTotalBarChart from '../components/FeedActivityAgentTotalBarChart';
import FeedAgentHeatmapChartTooltip from '../components/FeedAgentHeatmapChartTooltip';
import { SWARM_PAGE_SIZE } from '../store/feed.default';
import { getIntervalStatKey } from '../store/feed.helper';
import {
  KpiSummaryModes,
  feedActivitySelectedTabId,
  feedAgentsStats,
  feedAgentsStatsSortBy,
  feedAggregateBy,
  feedAggregatedEvents,
  feedSelectedAgentId,
  feedSelectedAgentIds,
  feedSelectedAgentsDataPage,
  feedSelectedDateRange,
  feedSelectedPickListId,
  showEventsKpiSummaryChart,
} from '../store/feed.state';
import { FeedAgentIntervalStat } from '../store/feed.types';

const colors = TwTheme.extend.colors;
const theme = TwTheme.extend.colors.chart;

export type FeedAggregatedHeatmapItem = FeedAgentIntervalStat & {
  color: string;
  x: number;
  y: number;
};

function getEmptyFeedAggregatedHeatmapItem(
  groupKey: string,
  intervalDate: Date,
  ticksWithValues: Set<number>,
): FeedAggregatedHeatmapItem {
  return {
    intervalKey: getIntervalStatKey(groupKey, intervalDate),
    intervalsCount: 0,
    aggregateBy: 'day',
    groupKey,
    intervalDate,
    eventCount: null,
    uomCount: null,
    color: ticksWithValues.has(intervalDate.getTime())
      ? theme.empty
      : theme.nodata, // for placeholder column
    x: new Date(intervalDate).getTime(),
    y: 0,
    jobIds: null,
    jobs: [],
  };
}

const FeedActivityAgentHeatmap: React.FC = () => {
  const { t } = useTranslation('feed');
  const selectedAgents = useRecoilValue(feedSelectedAgentIds);
  const aggregatedEvents = useRecoilValue(feedAggregatedEvents);
  const agentStatsAll = useRecoilValue(feedAgentsStats);
  const selectedPage = useRecoilValue(feedSelectedAgentsDataPage);
  const aggregateBy = useRecoilValue(feedAggregateBy);
  const agentSortBy = useRecoilValue(feedAgentsStatsSortBy);
  const [kpiSummaryMode, setKpiSummaryMode] = useRecoilState(
    showEventsKpiSummaryChart,
  );
  const setDateRange = useSetRecoilState(feedSelectedDateRange);
  const setSelectedTabId = useSetRecoilState(feedActivitySelectedTabId);
  const setSelectedAgentId = useSetRecoilState(feedSelectedAgentId);
  const setSelectedJobId = useSetRecoilState(feedSelectedPickListId);

  const getTargetRate = (metric: string): number => {
    switch (metric) {
      case 'uomCount':
        return _(aggregatedEvents)
          .map(r => r.uomCount / r.intervalsCount)
          .mean();
      case 'eventCount':
      default:
        return _(aggregatedEvents)
          .map(r => r.eventCount / r.intervalsCount)
          .mean();
    }
  };

  const targetRate = getTargetRate(agentSortBy);

  const getPerformanceColor = (v: number, targetRate: number) => {
    if (v > targetRate * 1.25) return colors.performance[90];
    if (v > targetRate) return colors.performance[80];
    if (v > targetRate * 0.75) return colors.performance[60];
    return colors.performance[30];
  };

  const selectedAgentsSet = new Set(selectedAgents);
  const showAll = _.isEmpty(selectedAgents);

  const agentsAll = _.map(agentStatsAll, a => a.agentId).filter(
    a => showAll || selectedAgentsSet.has(a),
  );

  const agentsVisible =
    showAll && selectedPage !== -1
      ? _.slice(
          agentsAll,
          (selectedPage - 1) * SWARM_PAGE_SIZE,
          selectedPage * SWARM_PAGE_SIZE,
        )
      : agentsAll;

  const agentsVisibleSet = new Set(agentsVisible);

  const extendedEvents: FeedAggregatedHeatmapItem[] = _(aggregatedEvents)
    .filter(e => agentsVisibleSet.has(e.groupKey))
    .map(e => {
      let metricValue = 0;
      if (e.intervalsCount > 0) {
        metricValue =
          (agentSortBy === 'totalUoms' ? e.uomCount : e.eventCount) /
          e.intervalsCount;
      }

      const item: FeedAggregatedHeatmapItem = {
        ...e,
        color: getPerformanceColor(metricValue, targetRate),
        x: e.intervalDate.getTime(),
        y: Math.round(metricValue),
      };
      return item;
    })
    .value();

  const ticksWithValues = _(extendedEvents)
    .map(e => e.intervalDate)
    .uniqBy(v => v.getTime());

  const ticksWithValuesSet = new Set(
    ticksWithValues.map(v => v.getTime()).value(),
  );

  const visibleTicks = ticksWithValues
    .flatMap(v => [v, aggregateBy === 'day' ? addDays(v, 1) : addHours(v, 1)])
    .uniqBy(v => v.getTime())
    .orderBy(v => v.getTime(), 'asc')
    .initial() // remove last synthetic
    .value();

  const isWithinOneDay =
    differenceInDays(_.head(visibleTicks), _.last(visibleTicks)) <= 1;

  const chartData: HeatMapSerie<FeedAggregatedHeatmapItem, {}>[] = _(
    extendedEvents,
  )
    .groupBy(e => e.groupKey)
    .map((data, agentId) => {
      const ticksWithData = _.keyBy(data, d => d.intervalKey);
      const rowData = _.map(visibleTicks, t => {
        return (
          ticksWithData[getIntervalStatKey(agentId, t)] ??
          getEmptyFeedAggregatedHeatmapItem(agentId, t, ticksWithValuesSet)
        );
      });

      return {
        id: agentId,
        data: rowData,
      };
    })
    .sortBy(d => _.indexOf(agentsVisible, d.id))
    .value();

  const hourWidth = 60;

  function formatTick(ms: number) {
    if (!ticksWithValuesSet.has(ms)) {
      return '.......';
    }

    if (aggregateBy === 'day') {
      return format(ms, 'dd MMM');
    }
    return isWithinOneDay ? format(ms, '(dd) HH:00') : format(ms, 'HH:00');
  }
  const ticksValues = _.map(visibleTicks, t => t.getTime());

  const totalChartData = _(chartData)
    .map(agentData => agentData.data)
    .flatten()
    .value();

  const shouldShowSummaryChart =
    kpiSummaryMode === KpiSummaryModes.Summary ||
    kpiSummaryMode === KpiSummaryModes.All;

  const shouldShowDetailedHeatmapChart =
    kpiSummaryMode === KpiSummaryModes.Detailed ||
    kpiSummaryMode === KpiSummaryModes.All;

  const hasData = _.size(chartData) > 0;
  if (!hasData) {
    return <InboxZero selfCenter message={t`No events`} />;
  }

  return (
    <div
      className="flex flex-col"
      style={{
        width:
          _.size(visibleTicks) > 24
            ? `${hourWidth * _.size(visibleTicks)}px`
            : '100%',
        height: '100%',
      }}
    >
      {shouldShowSummaryChart && (
        <FeedActivityAgentTotalBarChart
          data={totalChartData}
          tickFormatter={formatTick}
          ticks={ticksValues}
          agents={agentsVisible}
          fullHeight={selectedPage === -1}
          getRateColor={v => getPerformanceColor(v, targetRate)}
        />
      )}

      {shouldShowDetailedHeatmapChart && (
        <div className="flex-1">
          {selectedPage !== -1 && (
            <ResponsiveHeatMapCanvas
              data={chartData}
              margin={{
                top: kpiSummaryMode === KpiSummaryModes.Detailed ? 40 : 0,
                right: 40,
                bottom: 60,
                left: 120,
              }}
              axisTop={
                kpiSummaryMode === KpiSummaryModes.Detailed
                  ? {
                      tickValues: ticksValues,
                      tickSize: 10,
                      tickPadding: 5,
                      tickRotation: 0,
                      ticksPosition: 'before',
                      legend: isWithinOneDay ? t`Day&Time` : t`Time`,
                      legendPosition: 'middle',
                      legendOffset: 40,
                      format: formatTick,
                    }
                  : null
              }
              // axisBottom={{
              //   tickValues: ticksValues,
              //   tickSize: 10,
              //   tickPadding: 5,
              //   tickRotation: 0,
              //   ticksPosition: 'before',
              //   legend: _.size(ticks) > 24 ? t`Day&Time` : t`Time`,
              //   legendPosition: 'middle',
              //   legendOffset: 40,
              //   format: formatTick,
              // }}
              axisLeft={{
                tickSize: 10,
                tickPadding: 5,
                tickRotation: 0,
                truncateTickAt: 14,
              }}
              // yInnerPadding={0.2}
              inactiveOpacity={0.7}
              colors={datum => datum.data.color}
              borderWidth={2}
              borderColor={colors.app.background}
              tooltip={datum => (
                <FeedAgentHeatmapChartTooltip datum={datum.cell} />
              )}
              onClick={(cell, e) => {
                setSelectedTabId('tab-feed-process');
                setSelectedAgentId(cell.data.groupKey);
                setSelectedJobId(_.head(cell.data.jobs));
              }}
              theme={{
                axis: {
                  ticks: {
                    text: {
                      fontSize: 11,
                      fill: theme.ticks,
                    },
                  },
                  legend: {
                    text: {
                      fontSize: 13,
                      fill: theme.legend,
                    },
                  },
                  domain: {
                    line: {
                      stroke: theme.grid,
                      strokeWidth: 1,
                    },
                  },
                },
                grid: {
                  line: {
                    stroke: theme.legend,
                    strokeWidth: 1,
                  },
                },
                crosshair: {
                  line: {
                    stroke: theme.crosshair,
                    strokeWidth: 3,
                    // simulate line will dash stroke when index is even
                    strokeDasharray: '3, 3',
                  },
                },
              }}
            />
          )}
        </div>
      )}
    </div>
    // </div>
  );
};
export default FeedActivityAgentHeatmap;
