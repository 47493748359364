import classNames from 'classnames';
import _ from 'lodash';
import React, { PropsWithChildren } from 'react';
import { useTranslation } from 'react-i18next';
import { useRecoilValue, useSetRecoilState } from 'recoil';
import { AsyncLoadStatus } from '../../common/types';
import { Button } from '../../components/actions/Button';
import { ButtonWrapper } from '../../components/actions/ButtonWrapper';
import * as Icon from '../../components/icons';
import { Container } from '../../components/layout/ContainerFlex';
import { Spacer } from '../../components/layout/Spacer';
import { IconArrowDir } from '../../components/nav/IconArrowDirectional';
import SidebarContainer, {
  SidebarContainerProps,
} from '../../containers/SidebarContainer';
import ImportActivityFeedMapPanel from '../activityFeed/ImportActivityFeedMapPanel';
import ImportActivityFeedStartPanel from '../activityFeed/ImportActivityFeedStartPanel';
import ImportActivityFeedUploadPanel from '../activityFeed/ImportActivityFeedUploadPanel';
import ImportAssignmentMapPanel from '../assignment/ImportAssignmentMapPanel';
import ImportAssignmentStartPanel from '../assignment/ImportAssignmentStartPanel';
import ImportAssignmentUploadPanel from '../assignment/ImportAssignmentUploadPanel';
import ImportAssignmentPolicyMapPanel from '../assignmentPolicy/ImportAssignmentPolicyMapPanel';
import ImportAssignmentPolicyStartPanel from '../assignmentPolicy/ImportAssignmentPolicyStartPanel';
import ImportAssignmentPolicyUploadPanel from '../assignmentPolicy/ImportAssignmentPolicyUploadPanel';
import ImportItemSetMapPanel from '../itemSet/ImportItemSetMapPanel';
import ImportItemSetStartPanel from '../itemSet/ImportItemSetStartPanel';
import ImportItemSetUploadPanel from '../itemSet/ImportItemSetUploadPanel';
import ImportLayoutMapPanel from '../layout/ImportLayoutMapPanel';
import ImportLayoutStartPanel from '../layout/ImportLayoutStartPanel';
import ImportLayoutUploadPanel from '../layout/ImportLayoutUploadPanel';
import ImportOrderSetMapPanel from '../orders/ImportOrderSetMapPanel';
import ImportOrderSetStartPanel from '../orders/ImportOrderSetStartPanel';
import ImportOrderSetUploadPanel from '../orders/ImportOrderSetUploadPanel';
import { ImportTransformPanel } from '../panels/ImportTransformPanel';
import {
  importCanUpload,
  importJob,
  importMappingSettingsByType,
  importParsedFile,
  importSelectedStep,
  importTypeCurrent,
  importValidationStatus,
} from '../store/import.state';
import { DatasetType } from '../store/import.types';
import useProcessImportJob from '../useProcessImportJob';
import useUploadFile from '../useUploadFile';
import useValidatePreview from '../useValidatePreview';
import { HelperImporter } from './HelperImporter';
import ImportPanelStep2Select from './ImportSelectPreviewPanel';

function UploadButton() {
  const { t } = useTranslation('importer');
  const canUpload = useRecoilValue(importCanUpload);
  const setStepId = useSetRecoilState(importSelectedStep);
  const [uploadFile] = useUploadFile();
  const [processJob] = useProcessImportJob();
  const validatePreview = useValidatePreview();
  const validationStatus = useRecoilValue(importValidationStatus);
  const datasetType = useRecoilValue(importTypeCurrent);
  const mappingSettings = useRecoilValue(
    importMappingSettingsByType(datasetType),
  );
  const job = useRecoilValue(importJob);

  async function tryUpload() {
    const previewErrors = await validatePreview({ mappingSettings });
    if (!_.isEmpty(previewErrors)) return;
    setStepId('import-upload-data');
    if (job?.uploaded) {
      processJob({});
    } else {
      uploadFile({});
    }
  }

  const isValidating = validationStatus === AsyncLoadStatus.Loading;
  return (
    <ButtonWrapper disabled={!canUpload}>
      <Button
        className="border-menu/75 flex-1 border text-xl"
        label={t`Upload`}
        buttonType="primary"
        hasIconAfter
        buttonIcon={<Icon.CloudUpload className={`h-8 w-8 fill-current`} />}
        isDisabled={!canUpload || isValidating}
        onPress={() => tryUpload()}
      />
    </ButtonWrapper>
  );
}

function MapButton() {
  const { t } = useTranslation('importer');
  const parsedFile = useRecoilValue(importParsedFile);
  const setStepId = useSetRecoilState(importSelectedStep);
  const isDisabled = !_.isNil(parsedFile);

  return (
    <ButtonWrapper disabled={isDisabled}>
      <Button
        className={classNames('border-menu/75 flex-1 border text-xl')}
        label={t`Map fields`}
        buttonType="primary"
        hasIconAfter
        buttonIcon={
          <Icon.ArrowBottom className={classNames('h-8 w-8 fill-current')} />
        }
        isDisabled={!isDisabled}
        onPress={() => setStepId('import-map-fields')}
      />
    </ButtonWrapper>
  );
}

function NextButton() {
  const { t } = useTranslation('importer');
  const setStepId = useSetRecoilState(importSelectedStep);
  return (
    <ButtonWrapper>
      <Button
        className={classNames('border-menu/75 flex-1 border text-xl')}
        label={t`Next`}
        buttonType="primary"
        hasIconBefore
        buttonIcon={<IconArrowDir />}
        onPress={() => setStepId('import-select-csv')}
      />
    </ButtonWrapper>
  );
}

export type ImportInfoSidebarProps = {
  type: DatasetType;
};

const StartWrapper: React.FC<PropsWithChildren> = props => (
  <Container col hasOverflowY>
    {props.children}
  </Container>
);

const Wrapper: React.FC<PropsWithChildren> = props => (
  <Container col hasOverflowY>
    {props.children}
  </Container>
);

export const ImportInfoSidebar: React.FC<ImportInfoSidebarProps> = props => {
  const { t } = useTranslation('importer');
  const stepId = useRecoilValue(importSelectedStep);

  const getSidebarContent = () => {
    switch (props.type) {
      case 'assignment':
        switch (stepId) {
          case 'import-getting-started':
            return (
              <StartWrapper>
                <ImportAssignmentStartPanel />
                <Spacer flexspace />
                <HelperImporter hasStepCounter={`1.1`} />
              </StartWrapper>
            );
          case 'import-select-csv':
            return <ImportPanelStep2Select />;
          case 'import-transform':
            return <ImportTransformPanel />;
          case 'import-map-fields':
            return <ImportAssignmentMapPanel />;
          case 'import-upload-data':
            return <ImportAssignmentUploadPanel />;
        }
        break;

      case 'assignmentPolicy':
        switch (stepId) {
          case 'import-getting-started':
            return (
              <StartWrapper>
                <ImportAssignmentPolicyStartPanel />
                <Spacer flexspace />
                <HelperImporter hasStepCounter={`1.1`} />
              </StartWrapper>
            );
          case 'import-select-csv':
            return <ImportPanelStep2Select />;
          case 'import-transform':
            return <ImportTransformPanel />;
          case 'import-map-fields':
            return <ImportAssignmentPolicyMapPanel />;
          case 'import-upload-data':
            return <ImportAssignmentPolicyUploadPanel />;
        }
        break;

      case 'items':
        switch (stepId) {
          case 'import-getting-started':
            return (
              <StartWrapper>
                <ImportItemSetStartPanel />
                <Spacer flexspace />
                <HelperImporter hasStepCounter={`1.1`} />
              </StartWrapper>
            );
          case 'import-select-csv':
            return <ImportPanelStep2Select />;
          case 'import-transform':
            return <ImportTransformPanel />;
          case 'import-map-fields':
            return <ImportItemSetMapPanel />;
          case 'import-upload-data':
            return <ImportItemSetUploadPanel />;
        }
        break;

      case 'layout':
        switch (stepId) {
          case 'import-getting-started':
            return (
              <StartWrapper>
                <ImportLayoutStartPanel />
                <Spacer flexspace />
                <HelperImporter hasStepCounter={`1.1`} />
              </StartWrapper>
            );
          case 'import-select-csv':
            return <ImportPanelStep2Select />;
          case 'import-transform':
            return <ImportTransformPanel />;
          case 'import-map-fields':
            return <ImportLayoutMapPanel />;
          case 'import-upload-data':
            return <ImportLayoutUploadPanel />;
        }
        break;

      case 'orders':
        switch (stepId) {
          case 'import-getting-started':
            return (
              <StartWrapper>
                <ImportOrderSetStartPanel />
                <Spacer flexspace />
                <HelperImporter hasStepCounter={`1.1`} />
              </StartWrapper>
            );
          case 'import-select-csv':
            return <ImportPanelStep2Select />;
          case 'import-transform':
            return <ImportTransformPanel />;
          case 'import-map-fields':
            return <ImportOrderSetMapPanel />;
          case 'import-upload-data':
            return <ImportOrderSetUploadPanel />;
        }
      case 'activityFeed':
        switch (stepId) {
          case 'import-getting-started':
            return (
              <StartWrapper>
                <ImportActivityFeedStartPanel />
                <Spacer flexspace />
                <HelperImporter hasStepCounter={`1.1`} />
              </StartWrapper>
            );
          case 'import-select-csv':
            return <ImportPanelStep2Select />;
          case 'import-transform':
            return <ImportTransformPanel />;
          case 'import-map-fields':
            return <ImportActivityFeedMapPanel />;
          case 'import-upload-data':
            return <ImportActivityFeedUploadPanel />;
        }
      case 'dataset':
        switch (stepId) {
          // case 'import-getting-started':
          //   return (
          //     <StartWrapper>
          //       <ImportOrderSetStartPanel />
          //       <Spacer flexspace />
          //       <HelperImporter hasStepCounter={`1.1`} />
          //     </StartWrapper>
          //   );
          case 'import-select-csv':
            return <ImportPanelStep2Select />;
          case 'import-transform':
            return <ImportTransformPanel />;
          // case 'import-map-fields':
          //   return <ImportOrderSetMapPanel />;
          case 'import-upload-data':
            return <ImportOrderSetUploadPanel />;
        }
        break;
    }
  };

  return (
    <>
      <Container col hasOverflowY componentName="ImportInfoSidebar">
        {getSidebarContent()}
      </Container>
    </>
  );
};

export default (props: ImportInfoSidebarProps) => {
  const { t } = useTranslation('importer');

  const containerProps: SidebarContainerProps = {
    type: 'sidebar-importer-info',
    title: t`Import Helper`,
    size: 'sm',
  };

  return (
    <SidebarContainer {...containerProps}>
      <ImportInfoSidebar type={props.type} />
    </SidebarContainer>
  );
};
