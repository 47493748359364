import React from 'react';
import { useRecoilValue, useRecoilValueLoadable } from 'recoil';
import BayLocationLayer from '../../layout/bayViewer/layers/BayLocationLayer';
import { actualityHeatmapBayData } from '../store/actuality.heatmap.state';
import { actualityHeatmapTypeSelected } from '../store/actuality.state';
import { assignmentHeatmapBayData } from '../store/assignment.heatmap.state';
import useActualityLocationColor from './useActualityLocationColor';

const FeedBayLocationLayer: React.FC = () => {
  const heatmapType = useRecoilValue(actualityHeatmapTypeSelected);
  const eventsDataLoadable = useRecoilValueLoadable(actualityHeatmapBayData);
  const assignmentDataLoadable = useRecoilValueLoadable(
    assignmentHeatmapBayData,
  );

  const dataLoadable =
    heatmapType === 'events' ? eventsDataLoadable : assignmentDataLoadable;
  const [getHeatmapColor, getGradientHeatmapColors] = useActualityLocationColor(
    { dataLoadable },
  );

  return (
    <BayLocationLayer
      getColor={getHeatmapColor}
      getGradientColors={getGradientHeatmapColors}
    />
  );
};

export default FeedBayLocationLayer;
