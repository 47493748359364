import {
  BaseUomFragment,
  SimulationOrderLineFragment,
} from '@warebee/frontend/data-access-api-graphql';
import parseISO from 'date-fns/parseISO';
import _ from 'lodash';
import React, { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useRecoilValue } from 'recoil';
import { formatDate, formatDateTime } from '../common/formatHelper';
import { AsyncLoadStatus } from '../common/types';
import useFormatter from '../common/useFormatter';
import DatasetTable, { ColumnConfig } from '../components/DatasetTable';
import FormattedValue from '../components/FormattedValue';
import InboxZero from '../components/InboxZero';
import { ContainerScroll } from '../components/layout/ContainerScroll';
import {
  pickingPolicy,
  pickingPolicyOrderLineByRule,
  pickingPolicyOrderLineByRuleLoadStatus,
  pickingPolicyRule,
  pickingPolicySelectedDetailsIdentityAtom,
} from '../simulation/store/pickingPolicy/pickingPolicy.state';
import ItemTag from '../simulation/tags/ItemTag';
import useLoadOrderLinesByRule from './hooks/useLoadOrderLinesByRule';

type OrderLineByRuleRow = SimulationOrderLineFragment & BaseUomFragment;

const OrderLinesByRuleTable: React.FC = () => {
  const { t } = useTranslation('simulation');

  const policy = useRecoilValue(pickingPolicy);
  const selectedDetails = useRecoilValue(
    pickingPolicySelectedDetailsIdentityAtom,
  );
  const orderLinesData = useRecoilValue(pickingPolicyOrderLineByRule);
  const orderLinesDataLoadStatus = useRecoilValue(
    pickingPolicyOrderLineByRuleLoadStatus,
  );
  const rule = useRecoilValue(pickingPolicyRule(selectedDetails?.ruleId));
  const [loadCallback, cancelLoad] = useLoadOrderLinesByRule();
  // const [searchValues, setSearchValues] = useRecoilState(
  //   pickingPolicyOrderLineViewSearchValues,
  // );
  const formatter = useFormatter();
  const searchValues = {};

  const tableTitle = selectedDetails?.showOrderLinesUnmatched
    ? t`Not matched order lines`
    : t(`Order lines matched by rule '{{ruleTitle}}'`, {
        ruleTitle: rule?.title,
      });

  function callDataLoad(
    searchValues: Record<string, string>,
    page = { isAppend: false, skip: 0 },
  ) {
    if (_.isNull(selectedDetails?.ruleId)) return;

    const filter /*: OrderLinesFilter*/ = {
      consigneeContains: searchValues['consignee'],
      skuContains: searchValues['sku'],
      skuGroupContains: searchValues['skuGroup'],
    };

    cancelLoad();
    loadCallback({
      selectedDetails,
      policy,
      //filter,
      ...page,
    });
  }

  useEffect(() => {
    callDataLoad(searchValues);
  }, [selectedDetails, policy]);

  if (
    !(
      selectedDetails?.showOrderLinesByRule ||
      selectedDetails?.showOrderLinesUnmatched
    )
  )
    return (
      <InboxZero
        selfCenter
        hasIcon
        message={t`No Rule selected`}
        // message_helper="Select Orders Dataset"
      />
    );

  const columnsConfig: ColumnConfig<OrderLineByRuleRow>[] = [
    { field: 'orderId', title: t`Order Id`, isHeader: true, hasFilter: true },
    {
      field: 'consignee',
      title: t`Client (Consignee)`,
      hasFilter: true,
    },
    {
      field: 'sku',
      title: t`Item`,
      hasFilter: true,
      render: (sku: string, row) => (
        <ItemTag title={sku} filters={{ consignee: row['consignee'], sku }} />
      ),
    },
    // {
    //   field: 'cumulativePercentRank',
    //   title: t`Category`,
    //   hasSort: false,
    //   render: (v: number) => <ItemCategoryTag cmlPercent={v} />,
    // },
    { field: 'orderLineQuantity', title: t`Qty` },
    {
      field: 'orderDate',
      title: t`Order Date`,
      render: (v: string) => (_.isNil(v) ? '-' : formatDate(parseISO(v))),
    },
    { field: 'orderLine', title: t`Order Line (OL)` },
    {
      field: 'orderLineVolume',
      title: t`OL. Volume`,
      render: (v: number) => (
        <FormattedValue value={formatter.formatVolume(v)} />
      ),
    },
    {
      field: 'orderLineWeight',
      title: t`OL. Weight`,
      render: (v: number) => (
        <FormattedValue value={formatter.formatWeight(v)} />
      ),
    },
    {
      field: 'orderTotalVolume',
      title: t`Order Total Volume`,
      render: (v: number) => (
        <FormattedValue value={formatter.formatVolume(v)} />
      ),
    },
    {
      field: 'orderTotalWeight',
      title: t`Order Total Weight`,
      render: (v: number) => (
        <FormattedValue value={formatter.formatWeight(v)} />
      ),
    },
    { field: 'skuGroup', title: t`Item Group`, hasSort: true, hasFilter: true },
    { field: 'subGroup', title: t`Sub-Group`, hasSort: true, hasFilter: false },

    {
      field: 'transportClass',
      title: t`Transport Class`,
      hasSort: true,
      hasFilter: true,
    },
    {
      field: 'stockCategory',
      title: t`Stock Category`,
      hasSort: true,
      hasFilter: true,
    },
    {
      field: 'storageClass',
      title: t`Storage Class`,
      hasSort: true,
      hasFilter: true,
    },
    {
      field: 'pollutionClass',
      title: t`Pollution Class`,
      hasSort: true,
      hasFilter: true,
    },
    // {
    //   field: 'maxStack',
    //   title: t`Max Stackability`,
    //   hasSort: true,
    //   hasFilter: true,
    // },
    {
      field: 'customer',
      title: t`Customer`,
      hasFilter: true,
    },
    {
      field: 'orderLine',
      title: t`Order Line`,
      hasFilter: true,
    },

    {
      field: 'orderDate',
      title: t`Order Date`,
      hasFilter: false, // ToDo: Filter
      render: (v: string) => (_.isNil(v) ? '—' : formatDateTime(parseISO(v))),
    },
    {
      field: 'pickingPriority',
      title: t`Picking Priority`,
      // hasFilter: true, // ToDo: Filter
    },
    {
      field: 'deliveryRoute',
      title: t`Delivery Route`,
      hasFilter: true,
    },
    {
      field: 'dock',
      title: t`Dock`,
      hasFilter: true,
    },
    {
      field: 'stagingArea',
      title: t`Staging Area`,
      hasFilter: true,
    },
    {
      field: 'waveId',
      title: t`Wave ID`,
      hasFilter: true,
    },
    {
      field: 'pickingContainer',
      title: t`Container`,
      hasFilter: false,
    },
    {
      field: 'netWeight',
      title: t`Weight (Base UOM)`,
      render: (v: number) => (
        <FormattedValue value={formatter.formatWeight(v)} />
      ),
    },
    {
      field: 'length',
      title: t`Length (Base UOM)`,
      render: (v: number) => (
        <FormattedValue value={formatter.formatDistance(v)} />
      ),
    },
    {
      field: 'width',
      title: t`Width (Base UOM)`,
      render: (v: number) => (
        <FormattedValue value={formatter.formatDistance(v)} />
      ),
    },
    {
      field: 'height',
      title: t`Height (Base UOM)`,
      render: (v: number) => (
        <FormattedValue value={formatter.formatDistance(v)} />
      ),
    },
    {
      field: 'volume',
      title: t`Volume (Base UOM)`,
      render: (v: number) => (
        <FormattedValue value={formatter.formatVolume(v)} />
      ),
    },
  ];

  const isLoading = orderLinesDataLoadStatus === AsyncLoadStatus.Loading;
  const itemsCount = orderLinesData?.content?.length || 0;
  const totalCount = orderLinesData?.totalCount ?? 0;

  const reduceOrderLineSet = (
    simulationOrderLine: SimulationOrderLineFragment,
  ) => {
    const base = _.omit(simulationOrderLine, 'baseUom');
    const uom = simulationOrderLine.baseUom;
    return {
      ...base,
      ...uom,
    } as OrderLineByRuleRow;
  };

  const data = _.flatMap(orderLinesData?.content, reduceOrderLineSet);

  function onLoadNext() {
    callDataLoad(searchValues, {
      isAppend: true,
      skip: itemsCount,
    });
  }
  return (
    <ContainerScroll className="flex flex-col">
      <DatasetTable
        subtitle={t(`{{totalCount}} Filtered Order Lines for`, {
          totalCount,
        })}
        title={tableTitle}
        isSticky
        id={selectedDetails?.ruleId}
        columnsConfig={columnsConfig}
        keyFields={['orderLineId']}
        data={data}
        onLoadNext={onLoadNext}
        onSearch={_.noop} // setSearchValues}
        totalCount={totalCount}
        searchValues={searchValues}
        isLoading={isLoading}
      />
    </ContainerScroll>
  );
};

export default OrderLinesByRuleTable;
