import { DatasetObjectRawDataFieldSummaryFragment } from '@warebee/frontend/data-access-api-graphql';
import _ from 'lodash';
import { useTranslation } from 'react-i18next';
import InboxZero from '../../components/InboxZero';
import InputCheckbox from '../../components/inputs/InputCheckbox';
import { InputGroupList } from '../../components/inputs/InputGroupList';
import { InputValue } from '../../components/inputs/InputValue';
import TitleSection from '../../components/layout/TitleSection';
import { DatasetExtraFieldSettings } from '../store/datasetObject.types';

export type DatasetExtraFieldsSettingsEditorProps = {
  sectionId: string;
  sectionTitle: string;
  extraFields: DatasetObjectRawDataFieldSummaryFragment[];
  extraFieldsSettings: DatasetExtraFieldSettings[];
  onChange: (extraFieldsSettings: DatasetExtraFieldSettings[]) => void;
};

const DatasetExtraFieldsSettingsEditor: React.FC<
  DatasetExtraFieldsSettingsEditorProps
> = props => {
  const { t } = useTranslation('app');
  const extraFieldsSettingsMap = _.keyBy(
    props.extraFieldsSettings,
    s => s.name,
  );
  const hasExtraFields = _.size(props.extraFields) > 0;

  function onChange(name: string, patch: Partial<DatasetExtraFieldSettings>) {
    const changedFieldSettings = {
      ...extraFieldsSettingsMap[name],
      ...patch,
    };
    const newSettings = _.filter(
      props.extraFieldsSettings,
      s => s.name !== name,
    );
    newSettings.push(changedFieldSettings);

    props.onChange(newSettings);
  }

  return (
    <TitleSection
      id={props.sectionId}
      title={props.sectionTitle}
      inSidebarView
      collapsible
    >
      {!hasExtraFields && (
        <InboxZero selfCenter message={t`No Extra fields found`} />
      )}
      {hasExtraFields && (
        <InputGroupList className="space-y-2 p-1 lg:p-2 xl:p-4">
          {_.map(props.extraFields, f => {
            const settings = extraFieldsSettingsMap[f.name];

            return (
              <InputCheckbox
                key={`${props.sectionId}-${f.name}`}
                isSelected={settings?.enabled}
                onChange={v => onChange(f.name, { enabled: v })}
              >
                <InputValue
                  value={settings?.title ?? f.name}
                  onChange={v => onChange(f.name, { title: v })}
                  isDebouncedChange={true}
                />
                <div>
                  <span>
                    {f.name}[{f.type}] ({f.distinctValueCount})
                  </span>
                </div>
              </InputCheckbox>
            );
          })}
        </InputGroupList>
      )}
    </TitleSection>
  );
};

export default DatasetExtraFieldsSettingsEditor;
