import { QueryCreator } from 'kysely';
import { DatasetDatabase } from './datasetQueryBuilder';

export type AnalyzeEventsQueryBuilderParams = {
  actualityId: string;
};

export function getAnalyzeEventsFilteredQueryBuilder<T extends DatasetDatabase>(
  params: AnalyzeEventsQueryBuilderParams,
  db: QueryCreator<T>,
) {
  const at = db
    .selectFrom('___analyzed_job_event___')
    .where('documentId', '=', params.actualityId as any);
  return at;
}
