import { ImportPipelineConnectorSettingsFragment } from '@warebee/frontend/data-access-api-graphql';
import classNames from 'classnames';
import _ from 'lodash';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { useRecoilState, useRecoilValue, useSetRecoilState } from 'recoil';
import { cloneWithoutTypename } from '../common/utils';
import PolicyFilterItem from '../components/policies/PolicyFilterItem';
import RuleContainer from '../components/policies/RuleContainer';
import useUpdateImportPipeline from './hooks/useUpdateImportPipeline';
import {
  importPipelineIsActiveEditor,
  importPipelineSelected,
  importPipelineSelectedConnectorId,
} from './store/importPipelines.state';

export type ImportPipelineSFTPConnectorProps = {
  pipelineId: string;
  connector: ImportPipelineConnectorSettingsFragment;
};

const ImportPipelineSFTPConnector: React.FC<
  ImportPipelineSFTPConnectorProps
> = props => {
  const { t } = useTranslation('dataset');
  const pipeline = useRecoilValue(importPipelineSelected);
  const [selectedId, setSelectedId] = useRecoilState(
    importPipelineSelectedConnectorId,
  );

  const setShowEditor = useSetRecoilState(importPipelineIsActiveEditor);
  const updatePipeline = useUpdateImportPipeline();

  function removeConnectorById(id: string) {
    const connectors = _(pipeline.settings?.integrationSettings?.connectors)
      .filter(c => c.id !== id)
      .map(cloneWithoutTypename)
      .value();

    updatePipeline({
      importPipelineId: pipeline.id,
      settings: {
        ...pipeline.settings,
        integrationSettings: {
          connectors,
        },
      },
    });
  }

  const { id, sftpPull: sftp } = props.connector;
  const isActive = id === selectedId;

  return (
    <RuleContainer
      dataComponent="ImportPipelineSFTPConnector"
      key={`'pipeline-connector-'-${id}`}
      id={`'pipeline-connector-'-${id}`}
      title={t`SFTP Connector`}
      hasIcon={true}
      hasColorMode={false}
      isCollapsible={true}
      isActive={isActive}
      onClick={() => {
        setShowEditor(true);
        setSelectedId(isActive ? null : id);
      }}
      isRemovable={true}
      onDeleteClick={() => removeConnectorById(id)}
      className={classNames('p-1 xl:p-2')}
      transparent
      classNameInner={classNames('space-y-2 xl:space-y-4')}
    >
      <PolicyFilterItem
        name={t`Server`}
        value={`${sftp.host}:${sftp.port}${sftp.path}`}
        isActive={isActive}
        isDisabled={true}
      />
      <PolicyFilterItem
        name={t`File mask`}
        value={`${sftp.pattern}`}
        isActive={isActive}
        isDisabled={true}
      />
    </RuleContainer>
  );
};

export default ImportPipelineSFTPConnector;
