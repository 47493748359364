import { useRecoilValue, useSetRecoilState } from 'recoil';
import DateRangePicker from '../../components/inputs/calendar/DateRangePicker';
import {
  feedDateRange,
  feedEffectiveDateRange,
  feedSelectedDateRange,
} from '../store/feed.state';

export type FeedOption = {
  isDisabled?: boolean;
  showDates?: boolean;
  showTimeMenu?: boolean;
  showDateMenu?: boolean;
  selectorPosition?: 'start' | 'end';
};

const isValidDate = (date: Date) => !isNaN(date.getTime());

export default function FeedDatePicker(props: FeedOption) {
  const dateRange = useRecoilValue(feedDateRange);
  const selectedRange = useRecoilValue(feedEffectiveDateRange);
  const setSelectedRange = useSetRecoilState(feedSelectedDateRange);

  // Fallback date range if the actual range is invalid, using current date for simplicity
  const fallbackRange: [Date, Date] = [new Date(), new Date()];

  // Validate the minimum and maximum date values
  const minValue =
    dateRange && isValidDate(new Date(dateRange[0]))
      ? dateRange[0]
      : fallbackRange[0];
  const maxValue =
    dateRange && isValidDate(new Date(dateRange[1]))
      ? dateRange[1]
      : fallbackRange[1];

  // Validate the selected range
  const validSelectedRange =
    selectedRange &&
    isValidDate(new Date(selectedRange[0])) &&
    isValidDate(new Date(selectedRange[1]))
      ? selectedRange
      : null;

  return (
    <DateRangePicker
      value={validSelectedRange}
      minValue={minValue}
      maxValue={maxValue}
      onChange={v => setSelectedRange(v)}
      onResetClick={() => setSelectedRange(null)}
      isDisabled={props.isDisabled}
      showTimeMenu={props.showTimeMenu}
      showDateMenu={props.showDateMenu}
      headerMode
      selectorPosition={props.selectorPosition}
    />
  );
}
