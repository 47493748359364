import classNames from 'classnames';
import { TFunction } from 'i18next';
import _ from 'lodash';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { useRecoilState } from 'recoil';
import {
  FilterFieldConfigBase,
  FilterFieldEditorType,
} from '../../common/types';
import { cn } from '../../common/utils';
import { policyFilterCopyPasteBuffer } from '../../simulation/store/policyFilter.state';
import { PolicyFilterGroupType } from '../../simulation/store/policyFilter.types';
import DropdownSelector from '../actions/DropdownSelector';
import * as Icon from '../icons';
import { PolicyFilter } from './PolicyFilter';
import PolicyFilterEmptyItem from './PolicyFilterEmptyItem';
import PolicyFilterItem, { PolicyFilterItemProps } from './PolicyFilterItem';

const groupPopupOptions = ['clear', 'add', 'copy', 'paste'] as const;
type GroupPopupOption = (typeof groupPopupOptions)[number];

type FilterItem = {
  type: string;
  isNull?: boolean;
  isNot?: boolean;
  valueIn?: { title: string }[];
  range?: { from?: number; to?: number };
  stringRange?: { from?: string; to?: string };
};

export type FilterSetsData = {
  id: string;
  allOf: FilterItem[];
};

export type AddFilterGroupProps = {
  title: string;
  isSelected?: boolean;
  isNew?: boolean;
  onClick: () => void;
};

function getRangeValueText(
  filter: FilterItem,
  config: FilterFieldConfigBase<string>,
  t: TFunction<'simulation'>,
): string {
  if (filter.isNull) {
    const nullOptionTitle = config?.nullOptionTitle;
    return t('{{nullOptionTitle}}', {
      nullOptionTitle,
    });
  }
  const from = _.isNil(filter.range?.from)
    ? t`min`
    : _.isFunction(config.format)
      ? config.format(filter.range?.from).fullString
      : filter.range?.from;

  const to = _.isNil(filter.range?.to)
    ? t`max`
    : _.isFunction(config.format)
      ? config.format(filter.range?.to).fullString
      : filter.range?.to;

  return t('from {{from}} to {{to}}', {
    from,
    to,
  });
}

function getRangeStringText(
  filter: FilterItem,
  config: FilterFieldConfigBase<string>,
  t: TFunction<'simulation'>,
): string {
  if (filter.isNull) {
    const nullOptionTitle = config?.nullOptionTitle;
    return t('{{nullOptionTitle}}', {
      nullOptionTitle,
    });
  }

  const from = filter.stringRange?.from ?? t`min`;
  const to = filter.stringRange?.to ?? t`max`;

  return t('from {{from}} to {{to}}', {
    from,
    to,
  });
}

function getABCRangeValueText(
  filter: FilterItem,
  config: FilterFieldConfigBase<string>,
  t: TFunction<'simulation'>,
): string {
  if (filter.isNull) {
    const nullOptionTitle = config?.nullOptionTitle;
    return t('{{nullOptionTitle}}', {
      nullOptionTitle,
    });
  }
  const format = config.format ?? _.identity;
  const from = _.isNil(filter.range?.from)
    ? t`0`
    : format(filter.range?.from).fullString;

  const to = _.isNil(filter.range?.to)
    ? t`100`
    : format(filter.range?.to).fullString;

  return t('from {{from}} to {{to}}', {
    from,
    to,
  });
}

const AddFilterGroup: React.FC<AddFilterGroupProps> = props => {
  return (
    <div
      data-component="AddFilterGroup"
      className={classNames(['transition delay-200 duration-500 ease-in-out'])}
    >
      <div
        data-component="AddFilterButton"
        className={classNames([
          'flex flex-1 items-center justify-center',
          'z-0',
          'p-2',
          'm-1',
          'cursor-pointer rounded',
          'hover:bg-menu-active hover:text-menu-active-text',
          'group-hover:duration-400 group-hover:transition group-hover:delay-100 group-hover:ease-in-out',
          'hover:shadow-xl',
          { 'opacity-100': props.isNew },
          props.isSelected ? 'border-menu/20 border' : 'bg-app-panel/75',
        ])}
        onClick={() => props.onClick && props.onClick()}
      >
        <Icon.Plus
          className={classNames('h-3 w-3 fill-current ltr:mr-2 rtl:ml-2')}
        />
        <div className={classNames('flex-1 text-xs xl:text-sm')}>
          {props.title}
        </div>
      </div>
    </div>
  );
};

export type PolicyFilterGroupListProps = {
  children?: React.ReactNode;
  filterSets: FilterSetsData[];
  className?: string;
  selectedId: string;
  config: FilterFieldConfigBase<string>[];
  isDisabled?: boolean;
  isSelected?: boolean;
  isActive?: boolean;
  isNew?: boolean;
  hasExtraOrButton?: boolean;
  hideActionMenu?: boolean;
  showAddFilter?: boolean;
  transparent?: boolean;
  filterGroupType?: PolicyFilterGroupType;

  onAddGroup?: (filterData?: FilterSetsData) => void;
  onSelectGroup?: (groupId: string, isSelected: boolean) => void;
  onDeleteGroup?: (groupId: string) => void;
  onDeleteField?: (groupId: string, fieldId: string) => void;
};

const PolicyFilterGroupList: React.FC<PolicyFilterGroupListProps> = props => {
  const { t } = useTranslation('simulation');
  const [buffer, setBuffer] = useRecoilState(
    policyFilterCopyPasteBuffer(props.filterGroupType),
  );

  const isAllFiltersEmpty = _.every(
    props.filterSets,
    fs => fs.allOf?.length === 0,
  );
  const hasFilter = _.every(props.filterSets, fs => fs.allOf?.length > 0);

  const hasValue = (filter: FilterItem): boolean =>
    !_.isEmpty(filter.valueIn) ||
    !_.isNil(filter.stringRange?.from) ||
    !_.isNil(filter.stringRange?.to) ||
    filter.isNull ||
    !_.isNil(filter.range?.from) ||
    !_.isNil(filter.range?.to);

  const groupPopupOptionsTitlesAll: Record<GroupPopupOption, string> = {
    clear: t`Clear Filter`,
    add: t`Add (OR) Filter`,
    copy: t`Copy`,
    paste: t`Paste`,
  };
  const currentGroupPopupOptions: GroupPopupOption[] = ['clear', 'add'];

  if (props.filterGroupType) {
    currentGroupPopupOptions.push('copy');
    if (buffer) {
      currentGroupPopupOptions.push('paste');
    }
  }

  function handlePopupSelection(option: GroupPopupOption, groupId: string) {
    switch (option) {
      case 'add':
        props.onAddGroup && props.onAddGroup();
        break;
      case 'clear':
        props.onDeleteGroup && props.onDeleteGroup(groupId);
        break;
      case 'copy':
        setBuffer(_.find(props.filterSets, f => f.id === groupId));
        break;
      case 'paste':
        props.onAddGroup && props.onAddGroup(buffer);
        break;
    }
  }

  return (
    <div data-component="PolicyFilterGroupList" className={cn(props.className)}>
      {/* 'p-0.5 xl:p-1' */}
      {_.map(props.filterSets, (filterSet, filterSetIndex) => {
        const groupId = filterSet.id;
        const isSelected = filterSet.id === props.selectedId;

        const sortedFilters: FilterItem[] = _.sortBy(
          _.filter(filterSet?.allOf, hasValue),
          (f: FilterItem) => _.findIndex(props.config, c => c.type === f.type),
        );
        const isEmpty = _.isEmpty(sortedFilters);
        const hasNoFilters = filterSetIndex === 0;

        const titleText = hasNoFilters ? t`Filter By` : t`OR Filter By`;

        return (
          <PolicyFilter
            key={`${filterSet.id}-${filterSetIndex}`}
            isActive={isSelected || props.isActive}
            isDisabled={props.isDisabled}
            label={titleText}
            className={hasNoFilters ? '' : 'mt-2'}
            transparent={props.transparent}
            action={
              props.hideActionMenu ? null : (
                <DropdownSelector
                  className={classNames(
                    'text-xs',
                    isSelected
                      ? 'opacity-100'
                      : 'opacity-0 group-hover/PolicyFilter:opacity-100',
                  )}
                  w_sm
                  isSelected={isSelected}
                  DropAlignRight
                  buttonTransparent
                  vertical
                  value={'...'}
                  values={currentGroupPopupOptions}
                  renderValue={v => groupPopupOptionsTitlesAll[v] ?? '...'}
                  onClick={e => e.stopPropagation()}
                  onChange={(option: GroupPopupOption, e: React.MouseEvent) =>
                    handlePopupSelection(option, groupId)
                  }
                />
              )
            }
            onClick={e => {
              e.stopPropagation();
              !props.isDisabled &&
                props.onSelectGroup &&
                props.onSelectGroup(groupId, !isSelected);
            }}
          >
            {!props.isDisabled && isEmpty ? (
              <PolicyFilterEmptyItem isSelected={isSelected} />
            ) : (
              sortedFilters?.map((filter, i) => {
                const cfg = props.config.find(f => f.type === filter.type);
                if (_.isNil(cfg)) return null;

                let value: string = _.map(filter.valueIn, v => v.title).join(
                  ', ',
                );

                if (!_.isNil(filter.stringRange)) {
                  value = getRangeStringText(filter, cfg, t);
                }

                if (
                  cfg.editorType === FilterFieldEditorType.SliderRange ||
                  cfg.editorType === FilterFieldEditorType.SliderRangeFixed
                ) {
                  value = getRangeValueText(filter, cfg, t);
                }

                if (cfg.editorType === FilterFieldEditorType.ABCRange) {
                  value = getABCRangeValueText(filter, cfg, t);
                }
                const filterProps: PolicyFilterItemProps = {
                  name: cfg.title,

                  value: value,
                  onRemoveClick: () => {
                    props.onDeleteField &&
                      props.onDeleteField(groupId, filter.type);
                  },
                };
                return (
                  <PolicyFilterItem
                    isActive={isSelected}
                    isDisabled={props.isDisabled}
                    isRemovable={!props.isDisabled}
                    isNot={filter.isNot}
                    key={`ap-filter-item-${groupId}-${filter.type}`}
                    {...filterProps}
                  />
                );
              })
            )}
          </PolicyFilter>
        );
      })}
      {props.children}

      {!props.isDisabled &&
        isAllFiltersEmpty &&
        (hasFilter || props.showAddFilter) && (
          <AddFilterGroup
            isSelected={props.isSelected}
            isNew={props.isNew}
            title={isAllFiltersEmpty ? t`Filter` : t`(OR) Filter`}
            onClick={() => props.onAddGroup && props.onAddGroup()}
          />
        )}
    </div>
  );
};

export default PolicyFilterGroupList;
