import { ResponsiveBarCanvas } from '@nivo/bar';
import classNames from 'classnames';
import { format, isWeekend } from 'date-fns';
import _ from 'lodash';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { TwTheme } from '../../../Tw';
import {
  getBestContrastColor,
  getQualitativeColor,
} from '../../common/color.helper';
import { formatInteger } from '../../common/formatHelper';
import { TooltipItem } from '../../components/stats/TooltipItem';
import ContainerChart from '../../simulation/panels/ContainerChart';
import { FeedAggregatedHeatmapItem } from '../layout/FeedActivityAgentHeatmap';

const colors = TwTheme.extend.colors;
const theme = TwTheme.extend.colors.chart;

const FeedActivityAgentTotalBarChartTooltip = datum => {
  const { t } = useTranslation('feed');
  const data = datum.data;
  // const showOnLeft = props.totalX > 0 && datum.index / props.totalX > 0.66;

  const date = data.x;
  const weekend = _.isNil(date) ? false : isWeekend(date);

  const dayTitleShort = _.isNil(date) ? t`No Date` : format(date, 'dd hh:mm');
  const weekdayTitle = _.isNil(date) ? null : format(date, 'EE');

  return (
    <div
      key={datum.id}
      data-component="FeedAgentHeatmapChartTooltip"
      className={classNames(
        'bg-app-panel-dark/90',
        'backdrop-blur backdrop-filter',
        'text-sm',
        'whitespace-nowrap',
      )}
    >
      <div className="min-w-tooltip">
        <div
          className={classNames(
            'flex items-center p-2 text-xs ltr:pl-3 rtl:pr-3',
          )}
          style={{
            backgroundColor: datum.color,
            color: getBestContrastColor(datum.color),
          }}
        >
          {datum.id}
        </div>
        <div
          className={classNames(
            'flex items-center',
            'px-2 py-2',
            'text-start text-sm font-bold',
            'bg-app-panel-dark',
          )}
        >
          <span className={classNames('ltr:pl-1 rtl:pr-1', 'flex-1')}>
            {dayTitleShort}
          </span>
          {weekdayTitle && (
            <span
              className={classNames(
                'ltr:ml-3 rtl:mr-3',
                'px-2 py-0.5',
                'rounded uppercase',
                'border',
                weekend ? 'border-menu/50 text-menu-active' : 'border-menu',
              )}
            >
              {format(date, 'EE')}
            </span>
          )}
        </div>
      </div>

      <div
        className={classNames(
          'px-1',
          'divide-menu/50 divide-y divide-solid divide-opacity-50',
          'border-menu/75 border-t',
        )}
      >
        <TooltipItem title={t`Value`} value={datum.formattedValue} />
      </div>
    </div>
  );
};

export type FeedActivityAgentTotalBarChartProps = {
  data: FeedAggregatedHeatmapItem[];
  agents: string[];
  ticks: number[];
  tickFormatter: (ms: number) => string;
  fullHeight?: boolean;
  getRateColor: (v: number) => string;
};

const FeedActivityAgentTotalBarChart: React.FC<
  FeedActivityAgentTotalBarChartProps
> = props => {
  const { t } = useTranslation('feed');

  const chartData = _(props.data)
    .groupBy(item => item.intervalDate)
    .map((items, date) => {
      return _.reduce(
        items,
        (acc, item) => {
          return {
            ...acc,
            x: item.x,
            [item.groupKey as string]: item.y,
            uomCount: acc.uomCount + item.uomCount,
            eventCount: acc.eventCount + item.eventCount,
            intervalsCount: acc.intervalsCount + item.intervalsCount,
          };
        },
        {
          uomCount: 0,
          eventCount: 0,
          intervalsCount: 0,
        } as any,
      );
    })
    .map(byDay => ({
      ...byDay,
      uomRate:
        byDay.intervalsCount > 0 ? byDay.uomCount / byDay.intervalsCount : 0,
    }))
    .value();

  return (
    <>
      {/* <PanelContainer
      id="panel-feed-total-by-day"
      title={t`Show Totals`}
      collapsible
      hasPadding
      transparent
    > */}
      <ContainerChart containerHeight={props.fullHeight ? 620 : 300}>
        <ResponsiveBarCanvas
          data={_.orderBy(chartData, r => r.x) as any}
          indexBy={'x'}
          keys={props.fullHeight ? ['uomRate'] : props.agents}
          indexScale={{ type: 'band', round: false }}
          colors={datum =>
            props.fullHeight
              ? props.getRateColor(datum.value)
              : getQualitativeColor(datum.id?.toString(), 'dimension')[0]
          }
          valueFormat={v => formatInteger(v)}
          margin={{ top: 10, right: 40, bottom: 25, left: 120 }}
          // margin={{ top: 0, right: 40, bottom: 60, left: 120 }}
          padding={0}
          innerPadding={0}
          axisTop={null}
          axisRight={null}
          axisLeft={null}
          enableGridY={false}
          axisBottom={{
            tickValues: props.ticks,
            tickSize: 0,
            tickPadding: 10,
            tickRotation: 0,
            format: props.tickFormatter,
          }}
          borderWidth={2}
          borderColor={colors.app.background}
          labelSkipHeight={12}
          labelTextColor={datum => getBestContrastColor(datum.color)}
          tooltip={FeedActivityAgentTotalBarChartTooltip}
          theme={{
            axis: {
              ticks: {
                text: {
                  fontSize: 11,
                  fill: theme.ticks,
                },
              },
              legend: {
                text: {
                  fontSize: 12,
                  fill: theme.legend,
                },
              },
              domain: {
                line: {
                  stroke: theme.grid,
                  strokeWidth: 1,
                },
              },
            },
          }}
        />
      </ContainerChart>
      {/* </PanelContainer > */}
    </>
  );
};

export default FeedActivityAgentTotalBarChart;
