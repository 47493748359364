import _ from 'lodash';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { useRecoilState, useRecoilValue } from 'recoil';
import LoadingIndicator from '../../components/LoadingIndicator';
import { Container } from '../../components/layout/ContainerFlex';
import ImporterDataPreview from '../csv/components/ImporterDataPreview';
import ImporterTransformExpressionEditor from '../csv/components/ImporterTransformExpressionEditor';
import { getImportConfiguration } from '../store/import.default';
import {
  importJob,
  importRawPreview,
  importTransformedPreview,
  importTypeCurrent,
} from '../store/import.state';
import { ImportStepContainerProps } from '../store/import.types';

const ImportTransformStep: React.FC<ImportStepContainerProps> = props => {
  const { t } = useTranslation('importer');
  const [previewData] = useRecoilState(importRawPreview);
  const transformedData = useRecoilValue(importTransformedPreview);
  const job = useRecoilValue(importJob);
  const datasetType = useRecoilValue(importTypeCurrent);
  const isDatasetSource = datasetType === 'dataset';

  if (_.isNil(job))
    return <LoadingIndicator message={t`Loading preview ...`} />;

  const config = getImportConfiguration(props.type, t);
  const isParsed = !_.isNil(previewData);

  const previewSize = _.size(previewData.data);
  const transformedSize = _.size(transformedData?.data);
  return (
    <Container
      hasOverflowY
      componentName="ImportTransformStep"
      col
      className="flex-1 space-y-px"
    >
      {!isDatasetSource && (
        <ImporterDataPreview
          id={'source'}
          data={previewData.data}
          collapsible
          columns={previewData.fields}
        />
      )}

      <ImporterTransformExpressionEditor />
      <ImporterDataPreview
        id={'transformed'}
        data={transformedData?.data}
        columns={transformedData?.fields}
        collapsible
      />
    </Container>
  );
};

export default ImportTransformStep;
