import { Transition } from '@headlessui/react';
import classNames from 'classnames';
import _ from 'lodash';
import React, { PropsWithChildren, Suspense } from 'react';
import { ErrorBoundary } from 'react-error-boundary';
import { useTranslation } from 'react-i18next';
import { useRecoilState, useRecoilValue } from 'recoil';
import { analyticsAgent } from '../AnalyticTracker';
import ErrorIndicator from '../components/ErrorIndicator';
import LoadingIndicator from '../components/LoadingIndicator';
import { HeaderAppMenu } from '../components/headers/HeaderAppMenu';
import { Sidebar } from '../components/layout/Sidebar';
import { datasetAutomationSelectedMenuItem } from '../datasetAutomation/store/datasetAutomation.state';
import { datasetObjectSelectedMenuItem } from '../datasetObject/store/datasetObject.state';
import { actualitySelectedId } from '../feed/store/actuality.state';
import { feedMenuItems } from '../feed/store/feed.default';
import { feedSelectedMenuItemId } from '../feed/store/feed.state';
import { simulationWizardSelectedStep } from '../simulation/store/simulation.wizard.state';
import {
  SIDEBAR_TYPES_MANAGEABLE,
  SidebarType,
  sidebarStateByType,
} from '../store/sidebar.state';

export type SidebarWidth = 64 | 80 | 96 | 120 | 140 | 'full';

export type SidebarSize =
  | 'xs'
  | 'sm'
  | 'md'
  | 'lg'
  | 'xl'
  | 'full'
  | 'half'
  | 'auto'
  | 'fit'
  | 'mobile-sm'
  | 'mobile-md'
  | 'collapsed'
  | 'minimised'
  | 'warehouses';

function getSizeClass(size: SidebarSize) {
  switch (size) {
    case 'xs':
      return {
        width: 'SidebarSize-XS w-sidebar-xs',
        translate: 'translate-sidebar-xs',
      };
    case 'sm':
      return {
        width:
          'SidebarSize-MD w-sidebar-xs sm:w-sidebar-xs md:w-sidebar-xs lg:w-sidebar-xs xl:w-sidebar-sm 2xl:w-sidebar-sm',
        translate: 'translate-sidebar-sm',
      };
    // case 'mobile-sm':
    //   return { width: 'w-sidebar-xs', translate: 'translate-sidebar-sm' };
    case 'md':
      return {
        width:
          'SidebarSize-MD w-sidebar-xs sm:w-sidebar-xs md:w-sidebar-xs lg:w-sidebar-xs xl:w-sidebar-md 2xl:w-sidebar-md',
        translate: 'translate-sidebar-md',
      };
    // case 'mobile-md':
    //   return { width: 'w-sidebar-sm', translate: 'translate-sidebar-sm' };
    case 'lg':
      return { width: 'w-sidebar-lg', translate: 'translate-sidebar-lg' };
    case 'xl':
      return { width: 'w-sidebar-xl', translate: 'translate-sidebar-xl' };
    case 'full':
      return { width: 'w-sidebar-full', translate: 'translate-sidebar-full' };
    case 'half':
      return { width: 'w-sidebar-half', translate: 'translate-sidebar-half' };
    case 'auto':
      return { width: 'w-auto', translate: 'translate-auto' };
    case 'fit':
      return { width: 'w-sidebar-fit', translate: 'translate-sidebar-fit' };
    case 'collapsed':
      return {
        width: 'w-sidebar-collapsed',
        translate: 'translate-sidebar-collapsed',
      };
    case 'minimised':
      return {
        width: 'w-sidebar-minimised',
        translate: 'translate-sidebar-minimised',
      };
    case 'warehouses':
      return {
        width: 'w-sidebar-minimised-icons',
        translate: 'translate-sidebar-minimised-icon',
      };
  }

  return null;
}

export type SidebarContainerProps = PropsWithChildren & {
  type: SidebarType;
  className?: string;
  title: string;
  titleAction?: React.ReactNode;
  title_minimised?: string;
  loadingMessage?: string;
  width?: SidebarWidth;
  widthAuto?: boolean;
  widthAutoMinimised?: boolean;
  widthAutoOpen?: boolean;
  hasScroll?: boolean;
  hasIssue?: boolean;
  hasControls?: boolean;
  hasControlsPin?: boolean;
  withMenu?: React.ReactNode;
  withMenuIcons?: React.ReactNode;
  isGlobal?: boolean;
  sideRight?: boolean;
  sideLeft?: boolean;
  isHiddenMinimised?: boolean;
  noHeaderTitleMinimised?: boolean;
  noHeaderTitle?: boolean;
  size?: SidebarSize;
};

// States:
//  OPEN:
//    — width-set
//    — widthAuto
//  — withMenu

//  MINIMISED:
//  isHiddenMinimised
//    — width-set
//    — widthAuto
//  withMenuIcons
//  noHeaderTitle
//  HeaderAppIcon

const SidebarContainer: React.FC<SidebarContainerProps> = ({
  hasControls = true,
  hasControlsPin = true,
  ...props
}) => {
  const [state, setState] = useRecoilState(sidebarStateByType(props.type));
  const actualityId = useRecoilValue(actualitySelectedId);
  const visibleSidebarsSim = useRecoilValue(
    simulationWizardSelectedStep,
  )?.sidebar;
  const visibleSidebarsDataset = useRecoilValue(
    datasetObjectSelectedMenuItem,
  )?.sidebar;
  const visibleSidebarsAutomation = useRecoilValue(
    datasetAutomationSelectedMenuItem,
  )?.sidebar;
  const { t } = useTranslation('app');

  const feedMenuId = useRecoilValue(feedSelectedMenuItemId(actualityId));
  const feedMenuItemsMap = _.keyBy(feedMenuItems(t), m => m.id);

  const visibleSidebars = {
    ...(visibleSidebarsSim ?? {}),
    ...(visibleSidebarsDataset ?? {}),
    ...(visibleSidebarsAutomation ?? {}),
    ...(feedMenuItemsMap?.[feedMenuId]?.sidebar ?? {}),
  };

  const isHiddenDependsOnStepId = _.some(
    SIDEBAR_TYPES_MANAGEABLE,
    type => type === props.type,
  );
  const sidebarState = visibleSidebars[props.type];
  const isHiddenByStep = isHiddenDependsOnStepId && _.isNil(sidebarState);

  const isHidden = state?.isHidden || isHiddenByStep;

  if (isHidden) {
    return null;
  }

  // const width = props.width || 80;
  const width = props.width;
  const sizeClass = getSizeClass(props.size);
  // const hasScroll = false;

  const sidebarBlurStyle =
    'backdrop-filter backdrop-blur-lg backdrop-saturate-110';

  const sidebarClassBG = classNames('bg-sidebar-main', sidebarBlurStyle);
  const sidebarClassBgGlobal = classNames(
    'bg-sidebar-main/80',
    sidebarBlurStyle,
  );

  const tailwind_hack = (
    <div
      className={classNames(
        'duration-400 duration-100 duration-200 duration-500 duration-700',
        'w-sidebar-xs w-sidebar-sm w-sidebar-md w-sidebar-lg w-sidebar-xl w-sidebar-full w-sidebar-half w-sidebar-fit w-sidebar-collapsed w-sidebar-minimised',
        'w-sidebar-minimised-xs w-sidebar-minimised-sm w-sidebar-minimised-md w-sidebar-minimised-lg w-sidebar-minimised-icons',
        'xs:w-sidebar-xs xs:w-sidebar-sm xs:w-sidebar-md xs:w-sidebar-lg xs:w-sidebar-xl',
        'sm:w-sidebar-xs sm:w-sidebar-sm sm:w-sidebar-md sm:w-sidebar-lg sm:w-sidebar-xl',
        'md:w-sidebar-xs md:w-sidebar-sm md:w-sidebar-md md:w-sidebar-lg md:w-sidebar-xl',
        'lg:w-sidebar-xs lg:w-sidebar-sm lg:w-sidebar-md lg:w-sidebar-lg lg:w-sidebar-xl',
        'xl:w-sidebar-xs xl:w-sidebar-sm xl:w-sidebar-md xl:w-sidebar-lg xl:w-sidebar-xl',
        '2xl:w-sidebar-xs 2xl:w-sidebar-sm 2xl:w-sidebar-md 2xl:w-sidebar-lg 2xl:w-sidebar-xl',
        'translate-sidebar-sm translate-sidebar-md translate-sidebar-lg translate-sidebar-xl translate-sidebar-full translate-sidebar-half translate-sidebar-fit translate-sidebar-collapsed translate-sidebar-minimised translate-sidebar-minimised-icons',
        'translate-64 translate-80 translate-96 w-120 translate-120 w-140 translate-140 w-64 w-80 w-96',
        'top-[1]rem top-[1.5]rem top-[2]rem top-[2.5]rem top-[3]rem top-[3.5]rem top-[6.5]rem',
        'grid-cols-1 grid-cols-2 grid-cols-3 grid-cols-4 grid-cols-5 grid-cols-6',
        'text-state-draft text-state-analyzed text-state-optimized text-state-failed text-state-terminated',
      )}
    />
  );

  return (
    <>
      {/* MINIMISED */}
      <Transition
        data-component={`SideBarContainerCollapsed${
          props.isGlobal ? '-Global' : ''
        }${props.title ? `-${props.title}` : ''}`}
        show={state.isCollapsed}
        className={classNames(
          'flex',
          'border-menu ltr:border-r',
          'bg-sidebar-menu',
          state.isCollapsed && !props.withMenuIcons
            ? 'ltr:[direction:ltr] ltr:[writing-mode:vertical-lr] rtl:[direction:ltr] rtl:[writing-mode:vertical-rl]'
            : '',
          { 'flex-col': !state.isCollapsed },
          { hidden: props.isGlobal },
          { hidden: props.isHiddenMinimised && state.isCollapsed },
          {
            'max-w-collapsed': !props.isHiddenMinimised && !props.withMenuIcons,
          },
          props.withMenuIcons
            ? 'w-sidebar-minimised-xs sm:w-sidebar-minimised-sm md:w-sidebar-minimised-md lg:w-sidebar-minimised flex-col'
            : '',
        )}
        enter={`duration-200 ${
          props.withMenuIcons
            ? 'translate-sidebar-minimised'
            : 'translate-sidebar-collapsed'
        }`}
        enterFrom="w-0"
        enterTo={
          props.withMenuIcons
            ? 'w-sidebar-minimised-xs sm:w-sidebar-minimised-sm md:w-sidebar-minimised-md lg:w-sidebar-minimised'
            : 'w-sidebar-collapsed'
        }
        leave={`duration-200 ${
          props.withMenuIcons
            ? 'translate-sidebar-minimised'
            : 'translate-sidebar-collapsed'
        }`}
        leaveFrom={
          props.withMenuIcons
            ? 'w-sidebar-minimised-xs sm:w-sidebar-minimised-sm md:w-sidebar-minimised-md lg:w-sidebar-minimised'
            : 'w-sidebar-collapsed'
        }
        leaveTo="w-0"
      >
        {!props.isHiddenMinimised && !props.withMenuIcons && (
          <HeaderAppMenu
            title={props.title}
            collapsible
            collapsible_right
            collapsible_vertical
            isCollapsed={state.isCollapsed}
            hasIssue={props.hasIssue}
            // hasControls={hasControls}
            hasControlsCollapse={hasControls}
            hasControlsPin={props.titleAction ? false : hasControlsPin}
            toggleCollapsed={() =>
              setState({ ...state, isCollapsed: !state.isCollapsed })
            }
            onHeaderClick={() => {
              try {
                analyticsAgent?.track(`Sidebar: ${props.title} (Click)`);
              } catch (ex) {
                console.debug('analyticsAgent Error:', ex);
              }
              setState({ ...state, isCollapsed: !state.isCollapsed });
            }}
          />
        )}
        {props.withMenuIcons && (
          <>
            {!props.noHeaderTitleMinimised && (
              <HeaderAppMenu
                title={
                  props.title_minimised ? props.title_minimised : props.title
                }
                collapsible
                collapsible_by_button
                isCollapsed={state.isCollapsed}
                hasIssue={props.hasIssue}
                toggleCollapsed={() =>
                  setState({ ...state, isCollapsed: !state.isCollapsed })
                }
                onHeaderClick={() => {
                  try {
                    analyticsAgent?.track(`Sidebar: ${props.title} (Click)`);
                  } catch (ex) {
                    console.debug('analyticsAgent Error:', ex);
                  }
                  setState({ ...state, isCollapsed: !state.isCollapsed });
                }}
              />
            )}
            {props.withMenuIcons}
          </>
        )}
      </Transition>

      {/* OPEN */}
      <Transition
        data-component={`SideBarContainerOpen${props.isGlobal ? 'Global' : ''}${
          props.title ?? ''
        }`}
        appear={!state.openByDefault && !state.isCollapsed}
        show={!state.isCollapsed /*&& !isCollapsedInner*/}
        className={classNames(
          'flex flex-col',
          'z-container',
          // 'bg-sidebar-menu',
          'border-app-panel-dark/30 border-x',
          'overflow-hidden',
        )}
        enter={`transform transition translate duration-250 ease-out ${sizeClass?.translate}`}
        enterFrom={`transition w-sidebar-collapsed opacity-50 backdrop-filter backdrop-blur`}
        enterTo={`transition ${sizeClass ? sizeClass.width : 'w-' + width}`}
        leave={`transition duration-200 ease-out ${sizeClass?.translate}`}
        leaveFrom={`transition ${sizeClass ? sizeClass.width : 'w-' + width}`}
        leaveTo={`transform w-sidebar-collapsed opacity-0 backdrop-filter backdrop-blur`}
      >
        <Sidebar
          className={classNames(
            'h-full',
            props.isGlobal
              ? sidebarClassBgGlobal +
                  ' ' +
                  'isGlobal z-sidebar absolute top-0 h-full'
              : sidebarClassBG + ' relative',
            sizeClass ? sizeClass.width : 'w-' + width,
            {
              'border-app-panel-dark/50 shadow-4xl right-0 border-l':
                props.sideRight,
            },
            { 'border-app-panel-dark/50 left-0 border-r': props.sideLeft },
            { 'overflow-y-auto overflow-x-hidden': props.hasScroll },
            { hasIssue: props.hasIssue },
            props.className,
          )}
        >
          {!props.noHeaderTitle && (
            <>
              <HeaderAppMenu
                title={props.titleAction ? props.titleAction : props.title}
                collapsible
                collapsible_right
                collapsible_global={props.isGlobal}
                toggleCollapsed={() =>
                  setState({ ...state, isCollapsed: !state.isCollapsed })
                }
                togglePinned={() =>
                  setState({ ...state, isPinned: !state.isPinned })
                }
                isPinned={state.isPinned}
              />
            </>
          )}
          <Suspense
            fallback={
              <div
                className={classNames(
                  'flex flex-col',
                  'items-center justify-center',
                  'h-full',
                  'overflow-hidden',
                  'bg-sidebar-main',
                )}
              >
                <LoadingIndicator
                  selfCenter
                  message={props.loadingMessage || t('Loading Data...')}
                />
              </div>
            }
          >
            <ErrorBoundary
              fallbackRender={({ error, resetErrorBoundary }) => (
                <ErrorIndicator
                  selfCenter
                  message={error.message}
                  hasReloadButton
                  exception={error}
                />
              )}
            >
              {props.children}
            </ErrorBoundary>
          </Suspense>
        </Sidebar>
      </Transition>
    </>
  );
};

export default SidebarContainer;
