import { ImportJobImportType, useRemoveOrderSetMutation } from '@warebee/frontend/data-access-api-graphql';
import _ from 'lodash';
import React, { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useRecoilState, useRecoilValue, useSetRecoilState } from 'recoil';
import { AsyncLoadStatus } from '../../common/types';
import * as Icon from '../../components/icons';
import OrderSetDashboardCard from '../../orders/OrderSetDashboardCard';
import { importTriggeredBySim } from '../../store/global.state';
import { warehouseSelectedId } from '../../store/warehouse.state';
import DashboardItemsGroupContainer from '../DashboardItemsGroupContainer';
import DashboardItemContainer, {
  DashboardItemContainerProps,
} from '../containers/DashboardItemContainer';
import DashboardListItemContainer from '../containers/DashboardListItemContainer';
import useLoadOrderSetDashboard from '../hooks/useLoadOrderSetDashboard';
import DashboardOrderSetInfoCard from '../infoCards/DashboardOrderSetInfoCard';
import {
  dashboardOrderSetData,
  dashboardOrderSetLoadState,
  dashboardOrderSetState,
} from '../store/dashboard.state';
import {
  DashboardItemStatusType,
  DashboardOrder,
  DashboardViewMode,
} from '../store/dashboard.types';

const DataDashboardOrderSet: React.FC = () => {
  const { t } = useTranslation('app');
  const warehouseId = useRecoilValue(warehouseSelectedId);
  const [removeOrderSet] = useRemoveOrderSetMutation();
  const dashData = useRecoilValue(dashboardOrderSetData);
  const [dashState, setDashState] = useRecoilState(dashboardOrderSetState);
  const loadingState = useRecoilValue(dashboardOrderSetLoadState);
  const loadDashboardData = useLoadOrderSetDashboard();
  const setImportTriggerSimId = useSetRecoilState(importTriggeredBySim);

  useEffect(() => {
    // clear import from simulation state
    setImportTriggerSimId(null);
  });

  useEffect(() => {
    loadDashboardData({ sort: dashState.order });
  }, [warehouseId]);

  function changeViewMode(viewMode: DashboardViewMode) {
    setDashState({
      ...dashState,
      viewMode,
    });
  }

  function changeSort(sortMode: DashboardOrder) {
    setDashState({
      ...dashState,
      order: sortMode,
    });
    loadDashboardData({ sort: sortMode, limit: dashState.loadedCount });
  }

  function loadMore() {
    loadDashboardData({
      sort: dashState.order,
      skip: dashState.loadedCount,
      isAppend: true,
    });
  }

  const getOrderSetsCards = () => {
    if (_.isEmpty(dashData)) return null;
    return _.sortBy(dashData).map(orderSet => {
      const { id, title, description } = orderSet;
      const path = `/wh/i/${warehouseId}/orders/${id}`;
      const status = {
        type: DashboardItemStatusType.complete,
        title: t`Ready`,
      };
      const statusInUsed = {
        type: DashboardItemStatusType.inuse,
        title: t`In Use`,
      };
      const hasSimulations = (orderSet.simulationsWith?.totalCount ?? 0) === 0;

      const containerProps: DashboardItemContainerProps = {
        id,
        title,
        description,
        createdDate: new Date(orderSet.createdAt),
        updatedDate: new Date(orderSet.updatedAt),
        path,
        status: hasSimulations ? status : statusInUsed,
        canDelete: hasSimulations,
        onDeleteClick: async () => {
          await removeOrderSet({
            variables: { id: id },
          });
          loadDashboardData({ sort: dashState.order });
        },
      };

      return dashState.viewMode === DashboardViewMode.card ? (
        <DashboardItemContainer
          icon={Icon.DataOrders}
          key={`card-order-set-${id}`}
          {...containerProps}
        >
          <OrderSetDashboardCard summary={orderSet.summary} isActionable />
        </DashboardItemContainer>
      ) : (
        <DashboardListItemContainer
          key={`item-order-set-${id}`}
          {...containerProps}
        />
      );
    });
  };

  return (
    <DashboardItemsGroupContainer
      id={'dashboard-data-order-set'}
      title={t`Orders`}
      state={dashState}
      isLoading={loadingState === AsyncLoadStatus.Loading}
      // collapsible
      onSortChanged={changeSort}
      onViewModeChanged={changeViewMode}
      onLoadMoreClick={loadMore}
      datasetPath="orders"
      datasetJobType={ImportJobImportType.ORDER_SET}
    >
      <DashboardOrderSetInfoCard
        showButtonInline={!_.isEmpty(dashData)}
        listMode={dashState.viewMode === DashboardViewMode.list}
        isLoading={loadingState === AsyncLoadStatus.Loading}
      />
      {getOrderSetsCards()}
    </DashboardItemsGroupContainer>
  );
};

export default DataDashboardOrderSet;
