import Flatten from '@flatten-js/core';
import { AreaConfiguration } from '@warebee/shared/data-access-layout-import-converter';
import {
  LayoutTransformation,
  loadPoint,
  savePoint,
} from '@warebee/shared/data-access-layout-manager';
import {
  ConnectivityGraph,
  Point,
  Segment,
} from '@warebee/shared/engine-model';
import _ from 'lodash';
import { getBoxFromPoints } from '../../../../layout/viewer/store/viewer.helper';
import { ConvertedAreaFeature } from '../converter.serializable.model';

export interface TransformParams {
  center: Flatten.Point;
  offset: Flatten.Point;
  rotation: number;
  flipX: boolean;
  flipY: boolean;
}

const ROTATE_90_CCW = new Flatten.Matrix(0, 1, -1, 0, 0, 0);
const ROTATE_90_CW = new Flatten.Matrix(0, -1, 1, 0, 0, 0);
const ROTATE_180 = new Flatten.Matrix(-1, 0, 0, -1, 0, 0);
const ANGLE_EPS = 0.000001; // TODO input for angle +90 is inaccurate for some reason

function rotate(m: Flatten.Matrix, angle: number): Flatten.Matrix {
  // use precise rotation matrices for known angles
  if (angle === 0) {
    return m;
  } else if (Math.abs(angle - 90) < ANGLE_EPS) {
    return m.multiply(ROTATE_90_CCW);
  } else if (Math.abs(angle + 90) < ANGLE_EPS) {
    return m.multiply(ROTATE_90_CW);
  } else if (Math.abs((angle - 180) % 360) < ANGLE_EPS) {
    return m.multiply(ROTATE_180);
  } else {
    return m.rotate((angle * Math.PI) / 180);
  }
}

export function getTransformationMatrix(
  params: TransformParams,
): LayoutTransformation {
  const flipAndRotateOnly = rotate(new Flatten.Matrix(), params.rotation).scale(
    params.flipX ? -1 : 1,
    params.flipY ? -1 : 1,
  );

  const m = new Flatten.Matrix()
    .translate(params.offset.x, params.offset.y)
    .translate(params.center.x, params.center.y)
    .multiply(flipAndRotateOnly)
    .translate(-params.center.x, -params.center.y);

  return new LayoutTransformation(
    m,
    flipAndRotateOnly,
    params.flipX !== params.flipY,
  );
}

export function transformPoint(pt: Point, t: LayoutTransformation): Point {
  return savePoint(loadPoint(pt).transform(t.m));
}

export function transformSegment(
  { start, end }: Segment,
  t: LayoutTransformation,
): Segment {
  return {
    start: transformPoint(start, t),
    end: transformPoint(end, t),
  };
}

export function transformConnectivityGraph(
  { aislePortals, bayPortals }: ConnectivityGraph,
  t: LayoutTransformation,
): ConnectivityGraph {
  return {
    aislePortals: aislePortals.map(ap => ({
      ...ap,
      coords: transformSegment(ap.coords, t),
    })),
    bayPortals: bayPortals.map(bp => ({
      ...bp,
      coords: transformSegment(bp.coords, t),
    })),
  };
}

export function transformBox(
  { xmax, xmin, ymax, ymin }: Flatten.Box,
  m: Flatten.Matrix,
) {
  const [x1, y1] = m.transform([xmax, ymax]);
  const [x2, y2] = m.transform([xmin, ymin]);

  // console.log(`TRANSFORM`, xmax, x1);
  return new Flatten.Box(
    Math.min(x1, x2),
    Math.min(y1, y2),
    Math.max(x1, x2),
    Math.max(y1, y2),
  );
}

export function transformShape(
  shape: Flatten.Polygon,
  config: AreaConfiguration,
): Flatten.Polygon {
  const transformParams: TransformParams = {
    center: shape.box.center,
    offset: Flatten.point(config.x, config.y),
    flipX: config.flipX,
    flipY: config.flipY,
    rotation: config.rotation,
  };
  const transformMatrix = getTransformationMatrix(transformParams);
  const newPoly = shape.transform(transformMatrix.m);
  return newPoly;
}


export function getTransformedAreaBox(
  area: ConvertedAreaFeature,
  areaConfig: AreaConfiguration,
): Flatten.Box {
  // Validate area
  if (!area) {
    console.warn(
      `Missing or incomplete area data: ${
        area
          ? ''
          : `undefined ${JSON.stringify(area)} ${JSON.stringify(areaConfig)}`
      }`,
    );
    return null; // Ignore and return null
  }

  const areaBox = getBoxFromPoints(_.chunk(area.boundingBox, 2));
  const flattenBox = new Flatten.Box(
    areaBox.xmin,
    areaBox.ymin,
    areaBox.xmax,
    areaBox.ymax,
  );
  const shape = new Flatten.Polygon(flattenBox);
  const transformed = transformShape(shape, areaConfig);
  //return transformed.box;
  const { xmin, xmax, ymin, ymax } = transformed.box;
  return new Flatten.Box(
    parseFloat(xmin.toPrecision(7)),
    parseFloat(ymin.toPrecision(7)),
    parseFloat(xmax.toPrecision(7)),
    parseFloat(ymax.toPrecision(7)),
  );
}

export function getReverseTransformedAreaBox(
  box: Flatten.Box,
  areaConfig: AreaConfiguration,
  offset: Flatten.Point,
) {
  const transformParams: TransformParams = {
    center: box.center,
    offset: offset,
    flipX: false,
    flipY: false,
    rotation: -areaConfig.rotation,
  };
  const shape = new Flatten.Polygon(box);
  const transformMatrix = getTransformationMatrix(transformParams);
  const newShape = shape.transform(transformMatrix.m);
  const { xmin, xmax, ymin, ymax } = newShape.box;
  return new Flatten.Box(
    parseFloat(xmin.toPrecision(7)),
    parseFloat(ymin.toPrecision(7)),
    parseFloat(xmax.toPrecision(7)),
    parseFloat(ymax.toPrecision(7)),
  );
}
