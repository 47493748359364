import classNames from 'classnames';
import _ from 'lodash';
import { Suspense } from 'react';
import { useTranslation } from 'react-i18next';
import { useRecoilState, useRecoilValue } from 'recoil';
import AssignmentDataTable from '../assignment/AssignmentDataTable';
import { panelFixedHeightsCss } from '../common/component.types';
import { PanelHeader } from '../components/designer/panels/PanelHeader';
import { Container } from '../components/layout/ContainerFlex';
import { Spacer } from '../components/layout/Spacer';
import LoadingIndicator from '../components/LoadingIndicator';
import ItemSetDataTable from '../itemSet/ItemSetDataTable';
import ActualityDatasetSelector from './ActualityDatasetSelector';
import ActualityHeatmapDataTable from './heatmapTable/ActualityHeatmapDataTable';
import useActualityDatasetTableTypes from './hooks/useActualityDatasetTableTypes';
import ActualityHqDataTable from './hqTable/ActualityHqDataTable';
import ActualityJobsTable from './jobsTable/ActualityJobsTable';
import {
  actualityDataSetTableTypeSelected,
  actualityExtraDataViewHeight,
  actualitySelectedDocument,
} from './store/actuality.state';

const ActualityExtraDatasetView: React.FC = () => {
  const { t } = useTranslation('feed');
  const actuality = useRecoilValue(actualitySelectedDocument);
  const [height, setHeight] = useRecoilState(actualityExtraDataViewHeight);
  const datasetTypes = useActualityDatasetTableTypes();
  const [selected, setSelected] = useRecoilState(
    actualityDataSetTableTypeSelected,
  );

  if (_.isNil(selected)) return null;

  const selectedType = datasetTypes[selected];

  function genPanelTitleContent() {
    /** extra component for panel here  */
    return <ActualityDatasetSelector />;
  }

  function getFilterTags() {
    // switch (showDatasetTable) {
    //   case 'layout':
    //     return <LayoutFilterTag />;
    //   case 'assignment':
    //     return <AssignmentFilterTag />;
    //   case 'item-set':
    //     return <ItemSetFilterTag />;
    //   case 'items':
    //     return <ItemSetFilterTag />;
    //   case 'order-set':
    //     return <OrderSetFilterTag />;
    //   case 'orders':
    //     return <OrderSetFilterTag />;
    // }
    return null;
  }

  return (
    <Container
      col
      componentName={`ActualityExtraDatasetView`}
      overflow
      fullHeight={false}
      className={classNames(
        height !== 'h-min' ? 'min-h-20' : '',
        'border-app-panel-dark ltr:border-l rtl:border-r',
        'relative',
        height ? panelFixedHeightsCss[height] : null,
      )}
    >
      <Suspense
        fallback={
          <LoadingIndicator
            className="absolute left-0 right-0 top-[45%] m-auto max-h-28 max-w-10 shadow-2xl"
            absolute
            selfCenter
            backdrop
            message={t`Loading Data...`}
          />
        }
      >
        <PanelHeader
          title={
            <>
              {genPanelTitleContent()}
              <Spacer flexspace />
            </>
          }
          closable={true}
          onCloseClick={() => setSelected(null)}
          filterPlaceholder={getFilterTags()}
          adjustable={!_.isNil(height)}
          fixedHeight={height}
          onHeightChange={setHeight}
        />

        {height !== 'h-min' && (
          <>
            {selected === 'activity-feed' && (
              <ActualityHqDataTable id="actuality-hq-table" />
            )}
            {selected === 'jobs' && (
              <ActualityJobsTable id="actuality-job-table" />
            )}
            {selected === 'activity-metric' && (
              <ActualityHeatmapDataTable id="actuality-heatmap-table" />
            )}

            {selected === 'assignment' && (
              <AssignmentDataTable
                id={actuality.assignmentId}
                hideScreenTitle={true}
              />
            )}
            {selected === 'item-set' && (
              <ItemSetDataTable
                id={actuality.itemSetId}
                title="Items"
                hideScreenTitle={true}
              />
            )}
          </>
        )}
      </Suspense>
    </Container>
  );
};

export default ActualityExtraDatasetView;
