import React from 'react';
import { PipelineConnectorApi } from '../connectors/PipelineConnectorApi';
import { PipelineConnectorDataset } from '../connectors/PipelineConnectorDataset';
import { PipelineConnectorEmail } from '../connectors/PipelineConnectorEmail';
import { PipelineConnectorS3 } from '../connectors/PipelineConnectorS3';
import { PipelineConnectorSftp } from '../connectors/PipelineConnectorSftp';
import { PipelineConnectorSnowflake } from '../connectors/PipelineConnectorSnowflake';

export const PipelineSectionConnectors: React.FC = () => {
  return (
    <>
      <PipelineConnectorApi />
      <PipelineConnectorEmail />
      <PipelineConnectorDataset />
      <PipelineConnectorS3 />
      <PipelineConnectorSnowflake />
      <PipelineConnectorSftp />
    </>
  );
};
