import {
  LocationFilterUnionFragment,
  SimulationItemFilterUnionFragment,
} from '@warebee/frontend/data-access-api-graphql';
import { VolumeComplianceDataRow } from '@warebee/shared/export-converter';
import {
  HeatmapMetricDescriptor,
  HeatmapMetricDescriptorBase,
} from '../../../common/heatmap.types';

export type OccupancyCategoryDescriptorBase = {
  key: string;
  tag: string;
  color?: string;
  textColor?: string;
  fromVolumePercentile?: number;
  toVolumePercentile?: number;
};

export type OccupancyCategoryDescriptor = OccupancyCategoryDescriptorBase & {
  title?: string;
  description?: string;
};

export type OccupancyCategoryDescriptorMap = Record<
  string,
  OccupancyCategoryDescriptor
>;

export type OccupancyCategoryFilter = Record<string, boolean>;

export const occupancyModes = ['volume', 'stockUom', 'maxUom'] as const;
export type OccupancyMode = (typeof occupancyModes)[number];

export type OccupancySummary = {
  occupiedVolume: number;
  unOccupiedVolume: number;
  utilizedVolume: number;
  unUtilizedVolume: number;
  assignedVolume: number;
  unknownVolume: number;
  emptyVolume: number;
  totalStorageVolume: number;
};

export const occupancyMetrics = [
  'occupancy-volume-rate',
  'occupancy-rate',
  'utilization-rate',
  'utilization-max-uom-rate',
] as const;

export type OccupancyMetric = (typeof occupancyMetrics)[number];

export type OccupancyMetricDescriptorBase = HeatmapMetricDescriptorBase<
  OccupancyMetric,
  VolumeComplianceDataRow
>;

export type OccupancyMetricDescriptor = HeatmapMetricDescriptor<
  OccupancyMetric,
  VolumeComplianceDataRow
>;

export type AssignmentOccupancyViewMode = 'summary' | 'filters';
export type AssignmentOccupancyFilter = {
  items: SimulationItemFilterUnionFragment;
  locations: LocationFilterUnionFragment;
};
