import { ActualityExtraSettings } from './actuality.types';

export const actualityExtraSettingsDefault: ActualityExtraSettings = {
  autoRefresh: false,
  refreshInterval: 5,
  assignmentExtraFields: [],
  activityExtraFields: [],
  assignmentMetricDescriptors: [],
  actualityMetricDescriptors: [],
};
