import { ComputedCell } from '@nivo/heatmap';
import classNames from 'classnames';
import { format } from 'date-fns-tz';
import isWeekend from 'date-fns/isWeekend';
import _ from 'lodash';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { getQualitativeColor } from '../../common/color.helper';
import { formatInteger } from '../../common/formatHelper';
import { TooltipItem } from '../../components/stats/TooltipItem';
import { FeedAggregatedHeatmapItem } from '../layout/FeedActivityAgentHeatmap';

export type FeedAgentHeatmapChartTooltipProps = {
  datum: ComputedCell<FeedAggregatedHeatmapItem>;
};

const FeedAgentHeatmapChartTooltip: React.FC<
  FeedAgentHeatmapChartTooltipProps
> = props => {
  const { datum } = props;
  const { t } = useTranslation('feed');
  const data = props.datum.data;
  // const showOnLeft = props.totalX > 0 && datum.index / props.totalX > 0.66;
  console.log('props.datum:::', props.datum);

  const date = data.intervalDate;
  const weekend = _.isNil(date) ? false : isWeekend(date);

  const dayTitleShort = _.isNil(date)
    ? t`No Date`
    : format(date, 'yyyy dd hh:mm');
  const weekdayTitle = _.isNil(date) ? null : format(date, 'EE');

  const colors = getQualitativeColor(props.datum.data.groupKey, 'dimension');

  return (
    <div
      key={datum.id}
      data-component="FeedAgentHeatmapChartTooltip"
      className={classNames(
        'bg-app-panel-dark/90',
        'backdrop-blur backdrop-filter',
        'text-sm',
        'whitespace-nowrap',
      )}
      // style={{
      //   left: showOnLeft ? undefined : 16,
      //   right: showOnLeft ? 16 : undefined,
      //   top: -64,
      // }}
    >
      <div className="min-w-tooltip">
        <div
          className={classNames(
            'flex items-center p-2 text-xs ltr:pl-3 rtl:pr-3',
          )}
          style={{
            backgroundColor: colors[0],
            color: colors[1],
          }}
        >
          {props.datum.data.groupKey}
        </div>
        <div
          className={classNames(
            'flex items-center',
            'px-2 py-2',
            'text-start text-sm font-bold',
            'bg-app-panel-dark',
          )}
        >
          <span className={classNames('ltr:pl-1 rtl:pr-1', 'flex-1')}>
            {dayTitleShort}
          </span>
          {weekdayTitle && (
            <span
              className={classNames(
                'ltr:ml-3 rtl:mr-3',
                'px-2 py-0.5',
                'rounded uppercase',
                'border',
                weekend ? 'border-menu/50 text-menu-active' : 'border-menu',
              )}
            >
              {format(date, 'EE')}
            </span>
          )}
        </div>
      </div>

      <div
        className={classNames(
          'px-1',
          'divide-menu/50 divide-y divide-solid divide-opacity-50',
          'border-menu/75 border-t',
        )}
      >
        <TooltipItem
          title={t`Lines Picked`}
          value={formatInteger(data.eventCount)}
        />
        <TooltipItem
          title={t`Items Picked`}
          value={formatInteger(data.uomCount)}
        />

        {data.intervalsCount > 1 && (
          <TooltipItem
            title={t`Work hour`}
            value={formatInteger(data.intervalsCount)}
          />
        )}
        {data.intervalsCount > 1 && (
          <TooltipItem
            title={t`Lines Rate`}
            value={formatInteger(data.eventCount / data.intervalsCount)}
          />
        )}
        {data.intervalsCount > 1 && (
          <TooltipItem
            title={t`UOM's Rate`}
            value={formatInteger(data.uomCount / data.intervalsCount)}
          />
        )}
      </div>
    </div>
  );
};

export default FeedAgentHeatmapChartTooltip;
