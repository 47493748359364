import { SortDirection } from '@warebee/frontend/data-access-api-graphql';
import { SelectQueryBuilder } from 'kysely';
import { atom, selector } from 'recoil';
import { AsyncLoadStatus, DataTableState } from '../../common/types';
import { filterPresetSelected } from '../../filterPreset/store/filterPreset.state';
import { FilterPreset } from '../../filterPreset/store/filterPreset.types';
import { actualitySelectedDocument } from './actuality.state';
import {
  ActualityHqDataColumn,
  ActualityHqDataRows,
} from './datasetQueries/actualityHqDataRows';
import {
  ActualityHqQueryBuilderParams,
  getHqQueryBuilder,
} from './datasetQueries/actualityQueryBuilder';
import { feedQueryBuilderParams } from './feed.state';

/**
 * Actuality hyper cube data (feed joined with dataset ) state
 * - table data and table state
 */

const getKey = (postfix: string) => `warebee-actuality-hq-${postfix}`;

export const actualityHqBuilderParams = selector<ActualityHqQueryBuilderParams>(
  {
    key: getKey('hq-query-builder-params'),
    get: ({ get }) => {
      const actuality = get(actualitySelectedDocument);
      const queryParamsBase = get(feedQueryBuilderParams);
      return {
        ...queryParamsBase,
        itemSetId: actuality.itemSetId,
        layoutId: actuality.layoutId,
        assignmentId: actuality.assignmentId ?? 'dummy',
        filterPreset: get(
          filterPresetSelected,
        ) as FilterPreset<ActualityHqDataColumn>,
      };
    },
  },
);

export const actualityHqBuilderBase = selector<{
  b: SelectQueryBuilder<any, any, any>;
}>({
  key: getKey('hq-query-builder-base'),
  get: ({ get }) => {
    const queryParamsBase = get(actualityHqBuilderParams);
    const builder = getHqQueryBuilder(queryParamsBase).selectFrom('hq');
    return { b: builder };
  },
});

export const actualityHqTableData = atom<ActualityHqDataRows>({
  key: getKey('hq-table-data'),
  default: [],
});

export const actualityHqTableTotalCount = atom<number>({
  key: getKey('hq-table-total-count'),
  default: 0,
});

export const actualityHqTableDataLoadStatus = atom<AsyncLoadStatus>({
  key: getKey('hq-table-data-status'),
  default: AsyncLoadStatus.None,
});

export const actualityHqTableDataState = atom<
  DataTableState<ActualityHqDataColumn>
>({
  key: getKey('hq-table-data-state'),
  default: {
    sortValues: {
      eventEndTime: SortDirection.DESC,
    },
    searchValues: {},
  },
});
