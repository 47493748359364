import {
  AnalyzeResultProductFragment,
  ReassignTaskAction,
} from '@warebee/frontend/data-access-api-graphql';
import _ from 'lodash';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { useRecoilValue } from 'recoil';
import JobsDetailsUOM from '../../../components/jobs/JobsDetailsUOM';
import { StatListHeader } from '../../../components/stats/StatListHeader';
import { StatListItem } from '../../../components/stats/StatListItem';
import PanelContainer from '../../../containers/PanelContainer';
import {
  simulationHeatmapLocationByBay,
  simulationHeatmapLocationByLevel,
} from '../../store/simulation.heatmap.state';

export type ReassignLocationPropertiesProps = {
  locationId: string;
  analyzeId: string;
};

const ReassignLocationProperties: React.FC<
  ReassignLocationPropertiesProps
> = props => {
  const levelLocations = useRecoilValue(
    simulationHeatmapLocationByLevel(props.analyzeId),
  );
  const bayLocations = useRecoilValue(
    simulationHeatmapLocationByBay(props.analyzeId),
  );

  const products =
    levelLocations?.[props.locationId] ?? bayLocations?.[props?.locationId];

  const { t } = useTranslation('simulation');

  function getItems(
    products: AnalyzeResultProductFragment[],
    action: ReassignTaskAction,
  ) {
    const jobType = action === ReassignTaskAction.PUT ? 'add' : 'remove';
    return _(products)
      .map(p => {
        const tasks = _.filter(p.reassignTasks, t => t.action === action);
        return (
          <React.Fragment key={`item-${p.itemId}`}>
            <StatListItem
              titleSelectable
              title={p.sku}
              labelValueType={'itemId'}
              labelValueFilters={{
                consignee: p.consignee,
                sku: p.sku,
              }}
            />

            {!_.isEmpty(p.reassignTasks) && p.reassignCount > 0 && (
              <JobsDetailsUOM
                title={t`Reassign Task(s)`}
                taskCount={p.reassignCount}
                taskType={jobType}
                breakdownByUom={_.map(tasks, task => ({
                  uom: task?.breakdownByUom[0]?.uom ?? t`N/A`,
                  uomQuantity: task?.breakdownByUom[0]?.uomQuantity ?? 0,
                }))}
              />
            )}
            {/* 
            <StatListItem title={t`Visits`} value={p.hitCount} />
            <StatListItem title={t`ABC (%)`} value={p.cumulativePercentRank} />

            <StatListItem title={t`Picked`} value={p.pickCount} />
            <StatListItem title={t`Replenished`} value={p.replenishmentCount} />
            <StatListItem title={t`Reassigned`} value={p.reassignCount} />

            <StatListItem title={t`Picked (qty)`} value={p.quantityPicked} />
            <StatListItem
              title={t`Reassigned (qty)`}
              value={p.quantityReplenished}
            />
            <StatListItem
              title={t`Replenished (qty)`}
              value={p.quantityReassigned}
            /> */}
          </React.Fragment>
        );
      })
      .value();
  }

  const productsTake = _.filter(products, p =>
    _.some(p.reassignTasks, t => t.action === ReassignTaskAction.TAKE),
  );
  const productsPut = _.filter(products, p =>
    _.some(p.reassignTasks, t => t.action === ReassignTaskAction.PUT),
  );
  console.log(productsTake, productsPut);
  if (_.isEmpty(productsTake) && _.isEmpty(productsPut)) {
    return null;
  }

  return (
    <PanelContainer
      id="location-property-reassigned-products"
      title={t`Reassign`}
      collapsible
      hasPadding
    >
      {!_.isEmpty(productsTake) && (
        <>
          <StatListHeader title={t`Items removed`} />
          {getItems(productsTake, ReassignTaskAction.TAKE)}
        </>
      )}
      {!_.isEmpty(productsPut) && (
        <>
          <StatListHeader title={t`Items added`} />
          {getItems(productsPut, ReassignTaskAction.PUT)}
        </>
      )}
    </PanelContainer>
  );
};

export default ReassignLocationProperties;
