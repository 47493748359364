import { cn } from '../common/utils';
import CopyToClipboardButton from '../components/actions/CopyToClipboardButton';
import * as Icon from '../components/icons';
import LinkTraced from '../components/nav/LinkTraced';
import Tag from './Tag';

type TagProps = {
  value: string;
  url?: string;
  showFull?: boolean;
  canCopy?: boolean;
  startSlice?: number;
  endSlice?: number;
  className?: string;
};

export const TagFilename = ({
  value,
  url,
  showFull = false,
  canCopy = true,
  startSlice = 64,
  endSlice = 8,
  className,
}: TagProps) => {
  if (!value) return null;

  function getFileExtension(filename: string): JSX.Element {
    const extension = filename.split('.').pop()?.toLowerCase();
    return <span>{extension}</span>;
  }

  function getIconByExtension(filename: string): JSX.Element {
    const extension = filename.split('.').pop()?.toLowerCase();
    const iconStyle = 'h-6 w-6 fill-current';
    
    switch (extension) {
      case 'xls':
        return <Icon.FiletypeXlsx className={cn(iconStyle)} />;
      case 'csv':
        return <Icon.FiletypeCsv className={cn(iconStyle)} />;
      case 'zip':
        return <Icon.FiletypeData className={cn(iconStyle)} />;
      case 'json':
        return <Icon.FiletypeJson className={cn(iconStyle)} />;
      default:
        return <Icon.FiletypeAny className={cn(iconStyle)} />;
    }
  }

  return (
    <Tag className={cn('group relative w-full', className)} title={value}>
      <LinkTraced
        rel="noopener noreferrer"
        className={cn(
          'group',
          'cursor-pointer',
          'flex items-center',
          'flex-grow',
          'hover:text-menu-active',
          'w-full truncate ltr:mr-1 rtl:ml-1',
        )}
        to={url}
        target="_blank"
      >
        {getIconByExtension(value)}
        {showFull
          ? value
          : value.length > startSlice + endSlice
            ? `${value.slice(0, startSlice)} - ... - ${value.slice(-endSlice)}`
            : value}
      </LinkTraced>
      {canCopy && (
        <CopyToClipboardButton
          hasIconSmall
          value={value}
          className="absolute -left-2 top-0 hidden group-hover:inline-flex"
        />
      )}
    </Tag>
  );
};
