import {
  AppBillingProductCategory,
  AppBillingProductFragment,
  BillableOperationType,
  WarehouseSize,
} from '@warebee/frontend/app-billing-graphql-api';
import classNames from 'classnames';
import _ from 'lodash';
import React, { PropsWithChildren } from 'react';
import { useTranslation } from 'react-i18next';
import { getCurrencySymbolSettings } from '../common/formatHelper';
import { HelperMessage } from '../components/HelperMessage';
import ButtonSwitchMulti from '../components/actions/ButtonSwitchMulti';
import * as Icon from '../components/icons';
import { Container } from '../components/layout/ContainerFlex';
import { Spacer } from '../components/layout/Spacer';
import AppBillingPricingHeader from './AppBillingPricingHeader';
import AppBillingPriceCard from './cards/AppBillingPriceCard';
import { getWarehouseDescriptor } from './store/appBilling.helper';

export type AppBillingPricingCardsViewMode = 'FULL' | 'PREVIEW';
export type AppBillingPricingCardsPriceMode = 'PRICING_SHOW' | 'PRICING_HIDE';
export type AppBillingPricingCardsCategoryMode =
  | 'ALL'
  | 'SUBSCRIPTION'
  | 'BUNDLE';
export type AppBillingPricingCardsLinkMode = 'APP' | 'WEBSITE';

export function getFilteredProducts(
  products: AppBillingProductFragment[],
  currency: string,
  whSize: WarehouseSize,
  category: AppBillingProductCategory,
) {
  return _(products)
    .filter(
      p =>
        _.some(
          p.prices,
          price =>
            currency &&
            price.currency?.toLowerCase() === currency?.toLowerCase(),
        ) &&
        p.warehouseSize === whSize &&
        p.productCategory === category,
    )
    .sortBy(p => (p.operationType === BillableOperationType.ANALYZE ? 0 : 1))
    .value();
}

export function getFilteredPrices(
  products: AppBillingProductFragment[],
  currency: string,
  whSize: WarehouseSize,
  category: AppBillingProductCategory,
) {
  return _(getFilteredProducts(products, currency, whSize, category))
    .map(p => p.prices)
    .flatten()
    .filter(
      price =>
        currency && price.currency?.toLowerCase() === currency?.toLowerCase(),
    )
    .value();
}

export type AppBillingPricingCardsProps = PropsWithChildren & {
  products: AppBillingProductFragment[];
  selectedPriceId?: string;

  modePreview?: AppBillingPricingCardsViewMode;
  modeCategory?: AppBillingPricingCardsCategoryMode;
  modePrice?: AppBillingPricingCardsPriceMode;
  modeLink?: AppBillingPricingCardsLinkMode;

  currency: string;
  onCurrencyChange: (currency: string) => void;

  category: AppBillingProductCategory;
  onCategoryChange: (category: AppBillingProductCategory) => void;

  whSize: WarehouseSize;
  onWhSizeChange: (whSize: WarehouseSize) => void;
  hasNoScroll?: boolean;
};

const AppBillingPricingCards: React.FC<AppBillingPricingCardsProps> = props => {
  const { products, currency, category, whSize } = props;
  const { t } = useTranslation('app');

  const filteredProducts = getFilteredProducts(
    products,
    currency,
    whSize,
    category,
  );

  const currencies = _(products)
    .map(p => p.prices)
    .flatten()
    .map(price => price.currency.toUpperCase())
    .uniq()
    .value();

  const sizes = [
    WarehouseSize.SMALL,
    WarehouseSize.MEDIUM,
    WarehouseSize.LARGE,
  ];

  // const productCategories = [
  //   AppBillingProductCategory.SUBSCRIPTION,
  //   AppBillingProductCategory.BUNDLE,
  // ];

  const isHeroMode = props.modePreview === 'PREVIEW';

  const isModePricing = props.modePrice === 'PRICING_SHOW' ? true : false;
  const isModeCategoryAll = props.modeCategory === 'ALL' ? true : false;
  const isModeLinkPublic = props.modeLink === 'WEBSITE' ? true : false;

  return (
    <>
      <AppBillingPricingHeader
        products={products}
        currency={currency}
        onCurrencyChange={props.onCurrencyChange}
        category={category}
        onCategoryChange={props.onCategoryChange}
        showCurrencySwitch={isModePricing}
        showCategorySwitch={isModeCategoryAll}
        modePreview={props.modePreview}
        modeCategory={props.modeCategory}
        modePrice={props.modePrice}
        modeLink={props.modeLink}
      />

      <Container col hasOverflowY>
        <div
          data-component="PricingTableContainer"
          className={classNames(
            'flex flex-col xl:flex-row',
            'px-2 pb-20 xl:space-x-4 xl:px-10',
          )}
        >
          <div className={classNames('mb-6 flex-1 lg:mb-4')}>
            {props.children}
            <div
              className={classNames(
                'sticky top-0',
                'mt-4',
                'rounded-t-xl',
                'p-2 xl:p-4',
                'backdrop-saturate-110 backdrop-blur-lg backdrop-filter',
                'bg-opacity-80',
              )}
            >
              <div className={classNames('flex flex-1')}>
                <div
                  className={classNames(
                    'flex flex-1 items-center text-3xl xl:text-4xl',
                    isHeroMode ? 'text-menu-active-text' : 'text-menu-active',
                  )}
                >
                  <div
                    className={classNames(
                      'relative flex items-center justify-center rounded-full font-bold',
                      'h-8 w-8 xl:h-10 xl:w-10',
                      'text-sm',
                      'ltr:mr-2 rtl:ml-2',
                      isHeroMode
                        ? 'border-app-panel-dark border-2'
                        : 'border-menu-active text-menu-active border',
                    )}
                  >{`1`}</div>
                  <div
                    className={classNames(
                      'ltr:ml-1 rtl:mr-1',
                      'text-xl xl:text-2xl',
                    )}
                  >{t`Warehouse Size`}</div>
                </div>
              </div>

              <HelperMessage
                isHero={isHeroMode}
                hasBefore
                iconAction={Icon.ArrowBottom}
                textSize={'xl'}
              >
                {t`Please select your Warehouse size.`}
                <br />
                {t`Warehouse size is how many locations are in the simulated layout.`}
              </HelperMessage>

              <div
                className={classNames(
                  'mt-4',
                  'flex flex-col items-center rounded-xl p-2',
                  isHeroMode
                    ? 'bg-menu'
                    : 'border-app-panel-dark bg-menu-active text-menu-active-text border',
                )}
              >
                <div
                  className={classNames('mt-2 flex w-full flex-1 items-center')}
                >
                  <ButtonSwitchMulti
                    autoSize
                    buttonType={isHeroMode ? 'secondary' : 'secondary'}
                    className={classNames('!lg:text-xl flex-1 !text-xl')}
                    options={sizes.map(s => ({
                      label: getWarehouseDescriptor(s, t).shortTitle,
                    }))}
                    selectedIndex={_.indexOf(sizes, whSize)}
                    onClick={index => props.onWhSizeChange(sizes[index])}
                  />
                </div>
                <div
                  data-component="WarehouseSizeLimitDescriptor"
                  className={classNames(
                    'text-xl',
                    'mb-2 mt-4',
                    'px-3 py-4',
                    isHeroMode ? 'text-menu-active' : '',
                  )}
                >
                  {getWarehouseDescriptor(whSize, t).locationLimitText}
                </div>
              </div>

              <div className={classNames('flex flex-1 flex-col', 'mt-12')}>
                <div
                  className={classNames(
                    'flex flex-1 items-center',
                    'text-3xl xl:text-4xl',
                    isHeroMode ? 'text-menu-active-text' : 'text-menu-active',
                  )}
                >
                  <div
                    className={classNames(
                      'relative flex items-center justify-center rounded-full font-bold',
                      'h-8 w-8 xl:h-10 xl:w-10',
                      'text-sm ltr:mr-2 rtl:ml-2',
                      isHeroMode
                        ? 'border-app-panel-dark border-2'
                        : 'border-menu-active text-menu-active border',
                    )}
                  >{`2`}</div>
                  <div
                    className={classNames(
                      'ltr:ml-1 rtl:mr-1',
                      'text-xl xl:text-2xl',
                    )}
                  >{t`Select Plan`}</div>
                </div>

                <HelperMessage
                  isHero={isHeroMode}
                  hasBefore
                  iconAction={Icon.ArrowRight}

                  // textSize={'xl'}
                >
                  {t`Please select your plan:`}
                  <br />
                  {t`Full subscription with Optimisation which includes the Analyse and Compliance options or just the Analyse & Compliance options.`}
                </HelperMessage>
              </div>
            </div>
          </div>
          <div
            data-component="CurrencySwitchContainer"
            className={classNames(
              'flex xl:hidden',
              'items-center',
              'mb-4 ltr:ml-2 rtl:mr-2',
            )}
          >
            <Spacer flexspace />
            <ButtonSwitchMulti
              autoSize
              buttonType="secondary"
              className={classNames('!lg:text-xl !text-lg')}
              options={currencies.map(currencyCode => ({
                label: getCurrencySymbolSettings(currencyCode).symbol,
              }))}
              selectedIndex={_.indexOf(currencies, currency)}
              onClick={index => props.onCurrencyChange(currencies[index])}
            />
          </div>
          <div
            data-component="PricingTableContainer"
            className={classNames(
              'flex items-center',
              'w-full',
              'xl:ltr:ml-2 xl:rtl:mr-2',
            )}
          >
            <div
              data-component="PricingTable"
              className={classNames(
                'space-y-10 sm:space-y-0',
                'md:space-x-2 xl:space-x-4',
                'md:flex',
                'mb-4',
              )}
            >
              {filteredProducts.map(product => {
                const price = product.prices.find(
                  price =>
                    currency &&
                    price.currency?.toLowerCase() === currency?.toLowerCase(),
                );
                const isSelected = props.selectedPriceId === price.id;

                return (
                  <AppBillingPriceCard
                    key={product.id + '-' + price.id}
                    modePreview={props.modePreview}
                    modeCategory={props.modeCategory}
                    modePrice={props.modePrice}
                    modeLink={props.modeLink}
                    size={props.whSize}
                    price={price}
                    product={product}
                    isSelected={isSelected}
                    featurePreset={isHeroMode ? 'short' : 'full'}
                    displayPreset={isHeroMode ? 'short' : 'full'}
                  />
                );
              })}
            </div>
          </div>
        </div>
        {/* </Container> */}
      </Container>
    </>
  );
};

export default AppBillingPricingCards;
