import Flatten from '@flatten-js/core';
import Konva from 'konva';
import { Vector2d } from 'konva/lib/types';
import _ from 'lodash';
import { IStagePropsState } from './stage.state';

export const STAGE_TYPES = [
  'viewer-area-view',
  'viewer-bay-view',
  'simulation-area-view',
  'simulation-bay-view',
  'converter-area-view',
  'converter-bay-view',
  'workforce-agent-schedule',
  'jobs-schedule',
] as const;

type StageTuple = typeof STAGE_TYPES;
export type StageType = StageTuple[number];

const scaleBy = 1.2;
const touchScaleBy = 1.04;

export const getChangesOnWheel = _.throttle(
  (e: Konva.KonvaEventObject<WheelEvent>): Partial<IStagePropsState> => {
    const stage = e.target.getStage() as Konva.Stage;
    if (_.isNil(stage)) return null;
    const oldScale = stage.scaleX();
    const pointer = stage.getPointerPosition();

    const mousePointTo = {
      x: (pointer.x - stage.x()) / oldScale,
      y: (pointer.y - stage.y()) / oldScale,
    };

    const scale = e.evt.deltaY < 0 ? oldScale * scaleBy : oldScale / scaleBy;

    const position: [number, number] = [
      pointer.x - mousePointTo.x * scale,
      pointer.y - mousePointTo.y * scale,
    ];

    return { position, scale };
  },
  50,
  { leading: true },
);

export function getDistance(p1, p2) {
  return Math.sqrt(Math.pow(p2.x - p1.x, 2) + Math.pow(p2.y - p1.y, 2));
}

export function getCenter(p1, p2) {
  return {
    x: (p1.x + p2.x) / 2,
    y: (p1.y + p2.y) / 2,
  };
}

export const getChangesOnPinDrag =
  // _.throttle(
  (
    e: Konva.KonvaEventObject<TouchEvent>,
    distanceInitial: number,
    center: Vector2d,
  ): Partial<IStagePropsState> => {
    e.cancelBubble = true;
    e.evt.preventDefault();
    const stage = e.target.getStage() as Konva.Stage;
    const oldScale = stage.scaleX();
    const [p1, p2] = stage.getPointersPositions();

    // if the stage was under Konva's drag&drop
    // we need to stop it, and implement our own pan logic with two pointers
    // if (stage.isDragging()) {
    //   stage.stopDrag();
    // }

    //const center = getCenter(p1, p2);
    const deltaScale = getDistance(p1, p2) / distanceInitial;
    const scale =
      deltaScale > 1 ? oldScale * touchScaleBy : oldScale / touchScaleBy;

    const mousePointTo = {
      x: (center.x - stage.x()) / oldScale,
      y: (center.y - stage.y()) / oldScale,
    };
    const position: [number, number] = [
      center.x - mousePointTo.x * scale,
      center.y - mousePointTo.y * scale,
    ];

    return { position, scale };
  };
//   ,
//   50,
// );

// INFO: In konva [0,0] point is left-top. Y axis  reverts automatically, cause  stage use negative scale factor.
export function adjustStage(props: IStagePropsState): IStagePropsState {
  const { padW, padH } = props;
  const baseWidth = Math.abs(
    props.contentBounds[1][0] - props.contentBounds[0][0],
  );
  const baseHeight = Math.abs(
    props.contentBounds[1][1] - props.contentBounds[0][1],
  );
  const paddedBounds = props.contentBounds.map((coords, i) => {
    const factor = i === 0 ? 1 : -1;
    return [
      coords[0] - factor * padW * baseWidth,
      coords[1] - factor * padH * baseHeight,
    ];
  });
  const w = Math.abs(paddedBounds[1][0] - paddedBounds[0][0]);
  const h = Math.abs(paddedBounds[1][1] - paddedBounds[0][1]);
  const scaleX = props.size[0] / w;
  const scaleY = props.size[1] / h;
  const scale = Math.min(scaleX, scaleY);
  const position: [number, number] = [
    (paddedBounds[0][0] - (props.size[0] / scale - w) / 2) * scale * -1,
    (h + paddedBounds[0][1] + (props.size[1] / scale - h) / 2) * scale,
  ];

  return { ...props, scale, position, forceAdjustContent: false };
}

export function translateToCenter(
  points: Flatten.Point[],
  center: Flatten.Point[],
  delta: number,
): number[] {
  return _(points)
    .map((p, i) => {
      const deltaVector = new Flatten.Vector(p, center[i])
        .normalize()
        .multiply(delta);
      const targetPoint = p.translate(deltaVector);
      return [targetPoint.x, targetPoint.y];
    })
    .flatten()
    .value();
}
