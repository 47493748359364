import _ from 'lodash';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { useRecoilState } from 'recoil';
import useFormatter from '../../common/useFormatter';
import * as Icon from '../../components/icons';
import { Container } from '../../components/layout/ContainerFlex';
import { ScreenTitle } from '../../components/layout/ScreenTitle';
import TitleSection from '../../components/layout/TitleSection';
import { Stat } from '../../components/stats/Stat';
import { StatGroup } from '../../components/stats/StatGroup';
import useActualityMetricDescriptors from '../../metrics/actuality/useActualityMetricDescriptors';
import { useAssignmentMetricDescriptors } from '../../metrics/assignment/useAssignmentMetricDescriptors';
import { actualityMetricSelected } from '../store/actuality.heatmap.state';
import { actualityHeatmapTypeSelected } from '../store/actuality.state';
import { assignmentMetricSelected } from '../store/assignment.heatmap.state';

interface ActualityHeatmapPanelProps {
  heatmapType?: 'events' | 'assignment';
}

const ActualityHeatmapPanel: React.FC<ActualityHeatmapPanelProps> = ({
  heatmapType: externalHeatmapType,
}) => {
  const { t } = useTranslation('feed');
  const formatter = useFormatter();
  const [internalHeatmapType, setInternalHeatmapType] = useRecoilState(
    actualityHeatmapTypeSelected,
  );
  const heatmapType = externalHeatmapType || internalHeatmapType;

  // Update internal state when external prop changes
  React.useEffect(() => {
    if (externalHeatmapType && externalHeatmapType !== internalHeatmapType) {
      setInternalHeatmapType(externalHeatmapType);
    }
  }, [externalHeatmapType, internalHeatmapType, setInternalHeatmapType]);

  const [eventsMetric, setEventsMetric] = useRecoilState(
    actualityMetricSelected,
  );
  const [assignmentMetric, setAssignmentMetric] = useRecoilState(
    assignmentMetricSelected,
  );
  const actualityMetricDescriptors = useActualityMetricDescriptors();
  const assignmentMetricDescriptors = useAssignmentMetricDescriptors();

  const metricDescriptors =
    heatmapType === 'events'
      ? actualityMetricDescriptors
      : assignmentMetricDescriptors;
  const metric = heatmapType === 'events' ? eventsMetric : assignmentMetric;

  function setMetric(v) {
    if (heatmapType === 'events') {
      setEventsMetric(v);
    }
    if (heatmapType === 'assignment') {
      setAssignmentMetric(v);
    }
  }
  const fallbackGroupName = 'Others';
  const metricGroups = _.groupBy(
    metricDescriptors,
    metricDescriptor => metricDescriptor.titleCategory || fallbackGroupName,
  );

  const groupOrder =
    heatmapType === 'events'
      ? [
          'Time',
          'Velocity',
          'Compliance',
          'Proglove',
          'Scans',
          'Item',
          'Inventory',
          'Item (UOM)',
          'Location',
          fallbackGroupName,
        ]
      : [
          'Velocity',
          'Inventory',
          'Compliance',
          'Proglove',
          'Scans',
          'Item',
          'Item (UOM)',
          'Time',
          fallbackGroupName,
        ];

  // Sort the groups
  const orderedMetricGroups = _.sortBy(Object.keys(metricGroups), group =>
    _.indexOf(groupOrder, group),
  );

  return (
    <Container col hasOverflowY>
      <ScreenTitle
        title={heatmapType === 'events' ? t`Events` : t`Stock`}
        subtitle={t`Analyse`}
        isSticky
        helpNavTo={'simulation/analyse/simulation-analyse'}
        icon={heatmapType === 'events' ? Icon.HeatMap9 : Icon.Pallet}
      />

      {/* <ActualityHeatmapTypeSelect 
        value={heatmapType} 
        onChange={(newType) => setInternalHeatmapType(newType)} 
      /> */}

      <StatGroup classNameStatGroupItems={'!space-y-0.5'}>
        {orderedMetricGroups.map(category => (
          <TitleSection
            key={`dataset-metric-group-${category}`}
            id={`dataset-metric-group-${category}`}
            title={category}
            inPanelView
            collapsible
            classNameInner="space-y-px"
          >
            {metricGroups[category].map(d => (
              <Stat
                key={d.title}
                isActionable
                isSelectable
                isSelected={metric === d.type}
                title={d.title}
                onClick={() => setMetric(d.type)}
              />
            ))}
          </TitleSection>
        ))}
      </StatGroup>
    </Container>
  );
};

export default ActualityHeatmapPanel;
