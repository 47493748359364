import _ from 'lodash';
import React from 'react';
import { useRecoilValueLoadable } from 'recoil';
import LayoutAlertsBase from '../../layout/alerts/LayoutAlertsBase';
import { InformationAlertMessageType } from '../../layout/alerts/LayoutInformationAlert';
import { LoadingAlertMessageType } from '../../layout/alerts/LayoutLoadingAlert';
import { LoadingErrorAlertMessageType } from '../../layout/alerts/LayoutLoadingErrorAlert';
import { actualityHeatmapLevelData } from '../store/actuality.heatmap.state';
import { assignmentHeatmapLevelData } from '../store/assignment.heatmap.state';

const ActualityLayoutLoadingAlerts: React.FC = () => {
  const heatmapLoadable = useRecoilValueLoadable(actualityHeatmapLevelData);
  const assignmentHeatmapLoadable = useRecoilValueLoadable(
    assignmentHeatmapLevelData,
  );

  const messages: LoadingAlertMessageType[] = [];
  const errors: LoadingErrorAlertMessageType[] = [];
  const infos: InformationAlertMessageType[] = [];

  if (
    heatmapLoadable.state === 'loading' ||
    assignmentHeatmapLoadable.state === 'loading'
  ) {
    messages.push(LoadingAlertMessageType.heatmap);
  }
  if (heatmapLoadable.state === 'hasError') {
    errors.push(LoadingErrorAlertMessageType.heatmap);
  }

  return (
    <LayoutAlertsBase
      messages={_.take(messages, 1)}
      errors={errors}
      infos={infos}
    />
  );
};
export default ActualityLayoutLoadingAlerts;
