import { ImportPipelineConnectorType } from '@warebee/frontend/data-access-api-graphql';
import classNames from 'classnames';
import _ from 'lodash';
import { nanoid } from 'nanoid';
import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useRecoilState, useRecoilValue, useSetRecoilState } from 'recoil';
import { cloneWithoutTypename } from '../../../common/utils';
import { Button } from '../../../components/actions/Button';
import * as Icon from '../../../components/icons';
import InboxZero from '../../../components/InboxZero';
import TitleSection from '../../../components/layout/TitleSection';
import {
  warehouseCanUpdate,
  warehouseSelected,
} from '../../../store/warehouse.state';
import useUpdateWarehouseExtraSettings from '../../../warehouse/hooks/useUpdateWarehouseExtraSettings';
import useUpdateImportPipeline from '../../hooks/useUpdateImportPipeline';
import ImportPipelineDatasetConnector from '../../ImportPipelineDatasetConnector';
import { connectorNewDatasetKey } from '../../store/importPipeline.helper';
import {
  importPipelineIsActiveEditor,
  importPipelinesDataTableState,
  importPipelineSelected,
  importPipelineSelectedConnectorId,
  importPipelineSelectedId,
  importPipelineUpdateStatus,
} from '../../store/importPipelines.state';

export const PipelineConnectorDataset: React.FC = () => {
  const { t } = useTranslation('dataset');
  const wh = useRecoilValue(warehouseSelected);
  const pipeline = useRecoilValue(importPipelineSelected);
  // const [isLoading, setIsLoading] = useState(false);

  const setSelectedId = useSetRecoilState(importPipelineSelectedId);
  const setConnectorId = useSetRecoilState(importPipelineSelectedConnectorId);
  const [tableState, setTableState] = useRecoilState(
    importPipelinesDataTableState,
  );
  const updateStatus = useRecoilValue(importPipelineUpdateStatus);
  const setShowEditor = useSetRecoilState(importPipelineIsActiveEditor);

  const updatePipeline = useUpdateImportPipeline();

  const [query, setQuery] = useState(
    pipeline?.settings?.mappingSettings?.transformation?.query,
  );
  const [updateWhExtraSettings] = useUpdateWarehouseExtraSettings();

  const canUpdate = useRecoilValue(warehouseCanUpdate);
  const connectorId = useRecoilValue(importPipelineSelectedConnectorId);

  const [genLoading, setGenLoading] = useState(false);
  const [token, setToken] = useState<string>(null);

  const [isLoading, setIsLoading] = useState(false);
  const [error, setError] = useState<string>(null);

  function addDatasetConnector() {
    setConnectorId(connectorNewDatasetKey);
    setShowEditor(true);
  }

  function removeConnectorById(id: string) {
    const connectors = _(pipeline.settings?.integrationSettings?.connectors)
      .filter(c => c.id !== id)
      .map(cloneWithoutTypename)
      .value();

    updatePipeline({
      importPipelineId: pipeline.id,
      settings: {
        ...pipeline.settings,
        integrationSettings: {
          connectors,
        },
      },
    });
  }

  function removeAllConnectorsByType(type: ImportPipelineConnectorType) {
    const connectors = _(pipeline.settings?.integrationSettings?.connectors)
      .filter(c => c.type !== type)
      .map(cloneWithoutTypename)
      .value();

    updatePipeline({
      importPipelineId: pipeline.id,
      settings: {
        ...pipeline.settings,
        integrationSettings: {
          connectors,
        },
      },
    });
  }

  function addConnector(type: ImportPipelineConnectorType) {
    const connectors = _.map(
      pipeline.settings?.integrationSettings?.connectors,
      cloneWithoutTypename,
    );
    connectors.push({
      id: nanoid(10),
      type,
      enabled: true,
    });

    updatePipeline({
      importPipelineId: pipeline.id,
      settings: {
        ...pipeline.settings,
        integrationSettings: {
          connectors,
        },
      },
    });
  }

  const connectors = pipeline?.settings?.integrationSettings?.connectors;

  const datasetConnectors = _.filter(
    connectors,
    c => c.type === ImportPipelineConnectorType.DATASET_CHANGED,
  );
  const hasDatasetConnector = !_.isEmpty(datasetConnectors);

  return (
    <>
      <TitleSection
        id={`pipeline-setting-group-dataset`}
        title={t`Connector: Dataset`}
        inPanelView
        // className={classNames('z-400 top-12 xl:top-20')}
        classNameInner="p-4 xl:p-6 pb-10 space-y-8"
        hasScreenTitle
        collapsible
        hasAction={
          <>
            <Button
              label={t`Add`}
              className={classNames('rounded ltr:ml-4 rtl:mr-4')}
              buttonSize="xs"
              buttonType="primary"
              hasIconAfter={
                <Icon.CirclePlus className={`h-5 w-5 fill-current`} />
              }
              onPress={() => addDatasetConnector()}
            />
          </>
        }
      >
        {!hasDatasetConnector && <InboxZero message={t`No Connector`} />}
        {_.map(datasetConnectors, c => {
          const connectorDetails = _.find(
            pipeline.connectorsState,
            cs => cs.id === c.id,
          );
          return (
            <ImportPipelineDatasetConnector
              key={c.id}
              pipelineId={pipeline.id}
              connector={c}
              details={connectorDetails?.datasetChanged}
            />
          );
        })}
      </TitleSection>
    </>
  );
};
