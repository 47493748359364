import { PicklistEventType } from '@warebee/frontend/data-access-api-graphql';
import classNames from 'classnames';
import _ from 'lodash';
import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useRecoilValue, useSetRecoilState } from 'recoil';
import ButtonSwitchMulti, {
  ButtonSwitchMultiOption,
} from '../components/actions/ButtonSwitchMulti';
import ErrorIndicator from '../components/ErrorIndicator';
import InboxZero from '../components/InboxZero';
import LoadingIndicator from '../components/LoadingIndicator';
import { Stat } from '../components/stats/Stat';
import { StatListItem } from '../components/stats/StatListItem';
import { AnalyzeGatesStatsRow } from '../feed/store/datasetQueries/analyzeGatesStatsQuery';
import { analyzeEndpointsStats } from '../simulation/store/analyze.state';
import { simulationShowDatasetAsTable } from '../simulation/store/simulation.state';
import AnalyzeResultEndpointDistributionKPISelector, {
  EndpointDistributionKPI,
} from './AnalyzeResultEndpointDistributionKPISelector';
import LoadBalancingChart, {
  LoadBalancingChartDataRow,
} from './LoadBalancingChart';
import useEndpointDistributionKPIDescriptors from './useEndpointDistributionKPIDescriptors';

export type AnalyzeResultGateBalancingProps = {
  analyzeId: string;
};

const endpointsEventTypes = [
  PicklistEventType.PRE_HANDLING,
  PicklistEventType.TRAVELLING_HORIZONTAL_END,
];
const maxChartDimensions = 50;
const AnalyzeResultGateBalancing: React.FC<
  AnalyzeResultGateBalancingProps
> = props => {
  const { t } = useTranslation('simulation');
  const [kpi, setKpi] = useState<EndpointDistributionKPI>('jobsCount');
  const endpointsAll = useRecoilValue(analyzeEndpointsStats);
  const setShowDatasetTable = useSetRecoilState(simulationShowDatasetAsTable);
  const kpis = useEndpointDistributionKPIDescriptors();
  const [selectedType, setSelectedType] = useState(_.head(endpointsEventTypes));
  useEffect(() => {
    setShowDatasetTable('analyzed-endpoint-distribution');
  }, []);

  const endpointsSelectedAll = _(endpointsAll).filter(
    e => e.eventType === selectedType,
  );
  const endpoints = endpointsSelectedAll.take(maxChartDimensions).value();

  const totalEndpoints = endpointsSelectedAll.size();
  const chartEndpoints = _.size(endpoints);
  const getKPIValue = (row: AnalyzeGatesStatsRow) => {
    return row[kpi];
  };
  const getKPIFormattedValue = (row: AnalyzeGatesStatsRow) => {
    return kpis[kpi].formatter(row[kpi]);
  };

  const meanLoad = _.meanBy(endpoints, getKPIValue);

  const maxLoadGate = _.maxBy(endpoints, getKPIValue);
  const maxLoadEndpointFormatted = getKPIFormattedValue(maxLoadGate);
  const minLoadGate = _.minBy(endpoints, getKPIValue);
  const minLoadEndpointFormatted = getKPIFormattedValue(minLoadGate);

  const chartData: LoadBalancingChartDataRow[] = _(endpoints)
    .map((row, groupIndex) => {
      return {
        zoneId: row.locationId,
        zoneTitle: row.locationId ?? t`Default`,
        [`beforeAbsolute`]: getKPIValue(row),
      };
    })
    .sortBy(datum => datum[`beforeAbsolute`])
    .value() as any as LoadBalancingChartDataRow[];

  const zoneCount = chartData.length ?? 1;
  const calculatedHeight = 50 * zoneCount;

  const hasChartData = !_.isEmpty(chartData);
  const showData = hasChartData;
  const showPlaceholder = !hasChartData;
  const extraTitle =
    totalEndpoints !== chartEndpoints
      ? `(${chartEndpoints} of ${totalEndpoints})`
      : '';
  const title = t`Endpoints (Start/End) ` + extraTitle;

  const loading = false;
  const error = false;

  const filterOptionsByType: ButtonSwitchMultiOption[] = [
    {
      label: t`Start`,
    },
    {
      label: t`End`,
    },
  ];

  return (
    <div
      className={classNames(
        'relative flex w-full flex-1 flex-col items-center',
      )}
    >
      <Stat
        title={title}
        hasHelper
        isPreview
        inPanelMode
        switchUnitOfMeasure={
          <ButtonSwitchMulti
            className="mx-2 my-1"
            autoSize
            selectedIndex={_.indexOf(endpointsEventTypes, selectedType)}
            onClick={index => {
              setSelectedType(endpointsEventTypes[index]);
            }}
            options={filterOptionsByType}
            buttonType="secondary"
          />
        }
      >
        {showData && (
          <div className="mt-4">
            <AnalyzeResultEndpointDistributionKPISelector
              selected={kpi}
              setSelected={setKpi}
            />
            {!_.isNil(maxLoadGate?.locationId) && (
              <StatListItem
                title={t(`Max: {{zone}}`, {
                  zone: maxLoadGate?.locationId,
                })}
                value={maxLoadEndpointFormatted.value}
                unitOfMeasure={maxLoadEndpointFormatted.unit}
              />
            )}
            {!_.isNil(minLoadGate?.locationId) && (
              <StatListItem
                title={t(`Min: {{zone}}`, {
                  zone: minLoadGate?.locationId,
                })}
                value={minLoadEndpointFormatted.value}
                unitOfMeasure={minLoadEndpointFormatted.unit}
              />
            )}
          </div>
        )}

        <div
          className={classNames(
            'p-1 md:p-2 lg:p-3 xl:p-4 ltr:px-0.5 ltr:xl:px-1 rtl:px-0.5 rtl:xl:px-1',
            'm-0.5',
            // 'bg-app-panel-dark/60',
            'min-h-10',
          )}
          style={{ height: calculatedHeight, width: '100%', minWidth: '100%' }}
        >
          <div className="h-full">
            {loading && (
              <LoadingIndicator selfCenter message={t`Loading Data`} />
            )}
            {error && (
              <ErrorIndicator
                selfCenter
                message={t`Apologies, we couldn't load the data`}
              />
            )}
            {showPlaceholder && <InboxZero selfCenter message={t`No data`} />}
            {showData && (
              <LoadBalancingChart
                data={chartData}
                series={['before']}
                kpi={'absolute'}
                min={getKPIValue(minLoadGate)}
                max={getKPIValue(maxLoadGate)}
                avg={meanLoad}
              />
            )}
          </div>
        </div>
      </Stat>
    </div>
  );
};

export default AnalyzeResultGateBalancing;
