import _ from 'lodash';
import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useRecoilValue } from 'recoil';
import DatasetTable from '../../../components/DatasetTable';
import {
  importTypeCurrent,
  importValidationErrors,
  importValidationErrorsGrouped,
} from '../../store/import.state';
import useValidationErrorsTableConfig from './useValidationErrorsTableConfig';

type ImporterValidationErrorTableProps = {
  showAs: 'grouped' | 'all';
};

const ImporterValidationErrorTable: React.FC<
  ImporterValidationErrorTableProps
> = props => {
  const { t } = useTranslation('importer');
  const datasetType = useRecoilValue(importTypeCurrent);
  const validationErrors = useRecoilValue(importValidationErrors);
  const validationErrorsGrouped = useRecoilValue(importValidationErrorsGrouped);
  const [searchValues, setSearchValues] = useState({});

  const columnConfig = useValidationErrorsTableConfig({
    type: datasetType,
    showAs: props.showAs,
  });

  const data =
    props.showAs === 'all' ? validationErrors : validationErrorsGrouped;

  const totalCount = _.size(data);

  return (
    <DatasetTable
      id={'validation-errors-table'}
      data={data}
      keyFields={['property', 'code']}
      totalCount={totalCount}
      columnsConfig={columnConfig}
      onLoadNext={_.noop}
      onSearch={v => setSearchValues(v)}
      searchValues={searchValues}
      hideScreenTitle
    />
  );
};

export default ImporterValidationErrorTable;
