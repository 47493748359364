import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { cn } from '../../common/utils';
import ButtonSwitchMulti, {
  ButtonSwitchMultiOption,
} from '../../components/actions/ButtonSwitchMulti';
import { Spacer } from '../../components/layout/Spacer';
import { ActionBar } from '../../components/nav/ActionBar';
import { ScheduleCostControlsToggle } from '../../simulation/schedule/ScheduleCostControlsToggle';
import SimulationJobsTimeline from '../../simulation/schedule/SimulationJobsTimeline';
import SimulationWorkforceView from '../../simulation/stepContentViews/SimulationWorkforceView';

export type AnalyzeDashboardProps = {
  analyzeId: string;
};

const AnalyzeDashboard: React.FC<AnalyzeDashboardProps> = props => {
  const { t } = useTranslation('simulation');
  const [viewIndex, setViewIndex] = useState(0);
  const filterOptionsPerformance: ButtonSwitchMultiOption[] = [
    {
      label: t`Workforce`,
    },
    {
      label: t`Docks`,
    },
  ];

  return (
    <>
      <ActionBar className={cn('p-2')} sticky>
        <ButtonSwitchMulti
          className="mx-2"
          autoSize
          selectedIndex={viewIndex}
          onClick={index => {
            setViewIndex(index);
          }}
          options={filterOptionsPerformance}
          buttonType="secondary"
        />
        <Spacer flexspace />
        {viewIndex === 1 && <ScheduleCostControlsToggle />}
      </ActionBar>
      {viewIndex === 0 && (
        <SimulationWorkforceView analyzeId={props.analyzeId} />
      )}
      {viewIndex === 1 && <SimulationJobsTimeline />}
    </>
  );
};

export default AnalyzeDashboard;
