import { useTranslation } from 'react-i18next';
import useFormatter from '../../../common/useFormatter';
import { occupancyMetricDescriptorsMap } from './assignmentOccupancy.defaults';
import { OccupancyMetricDescriptor } from './assignmentOccupancy.types';

function useAssignmentOccupancyMetricDescriptors() {
  const { t } = useTranslation('simulation');
  const formatter = useFormatter();

  const metrics: OccupancyMetricDescriptor[] = [
    {
      ...occupancyMetricDescriptorsMap['occupancy-volume-rate'],
      titleCategory: t`By Stock`,
      title: t`Volume`,
      format: v => formatter.formatShare(v).fullString,
    },
    {
      ...occupancyMetricDescriptorsMap['occupancy-rate'],
      titleCategory: t`By Stock`,
      title: t`Occupancy`,
      format: v => formatter.formatShare(v).fullString,
    },
    {
      ...occupancyMetricDescriptorsMap['utilization-rate'],
      titleCategory: t`By Stock`,
      title: t`Util. Rate`,
      format: v => formatter.formatShare(v).fullString,
    },
    {
      ...occupancyMetricDescriptorsMap['utilization-max-uom-rate'],
      titleCategory: t`By Stock`,
      title: t`Util. Rate (Max UOM)`,
      format: v => formatter.formatShare(v).fullString,
    },
  ];
  return metrics;
}

export default useAssignmentOccupancyMetricDescriptors;
