import { StringSearchFilterType } from '@warebee/frontend/data-access-api-graphql';
import _ from 'lodash';
import {
  ActualityMetric,
  ActualityMetricDescriptorBase,
} from './actualityMetric.types';

export const actualityMetricDescriptors: ActualityMetricDescriptorBase[] = [
  //  Time Based
  {
    type: 'lastActivity',
    path: 'lastEventTime',
    rangeType: 'distinct-range',
    sequentialPaletteId: 'velocity-analytics',
    aggregationFn: 'max',
  },

  {
    type: 'processType',
    path: 'eventProcessType',
    rangeType: 'distinct',
    sequentialPaletteId: 'heatmap-analytics',
    aggregationFn: 'min',
  },
  // Velocity
  {
    type: 'abc',
    path: 'skuKey',
    rangeType: 'distinct',
    aggregationFn: 'min',
  },
  {
    type: 'visits',
    path: 'locationId',
    rangeType: 'range-pow',
    sequentialPaletteId: 'heatmap-analytics',
    aggregationFn: 'count',
  },
  {
    type: 'visitsReplenishment',
    path: 'assignmentItem',
    rangeType: 'range-pow',
    sequentialPaletteId: 'heatmap-analytics',
    aggregationFn: 'count',
    filterBy: {
      eventProcessType: {
        type: StringSearchFilterType.CONTAINS,
        value: 'REPLENISHMENT',
      },
    },
  },
  // Location
  {
    type: 'locationRackingType',
    path: 'locationRackingType',
    rangeType: 'distinct',
    aggregationFn: 'min',
  },
  {
    type: 'locationUsageType',
    path: 'locationUsageType',
    rangeType: 'distinct',
    aggregationFn: 'min',
  },
  {
    type: 'locationOrder',
    path: 'locationOrder',
    rangeType: 'distinct-range',
    aggregationFn: 'min',
  },
  {
    type: 'congestionZone',
    path: 'congestionZone',
    rangeType: 'distinct',
    aggregationFn: 'min',
  },
  // Item
  {
    type: 'consignee',
    path: 'consignee',
    rangeType: 'distinct',
    aggregationFn: 'min',
    qualitativePaletteId: 'dimension',
  },
  {
    type: 'consigneeSku',
    path: 'skuKey',
    rangeType: 'distinct',
    aggregationFn: 'min',
    qualitativePaletteId: 'dimension',
  },
  {
    type: 'skuGroup',
    path: 'skuGroup',
    rangeType: 'distinct',
    aggregationFn: 'min',
    qualitativePaletteId: 'dimension',
  },
  {
    type: 'subGroup',
    path: 'subGroup',
    rangeType: 'distinct',
    aggregationFn: 'min',
    qualitativePaletteId: 'dimension',
  },
  {
    type: 'transportClass',
    path: 'transportClass',
    rangeType: 'distinct',
    aggregationFn: 'min',
    qualitativePaletteId: 'dimension',
  },
  {
    type: 'stockCategory',
    path: 'stockCategory',
    rangeType: 'distinct',
    aggregationFn: 'min',
    qualitativePaletteId: 'dimension',
  },
  {
    type: 'storageClass',
    path: 'storageClass',
    rangeType: 'distinct',
    aggregationFn: 'min',
    qualitativePaletteId: 'dimension',
  },
  {
    type: 'pollutionClass',
    path: 'pollutionClass',
    rangeType: 'distinct',
    aggregationFn: 'min',
    qualitativePaletteId: 'dimension',
  },
  {
    type: 'uom',
    path: 'uom',
    rangeType: 'distinct',
    aggregationFn: 'min',
    qualitativePaletteId: 'dimension',
  },
  {
    type: 'uomWeight',
    path: 'netWeight',
    rangeType: 'distinct-range',
    aggregationFn: 'min',
    sequentialPaletteId: 'elevation-sort',
  },
  {
    type: 'uomWeightPow',
    path: 'netWeight',
    rangeType: 'distinct',
    aggregationFn: 'min',
    sequentialPaletteId: 'elevation-sort',
  },
  {
    type: 'uomVolume',
    path: 'volume',
    rangeType: 'distinct-range',
    aggregationFn: 'min',
    sequentialPaletteId: 'elevation-sort',
  },
  {
    type: 'pickByAssignment',
    path: 'pickByAssignment',
    rangeType: 'distinct',
    aggregationFn: 'min',
    qualitativePaletteId: 'booleanCompliance',
  },
];

export const actualityMetricDescriptorsMap = _.keyBy(
  actualityMetricDescriptors,
  d => d.type,
) as Record<ActualityMetric, ActualityMetricDescriptorBase>;
