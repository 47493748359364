import classNames from 'classnames';
import React, { PropsWithChildren } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import { useRecoilState, useRecoilValue, useSetRecoilState } from 'recoil';
import { getDatasetConfigurations } from '../../import/store/import.default';
import { DatasetType } from '../../import/store/import.types';
import {
  simulationCurrent,
  simulationIsEditable,
  simulationShowDatasetAsTable,
} from '../../simulation/store/simulation.state';
import { DataSetTableType } from '../../simulation/store/simulation.types';
import {
  appIsEmbeddedState,
  importTriggeredBySim,
} from '../../store/global.state';
import {
  warehouseCanUpdate,
  warehouseSelectedId,
} from '../../store/warehouse.state';
import { Button } from '../actions/Button';
import * as Icon from '../icons';
import LinkTraced from '../nav/LinkTraced';

export type DatasetButtonProps = PropsWithChildren & {
  className?: string;
  isLoading?: boolean;
  isSimulation?: boolean;
  updateDataset?: boolean;
  hasDatasets?: boolean;
  dataType: DatasetType;
  datasetId?: string;
  headerMode?: boolean;
  viewMode?: 'header' | 'table' | 'dashboard' | 'panel' | 'minimal';
};

export const ImportDatasetButton: React.FC<DatasetButtonProps> = props => {
  const { t } = useTranslation('app');
  const navigate = useNavigate();
  const warehouseId = useRecoilValue(warehouseSelectedId);
  const canUpdate = useRecoilValue(warehouseCanUpdate);
  const canChange = useRecoilValue(simulationIsEditable);
  const [simulation, setSimulation] = useRecoilState(simulationCurrent);
  const setImportTriggerSimId = useSetRecoilState(importTriggeredBySim);
  const importConfigurations = getDatasetConfigurations(t);
  const datasetConfig = importConfigurations.find(
    config => config.dataType === props.dataType,
  );
  const dataTypeTitleAction = datasetConfig.actionName;
  const DataTypeIcon = datasetConfig.icon;

  if (!canUpdate || !canChange || !datasetConfig) {
    return null;
  }

  const iconClassStyleBefore =
    props.viewMode === 'header'
      ? 'w-6 h-6 xl:w-8 xl:h-8 fill-current'
      : 'w-4 h-4 xl:w-5 xl:h-5 fill-current';
  const iconClassStyleAction = iconClassStyleBefore;

  return (
    <div
      data-component="ImportDatasetButton"
      className={classNames('flex w-full flex-col items-center')}
    >
      {props.hasDatasets && <div className="text-menu/50 mt-2">{t`OR`}</div>}

      <div
        className={classNames(
          props.className,
          'flex w-full items-center',
          props.isSimulation ? 'bg-app-panel-dark/60 p-4' : '',
        )}
      >
        <Button
          className={classNames(
            props.viewMode === 'header' ? 'text-sm' : 'text-xs',
            'rounded',
            'w-full',
          )}
          label={dataTypeTitleAction}
          buttonType={
            props.isSimulation || props.viewMode === 'header'
              ? 'primary'
              : 'secondary'
          }
          buttonSize={
            props.isSimulation || props.viewMode === 'header' ? 'sm' : 'xs'
          }
          isDisabled={props.isLoading}
          full
          hasIconBefore={
            props.viewMode === 'header' && (
              <DataTypeIcon className={iconClassStyleBefore} />
            )
          }
          hasIconAfter={<Icon.CirclePlus className={iconClassStyleAction} />}
          onPress={() => {
            props.isSimulation && setImportTriggerSimId(simulation.id);
            navigate(datasetConfig.getImportPath(warehouseId));
          }}
          isLoading={props.isLoading}
        />
        {props.children}
      </div>
    </div>
  );
};

// export default ImportDatasetButton;

export const ImportDatasetLink: React.FC<DatasetButtonProps> = props => {
  const { t } = useTranslation('app');
  const navigate = useNavigate();
  const warehouseId = useRecoilValue(warehouseSelectedId);
  const canUpdate = useRecoilValue(warehouseCanUpdate);
  const canChange = useRecoilValue(simulationIsEditable);
  const isAppEmbedded = useRecoilValue(appIsEmbeddedState);

  const importConfigurations = getDatasetConfigurations(t);
  const datasetConfig = importConfigurations.find(
    config => config.dataType === props.dataType,
  );

  const importPath = datasetConfig.getImportPath(warehouseId);

  if (!canUpdate || !canChange || !datasetConfig) {
    return null;
  }

  const dataTypeTitle = datasetConfig.actionNameLink;

  return (
    <LinkTraced
      data-component="ImportDatasetLink"
      data-label={`import-dataset-link-${dataTypeTitle || ''}`}
      aria-label={`import-dataset-link-${dataTypeTitle || ''}`}
      titleTrace={`Import Link: ${dataTypeTitle || ''}`}
      to={importPath}
      target={isAppEmbedded || props.viewMode === 'header' ? '_self' : '_blank'}
      className={classNames(
        'group',
        // 'bg-sidebar-header/50',
        // 'hover:bg-menu-active hover:text-menu-active-text',
        'rounded px-2 py-1',
        'underline',
        'decoration-menu-active/20 hover:decoration-menu-active',
        'underline-offset-4 hover:underline',
        'inline-flex',
        'text-app-panel-light hover:text-menu-active',
        'text-xs',
        'mx-1 mt-2',
        'px-1',
        props.className,
      )}
    >
      {dataTypeTitle}
      <Icon.CloudUpload
        className={classNames('h-4 w-4', 'mx-1', 'fill-current')}
      />
    </LinkTraced>
  );
};

export const ShowDatasetLink: React.FC<DatasetButtonProps> = props => {
  const { t } = useTranslation('app');
  const navigate = useNavigate();
  const warehouseId = useRecoilValue(warehouseSelectedId);
  const canUpdate = useRecoilValue(warehouseCanUpdate);
  const canChange = useRecoilValue(simulationIsEditable);
  const isAppEmbedded = useRecoilValue(appIsEmbeddedState);

  const importConfigurations = getDatasetConfigurations(t);
  const datasetConfig = importConfigurations.find(
    config => config.dataType === props.dataType,
  );

  const dataPath = datasetConfig.getDataTablePath(warehouseId, props.datasetId);

  if (!canUpdate || !canChange || !datasetConfig) {
    return null;
  }

  const dataTypeTitle = datasetConfig.actionTitleShowData;

  return (
    <LinkTraced
      data-component="ShowDatasetLink"
      data-label={`import-dataset-link-${dataTypeTitle || ''}`}
      aria-label={`import-dataset-link-${dataTypeTitle || ''}`}
      titleTrace={`Import Link: ${dataTypeTitle || ''}`}
      to={dataPath}
      target={isAppEmbedded ? '_self' : '_blank'}
      className={classNames(
        'group',
        // 'bg-sidebar-header/50',
        // 'hover:bg-menu-active hover:text-menu-active-text',
        'rounded px-2 py-1',
        'underline',
        'decoration-menu-active/20 hover:decoration-menu-active',
        'underline-offset-4 hover:underline',
        'inline-flex',
        'text-app-panel-light hover:text-menu-active',
        'text-xs',
        'mx-1 mt-2',
        'px-1',
        props.className,
      )}
    >
      {dataTypeTitle}
      <Icon.ShowTable
        className={classNames('h-4 w-4', 'mx-1', 'fill-current')}
      />
    </LinkTraced>
  );
};

export const ToggleDatasetTableLink: React.FC<DatasetButtonProps> = props => {
  const { t } = useTranslation('app');
  const canUpdate = useRecoilValue(warehouseCanUpdate);
  const canChange = useRecoilValue(simulationIsEditable);
  const [showAsTable, setShowAsTable] = useRecoilState(
    simulationShowDatasetAsTable,
  );

  const importConfigurations = getDatasetConfigurations(t);
  const datasetConfig = importConfigurations.find(
    config => config.dataType === props.dataType,
  );

  if (!canUpdate || !canChange || !datasetConfig) {
    return null;
  }

  const dataTypeTitle =
    showAsTable === props.dataType ? t('Hide Data') : t('Show Data');

  const toggleShowAsTable = (e: React.MouseEvent) => {
    e.preventDefault();
    setShowAsTable(prevState =>
      prevState === props.dataType
        ? null
        : (props.dataType as DataSetTableType),
    );
  };

  return (
    <a
      href="#"
      onClick={toggleShowAsTable}
      data-component="ToggleDatasetTableLink"
      data-label={`toggle-dataset-table-${dataTypeTitle || ''}`}
      aria-label={`toggle-dataset-table-${dataTypeTitle || ''}`}
      title={`Toggle: ${dataTypeTitle || ''}`}
      className={classNames(
        'group',
        'rounded px-2 py-1',
        'underline',
        'decoration-menu-active/20 hover:decoration-menu-active',
        'underline-offset-4 hover:underline',
        'inline-flex',
        'text-app-panel-light hover:text-menu-active',
        'text-sm',
        'mx-1 mt-2',
        'px-1',
        props.className,
      )}
    >
      {dataTypeTitle}
      {showAsTable === props.dataType ? (
        <Icon.HideTable
          className={classNames('h-5 w-5', 'mx-1', 'fill-current')}
        />
      ) : (
        <Icon.ShowTable
          className={classNames('h-5 w-5', 'mx-1', 'fill-current')}
        />
      )}
    </a>
  );
};

export const ImportToSimulationLink: React.FC<DatasetButtonProps> = props => {
  const { t } = useTranslation('app');
  const navigate = useNavigate();
  const warehouseId = useRecoilValue(warehouseSelectedId);
  const canUpdate = useRecoilValue(warehouseCanUpdate);
  const canChange = useRecoilValue(simulationIsEditable);
  const isAppEmbedded = useRecoilValue(appIsEmbeddedState);
  const setImportTriggerSimId = useSetRecoilState(importTriggeredBySim);
  const [simulation, setSimulation] = useRecoilState(simulationCurrent);

  const importConfigurations = getDatasetConfigurations(t);
  const datasetConfig = importConfigurations.find(
    config => config.dataType === props.dataType,
  );

  if (!canUpdate || !canChange || !datasetConfig || !simulation) {
    return null;
  }

  const importPath = datasetConfig.getImportPath(warehouseId);
  const dataTypeTitle = t('Import...');

  const proceedWithImport = (e: React.MouseEvent) => {
    e.preventDefault();
    setImportTriggerSimId(simulation.id);
    navigate(importPath);
  };

  return (
    <a
      href="#"
      onClick={proceedWithImport}
      data-component="ImportToSimulationLink"
      data-label={`import-to-simulation-link-${props.dataType}`}
      aria-label={`import-to-simulation-link-${props.dataType}`}
      title={`Import ${props.dataType}`}
      className={classNames(
        'group',
        'rounded px-2 py-1',
        'underline',
        'decoration-menu-active/20 hover:decoration-menu-active',
        'underline-offset-4 hover:underline',
        'inline-flex',
        'text-app-panel-light hover:text-menu-active',
        'text-sm',
        'mx-1 mt-2',
        'px-1',
        props.className,
      )}
    >
      {dataTypeTitle}
      <Icon.CloudUpload
        className={classNames('h-5 w-5', 'mx-1', 'fill-current')}
      />
    </a>
  );
};

export const DatasetNavigation: React.FC<{
  dataType: DatasetType;
  datasetId?: string;
}> = ({ dataType, datasetId }) => {
  return (
    <nav className="grid grid-cols-2 content-center gap-2 px-2">
      <ImportToSimulationLink
        className="justify-self-start"
        dataType={dataType}
      />

      {datasetId && (
        <ToggleDatasetTableLink
          className="justify-self-end"
          dataType={dataType}
          datasetId={datasetId}
        />
      )}
    </nav>
  );
};
