import classNames from 'classnames';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { useRecoilState } from 'recoil';
import DropdownSelector from '../../components/actions/DropdownSelector';
import {
  getViewModeLabelsMap,
  MenuViewMode,
  selectedViewModeAtom,
  viewMenuModesAtom,
} from '../store/simulation.wizard.state';

export const ModeSelectorDropdown: React.FC = () => {
  const { t } = useTranslation('simulation');
  const [modes] = useRecoilState(viewMenuModesAtom);
  const [selectedMode, setSelectedMode] = useRecoilState(selectedViewModeAtom);

  const viewModeLabels = getViewModeLabelsMap(t);

  console.log('viewModeLabels', viewModeLabels);
  // Update dropdown values to use the mapping
  const dropdownValues = modes.map(mode => ({
    label: viewModeLabels.get(mode),
    value: mode,
  }));

  // console.log('selectedMode', selectedMode);
  const handleChange = (selectedOption: {
    label: string;
    value: MenuViewMode;
  }) => {
    setSelectedMode(selectedOption.value);
  };

  // Function to render each option in the dropdown list
  const renderValue = (
    option: { label: string; value: MenuViewMode },
    filterString?: string,
  ) => <div>{option.label}</div>;

  // Function to render the selected value in the dropdown button
  const renderValueSelected = (
    option: { label: string; value: MenuViewMode },
    filterString?: string,
  ) => <div>{option.label}</div>;

  return (
    <DropdownSelector
      classNameLabel={classNames('text-xxs xl:text-sm')}
      className={classNames('ltr:mr-0.5 xl:ltr:mr-2 rtl:ml-0.5 xl:rtl:ml-2')}
      onClick={e => {
        e.stopPropagation();
      }}
      DropAlignLeft
      widthFull
      panelMode
      value={
        dropdownValues.find(option => option.value === selectedMode) ||
        dropdownValues[0]
      }
      values={dropdownValues}
      onChange={option => {
        handleChange(option);
      }}
      label={t('Mode')}
      renderValue={renderValue}
      renderValueSelected={renderValueSelected}
    />
  );
};
