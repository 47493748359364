import { useTranslation } from 'react-i18next';
import SidebarContainer, {
  SidebarContainerProps,
} from '../../containers/SidebarContainer';
import OptimisationReassignPanel from '../panels/optimization/OptimisationReassignPanel';

export default () => {
  const { t } = useTranslation('simulation');

  const containerProps: SidebarContainerProps = {
    type: 'sidebar-optimisation-results-items',
    title: t('Optimisation Reassignment plan (Re-Slotting)'),
    loadingMessage: t('Loading Reassignment plan'),
    size: 'xl',
  };

  return (
    <SidebarContainer key={containerProps.type} {...containerProps} width={140}>
      <OptimisationReassignPanel />
    </SidebarContainer>
  );
};
