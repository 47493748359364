import {
  GateUsageScheduleJobAssignmentFragment,
  GateUsageScheduleResourceFragment,
} from '@warebee/frontend/data-access-api-graphql';
import classNames from 'classnames';
import _ from 'lodash';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { useRecoilValue } from 'recoil';
import DatasetTable from '../../components/DatasetTable';
import TitleSection from '../../components/layout/TitleSection';
import { analyzeGateUsageSchedule } from '../store/analyze.state';
import useSimulationJobTimelineTableConfig from './useSimulationJobTimelineTableConfig';

export type JobTimelineDataTableRow = GateUsageScheduleJobAssignmentFragment & {
  resourceId: string;
};

function getRows(
  resources: GateUsageScheduleResourceFragment[],
): JobTimelineDataTableRow[] {
  return _(resources)
    .map(res => _.map(res.assignments, a => ({ ...a, resourceId: res.id })))
    .flatten()
    .value();
}

const SimulationJobTimelineDataTable: React.FC = () => {
  const { t } = useTranslation('simulation');
  const schedule = useRecoilValue(analyzeGateUsageSchedule);
  const columnsConfig = useSimulationJobTimelineTableConfig();
  const flattenData = getRows(schedule?.resources);

  const totalCount = _.size(flattenData);
  return (
    <>
      <TitleSection
        key={`schedule-datatable-${''}`}
        id={`schedule-datatable-${''}`}
        title={`Data`}
        inPanelView
        collapsible
        classNameInner="h-full flex-1"
      >
        <div
          data-component="SimulationJobTimelineDataTable"
          className={classNames(
            'flex h-full w-full flex-1 flex-col overflow-auto',
            'bg-app-panel-dark/60',
          )}
        >
          <DatasetTable
            id={'simulation-job-timeline-data-table'}
            hideScreenTitle
            isSticky
            isActionable
            columnsConfig={columnsConfig as any}
            keyFields={['job.id']}
            data={flattenData}
            onLoadNext={_.noop}
            onSearch={_.noop}
            sortBy={{}}
            onSort={_.noop}
            totalCount={totalCount}
            searchValues={{}}
            isLoading={false}
            onRowSelect={_.noop}
            //onStartExportClick={startExportCSV}
            hasCounter
          />
        </div>
      </TitleSection>
    </>
  );
};

export default SimulationJobTimelineDataTable;
