import {
  Filter,
  FilterIntersection,
  FilterUnion,
  FilterValue,
  NumberFilterRange,
} from '@warebee/shared/engine-model';
import {
  Expression,
  ExpressionOrFactory,
  RawBuilder,
  SelectQueryBuilder,
  SqlBool,
  expressionBuilder,
  sql,
} from 'kysely';
import { isEmpty } from 'lodash';
import { ATHENA_DIALECT_HELPER } from '../utils/kysely.utils';

export interface SkipLimitPageSpec {
  skip?: number;
  limit?: number;
}

export type KyselyFilterDesc<DB, TB extends keyof DB> =
  | ['string' | 'number', ExpressionOrFactory<DB, TB, any>]
  | [
      (q: SelectQueryBuilder<DB, TB, any>) => SelectQueryBuilder<DB, TB, any>,
      'string' | 'number',
      ExpressionOrFactory<DB, TB, any>,
    ];

export type KyselyFilterDescSet<
  FT extends string,
  DB,
  TB extends keyof DB,
> = Record<FT, KyselyFilterDesc<DB, TB>>;

export interface FindFilterValuesRequest<FT extends string> {
  filterType: FT;
  query?: string;
  page?: SkipLimitPageSpec;
}

export interface FilterValuesConnection {
  totalCount?: number;

  content?: FilterValue[];

  range?: NumberFilterRange;
}

export interface FindFilterValuesResult<FT extends string>
  extends FilterValuesConnection {
  filterType: FT;
}

function applyFilterDesc<DB, TB extends keyof DB>(
  filterDesc: KyselyFilterDesc<DB, TB>,
  q: SelectQueryBuilder<DB, TB, any>,
): [
  'string' | 'number',
  ExpressionOrFactory<DB, TB, any>,
  SelectQueryBuilder<DB, TB, any>,
] {
  if (filterDesc.length == 2) {
    return [filterDesc[0], filterDesc[1], q];
  } else if (filterDesc.length == 3) {
    return [filterDesc[1], filterDesc[2], filterDesc[0](q)];
  }
}

export function findFilterValuesExpr<
  FT extends string,
  DB,
  TB extends keyof DB,
>(
  q: SelectQueryBuilder<DB, TB, any>,
  filterDescSet: KyselyFilterDescSet<FT, DB, TB>,
  filters: FindFilterValuesRequest<FT>[],
  includeMatching?: FilterIntersection<Filter<FT>>,
): [SelectQueryBuilder<DB, TB, any>, RawBuilder<FindFilterValuesResult<FT>[]>] {
  function buildFilterValue(expr: Expression<string>): RawBuilder<FilterValue> {
    return sql`json_parse(${ATHENA_DIALECT_HELPER.jsonBuildObject({ title: expr })})` as any;
  }

  function filterValues(
    expr: Expression<string>,
    queryExpr?: Expression<any>,
    page?: SkipLimitPageSpec,
  ): RawBuilder<FilterValue[]> {
    const arrayExpr = sql<
      string[]
    >`array_agg(distinct ${expr} order by ${expr}) ${queryExpr}`;

    return ATHENA_DIALECT_HELPER.transformArray(
      ATHENA_DIALECT_HELPER.slice(
        arrayExpr,
        page?.skip ? sql.lit(page.skip + 1) : null,
        page?.limit ? sql.lit(page.limit) : null,
      ),
      v => buildFilterValue(v),
    );
  }

  let includeMatchingFilterResultExprs = null;
  if (includeMatching) {
    // const [nq, filterExpr] = applyFilterIntersection(
    //   q,
    //   filterDescSet,
    //   includeMatching,
    // );
    // q = nq.where(filterExpr);
    const [nq, exprs] = applyFilterIntersectionForArray(
      q,
      filterDescSet,
      includeMatching,
    );
    includeMatchingFilterResultExprs = exprs; // prepare expressions for filter type specific aggregate filters
  }

  const filterResultExprs = [];

  for (const i in filters) {
    const { filterType, query, page } = filters[i];
    const filterDesc = filterDescSet[filterType];

    const [filterDescType, filterExpr, nq] = applyFilterDesc(filterDesc, q);
    q = nq;

    const aggregateFilterClauses = [];

    if (includeMatchingFilterResultExprs) {
      aggregateFilterClauses.push(eb =>
        eb.and(
          includeMatchingFilterResultExprs.filter(
            (expr, i) => includeMatching.allOf[i].type != filterType,
          ),
        ),
      );
    }

    function buildAggregateFilter() {
      return aggregateFilterClauses.length > 0
        ? sql`filter (where ${eb =>
            sql.join(aggregateFilterClauses, sql` and `)})` // TODO how to write it properly?
        : sql``;
    }

    if (filterDescType == 'string') {
      if (query) {
        aggregateFilterClauses.push(
          sql`lower(${filterExpr}) like lower(${containsPattern(query)})`,
        );
      }

      const aggregateFilter = buildAggregateFilter();

      filterResultExprs.push(
        ATHENA_DIALECT_HELPER.jsonBuildObject({
          filterType: sql.lit(filterType),
          totalCount: sql<number>`cast(count(distinct ${filterExpr}) ${aggregateFilter} as int)`,
          // TODO some black magic needed to pass array(json) as argument to json_object(); is there any better way?
          content: sql`json_format(cast(${filterValues(sql`${filterExpr}`, aggregateFilter, page)} as json)) format json`,
        }),
      );
    } else if (filterDescType == 'number') {
      const aggregateFilter = buildAggregateFilter();

      filterResultExprs.push(
        ATHENA_DIALECT_HELPER.jsonBuildObject({
          filterType: sql.lit(filterType),
          range: ATHENA_DIALECT_HELPER.jsonBuildObject({
            from: sql`min(${filterExpr}) ${aggregateFilter}`,
            to: sql`max(${filterExpr}) ${aggregateFilter}`,
          }),
        }),
      );
    } else {
      throw new Error('unsupported filter');
    }
  }

  return [q, sql`json_array(${sql.join(filterResultExprs)})`];
}

export async function findFilterValues<
  FT extends string,
  DB,
  TB extends keyof DB,
>(
  q: SelectQueryBuilder<DB, TB, any>,
  filterDescSet: KyselyFilterDescSet<FT, DB, TB>,
  filters: FindFilterValuesRequest<FT>[],
  includeMatching?: FilterIntersection<Filter<FT>>,
): Promise<FindFilterValuesResult<FT>[]> {
  const [nq, expr] = findFilterValuesExpr(
    q,
    filterDescSet,
    filters,
    includeMatching,
  );

  const result = await nq.select(expr.as('result')).executeTakeFirst();

  return result['result'];
}

export function applyFilter<FT extends string, DB, TB extends keyof DB>(
  q: SelectQueryBuilder<DB, TB, any>,
  filterDescSet: KyselyFilterDescSet<FT, DB, TB>,
  filter: Filter<FT>,
): [SelectQueryBuilder<DB, TB, any>, ExpressionOrFactory<DB, TB, SqlBool>] {
  const eb = expressionBuilder<DB, TB>();

  const mapping = filterDescSet[filter.type];
  const [filterType, filterExpr, nq] = applyFilterDesc(mapping, q);

  let expr: ExpressionOrFactory<DB, TB, SqlBool>;
  if (filter.isNull) {
    expr = eb(filterExpr, 'is', null);
  } else if (filterType == 'string') {
    if (isEmpty(filter.valueIn)) {
      expr = eb.lit(false);
    } else {
      expr = eb(
        filterExpr,
        'in',
        filter.valueIn?.map(v => v.title),
      );
    }
  } else if (filterType == 'number') {
    const exprFrom =
      filter.range?.from != null
        ? eb(
            filterExpr,
            filter.range?.excludeFrom == true ? '>' : '>=',
            filter.range.from,
          )
        : null;
    const exprTo =
      filter.range?.to != null
        ? eb(
            filterExpr,
            filter.range?.excludeTo == true ? '<' : '<=',
            filter.range.to,
          )
        : null;
    expr = eb.and([exprFrom, exprTo].filter(e => e));
  }

  if (filter.isNot) {
    expr = eb.not(expr) as any;
  }

  return [nq, expr];
}

function applyFilterIntersectionForArray<
  FT extends string,
  F extends Filter<FT>,
  DB,
  TB extends keyof DB,
>(
  q: SelectQueryBuilder<DB, TB, any>,
  filterDescSet: KyselyFilterDescSet<FT, DB, TB>,
  intersection: FilterIntersection<F>,
): [SelectQueryBuilder<DB, TB, any>, ExpressionOrFactory<DB, TB, SqlBool>[]] {
  const results = [];
  for (const [nq, expr] of intersection.allOf.map(f =>
    applyFilter(q, filterDescSet, f),
  )) {
    results.push(expr);
    q = nq;
  }
  return [q, results];
}

export function applyFilterIntersection<
  FT extends string,
  F extends Filter<FT>,
  DB,
  TB extends keyof DB,
>(
  q: SelectQueryBuilder<DB, TB, any>,
  filterDescSet: KyselyFilterDescSet<FT, DB, TB>,
  intersection: FilterIntersection<F>,
): [SelectQueryBuilder<DB, TB, any>, ExpressionOrFactory<DB, TB, SqlBool>] {
  const [nq, results] = applyFilterIntersectionForArray(
    q,
    filterDescSet,
    intersection,
  );
  const eb = expressionBuilder<DB, TB>();
  return [nq, eb.and(results as any)];
}

export function applyFilterUnion<
  FT extends string,
  F extends Filter<FT>,
  DB,
  TB extends keyof DB,
>(
  q: SelectQueryBuilder<DB, TB, any>,
  filterDescSet: KyselyFilterDescSet<FT, DB, TB>,
  union: FilterUnion<F>,
): [SelectQueryBuilder<DB, TB, any>, ExpressionOrFactory<DB, TB, SqlBool>] {
  const results = [];
  for (const [nq, expr] of union.anyOf.map(f =>
    applyFilterIntersection(q, filterDescSet, f),
  )) {
    results.push(expr);
    q = nq;
  }

  const eb = expressionBuilder<DB, TB>();
  return [q, eb.or(results)];
}

function escapeLikeString(raw: string): string {
  return raw.replace(/[\\%_]/g, '\\$&');
}

function containsPattern(pattern: string): string {
  return `%${escapeLikeString(pattern)}%`;
}
