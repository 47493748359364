import { persistAtom } from '../../common/recoil/persistAtom';

export const scheduleDockCostControlsState = persistAtom<boolean>({
  key: 'state-scheduler-dock-cost-controls',
  default: false,
});

export const scheduleDockStatsState = persistAtom<boolean>({
  key: 'state-scheduler-dock-stats',
  default: false,
});
