import {
  AssignmentOccupancyLocationFilter,
  AssignmentOccupancyLocationSortBy,
  AssignmentOccupancyLocationSortOption,
  AssignmentOccupancyLocationStatus,
  LoadAssignmentOccupancyLocationsDocument,
  SortDirection,
  useRunExportJobMutation,
} from '@warebee/frontend/data-access-api-graphql';
import {
  getVolumeComplianceTableRows,
  VolumeComplianceConverterConfig,
  VolumeComplianceDataColumn,
  VolumeComplianceDataRow,
  volumeComplianceExportJobParams,
  VolumeComplianceExportJobParams,
} from '@warebee/shared/export-converter';
import _ from 'lodash';
import React, { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useRecoilState, useRecoilValue, useSetRecoilState } from 'recoil';
import { containsFilter, numberFilter } from '../common/filterHelper';
import { AsyncLoadStatus } from '../common/types';
import DatasetTable from '../components/DatasetTable';
import { Container } from '../components/layout/ContainerFlex';
import {
  viewerSelectedBayIdAtom,
  viewerSelectedLocationIdWithToggle,
  viewerSelectedPlaneId,
} from '../layout/viewer/store/viewer.state';
import useLoadAssignmentOccupancyLocations from '../simulation/hooks/useLoadAssignmentOccupancyLocations';
import {
  assignmentOccupancyDataTableState,
  assignmentOccupancyLocationsData,
  assignmentOccupancyLocationsDataLoadStatus,
} from '../simulation/store/occupancy/assignmentOccupancy.state';
import {
  simulationCurrentId,
  simulationEffectiveAssignmentId,
} from '../simulation/store/simulation.state';
import useAssignmentOccupancyTableConfig from './useAssignmentOccupancyTableConfig';

const sortFieldsMapping: Partial<
  Record<VolumeComplianceDataColumn, AssignmentOccupancyLocationSortOption>
> = {
  occupancy: AssignmentOccupancyLocationSortOption.OCCUPANCY,
  locationId: AssignmentOccupancyLocationSortOption.LOCATION_ID,
};

const AssignmentOccupancyTable: React.FC = () => {
  const { t } = useTranslation('simulation');
  const simulationId = useRecoilValue(simulationCurrentId);
  const assignmentId = useRecoilValue(simulationEffectiveAssignmentId);

  const loadStatus = useRecoilValue(assignmentOccupancyLocationsDataLoadStatus);
  const locData = useRecoilValue(assignmentOccupancyLocationsData);

  const [loadCallback, cancelLoad] = useLoadAssignmentOccupancyLocations();
  const [state, setState] = useRecoilState(assignmentOccupancyDataTableState);
  const selectLocation = useSetRecoilState(viewerSelectedLocationIdWithToggle);
  const selectBay = useSetRecoilState(viewerSelectedBayIdAtom);
  const selectPlane = useSetRecoilState(viewerSelectedPlaneId);
  const [runExportCSV] = useRunExportJobMutation();
  const columnsConfig = useAssignmentOccupancyTableConfig();

  const { searchValues, sortValues } = state;

  function callDataLoad(
    searchValues: Partial<Record<VolumeComplianceDataColumn, string>>,
    sortValues: Partial<Record<VolumeComplianceDataColumn, SortDirection>>,
    page = { isAppend: false, skip: 0 },
  ) {
    const filter: AssignmentOccupancyLocationFilter = {
      status: searchValues.occupancy
        ? ([searchValues.occupancy] as AssignmentOccupancyLocationStatus[])
        : null,
      locationId: containsFilter(searchValues.locationId),
      locationPlaneTitle: containsFilter(searchValues.locationPlaneTitle),
      locationAisleTitle: containsFilter(searchValues.locationAisleTitle),
      locationBayTitle: containsFilter(searchValues.locationBayTitle),
      locationLevel: numberFilter(searchValues.locationLevel),
      locationRackingType: containsFilter(searchValues.locationRackingType),
    };

    const sortBy: AssignmentOccupancyLocationSortBy[] = _.map(
      sortValues,
      (value, key, index) => {
        const sortObjectKey = sortFieldsMapping[key];
        return sortObjectKey
          ? { field: sortObjectKey, direction: value }
          : null;
      },
    ).filter(i => !!i);

    cancelLoad();
    loadCallback({
      filter,
      sortBy,
      ...page,
    });
  }

  useEffect(() => {
    callDataLoad(searchValues, sortValues);
  }, [searchValues, sortValues]);

  const converterConfig: VolumeComplianceConverterConfig = {
    columnsConfig,
    dictionaries: {
      // status: getAssignmentComplianceStatusMap(t),
      // rules: rulesMap,
    },
  };

  async function startExportCSV() {
    const variables: VolumeComplianceExportJobParams = {
      ...volumeComplianceExportJobParams,
      query: LoadAssignmentOccupancyLocationsDocument.loc.source.body,
      config: converterConfig,
      variables: {
        simulationId,
        assignmentId,
        // filter: getFilter(),
        // sortBy: getSortBy(),
        page: null,
      },
    };
    const { data, errors } = await runExportCSV({
      variables,
    });

    return {
      errors: errors,
      job: data.createExportJob,
    };
  }

  const isLoading = loadStatus === AsyncLoadStatus.Loading;
  const itemsCount = locData?.content?.length || 0;
  const totalCount = locData?.totalCount ?? 0;

  const flattenData = getVolumeComplianceTableRows(
    locData?.content,
    //converterConfig,
  );

  function onLoadNext() {
    callDataLoad(searchValues, sortValues, {
      isAppend: true,
      skip: itemsCount,
    });
  }

  function onRowSelect(row: VolumeComplianceDataRow, index: number) {
    const targetPlaneId = row.plane?.id;
    const targetBayId = row.bay?.id;
    const targetLocationId = row.locationId;
    targetPlaneId && selectPlane(targetPlaneId);
    targetBayId && selectBay(targetBayId);
    targetLocationId && selectLocation(targetLocationId);
  }

  return (
    <Container col>
      <DatasetTable
        isActionable
        subtitle={t`Filtered Items for`}
        title={t`Assignment Occupancy`}
        isSticky
        id={'assignment-occupancy-locations-table'}
        columnsConfig={columnsConfig}
        keyFields={['locationId']}
        data={flattenData}
        // groupBy="locationRackingType"
        // groupData={groupedData}
        onLoadNext={onLoadNext}
        onSearch={searchValues => setState({ ...state, searchValues })}
        totalCount={totalCount}
        searchValues={searchValues}
        sortBy={sortValues}
        onSort={sortValues => setState({ ...state, sortValues })}
        isLoading={isLoading}
        onRowSelect={onRowSelect}
        onStartExportClick={startExportCSV}
      />
    </Container>
  );
};

export default AssignmentOccupancyTable;
