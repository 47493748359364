import _ from 'lodash';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { useRecoilState, useRecoilValue } from 'recoil';
import AnalyzeResultGateBalancing from '../../analyze/AnalyzeResultGateBalancing';
import AnalyzeResultLoadBalancing from '../../analyze/AnalyzeResultLoadBalancing';
import AnalyzeResultWorkload from '../../analyze/AnalyzeResultWorkload';
import { AnalyzeComponentBaseProps } from '../../analyze/analyze.types';
import SwitchWorkforce from '../../components/actions/SwitchWorkforce';
import { ActionBar } from '../../components/nav/ActionBar';
import { loadBalancingPolicyDocument } from '../../loadBalancingPolicy/loadBalancingPolicy.state';
import { workloadContentViewAs } from '../store/analyze.state';
import SimulationAnalyzePanelWorkforce from './SimulationAnalyzePanelWorkforce';

const SimulationAnalyzePanelWork: React.FC<
  AnalyzeComponentBaseProps
> = props => {
  const { t } = useTranslation('simulation');
  const loadBalancingPolicy = useRecoilValue(loadBalancingPolicyDocument);

  const [workloadViewAs, setWorkloadViewAs] = useRecoilState(
    workloadContentViewAs,
  );

  const hasLoadPolicyRules = !_.isEmpty(loadBalancingPolicy?.rules);
  return (
    <>
      <ActionBar
        stickyTop
        className="!bg-app-panel flex items-center p-1 xl:p-4"
      >
        <SwitchWorkforce
          selected={workloadViewAs}
          onChange={v => setWorkloadViewAs(v)}
          availableOptions={['workforce', 'workloadbalance', 'endpoints']}
        />
      </ActionBar>

      {workloadViewAs === 'workforce' && (
        <SimulationAnalyzePanelWorkforce analyzeResult={props.analyzeResult} />
      )}
      {workloadViewAs === 'workloadbalance' && (
        <>
          {(!props.isOptimise || !hasLoadPolicyRules) && (
            <AnalyzeResultWorkload analyzeResult={props.analyzeResult} />
          )}
          {props.isOptimise && hasLoadPolicyRules && (
            <AnalyzeResultLoadBalancing analyzeResult={props.analyzeResult} />
          )}

          {/* <AnalyzeResultGateBalancing analyzeId={props.analyzeResult?.id} /> */}
        </>
      )}

      {workloadViewAs === 'endpoints' && (
        <AnalyzeResultGateBalancing analyzeId={props.analyzeResult?.id} />
      )}
    </>
  );
};

export default SimulationAnalyzePanelWork;
