import _ from 'lodash';
import { nanoid } from 'nanoid';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { useRecoilState } from 'recoil';
import { cn, getIndexedTitle } from '../common/utils';
import InboxZero from '../components/InboxZero';
import { Button } from '../components/actions/Button';
import * as Icon from '../components/icons';
import { Container } from '../components/layout/ContainerFlex';
import { ScreenTitle } from '../components/layout/ScreenTitle';
import TitleSection from '../components/layout/TitleSection';
import { StatusTag } from '../components/nav/StatusTag';
import PolicyRuleSectionTitle from '../components/policies/PolicyRuleSectionTitle';
import { PolicyWell } from '../components/policies/PolicyWell';
import { collapsibleStateAtom } from '../store/collapsible.state';
import FilterPreset from './FilterPreset';
import {
  filterPresetsAll,
  filterPresetSelectedIdentity,
} from './store/filterPreset.state';

const FilterPresets: React.FC = () => {
  const { t } = useTranslation('app');
  const [presets, setPresets] = useRecoilState(filterPresetsAll);

  const [selectedIdentity, setSelectedIdentity] = useRecoilState(
    filterPresetSelectedIdentity,
  );
  const [collapsible, setCollapsible] = useRecoilState(collapsibleStateAtom);

  const removePreset = (presetId: string) => {
    if (selectedIdentity?.presetId === presetId) {
      setSelectedIdentity(null);
    }
    setPresets(_.filter(presets, p => p.id !== presetId));
  };

  const addPreset = () => {
    const presetId = nanoid();

    const presetTitle = getIndexedTitle(
      new Set(presets.map(r => r.title)),
      t`Preset #`,
    );
    setPresets([
      {
        id: presetId,
        title: presetTitle,
        filterUnion: { anyOf: [] },
      },
      ...presets,
    ]);

    setCollapsible({
      ...collapsible,
      [presetId]: { isCollapsed: false },
    });
  };

  function movePreset(from, to) {
    const newPresets = [...presets];
    newPresets.splice(to, 0, newPresets.splice(from, 1)[0]);
    setPresets(newPresets);
  }

  const canUpdate = true;
  const hasRules = !_.isEmpty(presets);
  const policyRuleCounter = _.size(presets);

  return (
    <Container col hasOverflowY>
      <ScreenTitle
        title={t`Filter presets`}
        subtitle={t`Filter presets`}
        isSticky
        icon={Icon.Filter}
      />

      <TitleSection
        title={
          <PolicyRuleSectionTitle
            isFeasible={true}
            counter={policyRuleCounter}
          />
        }
        inPanelView
        className={cn('z-400 top-12 xl:top-20')}
        hasScreenTitle
        hasAction={
          canUpdate ? (
            <>
              {hasRules && (
                <Button
                  label={t`Add Preset`}
                  className={cn('rounded ltr:ml-4 rtl:mr-4')}
                  buttonSize="xs"
                  buttonType="primary"
                  hasIconAfter={
                    <Icon.CirclePlus className={cn('h-5 w-5 fill-current')} />
                  }
                  onPress={() => addPreset()}
                />
              )}
            </>
          ) : (
            <>
              <StatusTag
                title={t`Policy Locked`}
                type="locked"
                modeStyle="stroke"
                icon={Icon.Lock}
                onPress={null}
              />
            </>
          )
        }
      />
      <Container col flex1 hasOverflowY>
        <PolicyWell fullHeight isDisabled={!canUpdate} isCentred={!hasRules}>
          {!hasRules && (
            <InboxZero selfCenter hasIcon message={t`No Rules found`}>
              {canUpdate && (
                <div
                  className={cn(
                    'mt-4',
                    'flex items-center',
                    'flex-col xl:flex-row',
                    'space-y-2 xl:space-y-0',
                    'w-full',
                  )}
                >
                  <Button
                    full
                    buttonSize="xs"
                    buttonType="primary"
                    label={t`Add Policy`}
                    className={cn('w-full flex-1 rounded')}
                    onPress={addPreset}
                    hasIconAfter={
                      <Icon.CirclePlus
                        className={cn('h-5 w-5', 'fill-current')}
                      />
                    }
                  />
                </div>
              )}
            </InboxZero>
          )}

          {_.map(presets, (rule, index) => {
            return (
              <FilterPreset
                isDisabled={!canUpdate}
                key={`ap-filter-tag-rule-${rule.id}`}
                presetId={rule.id}
                index={index + 1}
                isRemovable={true}
                canDelete={true}
                canRename={true}
                onDeleteClick={() => removePreset(rule.id)}
                canMoveUp={index > 0}
                onMoveUp={() => movePreset(index, index - 1)}
                canMoveDown={index < presets.length - 1}
                onMoveDown={() => movePreset(index, index + 1)}
                isActive={selectedIdentity?.presetId === rule.id}
                hasOrder={true}
              />
            );
          })}
        </PolicyWell>
      </Container>
    </Container>
  );
};

export default FilterPresets;
