import { Expression, RawBuilder, Simplify, sql } from 'kysely';
import { jsonBuildObject } from 'kysely/helpers/postgres';

export type KyselyDialect = 'postgres' | 'athena';

export interface KyselyDialectHelper {
  jsonBuildObject<O extends Record<string, Expression<unknown>>>(
    obj: O,
  ): RawBuilder<
    Simplify<{
      [K in keyof O]: O[K] extends Expression<infer V> ? V : never;
    }>
  >;

  slice<T>(
    expr: Expression<T[]>,
    from?: Expression<number>,
    length?: Expression<number>,
  ): RawBuilder<T[]>;

  transformArray<F, T>(
    arrayExpr: Expression<F[]>,
    mapper: (param: Expression<F>) => Expression<T>,
  ): RawBuilder<T[]>;

  jsonPropertyValue(
    expr: Expression<any>,
    property: string,
  ): Expression<string>;
}

export const POSTGRES_DIALECT_HELPER: KyselyDialectHelper = {
  jsonBuildObject: jsonBuildObject,

  slice: (expr, from?, length?) => {
    const effectiveFrom = from ? from : sql.lit(1);
    return sql`(${expr})[${effectiveFrom}:${
      length ? sql`(${effectiveFrom}) + (${length}) - 1` : ''
    }]`;
  },

  transformArray: (arrayExpr, mapper) =>
    sql`(select array_agg(${mapper(
      sql.ref('v.v'),
    )}) from unnest(${arrayExpr}) as v(v))`,

  jsonPropertyValue: (expr: Expression<any>, property: string) =>
    sql`${expr}->>${sql.lit(property)}`,
};

export const ATHENA_DIALECT_HELPER: KyselyDialectHelper = {
  jsonBuildObject: obj => {
    return sql`json_object(${sql.join(
      Object.entries(obj).map(([k, v]) => sql`${sql.lit(k)}: ${v}`),
    )})`;
  },

  slice: (expr, from?, length?) =>
    sql`slice(${expr}, ${from ? from : sql.lit(1)}, ${
      length ? length : sql.lit(Number.MAX_SAFE_INTEGER)
    })`,

  transformArray: (arrayExpr, mapper) =>
    sql`transform(${arrayExpr}, v -> ${mapper(sql.ref('v'))})`,

  jsonPropertyValue: (expr: Expression<any>, property: string) =>
    sql`json_value(${expr}, ${sql.lit(`strict $.${property}`)})`,
};

export const KYSELY_DIALECT_HELPERS: Record<
  KyselyDialect,
  KyselyDialectHelper
> = {
  postgres: POSTGRES_DIALECT_HELPER,
  athena: ATHENA_DIALECT_HELPER,
};
