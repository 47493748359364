import React from 'react';
import { useTranslation } from 'react-i18next';
import { useRecoilState } from 'recoil';
import { cn } from '../../common/utils';
import { scheduleDockCostControlsState } from './schedule.state';

export const ScheduleCostControlsToggle: React.FC = () => {
  const { t } = useTranslation('simulation');
  const [showScheduleDockCostControls, setShowScheduleDockCostControls] =
    useRecoilState(scheduleDockCostControlsState);

  return (
    <span
      className={cn(
        'p-1',
        'text-menu-text/60 cursor-pointer text-sm hover:underline',
      )}
      onClick={() => {
        setShowScheduleDockCostControls(!showScheduleDockCostControls);
      }}
    >
      {showScheduleDockCostControls
        ? t`Hide cost controls`
        : t`Show cost controls`}
    </span>
  );
};
