import classNames from 'classnames';
import _ from 'lodash';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { useRecoilState, useRecoilValue } from 'recoil';
import useFormatter from '../../common/useFormatter';
import { ButtonToolbarGroup } from '../../components/actions/ButtonToolbarGroup';
import useActualityMetricDescriptors from '../../metrics/actuality/useActualityMetricDescriptors';
import HeatmapLegendBuckets, {
  HeatmapLegendBucketsProps,
} from '../../simulation/toolbars/HeatmapLegendBuckets';
import {
  actualityHeatmapBuckets,
  actualityHeatmapFilters,
  actualityMetricSelected,
} from '../store/actuality.heatmap.state';

const ActivityFeedToolbar: React.FC = () => {
  const { t } = useTranslation('feed');
  const formatter = useFormatter();
  const heatmapMetricId = useRecoilValue(actualityMetricSelected);
  const buckets = useRecoilValue(actualityHeatmapBuckets);
  const [heatmapFilter, setHeatmapFilter] = useRecoilState(
    actualityHeatmapFilters,
  );
  const metricDescriptors = useActualityMetricDescriptors();

  const metric = metricDescriptors[heatmapMetricId];

  const bucketProps: HeatmapLegendBucketsProps = {
    title: metric?.title ?? '—',
    buckets: _.values(buckets) ?? [],
    metric: metric,
    heatmapFilter,
    setHeatmapFilter,
  };

  return (
    <ButtonToolbarGroup
      className={classNames(
        'flex flex-col',
        'z-toolbar',
        'absolute top-1',
        'ltr:left-1 rtl:right-1',
      )}
    >
      <HeatmapLegendBuckets {...bucketProps} />
    </ButtonToolbarGroup>
  );
};

export default ActivityFeedToolbar;
