import classNames from 'classnames';
import _ from 'lodash';
import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useRecoilValue } from 'recoil';
import TitleSection from '../../../components/layout/TitleSection';
import Callout from '../../../helpContext/Callout';
import { warehouseSelected } from '../../../store/warehouse.state';
import { importPipelineSelected } from '../../store/importPipelines.state';
import RemovePipeline from '../editors/RemovePipeline';

export const PipelineSectionDeletePipeline: React.FC = () => {
  const { t } = useTranslation('dataset');
  const wh = useRecoilValue(warehouseSelected);
  const pipeline = useRecoilValue(importPipelineSelected);
  const [isLoading, setIsLoading] = useState(false);
  const [isDialogOpen, setIsDialogOpen] = useState(false);

  return (
    <>
      <TitleSection
        id={`pipeline-setting-delete`}
        title={t`Delete Pipeline`}
        inPanelView
        className={classNames('z-400 top-12 xl:top-20')}
        classNameInner="h-full"
        hasScreenTitle
        // collapsible
        hasPadding
        isClosed
      >
        <Callout type="warning">
          <div className={classNames('mb-2')}>{t`Warning!`}</div>
          <div
            className={classNames('mb-4')}
          >{t`Click Delete Pipeline to delete this pipeline. This action cannot be undone.`}</div>
          <RemovePipeline
            pipeline={pipeline}
            isNew={_.isNil(pipeline)}
            onChange={() => null}
          />
        </Callout>
      </TitleSection>
    </>
  );
};
