import React, { Suspense } from 'react';
import { useTranslation } from 'react-i18next';
import { ContainerMap } from '../../components/layout/ContainerMap';
import StageContainer from '../../layout/stage/StageContainer';
import ActiveFeatureLayer from '../../layout/viewer/layers/ActiveFeatureLayer';
import AisleLayer from '../../layout/viewer/layers/AisleLayer';
import BayLayer from '../../layout/viewer/layers/BayLayer';
import HoveredFeatureLayer from '../../layout/viewer/layers/HoveredFeatureLayer';
import PortalLayer from '../../layout/viewer/layers/PortalLayer';
import LayoutWatcher from '../../layout/watcher/LayoutWatcher';
import ActivityFeedToolbar from '../components/ActivityFeedToolbar';
import ActualityLayoutLoadingAlerts from '../components/ActualityLayoutLoadingAlerts';
import ActualityViewModeToolbar from '../components/ActualityViewModeToolbar';
import FeedLayoutTooltip from '../components/FeedLayoutTooltip';
import ActivityCongestionLayer from '../layers/ActivityCongestionLayer';
import ActivityLocationLayer from '../layers/ActivityLocationLayer';
import ActivityRoutesLayer from '../layers/ActivityRoutesLayer';

const ViewerActivityFeed: React.FC = () => {
  const { t } = useTranslation('feed');

  return (
    <>
      <ContainerMap>
        <StageContainer type="viewer-area-view">
          <AisleLayer />
          <ActivityCongestionLayer />
          <BayLayer />
          <ActivityLocationLayer />
          <PortalLayer />
          <ActivityRoutesLayer />
          <ActiveFeatureLayer />
          <HoveredFeatureLayer />
        </StageContainer>

        <ActualityLayoutLoadingAlerts />
        <Suspense>
          <FeedLayoutTooltip />
          <ActualityViewModeToolbar />
          <ActivityFeedToolbar />
        </Suspense>
      </ContainerMap>
      <LayoutWatcher
        stageId="viewer-area-view"
        sidebarIds={[
          'sidebar-layout-common-info',
          'sidebar-layout-info-location',
        ]}
      />
    </>
  );
};

export default ViewerActivityFeed;
