import React from 'react';

import { AriaTextFieldProps } from '@react-types/textfield';
import classNames from 'classnames';
import { useTextField } from 'react-aria';
import { useTranslation } from 'react-i18next';
import useSharedRef from '../../utils/use-shared-ref';

export type InputTextAreaProps = {
  className?: string;
  classNameLabel?: string;
  classNameLabelTitle?: string;
  animate?: boolean;
  optional?: boolean;
  required?: boolean;
  rows?: number;
} & AriaTextFieldProps;

const InputTextArea = React.forwardRef<HTMLTextAreaElement, InputTextAreaProps>(
  (props: InputTextAreaProps, ref) => {
    const { t } = useTranslation('app');

    const { label } = props;
    const sharedRef = useSharedRef<HTMLTextAreaElement>(ref);
    const {
      labelProps,
      inputProps,
    }: { labelProps: any; inputProps: { disabled?: boolean } } = useTextField(
      props,
      sharedRef as any,
    );

    // const { labelProps, inputProps } = useTextField(props, sharedRef as any);

    return (
      // eslint-disable-next-line jsx-a11y/label-has-associated-control, react/jsx-props-no-spreading
      <label
        {...labelProps}
        className={classNames(
          'flex flex-col',
          inputProps.disabled ? 'text-menu-text/50' : 'text-menu-text',
          props.classNameLabel,
        )}
        data-component={`InputTextArea`}
        data-label={`input-textarea-${label || ''}`}
        aria-label={`input-textarea-${label || ''}`}
      >
        <div
          className={classNames(props.classNameLabelTitle ?? 'mb-2 text-sm')}
        >
          {label}
          {props.optional && (
            <span
              className={classNames('opacity-50', 'ltr:ml-2 rtl:mr-2')}
            >{t`(Optional)`}</span>
          )}
        </div>
        <textarea
          type="textarea"
          rows={props.rows ?? 3}
          ref={sharedRef}
          {...(inputProps as any)}
          className={classNames(
            'flex-1 p-3 text-lg',
            'w-full',
            'placeholder-opacity-30',
            'focus:placeholder-menu-active-secondary focus:outline-none focus:ring-0',
            'active:text-menu-active active:border-opacity-100',
            'read-only:border-menu-active/20 read-only:hover:border-menu-active/10 read-only:text-menu-text/50 read-only:hover:text-menu-text/50 read-only:cursor-not-allowed',
            'disabled:border-menu/10 disabled:hover:border-menu/10 disabled:text-menu-text/75 disabled:cursor-not-allowed',
            'bg-app-input/90 active:bg-app-input focus:bg-app-input/75 text-menu-text',
            'shadow read-only:shadow-none focus:shadow-xl active:shadow-xl',
            inputProps.disabled
              ? 'text-menu-text/50 bg-app-input/60'
              : 'border-menu-active/50 placeholder-menu-text/30 hover:border-menu-active hover:text-menu-text hover:placeholder-menu-active-secondary focus:border-menu-active focus:text-menu-text hover:border-opacity-100 focus:border-opacity-100',
            {
              'border-animation animate-rotate-border-90': props.animate,
            },
            props.className,
          )}
        />
      </label>
    );
  },
);

export default InputTextArea;
