import { useUpdateActualityMutation } from '@warebee/frontend/data-access-api-graphql';
import _ from 'lodash';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { useRecoilState, useRecoilValue } from 'recoil';
import {
  FilterFieldConfigBase,
  FilterFieldConfigCustomizable,
} from '../../common/types';
import InputCheckbox from '../../components/inputs/InputCheckbox';
import { InputGroupList } from '../../components/inputs/InputGroupList';
import TitleSection from '../../components/layout/TitleSection';
import PanelContainer from '../../containers/PanelContainer';
import { FilterPresetDataType } from '../../filterPreset/store/filterPreset.types';
import { DBFieldType } from '../../policyFilters/policyFilter.types';
import { actualityHqFieldTypesBase } from '../store/actuality.hq.default';
import {
  actualityExtraSettings,
  actualitySelectedId,
} from '../store/actuality.state';
import {
  ActualityExtraSettings,
  DatasetExtraFields,
} from '../store/actuality.types';
import { ActualityHqDataColumn } from '../store/datasetQueries/actualityHqDataRows';
import { feedDatasetObject } from '../store/feed.state';
import { getActualityHqConfigBase } from '../store/getActualityHqConfigBase';

type FilterSetupSectionProps<T extends string> = {
  id: string;
  title: string;
  filterConfig: FilterFieldConfigBase<T>[];
  filterOverrides: Partial<Record<T, FilterFieldConfigCustomizable<T>>>;
  baseFields: Partial<Record<T, DBFieldType>>;
  extraFields: DatasetExtraFields[];
};

function FilterSetupSection<T extends string>(
  props: FilterSetupSectionProps<T>,
) {
  const { t } = useTranslation('app');
  return (
    <TitleSection id={`activity-fields-${props.id}`} title={props.title}>
      <TitleSection
        id={`activity-fields-base${props.id}`}
        title={t`Base filters`}
        inSidebarView
      >
        <InputGroupList className="space-y-2 p-1 lg:p-2 xl:p-4">
          {_.map(_.map(props.filterConfig), cfg => {
            return (
              <InputCheckbox
                key={`activity-extra-field-${cfg.type}`}
                isSelected={!cfg.disabled}
                onChange={value => {}}
              >
                {cfg.title}
              </InputCheckbox>
            );
          })}
        </InputGroupList>
      </TitleSection>
    </TitleSection>
  );
}

/**
 * Setup filters that will be available in filter presets
 */
const ActualityFiltersSettings: React.FC = () => {
  const { t } = useTranslation('feed');
  const actualityId = useRecoilValue(actualitySelectedId);
  const activityFeedSummary = useRecoilValue(feedDatasetObject);
  const [extraSettings, setExtraSettings] = useRecoilState(
    actualityExtraSettings,
  );
  const [callUpdateActuality] = useUpdateActualityMutation();

  function updateExtraSettings(patch: Partial<ActualityExtraSettings>) {
    const newSettings = {
      ...extraSettings,
      ...patch,
    };
    setExtraSettings(newSettings);
    callUpdateActuality({
      variables: {
        input: {
          actualityId,
          extraSettings: newSettings,
        },
      },
    });
  }

  const actualityHqPresetType: FilterPresetDataType = 'actualityHq';

  const activityFilterOverrides = _.find(
    extraSettings.filterSettings,
    f => f.presetType === actualityHqPresetType,
  )
    ?.filterConfigOverrides as FilterFieldConfigCustomizable<ActualityHqDataColumn>[];

  const filterOverridesMap = _.keyBy(activityFilterOverrides, f => f.type);
  const activityFilters: FilterSetupSectionProps<ActualityHqDataColumn> = {
    id: 'activity-feed',
    title: t`Activity Fields`,
    filterConfig: getActualityHqConfigBase(t),
    filterOverrides: filterOverridesMap,
    baseFields: actualityHqFieldTypesBase,
    extraFields: extraSettings.activityExtraFields,
  };

  return (
    <PanelContainer
      id="panel-filter-settings"
      title={t`Filter settings`}
      collapsible
      hasPadding
    >
      <FilterSetupSection {...activityFilters}></FilterSetupSection>
    </PanelContainer>
  );
};

export default ActualityFiltersSettings;
