import {
  LocationFilterFragment,
  LocationFilterIntersectionFragment,
  LocationFilterType,
} from '@warebee/frontend/data-access-api-graphql';
import _ from 'lodash';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { useRecoilState, useRecoilValue } from 'recoil';
import { FilterFieldConfigBase } from '../../../../common/types';
import LocationFilterFieldValueEditor, {
  PolicyFilterValueEditorProps,
} from '../../../../policyFilters/PolicyFilterValueEditor';
import { LoadLocationFilterValuesParams } from '../../../../policyFilters/useLoadLocationFilterValues';
import { getLocationFilterConfigCommon } from '../../../../simulation/store/assignmentPolicy.default';
import {
  allocationPolicyLocationFilterIntersection,
  allocationPolicySelectedIdentity,
} from '../../../store/allocationPolicy/allocationPolicy.state';

export type AllocationPolicyLocationFilterEditorProps = {
  filterConfig: FilterFieldConfigBase<LocationFilterType>;
  startLoadData: (
    params: Partial<LoadLocationFilterValuesParams>,
  ) => Promise<void>;
  cancelLoadData: (fieldId: string) => void;
};

const AllocationPolicyLocationFilterEditor: React.FC<
  AllocationPolicyLocationFilterEditorProps
> = props => {
  const { t } = useTranslation('simulation');
  const { type } = props.filterConfig;
  const { startLoadData, cancelLoadData } = props;

  const selectedIdentity = useRecoilValue(allocationPolicySelectedIdentity);
  const [filterIntersection, setFilterIntersection] = useRecoilState(
    allocationPolicyLocationFilterIntersection(selectedIdentity),
  );

  if (!selectedIdentity || !filterIntersection) return null;

  const fieldValue = _.find(filterIntersection?.allOf, f => f.type === type);

  const filterConfigs = getLocationFilterConfigCommon(t);
  const onSelectValue = (value: LocationFilterFragment) => {
    const others = filterIntersection.allOf.filter(fi => fi.type !== type);
    // If all values were deselected in filter, we shouldn't add this filter to set
    if (
      !_.isEmpty(value.valueIn || value.stringRange || value.range) ||
      value.isNot
    ) {
      others.push(value as LocationFilterFragment);
    }

    const newIntersection: LocationFilterIntersectionFragment = {
      id: filterIntersection.id,
      allOf: others,
    };

    setFilterIntersection(newIntersection);

    startLoadData({
      field: type,
      filterConfigs: getLocationFilterConfigCommon(t),
      affect: 'others',
      filterIntersection: newIntersection,
    });
  };

  const editorProps: PolicyFilterValueEditorProps<LocationFilterType> = {
    config: props.filterConfig,
    value: fieldValue,
    onValueChange: onSelectValue,
    startLoadData: params =>
      startLoadData({
        ...params,
        filterConfigs,
        affect: 'self',
        filterIntersection,
      }),
    cancelLoadData,
  };

  return <LocationFilterFieldValueEditor {...editorProps} />;
};

export default AllocationPolicyLocationFilterEditor;
