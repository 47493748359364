import { useRecoilCallback } from 'recoil';
import {
  importJob,
  importMappingPreviewViewAs,
  importPreviewRowIndex,
  importProcessStatus,
  importRawPreview,
  importRawPreviewStatus,
  importSelectedFile,
  importSelectedStep,
  importTransformedPreview,
  importTransformedPreviewStatus,
  importTypeCurrent,
  importUploadChunkStatusAtom,
  importUploadStatus,
  importValidationErrors,
  importValidationStatus,
} from './store/import.state';
import { DatasetType, ImportMenuItemId } from './store/import.types';

export type ResetImportStateParams = {
  step: ImportMenuItemId;
  importType?: DatasetType;
};

function useResetImportState() {
  const init = useRecoilCallback(
    ({ snapshot, set, reset }) =>
      async (params: ResetImportStateParams) => {
        reset(importJob);
        reset(importSelectedFile);
        reset(importRawPreview);
        reset(importRawPreviewStatus);
        reset(importTransformedPreview);
        reset(importTransformedPreviewStatus);
        reset(importValidationErrors);
        reset(importValidationStatus);
        reset(importUploadChunkStatusAtom);
        reset(importUploadStatus);
        reset(importPreviewRowIndex);
        reset(importProcessStatus);
        reset(importMappingPreviewViewAs);
        //importParsingSettings = atom;
        // ToDo : Reset New props
        params.importType && set(importTypeCurrent, params.importType);
        set(importSelectedStep, params.step);
      },
  );

  return init;
}
export default useResetImportState;
