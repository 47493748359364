import { TFunction } from 'i18next';
import * as Icon from '../../components/icons';
import { DatasetObjectMenuItem } from './datasetObject.types';

export const datasetObjectMenuItems: (
  t: TFunction<'app'>,
) => DatasetObjectMenuItem[] = t => [
  {
    id: 'dataset-object-menu-start',
    title: t('Dataset Object', { ns: 'app' }),
    titleMinimized: t('Data', { ns: 'app' }),
    disabled: false,
    icon: Icon.TableView,
    menuLevel: 1,
    sidebar: {
      'sidebar-dataset-object-main': { isHidden: false, isCollapsed: false },
    },
  },
  {
    id: 'dataset-object-menu-stats',
    title: t('Data Stats', { ns: 'app' }),
    titleMinimized: t('Stats', { ns: 'app' }),
    disabled: false,
    icon: Icon.DataStatsPie,
    menuLevel: 1,
    sidebar: {
      'sidebar-dataset-object-stats': { isHidden: true, isCollapsed: false },
    },
  },
  {
    id: 'dataset-object-menu-import-jobs',
    title: t('Import Jobs', { ns: 'app' }),
    titleMinimized: t('Jobs', { ns: 'app' }),
    disabled: false,
    icon: Icon.CloudUploadCsv,
    menuLevel: 1,
    sidebar: {
      // 'sidebar-dataset-object-main': { isHidden: true, isCollapsed: false },
    },
  },

  {
    id: 'dataset-object-menu-import-pipelines',
    title: t('Import Pipelines', { ns: 'app' }),
    titleMinimized: t('Pipelines', { ns: 'app' }),
    disabled: false,
    icon: Icon.LinkArrow,
    menuLevel: 1,
    sidebar: {
      'sidebar-dataset-pipeline': { isHidden: true, isCollapsed: false },
      'sidebar-dataset-pipeline-editor': { isHidden: true, isCollapsed: false },
    },
  },
];
