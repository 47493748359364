import _ from 'lodash';
import { useTranslation } from 'react-i18next';
import useFormatter from '../common/useFormatter';
import {
  EndpointDistributionKPI,
  EndpointDistributionKPIDescriptor,
} from './AnalyzeResultEndpointDistributionKPISelector';

function useEndpointDistributionKPIDescriptors(): Record<
  EndpointDistributionKPI,
  EndpointDistributionKPIDescriptor
> {
  const { t } = useTranslation('simulation');
  const formatter = useFormatter();

  const descriptors: EndpointDistributionKPIDescriptor[] = [
    {
      id: 'jobsCount',
      title: t`Jobs count`,
      formatter: formatter.formatInteger,
    },
    {
      id: 'duration',
      title: t`Duration`,
      formatter: formatter.formatTimeSpan,
    },
    {
      id: 'cost',
      title: t`Cost`,
      formatter: formatter.formatCurrency,
    },
    {
      id: 'volume',
      title: t`Volume`,
      formatter: formatter.formatVolume,
    },
    {
      id: 'weight',
      title: t`Weight`,
      formatter: formatter.formatWeight,
    },
  ];

  return _.keyBy(descriptors, 'id') as Record<
    EndpointDistributionKPI,
    EndpointDistributionKPIDescriptor
  >;
}

export default useEndpointDistributionKPIDescriptors;
