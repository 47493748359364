import { SortDirection } from '@warebee/frontend/data-access-api-graphql';
import classNames from 'classnames';
import _ from 'lodash';
import { useCallback, useEffect, useState } from 'react';
import { Paths } from 'type-fest';
import { cn } from '../common/utils';
import * as Icon from '../components/icons';
import { ColumnConfig, DatasetTableProps } from './DatasetTable';
import DropdownSelector from './actions/DropdownSelector';
import { InputSearch } from './inputs/InputSearch';
import { TableRowHead } from './table/TableRowHead';
import { TableTh } from './table/TableTh';

const sortOptions: SortDirection[] = [
  SortDirection.ASC,
  SortDirection.DESC,
  null,
];

export type DatasetTableHeaderProps<DataRow extends Object> =
  DatasetTableProps<DataRow> & {
    visibleColumns: ColumnConfig<DataRow>[];
  };

function DatasetTableHeader<DataRow extends Object>(
  props: DatasetTableHeaderProps<DataRow>,
) {
  const [
    searchValues = {} as Partial<Record<Paths<DataRow>, string>>,
    setSearchValues,
  ] = useState(props.searchValues);

  useEffect(() => {
    setSearchValues(props.searchValues);
  }, [props.searchValues]);

  const sortBy =
    props.sortBy ?? ({} as Partial<Record<Paths<DataRow>, SortDirection>>);

  const debouncedDataLoad = useCallback(
    _.debounce(searchValues => props.onSearch(searchValues), 500),
    [props.id],
  );

  function onSearch(field: Paths<DataRow>, value: string | null) {
    const newSearchValues = { ...searchValues, [field]: value };
    setSearchValues(newSearchValues);
    debouncedDataLoad(newSearchValues);
  }

  function SortIcon({ field }: { field: Paths<DataRow> }) {
    const value = sortBy[field];
    const hasActiveSort = !_.isNil(value);

    function onClick() {
      if (_.isNil(props.onSort)) return;

      const currentSortOptionIndex = sortOptions.indexOf(value);
      const newSortOptionIndex =
        (currentSortOptionIndex + 1) % sortOptions.length;

      const sortValues: Partial<Record<Paths<DataRow>, SortDirection>> =
        props.hasSortByMultipleColumns
          ? (_.omit(props.sortBy, field) as any)
          : {};

      if (sortOptions[newSortOptionIndex] !== null) {
        sortValues[field] = sortOptions[newSortOptionIndex];
      }

      props.onSort(sortValues);
    }

    const styleIconButton = classNames(
      'h-7 w-7',
      'fill-current',
      'bg-menu/10 lg:bg-menu/5',
      'hover:bg-menu-active hover:text-menu-active-text',
      hasActiveSort ? 'text-menu-active bg-menu/20' : '',
      'p-1',
      'mx-0.5',
      'rounded-full',
      'cursor-pointer',
    );

    const IconCpm = props => {
      switch (value) {
        case SortDirection.ASC:
          return (
            <Icon.SortAscending
              {...props}
              className={cn(styleIconButton, props.className)}
            />
          );

        case SortDirection.DESC:
          return (
            <Icon.SortDescending
              {...props}
              className={cn(styleIconButton, props.className)}
            />
          );

        default:
          return (
            <Icon.SortNone
              {...props}
              className={cn(styleIconButton, props.className)}
            />
          );
      }
    };

    return (
      <div
        data-component="SortIcon"
        className={classNames({
          'any-hover-none:opacity-0 any-hover:hover:opacity-100 any-hover:group-hover:opacity-50':
            !hasActiveSort,
        })}
        onClick={onClick}
      >
        <IconCpm />
      </div>
    );
  }

  return (
    <TableRowHead>
      {props.hasRowCounter && (
        <TableTh className="bg-table-header/50 !px-2 backdrop-blur">
          <span className="text-menu-text/50 text-xs">{`#`}</span>
        </TableTh>
      )}
      {props.hasCheckboxes && (
        <TableTh className="bg-table-header/50 !px-2 backdrop-blur">
          <span className="text-menu-text/50 text-xs">{``}</span>
        </TableTh>
      )}

      {props.visibleColumns.map((column, index) => {
        const filterValue = searchValues[column.field];
        const columnTitle = column.hasFilter
          ? column.filterOptions
            ? column.title + (filterValue ? '' : '')
            : null
          : column.title;

        const styleIconButton = classNames(
          'h-5 w-5',
          'fill-current',
          'text-opacity-60 hover:text-menu-active',
          'bg-menu/5 hover:bg-menu-hover',
          'text-menu-text',
          'p-1 mx-0.5',
          'rounded-full',
        );

        return (
          <TableTh
            key={`th-${index}-${column.field as string}`}
            classNameChildren={
              column.hasFilter && column.hasSort && column.filterOptions
                ? 'ltr:ml-4 ltr:mr-2 flex-1'
                : column.hasFilter && column.hasSort
                  ? 'flex-1'
                  : ''
            }
            className={
              column.isHeader
                ? 'isHeader !z-1000 bg-app-panel-light/50 backdrop-blur ltr:left-0 rtl:right-0'
                : ''
            }
            isSticky
            cellSize={`sm`}
            // isNarrow
            value={columnTitle}
            hasFilter={column.hasFilter}
            sortable={column.hasSort}
          >
            {column.filterOptions && filterValue && (
              <div
                data-component="filterOptions"
                // className={`ltr:ml-2 rtl:mr-2 px-2 py-1 bg-menu-500 rounded text-xxs flex items-center group isolate cursor-pointer
                // ${filterValue ? 'text-menu-active' : ''}`}
                className={classNames(
                  'ltr:ml-2 rtl:mr-2',
                  'px-2 py-1',
                  'bg-app-panel',
                  'rounded',
                  'text-xxs uppercase',
                  'flex items-center',
                  'flex items-center',
                  'group',
                  'cursor-pointer',
                  // 'group isolate cursor-pointer',
                  { 'text-menu-active': filterValue },
                )}
                onClick={() => onSearch(column.field, null)}
              >
                <div
                  className="block hover:hidden group-hover:hidden"
                  title="hide filter"
                >
                  <Icon.Filter className={classNames(styleIconButton)} />
                </div>
                <div
                  className="hidden hover:block group-hover:block"
                  title="show filter"
                >
                  <Icon.CircleX className={classNames(styleIconButton)} />
                </div>
                <span className="whitespace-pre">{`${column.filterOptions[filterValue]}`}</span>
              </div>
            )}

            {column.hasFilter &&
              (column.filterOptions ? (
                <DropdownSelector
                  className="filterOptions"
                  onClick={e => {
                    e.stopPropagation();
                  }}
                  DropAlignRight
                  buttonTransparent
                  tableMode
                  vertical
                  value={'...'}
                  values={_.keys(column.filterOptions)}
                  renderValue={key => column.filterOptions[key]}
                  onChange={v => onSearch(column.field, v)}
                />
              ) : (
                <InputSearch
                  headerTableMode
                  titleMode
                  placeholder={column.title}
                  value={searchValues[column.field] ?? ''}
                  onChange={v => onSearch(column.field, v)}
                />
              ))}
            {column.hasSort && <SortIcon field={column.field} />}
          </TableTh>
        );
      })}
    </TableRowHead>
  );
}

export default DatasetTableHeader;
