import {
  Field,
  ID,
  InputType,
  Int,
} from '@warebee/shared/util-backend-only-types';
import {
  IsInt,
  IsNotEmpty,
  IsNumber,
  IsOptional,
  IsPositive,
} from '@warego/class-validator';

@InputType()
export class ImportItemSetLineInput {
  @Field(() => ID)
  @IsNotEmpty()
  consignee: string;

  @Field(() => ID)
  @IsNotEmpty()
  sku: string;

  @Field({ nullable: true })
  @IsOptional()
  skuGroup?: string;

  @Field({ nullable: true })
  name?: string;

  @Field({ nullable: true })
  description?: string;

  @Field({ nullable: true })
  subGroup?: string;

  @Field({ nullable: true })
  transportClass?: string;

  @Field({ nullable: true })
  stockCategory?: string;

  @Field({ nullable: true })
  storageClass?: string;

  @Field({ nullable: true })
  pollutionClass?: string;

  @Field()
  @IsNotEmpty()
  uom: string;

  @Field({ nullable: true })
  @IsOptional()
  lowerUom?: string;

  @Field({ nullable: true })
  @IsOptional()
  @IsNumber()
  netWeight?: number;

  @Field({ nullable: true })
  @IsOptional()
  @IsNumber()
  length?: number;

  @Field({ nullable: true })
  @IsOptional()
  @IsNumber()
  width?: number;

  @Field({ nullable: true })
  @IsOptional()
  @IsNumber()
  height?: number;

  @Field({ nullable: true })
  @IsOptional()
  @IsNumber()
  volume?: number;

  @Field({ nullable: true })
  @IsOptional()
  @IsInt({})
  @IsPositive()
  unitsPerLowestUom?: number;

  @Field({ nullable: true })
  @IsOptional()
  ean?: string;

  @Field({ nullable: true })
  @IsOptional()
  upc?: string;

  @Field(() => Int, { nullable: true })
  @IsOptional()
  @IsInt()
  maxStack?: number;
}
