import {
  SkipLimitPageSpec,
  SortDirection,
  StringSearchFilter,
  StringSearchFilterType,
} from '@warebee/frontend/data-access-api-graphql';
import { InferResult } from 'kysely';
import _ from 'lodash';
import { KeysMatching } from '../../../common/utility.types';
import { getAnalyzeEventsFilteredQueryBuilder } from './queryBuilder/analyzedEventsQueryBuilder';
import { AnalyzedFeedEventsQueryBuilderParams } from './queryBuilder/analyzedFeedEventsQueryBuilder';
import { datasetQueryBuilder } from './queryBuilder/datasetQueryBuilder';

/**
 * Load All Warebee simulated events for selected job
 */
export type ActualitySimulatedEventsQueryBuilderParams =
  AnalyzedFeedEventsQueryBuilderParams & {
    jobId: string;
    filterBy?: ActualitySimulatedEventsFilterBy;
  };

export type ActualitySimulatedEventSortBy = {
  direction?: SortDirection;
  field: ActualitySimulatedEventsColumn;
};

export type ActualitySimulatedEventsFieldWithStringFilter = KeysMatching<
  ActualitySimulatedEvent,
  string
>;

export type ActualitySimulatedEventsFilterBy = Partial<
  Record<ActualitySimulatedEventsFieldWithStringFilter, StringSearchFilter>
>;

export type ActualitySimulatedEvents = InferResult<
  ReturnType<typeof getActualitySimulatedEventSelectPart>
>;
export type ActualitySimulatedEvent = ActualitySimulatedEvents[number];
export type ActualitySimulatedEventsColumn = keyof ActualitySimulatedEvent;

export type ActualitySimulatedEventsQueryBuilderPagedClientParams = {
  sortBy?: ActualitySimulatedEventSortBy;
  page?: SkipLimitPageSpec;
};

export type ActualitySimulatedEventsQueryBuilderPagedParams =
  ActualitySimulatedEventsQueryBuilderParams &
    ActualitySimulatedEventsQueryBuilderPagedClientParams;

export function getActualitySimulatedEventSelectPart(
  params: ActualitySimulatedEventsQueryBuilderParams,
) {
  let query = getAnalyzeEventsFilteredQueryBuilder(params, datasetQueryBuilder)
    .selectAll()
    .where('jobId', '=', params.jobId);

  return query;
}

function getActualitySimulatedEventsTotalPart(
  params: ActualitySimulatedEventsQueryBuilderParams,
) {
  let query = getAnalyzeEventsFilteredQueryBuilder(params, datasetQueryBuilder)
    .select(({ fn }) => [fn.countAll().as('totalCount')])
    .where('jobId', '=', params.jobId);

  if (params.filterBy) {
    query = _.reduce(
      params.filterBy,
      (q, value, key) => {
        if (
          value?.type === StringSearchFilterType.CONTAINS &&
          value?.value === 'undefined'
        ) {
          return q.where(key as any, 'is', null);
        }

        if (
          value?.type === StringSearchFilterType.CONTAINS &&
          !_.isEmpty(value?.value)
        ) {
          return q.where(key as any, 'like', `%${value.value}%`);
        }
        return q;
      },
      query,
    );
  }
  return query;
}

export function getActualitySimulatedEventsQuery(
  params: ActualitySimulatedEventsQueryBuilderPagedParams,
) {
  let query = getActualitySimulatedEventSelectPart(params)
    .orderBy(
      params.sortBy?.field ?? 'eventDate',
      params.sortBy?.direction === SortDirection.ASC ? 'asc' : 'desc',
    )
    .$if(!_.isNil(params.page), db => db.offset(params.page.skip ?? 0))
    .$if(!_.isNil(params.page), db => db.limit(params.page.limit ?? 100));

  return query;
}

export function getActualitySimulatedEventsDataTotalQuery(
  params: ActualitySimulatedEventsQueryBuilderParams,
) {
  let query = getActualitySimulatedEventsTotalPart(params);

  return query;
}
