import _ from 'lodash';
import { HeatmapMetricRange } from '../../../common/heatmap.types';
import {
  OccupancyMetric,
  OccupancyMetricDescriptorBase,
} from './assignmentOccupancy.types';

export const occupancyMetricDescriptors: OccupancyMetricDescriptorBase[] = [
  {
    type: 'occupancy-volume-rate',
    path: 'occupancy',
    rangeType: 'custom-range',
  },
  {
    type: 'occupancy-rate',
    path: 'occupancyRate',
    rangeType: 'custom-range',
  },
  {
    type: 'utilization-rate',
    path: 'utilizationRate',
    rangeType: 'custom-range',
  },
  {
    type: 'utilization-max-uom-rate',
    path: 'utilizationMaxUOMRate',
    rangeType: 'custom-range',
  },
];

export const occupancyMetricDescriptorsMap = _.keyBy(
  occupancyMetricDescriptors,
  d => d.type,
) as Record<OccupancyMetric, OccupancyMetricDescriptorBase>;

export const occupancyMetricRange: HeatmapMetricRange = {
  rangeType: 'custom-range',
};
