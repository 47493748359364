import _ from 'lodash';
import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { TwTheme } from '../../../../../Tw';
import { getBestContrastColor } from '../../../../common/color.helper';
import { FormattedValueWithUnit } from '../../../../common/formatHelper';
import useFormatter from '../../../../common/useFormatter';
import ButtonSwitchMulti from '../../../../components/actions/ButtonSwitchMulti';
import { Stat } from '../../../../components/stats/Stat';
import { StatCenterPie } from '../../../../components/stats/StatCenterPie';
import { StatGroup } from '../../../../components/stats/StatGroup';
import { StatListItem } from '../../../../components/stats/StatListItem';
import LayoutStats from '../../../../layout/LayoutStats';
import { OccupancySummary } from '../../../store/occupancy/assignmentOccupancy.types';
import ContainerChart from '../../ContainerChart';
import SimulationPieChart, { PieData } from '../../SimulationPieChart';
import OccupancyModeSwitch from './OccupancyModeSwitch';

const colorsOccupancy = TwTheme.extend.colors.categoryOccupancy;

export type OccupancySummaryPanelProps = {
  summary: OccupancySummary;
  cardView?: boolean;
};

const OccupancySummaryPanel: React.FC<OccupancySummaryPanelProps> = props => {
  const formatter = useFormatter();
  const { t } = useTranslation('simulation');
  const [selectedSwitchOption, setSelectedSwitchOption] = useState<number>(0);
  const isPercentageView = selectedSwitchOption === 0;
  const { summary } = props;

  const metricDescriptors: Record<
    keyof OccupancySummary,
    { title: string; colors: [string, string]; chartMetrics?: boolean }
  > = {
    occupiedVolume: {
      title: t`Occupied Volume`,
      colors: [colorsOccupancy[100], getBestContrastColor(colorsOccupancy[90])],
      chartMetrics: true,
    },
    unOccupiedVolume: {
      title: t`Un-occupied Volume`,
      colors: [colorsOccupancy[10], getBestContrastColor(colorsOccupancy[80])],
      chartMetrics: true,
    },

    utilizedVolume: {
      title: t`Utilised Volume`,
      colors: [colorsOccupancy[70], getBestContrastColor(colorsOccupancy[70])],
      chartMetrics: false,
    },
    unUtilizedVolume: {
      title: t`Un-Utilised Volume`,
      colors: [colorsOccupancy[30], getBestContrastColor(colorsOccupancy[60])],
      chartMetrics: true,
    },

    unknownVolume: {
      title: t`Unknown Data`,
      colors: [
        colorsOccupancy['unknown'],
        getBestContrastColor(colorsOccupancy['unknown']),
      ],
      chartMetrics: true,
    },

    emptyVolume: {
      title: t`Empty Volume`,
      colors: [colorsOccupancy[0], getBestContrastColor(colorsOccupancy[0])],
      chartMetrics: true,
    },

    assignedVolume: {
      title: t`Assigned Volume`,
      colors: [colorsOccupancy[50], getBestContrastColor(colorsOccupancy[50])],
      chartMetrics: false,
    },

    totalStorageVolume: {
      title: t`Total Volume`,
      colors: [
        colorsOccupancy[100],
        getBestContrastColor(colorsOccupancy[100]),
      ],
      chartMetrics: false,
    },
  };

  const volumesFormated: Record<
    keyof OccupancySummary,
    FormattedValueWithUnit
  > = _.entries(summary).reduce(
    (acc, [key, value]) => ({
      ...acc,
      [key]: formatter.formatVolume(value),
    }),
    {} as Record<keyof OccupancySummary, FormattedValueWithUnit>,
  );

  const volumesShareFormated: Record<
    keyof OccupancySummary,
    FormattedValueWithUnit
  > = _.entries(summary).reduce(
    (acc, [key, value]) => ({
      ...acc,
      [key]: formatter.formatShare(value / summary.totalStorageVolume),
    }),
    {} as Record<keyof OccupancySummary, FormattedValueWithUnit>,
  );

  function getMetricValue(
    key: keyof OccupancySummary,
    isPercentageView: boolean,
  ): FormattedValueWithUnit {
    return (isPercentageView ? volumesShareFormated : volumesFormated)[key];
  }

  const occupancyShare = formatter.formatShare(
    summary.occupiedVolume / summary.utilizedVolume,
  );

  const utilizationShare = formatter.formatShare(
    summary.utilizedVolume / summary.assignedVolume,
  );

  const chartMetrics: (keyof OccupancySummary)[] = [
    'occupiedVolume',
    'unOccupiedVolume',
    'unUtilizedVolume',
    'unknownVolume',
    'emptyVolume',
  ];

  const chartData: PieData[] = _.map(chartMetrics, metricKey => ({
    id: metricKey,
    label: metricDescriptors[metricKey].title,
    color: metricDescriptors[metricKey].colors,
    value: getMetricValue(metricKey, isPercentageView).raw,
    formatted: getMetricValue(metricKey, isPercentageView),
  }));

  const legendOptions = [{ label: t`Vol. (%)` }, { label: t`Vol. (m³)` }];

  const legendItems = isPercentageView ? volumesShareFormated : volumesFormated;

  return (
    <>
      <StatGroup
        data-component="OccupancySummaryPanel"
        titleAction={
          <>
            <OccupancyModeSwitch />
          </>
        }
        // title={t`Occupancy`}
        subTitle={t`Stats`}
        cardView={props.cardView}
        helpNavTo={'simulation/compliance/simulation-compliance-volume'}
      >
        <StatGroup horizontal className="!my-0" classNameStatGroupItems="!py-0">
          <Stat
            title={t`Occupancy Rate`}
            unitOfMeasure={'%'}
            value={occupancyShare.value}

            // legendColor={colorsOccupancy[10]}
            // legendType={'circle'}
          />
          <Stat
            title={t`Utilisation Rate`}
            value={utilizationShare.value}
            unitOfMeasure={'%'}
            // legendColor={colorsOccupancy[10]}
            // legendType={'circle'}
          />
        </StatGroup>

        <Stat
          cardView={props.cardView}
          hasHelper
          isPreview
          title={t`Occupancy`}
          inPanelMode
          value={getMetricValue('occupiedVolume', isPercentageView).value}
          unitOfMeasure={
            getMetricValue('occupiedVolume', isPercentageView).unit
          }
          switchUnitOfMeasure={
            <ButtonSwitchMulti
              buttonType="minimal"
              autoSize
              className="mx-2"
              selectedIndex={selectedSwitchOption}
              onClick={setSelectedSwitchOption}
              options={legendOptions}
            />
          }
        >
          <ContainerChart>
            <StatCenterPie
              statLabelTop={t`Occupied`}
              statValueTop={
                getMetricValue('occupiedVolume', isPercentageView).value +
                (isPercentageView ? '%' : '')
              }
              statLabelBottom={t`Free`}
              statValueBottom={
                getMetricValue('unOccupiedVolume', isPercentageView).value +
                (isPercentageView ? '%' : '')
              }
            />
            <SimulationPieChart data={chartData} />
          </ContainerChart>

          {_.map(
            legendItems,
            (value, key) =>
              metricDescriptors[key].chartMetrics == true && (
                <StatListItem
                  key={key}
                  title={metricDescriptors[key].title}
                  unitOfMeasure={value.unit}
                  value={value.value}
                  legendColor={metricDescriptors[key].colors[0]}
                  legendType={'circle'}
                />
              ),
          )}
        </Stat>
      </StatGroup>
      <StatGroup
        horizontal
        className="!my-0 mt-0.5"
        classNameStatGroupItems="!py-0"
      >
        <Stat
          title={t`Total Volume`}
          value={getMetricValue('totalStorageVolume', isPercentageView).value}
          unitOfMeasure={
            getMetricValue('totalStorageVolume', isPercentageView).unit
          }
        />
        <Stat
          title={t`Allocated Volume`}
          value={getMetricValue('assignedVolume', isPercentageView).value}
          unitOfMeasure={
            getMetricValue('assignedVolume', isPercentageView).unit
          }
        />
      </StatGroup>
      <LayoutStats />
    </>
  );
};

export default OccupancySummaryPanel;
