import { InferResult } from 'kysely';
import _ from 'lodash';
import { HeatmapAggregationFn } from '../../../common/heatmap.types';
import {
  ActualityHqQueryBuilderParams,
  getHqQueryBuilder,
} from './actualityQueryBuilder';

const actualityHqAllFieldsStub = getHqQueryBuilder({} as any)
  .selectFrom('hq')
  .selectAll();

export type ActualityHqRows = InferResult<typeof actualityHqAllFieldsStub>;
export type ActualityHqRow = ActualityHqRows[number];
export type ActualityHqField = keyof ActualityHqRow;

export type ActualityMetricSummaryRow = {
  dimension: string;
  locationLevel: number;
  totalCount: number;
  totalEvents: number;
  totalLocations: number;
  totalExistedLocations: number;
  totalItems: number;
  totalAssignments: number;
};

export type GetActualityMetricSummaryParams = ActualityHqQueryBuilderParams & {
  groupBy: ActualityHqField;
  aggregationFn: HeatmapAggregationFn;
  aggregateBy: ActualityHqField;
};
export function getActualityMetricSummary(
  params: GetActualityMetricSummaryParams,
) {
  return getHqQueryBuilder(params)
    .with(
      cte => cte('aggregated'),
      db =>
        db
          .selectFrom('hq')
          // .$if(params.aggregationFn === 'max', db =>
          //   db.select(({ fn }) => fn.max(params.groupBy).as('dimensionValue')),
          // )
          .select(({ fn }) =>
            _.compact([
              'assignmentItem',
              'skuKey',
              'locationLevel',
              params.aggregationFn === 'max'
                ? fn.max(params.groupBy).as('dimensionValue')
                : null,
              params.aggregationFn === 'min'
                ? fn.min(params.groupBy).as('dimensionValue')
                : null,
              params.aggregationFn === 'avg'
                ? fn.avg(params.groupBy).as('dimensionValue')
                : null,
              params.aggregationFn === 'sum'
                ? fn.sum(params.groupBy).as('dimensionValue')
                : null,
              params.aggregationFn === 'count'
                ? fn.count('eventId').as('dimensionValue')
                : null,
              params.aggregationFn === 'count-locations'
                ? fn
                    .count<number>(params.groupBy)
                    .distinct()
                    .as('dimensionValue')
                : null,

              fn.countAll<number>().as('totalEvents'),
              fn.count<number>('locationId').distinct().as('totalLocations'),
              fn
                .count<number>('layoutLocationId')
                .distinct()
                .as('totalExistedLocations'),
              fn.count<number>('skuKey').distinct().as('totalItems'),
              fn
                .count<number>('assignmentItem')
                .distinct()
                .as('totalAssignments'),
            ]),
          )
          .where('eventType', 'not in', params.informationalEventTypes)
          .groupBy(['assignmentItem', 'skuKey', 'locationLevel']),
    )

    .selectFrom('aggregated')

    .select(({ fn }) =>
      _.compact([
        'dimensionValue as dimension',
        'locationLevel',
        fn.countAll<number>().as('totalCount'),
        fn.sum<number>('totalEvents').as('totalEvents'),
        fn.sum<number>('totalLocations').as('totalLocations'),
        fn.sum<number>('totalExistedLocations').as('totalExistedLocations'),
        fn.count<number>('skuKey').distinct().as('totalItems'),
        fn.count<number>('assignmentItem').distinct().as('totalAssignments'),
      ]),
    )
    .groupBy(['dimensionValue', 'locationLevel']);
}
