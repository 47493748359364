import { OptimizationSettingsFragment } from '@warebee/frontend/data-access-api-graphql';
import _ from 'lodash';
import React from 'react';
import { useTranslation } from 'react-i18next';
import useFormatter from '../../../common/useFormatter';
import { Stat } from '../../../components/stats/Stat';
import { StatGroup } from '../../../components/stats/StatGroup';

export type OptimizationSettingsStatsProps = {
  optimizationSettings: OptimizationSettingsFragment;
};

const OptimizationSettingsStats: React.FC<OptimizationSettingsStatsProps> = ({
  optimizationSettings: settings,
}) => {
  const { t } = useTranslation('simulation');
  const formatter = useFormatter();

  if (_.isNil(settings)) {
    return null;
  }

  const swapRulesCount = _.size(settings?.swapSettings?.rules);
  const loadbalancingRulesCount = _.size(settings?.loadBalancing?.rules);

  const hasLocationSizeConstraint =
    _.isNil(settings?.locationSizeConstraint) ||
    !settings?.locationSizeConstraint?.disabled;

  const hasBayConstraint =
    _.isNil(settings?.bayWidthConstraint) ||
    !settings?.bayWidthConstraint?.disabled;

  const hasPickabiltyConstraint =
    _.isNil(settings?.pickabilityConstraint) ||
    !settings?.pickabilityConstraint?.disabled;

  const reassignJobs = !settings?.reassignJobs?.disabled;
  const reassignMode = !settings?.reassignJobs?.cycleMode;

  const fields = [
    {
      title: t`Max: Moves`,
      value: settings?.limitSettings?.maxMoves,
    },
    {
      title: t`Max: Time limit`,
      value: settings?.terminationSettings?.timeLimit,
      formatter: formatter.formatTimeSpan,
    },
    {
      title: t`Max: Improvement Rate`,
      value:
        settings?.terminationSettings?.minImprovementRate?.minImprovementChange,
      formatter: formatter.formatShare,
    },
    {
      title: t`Max: Improvement Steps`,
      value: settings?.terminationSettings?.minImprovementRate?.steps,
      formatter: formatter.formatShare,
    },
    {
      title: t`Max: Target Improvement`,
      value: settings?.terminationSettings?.targetImprovement,
      formatter: formatter.formatShare,
    },
    {
      title: t`Swap Rules`,
      value:
        swapRulesCount === 0
          ? '—'
          : t(`{{swapRulesCount}}`, { swapRulesCount }),
    },
    {
      title: t`Load Balancing Rules`,
      value:
        loadbalancingRulesCount === 0
          ? '—'
          : t(`{{loadbalancingRulesCount}}`, {
              loadbalancingRulesCount,
            }),
    },
    {
      title: t`Pickability constraint`,
      value: hasPickabiltyConstraint ? t`Enabled` : t`Disabled`,
    },
    {
      title: t`Location Size Constraint`,
      value: hasLocationSizeConstraint ? t`Enabled` : t`Disabled`,
    },
    {
      title: t`Bay Width Constraint`,
      value: hasBayConstraint ? t`Enabled` : t`Disabled`,
    },
    {
      title: t`Reassign Jobs`,
      value: reassignJobs ? t`Enabled` : t`Disabled`,
    },
    {
      title: t`Reassign Mode`,
      value: reassignMode,
    },
  ];

  return (
    <StatGroup
      title={`Optimisation Settings`}
      className="p-0 pb-20"
      classNameTitle="!text-xs"
      classNameStatGroupItems="divide-y divide-menu-400/50 bg-app-panel-dark/60"
    >
      {_.map(fields, f => {
        let formatted = '—';
        if (!_.isNil(f.value)) {
          formatted = f.formatter
            ? f.formatter(f.value as any).fullString
            : (f.value as string);
        }
        return (
          <Stat
            key={f.title}
            isMetaValue
            className="px-2 py-2"
            title={f.title}
            value={formatted}
          />
        );
      })}
    </StatGroup>
  );
};

export default OptimizationSettingsStats;
