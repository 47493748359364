import { LoadBalancingZoneType } from '@warebee/frontend/data-access-api-graphql';
import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import DropdownSelector from '../components/actions/DropdownSelector';
import { StatGroup } from '../components/stats/StatGroup';
import AnalyzeResultLoadBalancingRule from './AnalyzeResultLoadBalancingRule';
import { AnalyzeComponentBaseProps } from './analyze.types';

const AnalyzeResultWorkload: React.FC<AnalyzeComponentBaseProps> = props => {
  const { t } = useTranslation('simulation');
  const [workloadType, setWorkloadType] = useState<LoadBalancingZoneType>(
    LoadBalancingZoneType.AISLE,
  );

  const kpiOptions: { key: LoadBalancingZoneType; label: string }[] = [
    {
      key: LoadBalancingZoneType.AISLE,
      label: t`Aisle`,
    },
    {
      key: LoadBalancingZoneType.CONGESTION_ZONE,
      label: t`Zone`,
    },
    {
      key: LoadBalancingZoneType.PLANE,
      label: t`Plane`,
    },
    {
      key: LoadBalancingZoneType.WAREHOUSE_AREA,
      label: t`Area`,
    },
  ];
  const selectedKPI = kpiOptions.find(o => o.key === workloadType);

  return (
    <StatGroup
      title={t`Workload`}
      subTitle={t`Stats`}
      //   helpNavTo={'simulation/optimise/simulation-optimise-summary-workload'}
      titleAction={
        // <ButtonSwitchMulti
        //   buttonType="header"
        //   autoSize
        //   options={kpiOptions}
        //   selectedIndex={_.findIndex(kpiOptions, o => o.key === workloadType)}
        //   onClick={index => {
        //     setWorkloadType(kpiOptions[index].key);
        //   }}
        // />

        <DropdownSelector
          className="rounded-full px-2 lg:ml-2"
          classNameDropdown="flex-1"
          // label={t`Labour as`}
          classNameLabel="text-xs opacity-80"
          classNameValue="text-sm"
          value={selectedKPI}
          values={kpiOptions}
          renderValue={item => item.label}
          onChange={item => {
            setWorkloadType(item.key);
          }}
        />
      }
    >
      <AnalyzeResultLoadBalancingRule
        analyzeId={props.analyzeResult.id}
        rule={{
          type: workloadType,
        }}
        series={'before'}
      />
    </StatGroup>
  );
};

export default AnalyzeResultWorkload;
