import {
  Field,
  GraphQLLocalDateTime,
  ID,
  ObjectType,
} from '@warebee/shared/util-backend-only-types';
import { AgentRoleSettings } from '../policies';

export class ScheduleGateUsageRequest {
  analyzeResultId: string;
  waitCost: number;
  startTime?: string;
  loadingAgentSettings: AgentRoleSettings;
}

@ObjectType()
export class GateUsageScheduleCost {
  @Field()
  tardiness: number;

  @Field()
  cost: number;
}

@ObjectType()
export class GateUsageScheduleJob {
  @Field(() => ID)
  id: string;

  @Field(() => GraphQLLocalDateTime, { nullable: true })
  deadline?: string;

  @Field()
  duration: number;
}

@ObjectType()
export class GateUsageScheduleJobAssignment {
  @Field(() => GateUsageScheduleJob)
  job: GateUsageScheduleJob;

  @Field(() => GraphQLLocalDateTime)
  startTime: string;

  @Field(() => GraphQLLocalDateTime)
  completionTime: string;
}

@ObjectType()
export class GateUsageScheduleResource {
  @Field(() => ID)
  id: string;

  @Field(() => [GateUsageScheduleJobAssignment])
  assignments: GateUsageScheduleJobAssignment[];
}

@ObjectType()
export class GateUsageSchedule {
  @Field(() => GraphQLLocalDateTime)
  startTime: string;

  @Field(() => GraphQLLocalDateTime)
  completionTime: string;

  @Field(() => [GraphQLLocalDateTime])
  deadlines: string[];

  @Field(() => [GateUsageScheduleResource])
  resources: GateUsageScheduleResource[];

  @Field(() => GateUsageScheduleCost)
  totalCost: GateUsageScheduleCost;

  @Field(() => GateUsageScheduleCost)
  totalCostWithoutWaiting: GateUsageScheduleCost;
}
