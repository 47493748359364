import {
  QueryDatasetQuery,
  QueryDatasetQueryVariables,
} from '@warebee/frontend/data-access-api-graphql';
import _ from 'lodash';
import { postProcessDatasetQuery } from './export-converter-utils';
import { getDataRows, getHeaderRow } from './export-converter.helper';
import { ColumnConfigBase, ExportConverter, ExportJobParams } from './types';

export type DatasetQueryExportJobParams<T> = ExportJobParams<
  QueryDatasetQueryVariables,
  DatasetQueryConverterConfig<T>
>;

export type DatasetQueryDataRow = Object;
export type DatasetQueryDataColumn = keyof DatasetQueryDataRow;

export type DatasetQueryConverterConfig<T> = {
  columnsConfig: ColumnConfigBase<T>[];
  dictionaries?: Record<string, Record<string, string>>;
  dictionariesDefault?: Record<string, string>;
};

export function getDatasetQueryDataRows<T>(
  data: T[],
  config: DatasetQueryConverterConfig<T>,
): T[] {
  if (_.isNil(config.dictionaries)) {
    return data;
  }

  return _.map(data, row => {
    const transformed = { ...row };

    _.forEach(config.dictionaries, (resolver, key) => {
      const value = transformed[key];
      if (!_.isNil(value) && typeof value === 'string') {
        transformed[key] =
          resolver[value] ?? config.dictionariesDefault?.[key] ?? value;
      }
    });
    return transformed;
  });
}

export const DATASET_QUERY_EXPORT_CONVERTER: ExportConverter<
  QueryDatasetQuery,
  DatasetQueryConverterConfig<Record<string, any>>
> = (input, config) => {
  const dataRaw = postProcessDatasetQuery<DatasetQueryDataRow>(
    input.warehouse?.datasetObjectQuery,
  );
  const data = getDatasetQueryDataRows(dataRaw, config);

  return [
    getHeaderRow(config.columnsConfig),
    getDataRows(data, config.columnsConfig),
  ];
};
