import classNames from 'classnames';
import React, { HTMLAttributes } from 'react';
import CopyToClipboardButton from '../../../components/actions/CopyToClipboardButton';
import * as Icon from '../../../components/icons';
import { StatProps } from '../../../components/stats/Stat';

export type StatInlineProps = {
  className?: string;
  id?: string;
  title?: string;
  titleAlt?: string;
  unitOfMeasure?: string;
  value?: string | number;
  transparent?: boolean;
  hasIssue?: boolean;
  canCopy?: boolean;
  truncate?: boolean;
  iconStat?: React.FC<HTMLAttributes<Element>>;
};

const UnitOfMeasure = ({ unitOfMeasure }: StatProps) => {
  return (
    <span
      data-component="unitOfMeasure"
      className={classNames('opacity-75 ltr:ml-px rtl:mr-px')}
    >
      {unitOfMeasure}
    </span>
  );
};

export const StatMicroInline: React.FC<StatInlineProps> = props => {
  return (
    <div
      data-component="StatMicroInline"
      title={props.titleAlt ? props.titleAlt : props.title}
      className={classNames(
        'group rounded p-1 text-xs',
        { 'bg-alerts-error': props.hasIssue },
        { 'bg-app-panel-dark/70': !props.transparent },
        props.className,
      )}
    >
      {props.hasIssue && (
        <Icon.TriangleInfo
          className={classNames('h-4 w-4', 'ltr:mr-2 rtl:ml-2', 'fill-current')}
        />
      )}
      <span data-component="StatValue">{props.value}</span>
      <UnitOfMeasure unitOfMeasure={props.unitOfMeasure} />
    </div>
  );
};

export const StatMiniInline: React.FC<StatInlineProps> = props => {
  const IconMenu = props.iconStat;
  return (
    <div
      data-component="StatMiniInline"
      data-title={props.titleAlt || ''}
      title={props.titleAlt ? props.titleAlt : props.title}
      className={classNames(
        'group',
        'relative',
        'flex flex-1 items-center',
        'p-1',
        'text-xs',
        'rounded',
        { 'bg-menu-active text-menu-active-text': props.hasIssue },
        {
          'bg-app-panel-dark/70 text-menu-text':
            !props.transparent && !props.hasIssue,
        },
        props.className,
      )}
    >
      {IconMenu && (
        <IconMenu
          className={classNames('fill-current', 'h-5 w-5', 'ltr:mr-2 rtl:ml-2')}
        />
      )}

      {props.hasIssue && (
        <Icon.TriangleInfo
          className={classNames('h-3 w-3 fill-current ltr:mr-2 rtl:ml-2')}
        />
      )}
      <label className={classNames('text-minimal uppercase opacity-60')}>
        {props.title}
      </label>
      <div
        className={classNames(
          'whitespace-nowrap text-xs ltr:ml-1 rtl:mr-1',
          props.truncate && 'truncate',
        )}
      >
        {props.value}
      </div>
      <UnitOfMeasure unitOfMeasure={props.unitOfMeasure} />

      {props.canCopy && (
        <CopyToClipboardButton
          hasIconSmall
          className={classNames(
            'hidden group-hover:flex',
            'absolute',
            'top-0',
            'right-0',
          )}
          value={`${props.title ? props.title + ':\n' : ''}${props.value}`}
        />
      )}
    </div>
  );
};

export const IssueMiniInline: React.FC<StatInlineProps> = props => {
  const IconMenu = props.iconStat;

  return (
    <div
      data-component="IssueMiniInline"
      title={props.titleAlt ? props.titleAlt : props.title}
      className={classNames(
        'flex flex-1 flex-row items-start xl:flex-col xl:items-center',
        'rounded',
        'p-1',
        'text-xs',
        'text-menu-text/90',
        {
          'bg-alerts-error group-hover:text-menu-text': !props.transparent,
        },
        props.className,
      )}
    >
      {props.canCopy && (
        <CopyToClipboardButton
          hasIconSmall
          className={classNames(
            'hidden group-hover:flex',
            'absolute',
            '-top-1',
            '-right-1',
          )}
          value={`${props.title ? props.title + ':\n' : ''}${props.value}`}
        />
      )}
      {IconMenu && (
        <IconMenu
          className={classNames('fill-current', 'h-4 w-4', 'ltr:mr-2 rtl:ml-2')}
        />
      )}

      {props.hasIssue && (
        <Icon.TriangleInfo
          className={classNames('h-3 w-3', 'ltr:mr-1 rtl:ml-1', 'fill-current')}
        />
      )}
      <div className={classNames('text-xxs opacity-60')}>{props.title}</div>
      <div
        className={classNames(
          'text-xs ltr:ml-1 rtl:mr-1',
          props.truncate && 'truncate',
        )}
      >
        {props.value}
      </div>
      {props.unitOfMeasure && (
        <UnitOfMeasure unitOfMeasure={props.unitOfMeasure} />
      )}
    </div>
  );
};

export type ContainerStatsMiniProps = {
  children: React.ReactNode;
  className?: string;
};

export const ContainerStatsMini: React.FC<ContainerStatsMiniProps> = props => {
  return (
    <div
      data-component="ContainerStatsMini"
      className={classNames(
        'flex flex-col',
        'flex-wrap',
        'gap-1.5',
        // 'space-x-0.5',
        // props.stats?.length > 2
        //   ? 'space-y-0.5 md:space-y-1'
        //   : 'md:space-y-0',
        'flex-1 md:flex-row',
        // 'ltr:pr-1 rtl:pl-1',
        'text-menu-text/30',
        props.className,
      )}
    >
      {props.children}
    </div>
  );
};
