import { ApolloQueryResult, useApolloClient } from '@apollo/client';
import {
  GetWarehousesDocument,
  GetWarehousesQuery,
  GetWarehousesQueryVariables,
} from '@warebee/frontend/data-access-api-graphql';
import _ from 'lodash';
import { nanoid } from 'nanoid';
import { useTranslation } from 'react-i18next';
import { useRecoilCallback } from 'recoil';
import useLoadWarehouseBillingState from '../../appBilling/hooks/useLoadWarehouseBillingState';
import { AsyncLoadStatus } from '../../common/types';
import {
  getDatasetConfigurations,
  schemaVersionKey,
} from '../../import/store/import.default';
import { mergeMappingSettings } from '../../import/store/import.helper';
import { importSettingsAtomAll } from '../../import/store/import.state';
import {
  DatasetType,
  ImportSettingsMap,
  MappingSettings,
} from '../../import/store/import.types';
import { errorAppender } from '../../store/error.state';
import { namedColors } from '../../store/namedColors.default';
import { namedColorsByWarehouse } from '../../store/namedColors.state';
import { WarehouseNamedColors } from '../../store/namedColors.types';
import {
  warehouseList,
  warehouseListLoadStatus,
} from '../../store/warehouse.state';
import { WarehouseExtraSettings } from '../../store/warehouse.types';

function useLoadWarehouses() {
  const client = useApolloClient();
  const { t: tImporter } = useTranslation('importer');
  const { t } = useTranslation('app');
  const loadBillingStates = useLoadWarehouseBillingState();

  const initLoad = useRecoilCallback(({ set }) => async () => {
    set(warehouseList, null);
    set(warehouseListLoadStatus, AsyncLoadStatus.Loading);
  });

  const defaultConfigs = getDatasetConfigurations(tImporter);

  const callLoad = useRecoilCallback(({ snapshot, set }) => async () => {
    let getWhResponse: ApolloQueryResult<GetWarehousesQuery>;
    try {
      getWhResponse = await client.query<
        GetWarehousesQuery,
        GetWarehousesQueryVariables
      >({
        query: GetWarehousesDocument,
      });
    } catch (ex) {
      console.error(ex);
      set(errorAppender, {
        id: nanoid(),
        title: t`Cannot load warehouses`,
        details: ex.message || ex,
        callStack: ex.stack || null,
      });
      set(warehouseListLoadStatus, AsyncLoadStatus.Error);
    }
    if (getWhResponse.errors) {
      console.error(getWhResponse.errors);
      set(errorAppender, {
        id: nanoid(),
        title: t`Cannot load warehouses`,
        details: null,
        callStack: getWhResponse.errors.map(e => e.message).join('. '),
      });
      set(warehouseListLoadStatus, AsyncLoadStatus.Error);
      return;
    }

    if (getWhResponse.data) {
      const result = getWhResponse.data.warehouses.content;
      set(warehouseList, result);
      set(warehouseListLoadStatus, AsyncLoadStatus.Ok);

      const importSettings = _.reduce(
        result,
        (acc, wh) => {
          const extraSettings: WarehouseExtraSettings = wh.extraSettings;

          const currentVersionSettings =
            extraSettings?.importSettings?.[schemaVersionKey];

          const merged = _.reduce(
            defaultConfigs,
            (acc, config) => {
              const type = config.dataType;
              return {
                ...acc,
                [type]: mergeMappingSettings(
                  config.mappingSettingsDefault,
                  currentVersionSettings?.[type],
                ),
              };
            },
            {} as Partial<Record<DatasetType, MappingSettings<object>>>,
          );
          return {
            ...acc,
            [wh.id]: merged,
          };
        },
        {} as ImportSettingsMap,
      );

      const namedColorsByWh: Record<string, WarehouseNamedColors> = _.reduce(
        result,
        (acc, wh) => {
          const extraSettings: WarehouseExtraSettings = wh.extraSettings;
          return {
            ...acc,
            [wh.id]: extraSettings?.namedColors ?? namedColors,
          };
        },
        {},
      );

      set(importSettingsAtomAll, importSettings);
      set(namedColorsByWarehouse, namedColorsByWh);
      return result;
    }
  });

  return async () => {
    await initLoad();
    const whs = await callLoad();
    await loadBillingStates(_.map(whs, wh => wh.id));
  };
}
export default useLoadWarehouses;
