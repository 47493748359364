import React, { Suspense } from 'react';
import { useTranslation } from 'react-i18next';
import { useRecoilState } from 'recoil';
import { Container } from '../../components/layout/ContainerFlex';
import { HelperLearnMore } from '../../components/layout/HelperLearnMore';
import LoadingIndicator from '../../components/LoadingIndicator';
import SidebarContainer, {
  SidebarContainerProps,
} from '../../containers/SidebarContainer';
import { HelperTemplate } from '../helper/HelperTemplate';
import SimulationDatasetPanelAssignment from '../panels/SimulationDatasetPanelAssignment';
import SimulationDatasetPanelItems from '../panels/SimulationDatasetPanelItems';
import SimulationDatasetPanelLayout from '../panels/SimulationDatasetPanelLayout';
import SimulationDatasetPanelOrders from '../panels/SimulationDatasetPanelOrders';
import {
  MenuViewMode,
  selectedViewModeAtom,
} from '../store/simulation.wizard.state';
import ActionBarNextStep from './ActionBarNextStep';

const SimulationSidebarDataset: React.FC = () => {
  const { t } = useTranslation('simulation');
  const [selectedMode] = useRecoilState(selectedViewModeAtom);

  return (
    <>
      <Container col hasOverflowY>
        {selectedMode === MenuViewMode.GUIDED && (
          <HelperTemplate
            title={t`About Simulation Dataset`}
            subtitle={t`Getting Started`}
            hasLearnMore={false}
            hasPadding
          >
            {t`Let's Get Started with the new Slotting Simulation Dataset and Policies`}
            <HelperLearnMore title={t`Learn More...`} />
          </HelperTemplate>
        )}

        <div className="space-y-4 pb-20">
          <Suspense
            fallback={
              <LoadingIndicator selfCenter message={t`Loading Dataset...`} />
            }
          >
            {/* <DataSettingSelector
              dataType="layout"
              datasetId={simulation.layout.id}
              onChange={id => updateSimulation({ layoutId: id })}
              viewMode="panel"
            /> */}

            <SimulationDatasetPanelLayout />
            <SimulationDatasetPanelAssignment />
            <SimulationDatasetPanelItems />
            <SimulationDatasetPanelOrders />
          </Suspense>

          {/* <DataSettingSelector dataType="items" onChange={updateSimulation} />
          <DataSettingSelector
            dataType="assignment"
            onChange={updateSimulation}
          <DataSettingSelector dataType="orders" onChange={updateSimulation} /> */}
        </div>
      </Container>
      <ActionBarNextStep title={t`Start`} />
    </>
  );
};

export default () => {
  const { t } = useTranslation('simulation');

  const containerProps: SidebarContainerProps = {
    type: 'sidebar-simulation-dataset',
    title: t`Dataset`,
    size: 'md',
  };

  return (
    <SidebarContainer key={containerProps.type} {...containerProps}>
      <SimulationSidebarDataset />
    </SidebarContainer>
  );
};
