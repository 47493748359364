import { ImportPipelineConnectorType } from '@warebee/frontend/data-access-api-graphql';
import classNames from 'classnames';
import _ from 'lodash';
import { nanoid } from 'nanoid';
import React, { Fragment, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useRecoilState, useRecoilValue, useSetRecoilState } from 'recoil';
import { cloneWithoutTypename } from '../../../common/utils';
import { Button } from '../../../components/actions/Button';
import ModalDialog from '../../../components/actions/ModalDialog';
import * as Icon from '../../../components/icons';
import InboxZero from '../../../components/InboxZero';
import KeyValueItem from '../../../components/KeyValueItem';
import TitleSection from '../../../components/layout/TitleSection';
import Callout from '../../../helpContext/Callout';
import {
  warehouseCanUpdate,
  warehouseSelected,
} from '../../../store/warehouse.state';
import useUpdateWarehouseExtraSettings from '../../../warehouse/hooks/useUpdateWarehouseExtraSettings';
import useUpdateImportPipeline from '../../hooks/useUpdateImportPipeline';
import { connectorNewDatasetKey } from '../../store/importPipeline.helper';
import {
  importPipelineIsActiveEditor,
  importPipelinesDataTableState,
  importPipelineSelected,
  importPipelineSelectedConnectorId,
  importPipelineSelectedId,
  importPipelineUpdateStatus,
} from '../../store/importPipelines.state';

export const PipelineConnectorSnowflake: React.FC = () => {
  const { t } = useTranslation('dataset');
  const wh = useRecoilValue(warehouseSelected);
  const pipeline = useRecoilValue(importPipelineSelected);
  // const [isLoading, setIsLoading] = useState(false);

  const setSelectedId = useSetRecoilState(importPipelineSelectedId);
  const setConnectorId = useSetRecoilState(importPipelineSelectedConnectorId);
  const [tableState, setTableState] = useRecoilState(
    importPipelinesDataTableState,
  );
  const updateStatus = useRecoilValue(importPipelineUpdateStatus);
  const setShowEditor = useSetRecoilState(importPipelineIsActiveEditor);

  const updatePipeline = useUpdateImportPipeline();

  const [query, setQuery] = useState(
    pipeline?.settings?.mappingSettings?.transformation?.query,
  );
  const [updateWhExtraSettings] = useUpdateWarehouseExtraSettings();

  const canUpdate = useRecoilValue(warehouseCanUpdate);
  const connectorId = useRecoilValue(importPipelineSelectedConnectorId);

  const [genLoading, setGenLoading] = useState(false);
  const [token, setToken] = useState<string>(null);

  const [isLoading, setIsLoading] = useState(false);
  const [error, setError] = useState<string>(null);

  function addDatasetConnector() {
    setConnectorId(connectorNewDatasetKey);
    setShowEditor(true);
  }
  const [isDialogOpen, setIsDialogOpen] = useState(false);

  function removeConnectorById(id: string) {
    const connectors = _(pipeline.settings?.integrationSettings?.connectors)
      .filter(c => c.id !== id)
      .map(cloneWithoutTypename)
      .value();

    updatePipeline({
      importPipelineId: pipeline.id,
      settings: {
        ...pipeline.settings,
        integrationSettings: {
          connectors,
        },
      },
    });
  }

  function removeAllConnectorsByType(type: ImportPipelineConnectorType) {
    const connectors = _(pipeline.settings?.integrationSettings?.connectors)
      .filter(c => c.type !== type)
      .map(cloneWithoutTypename)
      .value();

    updatePipeline({
      importPipelineId: pipeline.id,
      settings: {
        ...pipeline.settings,
        integrationSettings: {
          connectors,
        },
      },
    });
  }

  function addConnector(type: ImportPipelineConnectorType) {
    const connectors = _.map(
      pipeline.settings?.integrationSettings?.connectors,
      cloneWithoutTypename,
    );
    connectors.push({
      id: nanoid(10),
      type,
      enabled: true,
    });

    updatePipeline({
      importPipelineId: pipeline.id,
      settings: {
        ...pipeline.settings,
        integrationSettings: {
          connectors,
        },
      },
    });
  }

  const connectors = pipeline?.settings?.integrationSettings?.connectors;

  // Connector: S3 (Push)
  const s3Connectors = _.filter(
    connectors,
    c => c.type === ImportPipelineConnectorType.S3_PUSH,
  );
  const hasS3Connector = !_.isEmpty(s3Connectors);

  const styleApiText = classNames(
    'font-mono',
    'text-menu-text',
    'text-start',
    'break-all',
    'select-text',
    'w-full',
    'shadow',
    'rounded',
  );

  return (
    <>
      <TitleSection
        id={`pipeline-setting-group-s3-snowflake`}
        title={t`Connector: Snowflake S3 (Push)`}
        inPanelView
        // className={classNames('z-400 top-12 xl:top-20')}
        classNameInner="p-4 xl:p-6 pb-10 space-y-8"
        hasScreenTitle
        collapsible
        hasAction={
          <>
            {!hasS3Connector ? (
              <Button
                label={t`Add`}
                className={classNames('rounded ltr:ml-4 rtl:mr-4')}
                buttonSize="xs"
                buttonType="primary"
                hasIconAfter={
                  <Icon.CirclePlus
                    className={classNames('h-5 w-5 fill-current')}
                  />
                }
                onPress={() =>
                  addConnector(ImportPipelineConnectorType.S3_PUSH)
                }
              />
            ) : (
              <Icon.CircleOk className={classNames('h-6 w-6 fill-current')} />
            )}
          </>
        }
      >
        {/* <PolicyWell
          fullHeight
          className={classNames('flex-1')}
          classNameChildren={classNames('space-y-4')}
        > */}
        {_.isEmpty(s3Connectors) && <InboxZero message={t`No Connector`} />}
        {_.map(s3Connectors, c => {
          const connectorState = _.find(
            pipeline.connectorsState,
            cs => cs.id === c.id,
          );
          return (
            <Fragment key={c.id}>
              <Callout type="system">
                <div>{t`Snowflake command to export the data to WareBee`}</div>
                <div
                  className={classNames(
                    styleApiText,
                    'mt-4',
                    'text-sm',
                    // 'space-y-2',
                  )}
                >
                  <KeyValueItem
                    className={styleApiText}
                    keyName={t`-- SQL Snowflake Export with S3 WareBee endpoint`}
                    keyValue={`                    
-- Create a storage integration for S3, enabling access to an S3 bucket
CREATE STORAGE INTEGRATION WAREBEE_S3_STORAGE
  TYPE = EXTERNAL_STAGE
  STORAGE_PROVIDER = S3
  ENABLED = TRUE
  STORAGE_ALLOWED_LOCATIONS = ('${connectorState?.s3Push?.s3Url}');

-- Switch to the intended database and schema
USE DATABASE test;
USE SCHEMA public;

-- Setup file format for CSV data export
CREATE OR REPLACE FILE FORMAT warebee_data_export_format
  TYPE = 'CSV'
  -- Fields enclosed by double quotes
  FIELD_OPTIONALLY_ENCLOSED_BY = '"'
  COMPRESSION = 'NONE'
  ENCODING = UTF8
  -- Do not skip any header row
  SKIP_HEADER = 0
  -- Use comma as field delimiter                
  FIELD_DELIMITER = ','
   -- Treat 'NULL' and 'null' as null values         
  NULL_IF = ('NULL', 'null')
  -- Treat empty fields as null values
  EMPTY_FIELD_AS_NULL = TRUE;       

-- Setup WareBee Integration S3 stage
CREATE OR REPLACE STAGE warebee_s3_stage
  -- WareBee Extract S3 bucket URL
  URL='${connectorState?.s3Push?.s3Url}' 
  -- WareBee AWS S3 credentials
  CREDENTIALS=(AWS_KEY_ID='${connectorState?.s3Push?.awsCredentials?.accessKeyId}' AWS_SECRET_KEY='${connectorState?.s3Push?.awsCredentials?.secretAccessKey}')
  -- Use the previously defined file format
  FILE_FORMAT = (FORMAT_NAME = warebee_data_export_format);

-- Generate the file structure, and copy the data to the S3 bucket
-- 1. Replace WAREHOUSENAME with the name of the warehouse
-- 2. Replace DATATYPE with the type of data being exported
-- 3. Replace PUBLIC.TABLE_ORDERS with the source table/query

DECLARE
  file_path STRING;
BEGIN
  file_path := '@warebee_s3_stage/WAREBEE-WAREHOUSENAME-DATATYPE-' || TO_VARCHAR(CONVERT_TIMEZONE('UTC', CURRENT_TIMESTAMP()), 'YYYY-MM-DD-HH24MISS') || '.csv';
  

  EXECUTE IMMEDIATE '
    COPY INTO ' || :file_path || '
    -- Specify the source table/query
    FROM (
      SELECT * FROM PUBLIC.TABLE_ORDERS 
    )
    -- WareBee defined file format
    FILE_FORMAT = (FORMAT_NAME = warebee_data_export_format) 
    -- Overwrite existing files
    OVERWRITE = TRUE
    -- Export as a single file
    SINGLE = TRUE;      
END;

`}
                    isDisabled={true}
                    isCopyable
                  />
                </div>
              </Callout>
            </Fragment>
          );
        })}

        {hasS3Connector && (
          <Button
            label={t`Delete Snowflake Connection (S3)`}
            className={classNames('rounded')}
            buttonSize="xs"
            buttonType="delete"
            full
            hasIconAfter={
              <Icon.CircleX className={classNames('h-5 w-5 fill-current')} />
            }
            onPress={() => setIsDialogOpen(true)}
          />
        )}

        <ModalDialog
          title={t(
            `Are you sure you want to delete the Snowflake (S3) connector
          This action cannot be undone!`,
          )}
          okButtonTitle={t`Delete Snowflake (S3)`}
          okButtonType={'delete'}
          isOpen={isDialogOpen}
          setIsOpen={setIsDialogOpen}
          hasCancelButton
          onConfirm={async () =>
            removeAllConnectorsByType(ImportPipelineConnectorType.S3_PUSH)
          }
        />
      </TitleSection>
    </>
  );
};
