import { format } from 'date-fns';
import _ from 'lodash';
import { useTranslation } from 'react-i18next';
import { useRecoilValue } from 'recoil';
import { toLocalDateFromMs } from '../../common/dateTimeHelper';
import { formatInteger } from '../../common/formatHelper';
import useFormatter from '../../common/useFormatter';
import * as Icon from '../../components/icons';
import { actualityExtraMetricDescriptors } from '../../feed/store/actuality.heatmap.state';
import { actualityMetricDescriptorsMap } from './actualityMetric.default';
import { ActualityMetricDescriptor } from './actualityMetric.types';

function useActualityMetricDescriptorsMap() {
  const { t } = useTranslation('feed');
  const formatter = useFormatter();
  const extraDescriptors = useRecoilValue(actualityExtraMetricDescriptors);

  const metrics: ActualityMetricDescriptor[] = [
    // TIME BASED METRICS
    {
      ...actualityMetricDescriptorsMap.lastActivity,
      titleCategory: t('Time', { ns: 'simulation' }),
      title: t('Activity (Latest)', { ns: 'simulation' }),
      subTitle: t('Item', { ns: 'simulation' }),
      actionTitle: t('show Visits heatmap', { ns: 'simulation' }),
      icon: Icon.HeatMap9,
      format: ms =>
        ms > 0 ? format(toLocalDateFromMs(ms * 1000), 'dd MMM, HH:mm') : '-',
    },

    // VELOCITY METRICS
    {
      ...actualityMetricDescriptorsMap.visits,
      titleCategory: t('Velocity', { ns: 'simulation' }),
      title: t('Visits (All)', { ns: 'simulation' }),
      subTitle: t('Item', { ns: 'simulation' }),
      actionTitle: t('show Visits heatmap', { ns: 'simulation' }),
      icon: Icon.HeatMap9,
      format: formatInteger,
    },
    // {
    //   ...actualityMetricDescriptorsMap.visitsCurrent,
    //   titleCategory: t('Velocity', { ns: 'simulation' }),
    //   title: t('Visits', { ns: 'simulation' }),
    //   subTitle: t('Item', { ns: 'simulation' }),
    //   actionTitle: t('show Visits heatmap', { ns: 'simulation' }),
    //   icon: Icon.HeatMap9,
    //   format: formatInteger,
    // },
    {
      ...actualityMetricDescriptorsMap.abc,
      titleCategory: t('Velocity', { ns: 'simulation' }),
      title: t('ABC+', { ns: 'simulation' }),
      subTitle: t('Item', { ns: 'simulation' }),
      actionTitle: t(`show ABC heatmap`, { ns: 'simulation' }),
      icon: Icon.HeatMap4,
    },
    {
      ...actualityMetricDescriptorsMap.visitsReplenishment,
      titleCategory: t('Velocity', { ns: 'simulation' }),
      title: t('Replenishments', { ns: 'simulation' }),
      subTitle: t('Item', { ns: 'simulation' }),
      actionTitle: t('show Visits heatmap', { ns: 'simulation' }),
      icon: Icon.HeatMap9,
      format: formatInteger,
    },

    {
      ...actualityMetricDescriptorsMap.processType,
      titleCategory: t('Time', { ns: 'simulation' }),
      // titleCategory: t('By Process', { ns: 'simulation' }),
      title: t('Process (Latest)', { ns: 'simulation' }),
      subTitle: t('Item', { ns: 'simulation' }),
      actionTitle: t('show Visits heatmap', { ns: 'simulation' }),
      icon: Icon.HeatMap9,
    },

    // ITEMS METRICS
    {
      ...actualityMetricDescriptorsMap.consigneeSku,
      titleCategory: t('Item', { ns: 'simulation' }),
      title: t('SKU', { ns: 'simulation' }),
      subTitle: t('Item', { ns: 'simulation' }),
      actionTitle: t('show sku', { ns: 'simulation' }),
      icon: Icon.CongestionMap4,
    },
    {
      ...actualityMetricDescriptorsMap.consignee,
      titleCategory: t('Item', { ns: 'simulation' }),
      title: t('Consignee', { ns: 'simulation' }),
      subTitle: t('Item', { ns: 'simulation' }),
      actionTitle: t('show consignee', { ns: 'simulation' }),
      icon: Icon.CongestionMap4,
    },
    {
      ...actualityMetricDescriptorsMap.skuGroup,
      titleCategory: t('Item', { ns: 'simulation' }),
      title: t('Item Group', { ns: 'simulation' }),
      subTitle: t('Item', { ns: 'simulation' }),
      actionTitle: t('show Item (SKU) Group', { ns: 'simulation' }),
      icon: Icon.CongestionMap4,
    },
    {
      ...actualityMetricDescriptorsMap.subGroup,
      titleCategory: t('Item', { ns: 'simulation' }),
      title: t('Sub Group', { ns: 'simulation' }),
      subTitle: t('Item', { ns: 'simulation' }),
      actionTitle: t('show Sub Group', { ns: 'simulation' }),
      icon: Icon.CongestionMap4,
    },

    {
      ...actualityMetricDescriptorsMap.stockCategory,
      titleCategory: t('Item', { ns: 'simulation' }),
      title: t('Stock Category', { ns: 'simulation' }),
      subTitle: t('Stock Category', { ns: 'simulation' }),
      actionTitle: t('show tStock Category', { ns: 'simulation' }),
      icon: Icon.CongestionMap4,
    },
    {
      ...actualityMetricDescriptorsMap.transportClass,
      titleCategory: t('Item', { ns: 'simulation' }),
      title: t('Transport Class', { ns: 'simulation' }),
      subTitle: t('Transport Class', { ns: 'simulation' }),
      actionTitle: t('show Transport Class', { ns: 'simulation' }),
      icon: Icon.CongestionMap4,
    },
    {
      ...actualityMetricDescriptorsMap.storageClass,
      titleCategory: t('Item', { ns: 'simulation' }),
      title: t('Storage Class', { ns: 'simulation' }),
      subTitle: t('Storage Class', { ns: 'simulation' }),
      actionTitle: t('show Storage Class', { ns: 'simulation' }),
      icon: Icon.CongestionMap4,
    },
    {
      ...actualityMetricDescriptorsMap.pollutionClass,
      titleCategory: t('Item', { ns: 'simulation' }),
      title: t('Pollution Class', { ns: 'simulation' }),
      subTitle: t('Pollution Class', { ns: 'simulation' }),
      actionTitle: t('show pollution class', { ns: 'simulation' }),
      icon: Icon.CongestionMap4,
    },
    // LOCATION METRICS
    {
      ...actualityMetricDescriptorsMap.congestionZone,
      titleCategory: t('Location', { ns: 'simulation' }),
      title: t('Work Zone', { ns: 'simulation' }),
      subTitle: t('Location', { ns: 'simulation' }),
    },
    // {
    //   ...actualityMetricDescriptorsMap.locationBayType,
    //   titleCategory: t('Location', { ns: 'simulation' }),
    //   title: t('Bay Type', { ns: 'simulation' }),
    //   subTitle: t('Location', { ns: 'simulation' }),
    // },
    {
      ...actualityMetricDescriptorsMap.locationRackingType,
      titleCategory: t('Location', { ns: 'simulation' }),
      title: t('Location Type', { ns: 'simulation' }),
      subTitle: t('Location', { ns: 'simulation' }),
    },
    {
      ...actualityMetricDescriptorsMap.locationUsageType,
      titleCategory: t('Location', { ns: 'simulation' }),
      title: t('Usage Type', { ns: 'simulation' }),
      subTitle: t('Location', { ns: 'simulation' }),
    },
    {
      ...actualityMetricDescriptorsMap.locationOrder,
      titleCategory: t('Location', { ns: 'simulation' }),
      title: t('Picking Order', { ns: 'simulation' }),
      subTitle: t('Location', { ns: 'simulation' }),
    },
    // Item UOM METRICS
    {
      ...actualityMetricDescriptorsMap.uom,
      titleCategory: t('Item (UOM)', { ns: 'simulation' }),
      title: t('UOM Type', { ns: 'simulation' }),
      subTitle: t('Item', { ns: 'simulation' }),
      icon: Icon.CongestionMap4,
    },
    {
      ...actualityMetricDescriptorsMap.uomWeight,
      titleCategory: t('Item (UOM)', { ns: 'simulation' }),
      title: t('UOM Weight', { ns: 'simulation' }),
      subTitle: t('Item', { ns: 'simulation' }),
      format: v => formatter.formatWeight(v, 10).fullString,
      icon: Icon.CongestionMap4,
    },
    {
      ...actualityMetricDescriptorsMap.uomWeightPow,
      titleCategory: t('Item (UOM)', { ns: 'simulation' }),
      title: t('UOM Weight (Distinct)', { ns: 'simulation' }),
      subTitle: t('Item', { ns: 'simulation' }),
      format: v => formatter.formatWeight(v, 10).fullString,
      icon: Icon.CongestionMap4,
    },
    {
      ...actualityMetricDescriptorsMap.uomVolume,
      titleCategory: t('Item (UOM)', { ns: 'simulation' }),
      title: t('UOM Volume', { ns: 'simulation' }),
      subTitle: t('Item', { ns: 'simulation' }),
      format: v => formatter.formatVolume(v).fullString,
      icon: Icon.CongestionMap4,
    },

    {
      ...actualityMetricDescriptorsMap.pickByAssignment,
      titleCategory: t('Compliance', { ns: 'simulation' }),
      title: t('Assignment Compliance', { ns: 'simulation' }),
      subTitle: t('Item', { ns: 'simulation' }),
      icon: Icon.CongestionMap4,
    },
    // {
    //   ...actualityMetricDescriptorsMap.stockActivityTime,
    //   titleCategory: t('Inventory', { ns: 'simulation' }),
    //   title: t('Stock Duration', { ns: 'simulation' }),
    //   subTitle: t('Item', { ns: 'simulation' }),
    //   icon: Icon.CongestionMap4,
    // },

    // {
    //   ...actualityMetricDescriptorsMap.uomVolumePow,
    //   titleCategory: t('Item (UOM)', { ns: 'simulation' }),
    //   title: t('Base UOM Volume (Relative)', { ns: 'simulation' }),
    //   subTitle: t('Item', { ns: 'simulation' }),
    //   format: v => formatter.formatVolume(v).fullString,
    //   icon: Icon.CongestionMap4,
    // },
    // {
    //   ...actualityMetricDescriptorsMap.uomDensity,
    //   titleCategory: t('Item (UOM)', { ns: 'simulation' }),
    //   title: t('UOM Density', { ns: 'simulation' }),
    //   subTitle: t('Item', { ns: 'simulation' }),
    //   format: v => formatter.formatInteger(v).fullString,
    //   icon: Icon.CongestionMap4,
    // },
    // {
    //   ...actualityMetricDescriptorsMap.uomCapacity,
    //   titleCategory: t('Item (UOM)', { ns: 'simulation' }),
    //   title: t('UOM Capacity', { ns: 'simulation' }),
    //   subTitle: t('Item', { ns: 'simulation' }),
    //   format: v => formatter.formatInteger(v).fullString,
    //   icon: Icon.CongestionMap4,
    // },
    // {
    //   ...actualityMetricDescriptorsMap.uomProportionality,
    //   titleCategory: t('Item (UOM)', { ns: 'simulation' }),
    //   title: t('UOM Proportionality', { ns: 'simulation' }),
    //   subTitle: t('Item', { ns: 'simulation' }),
    //   format: v => formatter.formatInteger(v).fullString,
    //   icon: Icon.CongestionMap4,
    // },

    ...(extraDescriptors as any),
  ];

  return _.keyBy(metrics, d => d.type);
}

export default useActualityMetricDescriptorsMap;
