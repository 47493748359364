import {
  AssignmentOccupancyDataFragment,
  AssignmentOccupancyFragment,
  AssignmentOccupancyMetaFragment,
  AssignmentOccupancySummaryGroupBy,
  AssignmentOccupancySummaryGroupFragment,
  BatchJobStatus,
  LoadAssignmentOccupancyByGroupDocument,
  LoadAssignmentOccupancyByGroupQuery,
  LoadAssignmentOccupancyByGroupQueryVariables,
  LoadAssignmentOccupancyDocument,
  LoadAssignmentOccupancyQuery,
  LoadAssignmentOccupancyQueryVariables,
  LocationFilterUnionInput,
  SortDirection,
} from '@warebee/frontend/data-access-api-graphql';
import {
  VolumeComplianceDataColumn,
  VolumeComplianceDataRow,
} from '@warebee/shared/export-converter';
import _ from 'lodash';
import { atom, selector, selectorFamily } from 'recoil';
import { secureClient } from '../../../GraphQLClient';
import { AsyncLoadStatus, DataTableState } from '../../../common/types';
import {
  viewerSelectedBayIdAtom,
  viewerSelectedLevel,
  viewerSelectedPlaneId,
} from '../../../layout/viewer/store/viewer.state';
import {
  simulationCurrentId,
  simulationEffectiveAssignmentId,
} from '../simulation.state';
import {
  simulationComplianceTabKey,
  simulationWizardSelectedStepId,
} from '../simulation.wizard.state';
import { occupancyMetricDescriptorsMap } from './assignmentOccupancy.defaults';
import {
  categoryOccupancyWidths,
  loadOccupancyHeatmap,
} from './assignmentOccupancy.helper';
import {
  AssignmentOccupancyViewMode,
  OccupancyCategoryFilter,
  OccupancyMetric,
  OccupancyMetricDescriptorBase,
  OccupancyMode,
} from './assignmentOccupancy.types';

const getKey = (postfix: string) => `warebee-assignment-occupancy-${postfix}`;

export const assignmentOccupancyMode = atom<OccupancyMode>({
  key: getKey('mode'),
  default: 'volume',
});

export const assignmentOccupancyMetricSelected = atom<OccupancyMetric>({
  key: getKey('metric-selected'),
  default: 'occupancy-rate',
});

export const assignmentOccupancyMetricDescriptorSelected =
  selector<OccupancyMetricDescriptorBase>({
    key: getKey('metric-descriptor-selected'),
    get: ({ get }) => {
      const m = get(assignmentOccupancyMetricSelected);
      return occupancyMetricDescriptorsMap[m];
    },
  });

export const assignmentOccupancyMetaAtom = atom<
  Record<string, AssignmentOccupancyMetaFragment>
>({
  key: getKey('document-atom'),
  default: null,
});

export const assignmentOccupancyMeta = selectorFamily<
  AssignmentOccupancyMetaFragment,
  string
>({
  key: getKey('document-atom'),
  get:
    (assignmentId: string) =>
    ({ get }) =>
      get(assignmentOccupancyMetaAtom)?.[assignmentId],
  set:
    (assignmentId: string) =>
    ({ get, set }, value: AssignmentOccupancyMetaFragment) =>
      set(assignmentOccupancyMetaAtom, current => ({
        ...current,
        [assignmentId]: value,
      })),
});

export const assignmentOccupancyStatus = selectorFamily<BatchJobStatus, string>(
  {
    key: getKey('document-status'),
    get:
      (assignmentId: string) =>
      ({ get }) =>
        get(assignmentOccupancyMeta(assignmentId))?.status,
    set:
      (assignmentId: string) =>
      ({ get, set }, value: BatchJobStatus) => {
        set(assignmentOccupancyMeta(assignmentId), current => ({
          ...current,
          status: value,
        }));
      },
  },
);

export const assignmentOccupancyMetaLoadStatusAtom = atom<
  Record<string, AsyncLoadStatus>
>({
  key: getKey('summary-load-status-atom'),
  default: {},
});

export const assignmentOccupancyMetaLoadStatus = selectorFamily<
  AsyncLoadStatus,
  string
>({
  key: getKey('summary-load-status'),
  get:
    (assignmentId: string) =>
    ({ get }) =>
      get(assignmentOccupancyMetaLoadStatusAtom)?.[assignmentId],
  set:
    (assignmentId: string) =>
    ({ get, set }, value: AsyncLoadStatus) =>
      set(assignmentOccupancyMetaLoadStatusAtom, current => ({
        ...current,
        [assignmentId]: value,
      })),
});

export const assignmentOccupancyCategoryFilter = atom<
  Partial<OccupancyCategoryFilter>
>({
  key: getKey('filtered-categories'),
  default: Object.create(null),
});

export const assignmentOccupancyLevelLocation = selector<
  Record<string, VolumeComplianceDataRow[]>
>({
  key: getKey('locations-size-by-level'),
  get: async ({ get }) => {
    const simulationId = get(simulationCurrentId);
    const assignmentId = get(simulationEffectiveAssignmentId);
    const status = get(assignmentOccupancyStatus(assignmentId));
    const areaId = get(viewerSelectedPlaneId);
    const level = get(viewerSelectedLevel);
    const stepId = get(simulationWizardSelectedStepId);
    const tab = get(simulationComplianceTabKey);
    const locationsMatch = get(assignmentOccupancyLocationFilter);

    if (
      status !== BatchJobStatus.READY ||
      _.isNil(level) ||
      stepId !== 'compliance' ||
      tab !== 'tab-compliance-volume'
    )
      return null;

    const locationMap = await loadOccupancyHeatmap({
      simulationId,
      assignmentId,
      filter: {
        planeId: [areaId],
        level: [level],
        locationsMatch,
      },
    });
    return locationMap;
  },
});

export const assignmentOccupancyBayLocation = selector<
  Record<string, VolumeComplianceDataRow[]>
>({
  key: getKey('locations-size-by-bay'),
  get: async ({ get }) => {
    const simulationId = get(simulationCurrentId);
    const assignmentId = get(simulationEffectiveAssignmentId);
    const status = get(assignmentOccupancyStatus(assignmentId));
    const areaId = get(viewerSelectedPlaneId);
    const bayId = get(viewerSelectedBayIdAtom);
    const stepId = get(simulationWizardSelectedStepId);
    const tab = get(simulationComplianceTabKey);
    const locationsMatch = get(assignmentOccupancyLocationFilter);

    if (
      status !== BatchJobStatus.READY ||
      _.isNil(bayId) ||
      stepId !== 'compliance' ||
      tab !== 'tab-compliance-volume'
    ) {
      return null;
    }
    const locationMap = await loadOccupancyHeatmap({
      simulationId,
      assignmentId,
      filter: {
        planeId: [areaId],
        bayId: [bayId],
        locationsMatch,
      },
    });
    return locationMap;
  },
});

export const assignmentOccupancyCategories =
  selector<AssignmentOccupancyFragment>({
    key: getKey('categories-summary'),
    get: async ({ get }) => {
      const simulationId = get(simulationCurrentId);
      const assignmentId = get(simulationEffectiveAssignmentId);
      const status = get(assignmentOccupancyStatus(assignmentId));
      const stepId = get(simulationWizardSelectedStepId);

      const tab = get(simulationComplianceTabKey);

      if (
        status !== BatchJobStatus.READY ||
        stepId !== 'compliance' ||
        tab !== 'tab-compliance-volume'
      ) {
        return null;
      }

      const response = await secureClient.query<
        LoadAssignmentOccupancyQuery,
        LoadAssignmentOccupancyQueryVariables
      >({
        query: LoadAssignmentOccupancyDocument,
        variables: {
          simulationId,
          assignmentId,
          categoryWidths: categoryOccupancyWidths,
        },
      });
      return response.data?.simulation.assignmentOccupancy;
    },
  });

export const assignmentOccupancyShowHeatmap = atom<boolean>({
  key: getKey('show-heatmap'),
  default: true,
});

export const assignmentOccupancyShowHeatmapLegend = atom<boolean>({
  key: getKey('show-heatmap-legend'),
  default: true,
});

export const assignmentOccupancyDataTableState = atom<
  DataTableState<VolumeComplianceDataColumn>
>({
  key: getKey('data-table-state'),
  default: {
    searchValues: {},
    sortValues: {
      locationId: SortDirection.ASC,
    },
  },
});

export const assignmentOccupancyLocationsData =
  atom<AssignmentOccupancyDataFragment>({
    key: getKey('table-data'),
    default: null,
  });

export const assignmentOccupancyLocationsDataLoadStatus = atom<AsyncLoadStatus>(
  {
    key: getKey('table-data-load-status'),
    default: AsyncLoadStatus.None,
  },
);

export const assignmentOccupancyDrillDownSelected =
  atom<AssignmentOccupancySummaryGroupBy>({
    key: getKey('drill-down'),
    default: AssignmentOccupancySummaryGroupBy.STATUS,
  });

export const assignmentOccupancyGroups = selector<
  AssignmentOccupancySummaryGroupFragment[]
>({
  key: getKey('by-group'),
  get: async ({ get }) => {
    const errorMsg = 'Cannot load occupancy by group';
    const simulationId = get(simulationCurrentId);
    const assignmentId = get(simulationEffectiveAssignmentId);
    const status = get(assignmentOccupancyStatus(assignmentId));
    const drill = get(assignmentOccupancyDrillDownSelected);
    const stepId = get(simulationWizardSelectedStepId);
    const locationsMatch = get(assignmentOccupancyLocationFilter);
    const tab = get(simulationComplianceTabKey);

    if (
      status !== BatchJobStatus.READY ||
      stepId !== 'compliance' ||
      tab !== 'tab-compliance-volume'
    ) {
      return null;
    }

    try {
      const response = await secureClient.query<
        LoadAssignmentOccupancyByGroupQuery,
        LoadAssignmentOccupancyByGroupQueryVariables
      >({
        query: LoadAssignmentOccupancyByGroupDocument,
        variables: {
          simulationId,
          assignmentId,
          groupBy: [drill],
          filter: {
            locationsMatch,
          },
        },
      });

      if (!_.isEmpty(response.errors)) {
        console.error(errorMsg, response.errors);
        throw new Error(errorMsg);
      }
      return response.data?.simulation?.assignmentOccupancy?.summaryByGroup;
    } catch (ex) {
      console.error(errorMsg, ex);
      throw new Error(errorMsg);
    }
  },
});

export const assignmentOccupancyViewMode = atom<AssignmentOccupancyViewMode>({
  key: getKey('view-mode'),
  default: 'summary',
});

export const assignmentOccupancyLocationFilter = atom<LocationFilterUnionInput>(
  {
    key: getKey('filter'),
    default: {
      anyOf: [
        {
          id: 'assignment-occupancy-filter',
          allOf: [],
        },
      ],
    },
  },
);
