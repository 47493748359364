import { ImportItemSetLineInput } from '@warebee/shared/data-access-api-dto';
import { TFunction } from 'i18next';
import { has } from 'lodash';
import { MappingSchema } from '../mapping';
import {
  BusinessLogicValidationError,
  BusinessLogicValidator,
} from '../validator';

export function isBaseUomLine(line: ImportItemSetLineInput) {
  return line.uom && (!line.lowerUom || line.lowerUom == line.uom);
}

class ItemSetBusinessLogicValidator
  implements BusinessLogicValidator<ImportItemSetLineInput>
{
  private itemsKeyMap = {};

  validateRow(
    row: ImportItemSetLineInput,
    t: TFunction<'importer', undefined>,
  ): BusinessLogicValidationError[] {
    const { consignee, sku, uom } = row;
    const itemKey = `${consignee}-${sku}-${uom}`;
    if (has(this.itemsKeyMap, itemKey)) {
      const message = t(
        t`Duplicates item(s) found: \n
          Consignee: {{consignee}}, \n
          SKU: {{sku}},\n
          UOM: {{uom}}\n`,
        {
          consignee,
          sku,
          uom,
        },
      );
      return [
        {
          type: t`Item duplication error`,
          message,
        },
      ];
    } else {
      this.itemsKeyMap[itemKey] = 1;
    }

    if (isBaseUomLine(row)) {
      if (row.unitsPerLowestUom != null && row.unitsPerLowestUom != 1) {
        return [
          {
            type: t`Invalid units number`,
            message: t(
              'Invalid units number ({{unitsPerLowestUom}}) for base UOM {{consignee}}|{{sku}} {{uom}} {{lowerUom}}',
              {
                unitsPerLowestUom: row.unitsPerLowestUom,
                consignee: row.consignee,
                sku: row.sku,
                uom: row.uom,
                lowerUom: row.lowerUom,
              },
            ),
          },
        ];
      }
    }

    return [];
  }
}

export const ITEM_SET_MAPPING_SCHEMA: MappingSchema<ImportItemSetLineInput> = {
  fields: [
    {
      name: 'consignee',
      type: 'string',
    },
    {
      name: 'sku',
      type: 'string',
    },
    {
      name: 'skuGroup',
      type: 'string',
      optional: true,
    },
    {
      name: 'uom',
      type: 'string',
      optional: false,
    },
    {
      name: 'lowerUom',
      type: 'string',
      optional: true,
    },
    {
      name: 'unitsPerLowestUom',
      type: 'number',
      optional: true,
    },
    {
      name: 'netWeight',
      type: 'number',
      optional: true,
      measureType: 'weight',
    },
    {
      name: 'length',
      type: 'number',
      optional: true,
      measureType: 'size',
    },
    {
      name: 'width',
      type: 'number',
      optional: true,
      measureType: 'size',
    },
    {
      name: 'height',
      type: 'number',
      optional: true,
      measureType: 'size',
    },
    {
      name: 'volume',
      type: 'number',
      optional: true,
      measureType: 'volume',
    },
    {
      name: 'ean',
      type: 'string',
      optional: true,
    },
    {
      name: 'upc',
      type: 'string',
      optional: true,
    },
    {
      name: 'name',
      type: 'string',
      optional: true,
    },

    {
      name: 'description',
      type: 'string',
      optional: true,
    },

    {
      name: 'subGroup',
      type: 'string',
      optional: true,
    },

    {
      name: 'transportClass',
      type: 'string',
      optional: true,
    },
    {
      name: 'stockCategory',
      type: 'string',
      optional: true,
    },
    {
      name: 'storageClass',
      type: 'string',
      optional: true,
    },
    {
      name: 'pollutionClass',
      type: 'string',
      optional: true,
    },
    {
      name: 'maxStack',
      type: 'integer',
      optional: true,
    },
  ],
  createValidator: () => new ItemSetBusinessLogicValidator(),
};
