import { InferResult, sql } from 'kysely';
import {
  ActualityHqQueryBuilderParams,
  getHqQueryBuilder,
} from './actualityQueryBuilder';
import { ActivityFeedEvent } from './queryBuilder/feedEventsQueryBuilders';

export type FeedAggregatedEvents = InferResult<
  ReturnType<typeof getFeedAggregatedEventsQuery>
>;
export type FeedAggregatedEvent = FeedAggregatedEvents[number];

export type FeedAggregatedEventsQueryParams = ActualityHqQueryBuilderParams & {
  groupBy: keyof ActivityFeedEvent;
};

export function getFeedAggregatedEventsQuery(
  params: FeedAggregatedEventsQueryParams,
) {
  return getHqQueryBuilder(params)
    .selectFrom('hq')
    .select(({ fn }) => [
      sql.ref<string>(params.groupBy).as('groupKey'),

      sql<string>`date_trunc('hour' , ${sql.ref('eventEndTime')})`.as(
        'eventHour',
      ),
      fn.count<number>('eventId').as('eventCount'),
      fn.sum<number>('quantity').as('uomCount'),
      fn
        .agg<string>('array_agg', [
          sql<string>`concat('"', ${sql.ref('jobId')},'"')`,
        ])
        .distinct()
        .as('jobIds'),
    ])
    .where('locationId', 'is not', null)
    .groupBy(params.groupBy)
    .groupBy(sql<string>`date_trunc('hour' , ${sql.ref('eventEndTime')})`);
}
