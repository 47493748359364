import { ImportJobImportType } from '@warebee/frontend/data-access-api-graphql';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { HeaderAppMenu } from '../components/headers/HeaderAppMenu';
import { Container } from '../components/layout/ContainerFlex';
import ImportJobsTable from './ImportJobsTable';

export type ImportJobsViewProps = {
  documentId?: string;
  documentImportType: ImportJobImportType;
};

const ImportJobsView: React.FC<ImportJobsViewProps> = props => {
  const { t } = useTranslation('dataset');

  return (
    <Container col overflow flex1 componentName="ImportJobsView">
      <HeaderAppMenu
        title={t`Import Jobs`}
        // filterPlaceholder={<AssignmentFilterTag />}
      />
      <ImportJobsTable
        datasetType={props.documentImportType}
        targetId={props.documentId}
      />
    </Container>
  );
};

export default ImportJobsView;
