import {
  AssignmentOccupancyLocationDetailsFragment,
  AssignmentOccupancyLocationFragment,
  AssignmentOccupancyLocationItemFragment,
  AssignmentOccupancyLocationStatus,
  LoadAssignmentOccupancyLocationsQuery,
  LoadAssignmentOccupancyLocationsQueryVariables,
} from '@warebee/frontend/data-access-api-graphql';
import _ from 'lodash';
import { getDataRows, getHeaderRow } from './export-converter.helper';
import { ColumnConfigBase, ExportConverter, ExportJobParams } from './types';

export type VolumeComplianceExportJobParams = ExportJobParams<
  LoadAssignmentOccupancyLocationsQueryVariables,
  VolumeComplianceConverterConfig
>;

export const volumeComplianceExportJobParams: VolumeComplianceExportJobParams =
  {
    converterId: 'VOLUME_COMPLIANCE',
    query: null,
    config: null,
    variables: null,
    filename: 'volume-compliance.csv',
  };

export type VolumeComplianceDataRow = AssignmentOccupancyLocationItemFragment &
  AssignmentOccupancyLocationFragment & {
    locationVolume: number;
    occupiedVolume: number;
    occupancy: number;
    locationStatus: AssignmentOccupancyLocationStatus;
    occupancyRate: number;
    utilizationRate: number;
    utilizationMaxUOMRate: number;
    locationPlaneTitle: string;
    locationBayTitle: string;
    locationAisleTitle: string;
  };

export type VolumeComplianceDataColumn = keyof VolumeComplianceDataRow;

export type VolumeComplianceConverterConfig = {
  columnsConfig: ColumnConfigBase<VolumeComplianceDataRow>[];
  dictionaries: {
    // status: Record<VolumeComplianceItemStatus, string>;
    // rules: Record<string, string>;
  };
};

function rate(volume: number, capacity: number) {
  if (
    _.isNil(capacity) ||
    _.isNil(volume) ||
    capacity === 0 ||
    _.isNaN(volume) ||
    _.isNaN(capacity)
  ) {
    return null;
  }
  return volume / capacity;
}

export const getVolumeComplianceTableRows = (
  data: AssignmentOccupancyLocationDetailsFragment[],
  // config: VolumeComplianceConverterConfig,
): VolumeComplianceDataRow[] => {
  const reduceLocation = (
    locDetails: AssignmentOccupancyLocationDetailsFragment,
  ) => {
    const loc = locDetails.location;

    const details = _.pick(locDetails, [
      'locationVolume',
      'occupiedVolume',
      'occupancy',
    ]);

    return _.map(
      locDetails.items,
      item =>
        ({
          ...item,
          ...loc,
          ...details,
          locationPlaneTitle: loc.plane.title,
          locationBayTitle: loc.bay.title,
          locationAisleTitle: loc.aisle.title,
          locationStatus: locDetails.status,
          occupancyRate: rate(
            item.occupiedVolume,
            item.stockUomCapacity?.occupiedVolume,
          ),
          utilizationRate: rate(
            item.stockUomCapacity?.occupiedVolume,
            details.locationVolume,
          ),
          utilizationMaxUOMRate: rate(
            item.maxUomCapacity?.occupiedVolume,
            details.locationVolume,
          ),
        }) as VolumeComplianceDataRow,
    );
  };

  return _.flatMap(data, reduceLocation);
};

export const VOLUME_COMPLIANCE_EXPORT_CONVERTER: ExportConverter<
  LoadAssignmentOccupancyLocationsQuery,
  VolumeComplianceConverterConfig
> = (input, config) => {
  const data = getVolumeComplianceTableRows(
    input.simulation?.assignmentOccupancy?.locations?.content,
    // config,
  );
  return [
    getHeaderRow(config.columnsConfig),
    getDataRows(data, config.columnsConfig),
  ];
};
