import _ from 'lodash';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { useRecoilState } from 'recoil';
import { cn } from '../../common/utils';
import DropdownSelector from '../../components/actions/DropdownSelector';
import * as Icon from '../../components/icons';
import useActualityDatasetTableTypes from '../hooks/useActualityDatasetTableTypes';
import { actualityDataSetTableTypeSelected } from '../store/actuality.state';
import { ActualityEntityType } from '../store/actuality.types';

type ActualityEntityTagProps = {
  entity: ActualityEntityType;
  title: string;
  filters: Record<string, string>;
  color?: string;
  className?: string;
};

const ActualityEntityTag: React.FC<ActualityEntityTagProps> = props => {
  const { t } = useTranslation('feed');
  const [selectedTableType, setSelectedTableType] = useRecoilState(
    actualityDataSetTableTypeSelected,
  );
  // const [feedTableState, setFeedTableState] = useRecoilState(
  //   actualityHqTableDataState,
  // );

  const tableDescriptorsMap = useActualityDatasetTableTypes();
  const applicableTables = _(tableDescriptorsMap)
    .filter(d => d.entities.has(props.entity))
    .value();
  const applicableTablesMap = _.keyBy(applicableTables, 'id');

  // const isSelectedActivityFeed =
  //   selectedTableType === 'activity-feed' &&
  //   _.isEqual(feedTableState?.searchValues, props.filters);

  const iconStyle = 'h-4 w-4 mx-1';

  return (
    <div
      data-component="ActualityEntityTag"
      className={cn(
        'group/itemdatatag',
        'bg-menu/30 hover/itemdatatag:bg-menu/90',
        'hover/itemdatatag:shadow-xl',
        'rounded',
        'flex-1',
        'w-full',
        'text-sm',
        'flex items-center',
        'group-hover/itemdatatag:text-menu-active cursor-pointer',
        'whitespace-nowrap',
        props.className,
      )}
    >
      <div className="flex w-full items-center">
        {!_.isNil(props.color) && (
          <div
            data-component="legend"
            className={cn('h-6 w-1 flex-shrink-0 rounded-sm')}
            style={{
              backgroundColor: props.color,
            }}
          />
        )}

        <DropdownSelector
          className="text-xxs flex-shrink-0"
          classNameLabel="text-minimal"
          classNameItem="text-xxs"
          onClick={e => {
            e.stopPropagation();
          }}
          DropAlignLeft
          buttonTransparent
          vertical
          panelMode
          iconDropMenu={Icon.MenuDotsHorizontal}
          iconSelectionClass={iconStyle}
          value={'...'}
          values={_.keys(applicableTablesMap)}
          renderValue={v => applicableTablesMap[v].shortTitle}
          onChange={async (v, e) => {
            e.stopPropagation();
            setSelectedTableType(applicableTablesMap[v].id);
            applicableTablesMap[v].setFilter({
              searchValues: props.filters,
              sortValues: {},
            });
          }}
        />

        <span
          className={cn(
            'flex-1',
            'truncate',
            'ltr:ml-1 rtl:mr-1',
            'ltr:mr-4 rtl:ml-4',
            'min-w-0',
          )}
        >
          {props.title}
        </span>
      </div>
    </div>
  );
};

export default ActualityEntityTag;
