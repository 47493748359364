import _ from 'lodash';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { formatInteger } from '../../common/formatHelper';
import { cn } from '../../common/utils';
import * as Icon from '../icons';

type UOMBreakdown = {
  uom: string;
  uomQuantity?: number;
  className?: string;
};

export type JobsDetailsUOMProps = {
  title: string;
  taskCount: number;
  taskType?: 'add' | 'remove';
  breakdownByUom: UOMBreakdown[];
};

export const UOMDetails: React.FC<UOMBreakdown> = props => {
  return (
    <span
      className={cn('flex items-center', 'w-full', 'mx-1', props.className)}
    >
      <span
        className={cn(
          'border-menu rounded border',
          'bg-app-panel/20',
          'rounded',
          'p-1 px-1',
          'text-end text-xs',
          'ltr:mr-2 ltr:pr-2 rtl:ml-2 rtl:pl-2',
        )}
      >
        {props.uomQuantity && (
          <span className="mx-1">{formatInteger(props.uomQuantity)}</span>
        )}
        <span className="mx-0.5">
          {props.uom}
          {/* {`(s)`} */}
        </span>
      </span>
    </span>
  );
};

const JobsDetailsUOM: React.FC<JobsDetailsUOMProps> = props => {
  const { t } = useTranslation('app');
  const type = props.taskType ?? 'add';
  const ActionIcon =
    type === 'add' ? Icon.MoveTypeMovePut : Icon.MoveTypeMoveTake;

  // Group by UOM and sum quantities within each group
  const groupedByUom = _.groupBy(props.breakdownByUom, 'uom');
  const totalUOMQuantity = Object.values(groupedByUom)
    .map(group => _.sumBy(group, 'uomQuantity'))
    .reduce((a, b) => a + b, 0);

  return (
    <div
      data-component="JobsDetailsUOM"
      className={cn('mb-4 flex items-center')}
    >
      <div className={cn('flex flex-1 flex-col')}>
        <div
          className={cn(
            'border-menu border-b',
            'text-xxs lg:text-xs',
            'text-menu-text/60',
            'mb-1 mt-2',
            'flex items-center',
            'py-1',
          )}
        >
          <ActionIcon
            className={cn(
              'h-5 w-5',
              'mx-1',
              'fill-current',
              'text-menu-text/80',
            )}
          />
          <div className={cn('flex-1', 'space-x-1')}>
            <span
              className={cn(
                'border-menu rounded border',
                'bg-app-panel/50',
                'p-1 px-2',
                'text-end',
                'text-xs',
                'space-x-1',
              )}
            >
              {formatInteger(props.taskCount)}
            </span>
            <span className={cn('text-xs uppercase')}>{props.title}</span>
          </div>

          <div
            className={cn(
              'border-menu rounded border',
              'bg-app-panel/50',
              'p-1 px-2',
              'text-end',
              'text-xs',
              'space-x-1',
            )}
          >
            <span className={cn('text-xxs uppercase')}>{t`Total`}</span>
            <span className={cn('text-menu-text/60')}>
              {formatInteger(totalUOMQuantity)}
            </span>
          </div>
        </div>

        <div className={cn('flex w-full items-center')}>
          <ul
            className={cn(
              'marker:text-menu-text/30 list-outside list-decimal',
              'space-y-1',
              'text-xs',
              'ltr:pl-6 rtl:pr-6',
              'w-full',
            )}
          >
            {_.map(
              _.orderBy(props.breakdownByUom, ['uomQuantity'], ['desc']),
              uom => (
                <li className={cn('w-full', 'py-0.5')}>
                  <UOMDetails key={uom.uom} {...uom} />
                </li>
              ),
            )}
          </ul>
        </div>
      </div>
    </div>
  );
};

export default JobsDetailsUOM;
