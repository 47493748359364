import classNames from 'classnames';
import _ from 'lodash';
import { highlight } from 'prismjs/components/prism-core';
import 'prismjs/components/prism-sql';
import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import Editor from 'react-simple-code-editor';
import { useRecoilValue } from 'recoil';
import * as Icon from '../../../components/icons';
import TitleSection from '../../../components/layout/TitleSection';
import Tag from '../../../helpContext/Tag';
import { sql } from '../../../import/csv/ImportExpressionSqlLanguage';
import { warehouseSelected } from '../../../store/warehouse.state';
import useUpdateWarehouseExtraSettings from '../../../warehouse/hooks/useUpdateWarehouseExtraSettings';
import { importPipelineSelected } from '../../store/importPipelines.state';

export const PipelineSectionSettings: React.FC = () => {
  const { t } = useTranslation('dataset');
  const wh = useRecoilValue(warehouseSelected);
  const pipeline = useRecoilValue(importPipelineSelected);
  const [isLoading, setIsLoading] = useState(false);

  const [query, setQuery] = useState(
    pipeline?.settings?.mappingSettings?.transformation?.query,
  );
  const [updateWhExtraSettings] = useUpdateWarehouseExtraSettings();

  return (
    <>
      <TitleSection
        id={`pipeline-settings-query`}
        title={t`Settings: Transformation`}
        inPanelView
        hasScreenTitle
        collapsible
      >
        <Editor
          value={pipeline?.settings?.mappingSettings?.transformation?.query}
          onValueChange={query =>
            setQuery(pipeline?.settings?.mappingSettings?.transformation?.query)
          }
          highlight={code => (_.isEmpty(code) ? code : highlight(code, sql))}
          padding={20}
          disabled={isLoading}
          style={{
            position: 'relative',
            overflowY: 'auto',
            fontFamily: 'monospace',
            fontSize: 13,
            paddingBottom: '3rem',
          }}
          className={classNames('bg-app-panel-dark')}
        />
      </TitleSection>
      <TitleSection
        id={`pipeline-settings-column-names`}
        title={t`Settings: Fields`}
        inPanelView
        hasScreenTitle
        collapsible
      >
        <ul className={classNames('p-1', 'space-y-2')}>
          {pipeline.settings?.mappingSettings?.mapping?.fields.map((f, idx) => {
            return (
              <li
                key={`${f.index}-${idx}`}
                className={classNames(
                  'flex',
                  'px-1 py-2',
                  'border-menu border-b',
                  'select-text',
                  'text-menu-text text-xs',
                )}
              >
                <Tag leftTag fullWidth>
                  {f.columnName}
                </Tag>
                <div className="bg-app-panel/50 flex select-text flex-col items-center justify-center px-3 py-1 text-xs">
                  <Icon.ArrowRight
                    className={classNames('h-4 w-4 fill-current opacity-50')}
                  />
                </div>
                <Tag rightTag fullWidth>
                  {f.name}
                  {f.measureValue && (
                    <div className={classNames('select-text opacity-70')}>
                      {f.measureValue}
                    </div>
                  )}
                </Tag>
              </li>
            );
          })}
        </ul>
      </TitleSection>
    </>
  );
};
