import {
  BatchJobStatus,
  ImportJobPipelineTargetSettingsInput,
  ImportJobSortOption,
  ImportJobTargetType,
  ImportPipelineConnectorSettingsFragment,
  ImportPipelineFragment,
  ImportPipelineSettingsInput,
  ImportSource,
  SortDirection,
  useCreateImportPipelineMutation,
  useLoadImportJobsQuery,
  useUpdateImportPipelineMutation,
} from '@warebee/frontend/data-access-api-graphql';
import classNames from 'classnames';
import _ from 'lodash';
import { highlight } from 'prismjs/components/prism-core';
import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import Editor from 'react-simple-code-editor';
import { useRecoilState, useRecoilValue, useSetRecoilState } from 'recoil';
import { formatDateTime } from '../../../common/formatHelper';
import { cloneWithoutTypename } from '../../../common/utils';
import ErrorIndicator from '../../../components/ErrorIndicator';
import { Button } from '../../../components/actions/Button';
import ButtonSwitchMulti from '../../../components/actions/ButtonSwitchMulti';
import DropdownSelector from '../../../components/actions/DropdownSelector';
import { InputGroupList } from '../../../components/inputs/InputGroupList';
import InputText from '../../../components/inputs/InputText';
import InputTextArea from '../../../components/inputs/InputTextArea';
import { Container } from '../../../components/layout/ContainerFlex';
import { ScreenTitle } from '../../../components/layout/ScreenTitle';
import TitleSection from '../../../components/layout/TitleSection';
import { ActionBar } from '../../../components/nav/ActionBar';
import PanelContainer from '../../../containers/PanelContainer';
import { datasetAutomationType } from '../../../datasetAutomation/store/datasetAutomation.state';
import { datasetObjectDocument } from '../../../datasetObject/store/datasetObject.state';
import DataSettingSelector from '../../../feed/components/DataSettingSelector';
import { sql } from '../../../import/csv/ImportExpressionSqlLanguage';
import {
  DatasetType,
  ImportTypeToJobTypeMap,
} from '../../../import/store/import.types';
import { warehouseSelectedId } from '../../../store/warehouse.state';
import {
  importPipelineIsActiveEditor,
  importPipelineSelectedId,
  importPipelinesDataTableState,
} from '../../store/importPipelines.state';

type PipelineEditableProps = Pick<
  ImportPipelineFragment,
  'title' | 'description' | 'enabled' | 'settings' | 'extraSettings'
>;

type PipelineEditorProps = {
  pipeline: ImportPipelineFragment;
  isNew: boolean;
  onChange: (cnn: ImportPipelineConnectorSettingsFragment) => Promise<void>;
};

type PipelineAppendType = 'new' | 'append' | 'overwrite';

function getAppendType(pipeline: PipelineEditableProps): PipelineAppendType {
  if (_.isNil(pipeline?.settings?.targetSettings?.appendSettings)) {
    return 'new';
  }
  if (pipeline?.settings?.targetSettings?.appendSettings?.overwrite) {
    return 'overwrite';
  }

  return 'append';
}
const PipelineEditor: React.FC<PipelineEditorProps> = props => {
  const { t } = useTranslation('dataset');

  // recoil state
  const warehouseId = useRecoilValue(warehouseSelectedId);
  const setShowEditor = useSetRecoilState(importPipelineIsActiveEditor);
  const datasetObject = useRecoilValue(datasetObjectDocument);
  const automationImportType = useRecoilValue(datasetAutomationType);
  const setSelectedId = useSetRecoilState(importPipelineSelectedId);
  const [tableState, setTableState] = useRecoilState(
    importPipelinesDataTableState,
  );
  const [callCreate] = useCreateImportPipelineMutation();
  const [callUpdate] = useUpdateImportPipelineMutation();

  // local state
  const [pipeline, setPipeline] = useState<PipelineEditableProps>(
    props.pipeline,
  );
  const [jobId, setJobId] = useState<string>(null);
  const [appendType, setAppendType] = useState<PipelineAppendType>(
    getAppendType(pipeline),
  );
  const [targetTitle, setTargetTitle] = useState<string>(
    pipeline?.settings?.targetSettings?.newSettings?.targetTitle ?? '',
  );
  const [targetId, setTargetId] = useState<string>(
    pipeline?.settings?.targetSettings?.appendSettings?.targetId,
  );

  const [query, setQuery] = useState(
    pipeline?.settings?.mappingSettings?.transformation?.query,
  );
  const [isChanged, setIsChanged] = useState(false);
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [error, setError] = useState<any>(null);

  const jobImportType = automationImportType ?? datasetObject?.objectType;
  const importType = _.toPairs(ImportTypeToJobTypeMap).find(
    ([key, value]) => value === jobImportType,
  )?.[0] as DatasetType;

  if (_.isNil(jobImportType)) {
    return <ErrorIndicator message={t`Unknown dataset object type`} />;
  }

  const { data: importJobResult, loading } = useLoadImportJobsQuery({
    variables: {
      filter: {
        importType: [jobImportType],
        status: [BatchJobStatus.READY],
        source: [
          ImportSource.UPLOAD,
          ImportSource.DATASET,
          ImportSource.PIPELINE,
        ],
        warehouseId: [warehouseId],
        targetId: datasetObject ? [datasetObject.id] : null,
      },
      // page: null,
      page: { limit: 50 },
      sortBy: {
        field: ImportJobSortOption.UPDATED_AT,
        direction: SortDirection.DESC,
      },
    },
  });

  useEffect(() => {
    setPipeline(
      props.pipeline ?? {
        enabled: true,
      },
    );
    setIsChanged(false);
  }, [props.pipeline]);

  function reloadTable() {
    setTableState({
      ...tableState,
      searchValues: { ...tableState?.searchValues },
    });
  }

  function applyPatch(patch: Partial<ImportPipelineFragment>) {
    setPipeline({ ...pipeline, ...patch });
  }

  function getTargetSettings() {
    const createNew = appendType === 'new';
    const targetSettings: ImportJobPipelineTargetSettingsInput = {
      target: createNew ? ImportJobTargetType.NEW : ImportJobTargetType.APPEND,
      newSettings: createNew
        ? {
            targetTitle,
          }
        : null,
      appendSettings: !createNew
        ? {
            targetId,
            overwrite: appendType === 'overwrite',
          }
        : null,
    };
    return targetSettings;
  }

  async function updatePipeline() {
    setIsLoading(true);
    const targetSettings = getTargetSettings();

    const pipelineSettings: ImportPipelineSettingsInput = job
      ? {
          ...cloneWithoutTypename(pipeline.settings),
          targetSettings,
          sourceSettings: {
            format: job.sourceSettings.format,
            compression: job.sourceSettings.compression,
          },
          mappingSettings: {
            ...cloneWithoutTypename(job.settings),
            transformation: _.isEmpty(query)
              ? null
              : {
                  enabled: true,
                  query,
                },
          },
        }
      : {
          ...cloneWithoutTypename(pipeline.settings),
          targetSettings,
          mappingSettings: {
            ...cloneWithoutTypename(pipeline.settings.mappingSettings),
            transformation: _.isEmpty(query)
              ? null
              : {
                  enabled: true,
                  query,
                },
          },
        };

    try {
      await callUpdate({
        variables: {
          input: {
            importPipelineId: props.pipeline.id,
            title: pipeline.title,
            description: pipeline.description,
            enabled: pipeline.enabled,
            //extraSettings: cloneWithoutTypename( pipeline.extraSettings),
            settings: pipelineSettings,
          },
        },
      });
      reloadTable();
      setShowEditor(false);
    } catch (ex) {
      console.error(ex);
      setError(true);
    } finally {
      setIsLoading(false);
    }
  }

  async function runCreatePipeline() {
    try {
      setIsLoading(true);

      const job = jobs[jobId];
      const targetSettings = getTargetSettings();

      const response = await callCreate({
        variables: {
          input: {
            ...pipeline,
            warehouseId,
            importType: jobImportType,
            settings: {
              targetSettings,
              sourceSettings: {
                format: job.sourceSettings.format,
                compression: job.sourceSettings.compression,
              },
              mappingSettings: {
                ...cloneWithoutTypename(job.settings),
                transformation: _.isEmpty(query)
                  ? null
                  : {
                      enabled: true,
                      query,
                    },
              },
            },
          },
        },
      });

      setSelectedId(response?.data?.createImportPipeline?.id);
      reloadTable();
      setShowEditor(false);
    } catch (ex) {
      console.error(ex);
    } finally {
      setIsLoading(false);
    }
  }

  const jobs = _(importJobResult?.importJobs?.content)
    .keyBy(j => j.id)
    .value();

  const job = jobs?.[jobId];
  const appendOptions: { key: PipelineAppendType; label: string }[] = [
    {
      key: 'new',
      label: t`Create new`,
    },
    {
      key: 'append',
      label: t`Append`,
    },
    {
      key: 'overwrite',
      label: t`Overwrite`,
    },
  ];

  const subtitle = props.isNew ? t`Create pipeline` : t`Update pipeline`;

  if (!pipeline) {
    return null;
  }

  return (
    <Container col hasOverflowY>
      <ScreenTitle subtitle={subtitle} title={t`Pipeline`} isSticky />
      <PanelContainer id="panel-pipeline-settings" title={``} hasPadding>
        {!_.isNil(error) && (
          <ErrorIndicator message={t`Error! Setting up the Data Pipeline`} />
        )}

        <InputGroupList hasPadding hasSpacing panelMode>
          <InputText
            required
            placeholder={t`Pipeline Name`}
            label={t`Pipeline Name`}
            name={t`Pipeline Name`}
            value={pipeline?.title}
            onChange={v => applyPatch({ title: v })}
          />
          <InputTextArea
            optional
            label={t`Pipeline Description`}
            placeholder={t`About the Pipeline...`}
            value={pipeline.description}
            onChange={v => applyPatch({ description: v })}
          />
          <DropdownSelector
            label={t`Import Template`}
            value={jobId}
            values={_.keys(jobs)}
            renderValue={v =>
              _.isNil(v)
                ? t`No Job Template selected`
                : (jobs[v]?.sourceSettings?.filename ?? '')
            }
            valueHelper={v =>
              v && jobs[v] ? formatDateTime(new Date(jobs[v].createdAt)) : ''
            }
            onChange={v => {
              const job = jobs[v];
              setJobId(v);
              setQuery(job.settings.transformation?.query ?? '');
            }}
            panelModeMultiline
            DropAlignRight
            widthFull
            panelMode
            hasSearch
            hasSearchLabel={t`Import jobs`}
          />

          <ButtonSwitchMulti
            autoSize
            className={classNames('mx-3')}
            classNameLabel={classNames('text-xs py-1 px-2')}
            buttonType="secondary"
            options={appendOptions}
            selectedIndex={_.findIndex(
              appendOptions,
              o => o.key === appendType,
            )}
            onClick={index => setAppendType(appendOptions[index].key)}
          />
          {appendType === 'new' ? (
            <InputText
              required
              placeholder={t`New Dataset Name Prefix`}
              label={t`New Dataset Name Prefix`}
              name={t`New Dataset Name Prefix`}
              value={targetTitle}
              onChange={v => setTargetTitle(v)}
              // isDisabled={appendType === 'append'}
            />
          ) : (
            <DataSettingSelector
              dataType={importType}
              datasetId={targetId}
              onChange={id => setTargetId(id)}
              viewMode="minimal"
            />
          )}

          {!props.isNew && (
            <DropdownSelector
              label={t`Status`}
              value={pipeline.enabled}
              values={[false, true]}
              renderValue={v => (v ? t`Enabled` : t`Disabled`)}
              onChange={v => applyPatch({ enabled: v })}
              panelModeMultiline
              DropAlignRight
              widthFull
              panelMode
            />
          )}
          {!_.isNil(query) && (
            <TitleSection
              id={`job-settings-query`}
              title={t`Settings: Transformation`}
              inPanelView
              hasScreenTitle
              collapsible
              className="-mx-3"
            >
              <Editor
                value={query}
                onValueChange={v => setQuery(v)}
                highlight={code =>
                  _.isEmpty(code) ? code : highlight(code, sql)
                }
                padding={10}
                disabled={false}
                style={{
                  position: 'relative',
                  overflowY: 'auto',
                  fontFamily: 'monospace',
                  fontSize: 13,
                  paddingBottom: '3rem',
                }}
                className={classNames('bg-app-panel-dark')}
              />
            </TitleSection>
          )}
        </InputGroupList>
      </PanelContainer>
      <ActionBar stickyBottom>
        <Button
          className={classNames('flex-1 text-sm')}
          label={t`Cancel`}
          buttonType="secondary"
          onPress={() => setShowEditor(false)}
          isDisabled={isLoading}
          isLoading={isLoading}
        />
        <Button
          className={classNames('flex-1 text-sm')}
          label={props.isNew ? t`Create` : t`Update`}
          buttonType="primary"
          onPress={props.isNew ? runCreatePipeline : updatePipeline}
          isDisabled={isLoading || (_.isNil(jobId) && props.isNew)}
          isLoading={isLoading}
        />
      </ActionBar>
    </Container>
  );
};

export default PipelineEditor;
