import { TFunction } from 'i18next';
import _ from 'lodash';
import { Formatter } from '../../common/useFormatter';
import * as Icon from '../../components/icons';
import {
  AnalyzeProductMetric,
  AnalyzeProductMetricDescriptor,
  AnalyzeProductMetricDescriptorBase,
} from './analyzeProductMetric.types';

export const analyzeProductMetricDescriptors: AnalyzeProductMetricDescriptorBase[] = [
  {
    type: 'abc',
    path: 'categoryString',
    rangeType: 'distinct',
  },
  {
    type: 'visits',
    path: 'locationVisits',
    rangeType: 'range-pow',
    sequentialPaletteId: 'heatmap-analytics',
  },
  {
    type: 'reassign-task-type',
    path: 'reassignKey',
    rangeType: 'distinct',
  },
  {
    type: 'replenishment-count',
    path: 'replenishmentCount',
    rangeType: 'range-linear',
  },
  {
    type: 'reassign-count',
    path: 'reassignCount',
    rangeType: 'range-linear',
  },
  {
    type: 'applied-reorder',
    path: 'appliedReorder.count',
    rangeType: 'range-linear',
    sequentialPaletteId: 'elevation-sort',
  },
  {
    type: 'triggered-reorder',
    path: 'triggeredReorder.count',
    rangeType: 'range-linear',
    sequentialPaletteId: 'elevation-sort',
  },
  {
    type: 'applied-reorder-cost',
    path: 'appliedReorder.totalCost',
    rangeType: 'range-linear',
    sequentialPaletteId: 'elevation-sort',
  },
  {
    type: 'triggered-reorder-cost',
    path: 'triggeredReorder.totalCost',
    rangeType: 'range-linear',
    sequentialPaletteId: 'elevation-sort',
  },
  {
    type: 'applied-reorder-duration',
    path: 'appliedReorder.totalDuration',
    rangeType: 'range-linear',
    sequentialPaletteId: 'elevation-sort',
  },
  {
    type: 'triggered-reorder-duration',
    path: 'triggeredReorder.totalDuration',
    rangeType: 'range-linear',
    sequentialPaletteId: 'elevation-sort',
  },
  {
    type: 'applied-reorder-weight',
    path: 'appliedReorder.totalReorderedWeight',
    rangeType: 'range-linear',
    sequentialPaletteId: 'elevation-sort',
  },
  {
    type: 'triggered-reorder-weight',
    path: 'triggeredReorder.totalReorderedWeight',
    rangeType: 'range-linear',
    sequentialPaletteId: 'elevation-sort',
  },
  // {
  //   type: 'locationRackingType',
  //   path: 'location.locationRackingType',
  //   rangeType: 'distinct',
  // },
  // {
  //   type: 'locationUsageType',
  //   path: 'location.locationUsageType',
  //   rangeType: 'distinct',
  // },
  // {
  //   type: 'locationOrder',
  //   path: 'location.locationOrder',
  //   rangeType: 'range-linear',
  // },
  // {
  //   type: 'congestionZone',
  //   path: 'location.congestionZone',
  //   rangeType: 'distinct',
  // },

  {
    type: 'congestion-zone',
    path: 'congestionZone',
    rangeType: 'distinct',
    qualitativePaletteId: 'policy',
  },
  // Item
  {
    type: 'consignee',
    path: 'consignee',
    rangeType: 'distinct',
    qualitativePaletteId: 'dimension',
  },
  {
    type: 'consignee-sku',
    path: 'skuKey',
    rangeType: 'distinct',
    qualitativePaletteId: 'dimension',
  },
  {
    type: 'sku-group',
    path: 'item.skuGroup',
    rangeType: 'distinct',
    qualitativePaletteId: 'dimension',
  },
  {
    type: 'sub-group',
    path: 'item.subGroup',
    rangeType: 'distinct',
    qualitativePaletteId: 'dimension',
  },
  {
    type: 'transport-class',
    path: 'item.transportClass',
    rangeType: 'distinct',
    qualitativePaletteId: 'dimension',
  },
  {
    type: 'stock-category',
    path: 'item.stockCategory',
    rangeType: 'distinct',
    qualitativePaletteId: 'dimension',
  },
  {
    type: 'storage-class',
    path: 'item.storageClass',
    rangeType: 'distinct',
    qualitativePaletteId: 'dimension',
  },
  {
    type: 'pollution-class',
    path: 'item.pollutionClass',
    rangeType: 'distinct',
    qualitativePaletteId: 'dimension',
  },
  {
    type: 'baseUOM',
    path: 'item.baseUom.uom',
    rangeType: 'distinct',
    qualitativePaletteId: 'dimension',
  },

  {
    type: 'baseUOMWeight',
    path: 'item.baseUom.height',
    rangeType: 'range-linear',
    sequentialPaletteId: 'elevation-sort',
  },
  {
    type: 'baseUOMWeight-pow',
    path: 'item.baseUom.netWeight',
    rangeType: 'range-pow',
    sequentialPaletteId: 'elevation-sort',
  },
  {
    type: 'baseUOMVolume',
    path: 'item.baseUom.volume',
    rangeType: 'range-linear',
    sequentialPaletteId: 'elevation-sort',
  },
  {
    type: 'baseUOMVolume-pow',
    path: 'item.baseUom.volume',
    rangeType: 'range-pow',
    sequentialPaletteId: 'elevation-sort',
  },
  {
    type: 'unusedVolume',
    path: 'unusedVolume',
    rangeType: 'range-linear',
    sequentialPaletteId: 'elevation-sort',
  },
  
  {
    type: 'totalDaysWithOrders',
    path: 'item.details.dailyStats.totalDaysWithOrders',
    rangeType: 'range-linear',
    sequentialPaletteId: 'elevation-sort',
  },

  {
    type: 'totalWeeksWithOrders',
    path: 'item.details.weeklyStats.totalWeeksWithOrders',
    rangeType: 'distinct',
    sequentialPaletteId: 'elevation-sort',
  },
  {
    type: 'dailyOrderLineCountAvg',
    path: 'item.details.dailyStats.orderLineCount.avg',
    rangeType: 'range-linear',
    sequentialPaletteId: 'elevation-sort',
  },
  {
    type: 'dailyOrderLineCountMax',
    path: 'item.details.dailyStats.orderLineCount.max',
    rangeType: 'range-pow',
    sequentialPaletteId: 'elevation-sort',
  },
  {
    type: 'dailyDaysWithOrdersCv',
    path: 'item.details.dailyStats.orderLineCount.cv',
    rangeType: 'range-linear',
    sequentialPaletteId: 'elevation-sort',
  },

  {
    type: 'weeklyOrderLineCountAvg',
    path: 'item.details.weeklyStats.orderLineCount.avg',
    rangeType: 'range-linear',
    sequentialPaletteId: 'elevation-sort',
  },
  {
    type: 'weeklyOrderLineCountMax',
    path: 'item.details.weeklyStats.orderLineCount.max',
    rangeType: 'range-linear',
    sequentialPaletteId: 'elevation-sort',
  },

  {
    type: 'weeklyDaysWithOrdersAvg',
    path: 'item.details.weeklyStats.daysWithOrders.avg',
    rangeType: 'range-linear',
    sequentialPaletteId: 'elevation-sort',
  },
  {
    type: 'weeklyDaysWithOrdersMax',
    path: 'item.details.weeklyStats.daysWithOrders.max',
    rangeType: 'distinct',
    sequentialPaletteId: 'elevation-sort',
  },
  {
    type: 'weeklyDaysWithOrdersCv',
    path: 'item.details.weeklyStats.daysWithOrders.cv',
    rangeType: 'range-linear',
    sequentialPaletteId: 'elevation-sort',
  },

  // {
  //   type: 'baseUOMCapacity',
  //   path: 'baseUOMCapacity',
  //   rangeType: 'range-pow',
  //   sequentialPaletteId: 'elevation-sort',
  // },
  // {
  //   type: 'baseUOMDensity',
  //   path: 'baseUOMDensity',
  //   rangeType: 'range-linear',
  //   sequentialPaletteId: 'elevation-sort',
  // },
  // {
  //   type: 'baseUOMProportionality',
  //   path: 'baseUOMProportionality',
  //   rangeType: 'range-pow',
  //   sequentialPaletteId: 'elevation-sort',
  // },
];

export const analyzeProductMetricDescriptorsMap = _.keyBy(
  analyzeProductMetricDescriptors,
  d => d.type,
) as Record<AnalyzeProductMetric, AnalyzeProductMetricDescriptorBase>;

export function getAnalyzeProductMetricDescriptorsMap(
  t: TFunction<'simulation'>,
  formatter: Formatter,
) {
  const metrics: AnalyzeProductMetricDescriptor[] = [
    // VELOCITY METRICS
    {
      ...analyzeProductMetricDescriptorsMap.abc,
      titleCategory: t('Velocity', { ns: 'simulation' }),
      title: t('ABC', { ns: 'simulation' }),
      subTitle: t('Item', { ns: 'simulation' }),
      actionTitle: t(`show ABC heatmap`, { ns: 'simulation' }),
      icon: Icon.HeatMap4,
    },
    {
      ...analyzeProductMetricDescriptorsMap.visits,
      titleCategory: t('Velocity', { ns: 'simulation' }),
      title: t('Visits', { ns: 'simulation' }),
      subTitle: t('Item', { ns: 'simulation' }),
      actionTitle: t('show Visits heatmap', { ns: 'simulation' }),
      icon: Icon.HeatMap9,
    },
    {
      ...analyzeProductMetricDescriptorsMap['replenishment-count'],
      titleCategory: t('Velocity', { ns: 'simulation' }),
      title: t('Replenishments', { ns: 'simulation' }),
      subTitle: t('Show Replenishment count', { ns: 'simulation' }),
    },
    // LOCATION METRICS
    {
      ...analyzeProductMetricDescriptorsMap['congestion-zone'],
      titleCategory: t('Location', { ns: 'simulation' }),
      title: t('Work Zone', { ns: 'simulation' }),
      subTitle: t('Workload', { ns: 'simulation' }),
      actionTitle: t('show zones', { ns: 'simulation' }),
      icon: Icon.CongestionMap14,
    },
    // ITEMS METRICS
    {
      ...analyzeProductMetricDescriptorsMap['consignee'],
      titleCategory: t('Item', { ns: 'simulation' }),
      title: t('Consignee', { ns: 'simulation' }),
      subTitle: t('Item', { ns: 'simulation' }),
      actionTitle: t('show consignee', { ns: 'simulation' }),
      icon: Icon.CongestionMap4,
    },
    {
      ...analyzeProductMetricDescriptorsMap['consignee-sku'],
      titleCategory: t('Item', { ns: 'simulation' }),
      title: t('SKU', { ns: 'simulation' }),
      subTitle: t('Item', { ns: 'simulation' }),
      actionTitle: t('show consignee', { ns: 'simulation' }),
      icon: Icon.CongestionMap4,
    },
    {
      ...analyzeProductMetricDescriptorsMap['sku-group'],
      titleCategory: t('Item', { ns: 'simulation' }),
      title: t('Item Group', { ns: 'simulation' }),
      subTitle: t('Item', { ns: 'simulation' }),
      actionTitle: t('show SKU Group', { ns: 'simulation' }),
      icon: Icon.CongestionMap4,
    },
    {
      ...analyzeProductMetricDescriptorsMap['sub-group'],
      titleCategory: t('Item', { ns: 'simulation' }),
      title: t('Sub Group', { ns: 'simulation' }),
      subTitle: t('Item', { ns: 'simulation' }),
      actionTitle: t('show Sub Group', { ns: 'simulation' }),
      icon: Icon.CongestionMap4,
    },
    {
      ...analyzeProductMetricDescriptorsMap['stock-category'],
      titleCategory: t('Item', { ns: 'simulation' }),
      title: t('Stock Category', { ns: 'simulation' }),
      subTitle: t('Stock Category', { ns: 'simulation' }),
      actionTitle: t('show tStock Category', { ns: 'simulation' }),
      icon: Icon.CongestionMap4,
    },
    {
      ...analyzeProductMetricDescriptorsMap['transport-class'],
      titleCategory: t('Item', { ns: 'simulation' }),
      title: t('Transport Class', { ns: 'simulation' }),
      subTitle: t('Transport Class', { ns: 'simulation' }),
      actionTitle: t('show Transport Class', { ns: 'simulation' }),
      icon: Icon.CongestionMap4,
    },
    {
      ...analyzeProductMetricDescriptorsMap['storage-class'],
      titleCategory: t('Item', { ns: 'simulation' }),
      title: t('Storage Class', { ns: 'simulation' }),
      subTitle: t('Storage Class', { ns: 'simulation' }),
      actionTitle: t('show Storage Class', { ns: 'simulation' }),
      icon: Icon.CongestionMap4,
    },
    {
      ...analyzeProductMetricDescriptorsMap['pollution-class'],
      titleCategory: t('Item', { ns: 'simulation' }),
      title: t('Pollution Class', { ns: 'simulation' }),
      subTitle: t('Pollution Class', { ns: 'simulation' }),
      actionTitle: t('show pollution class', { ns: 'simulation' }),
      icon: Icon.CongestionMap4,
    },
    // BASE UOM METRICS
    {
      ...analyzeProductMetricDescriptorsMap['baseUOM'],
      titleCategory: t('Item (UOM)', { ns: 'simulation' }),
      title: t('Base Uom', { ns: 'simulation' }),
      subTitle: t('Item', { ns: 'simulation' }),
      icon: Icon.CongestionMap4,
    },
    {
      ...analyzeProductMetricDescriptorsMap['baseUOMWeight'],
      titleCategory: t('Item (UOM)', { ns: 'simulation' }),
      title: t('Base UOM Weight', { ns: 'simulation' }),
      subTitle: t('Item', { ns: 'simulation' }),
      format: v => formatter.formatWeight(v, 10).fullString,
      icon: Icon.CongestionMap4,
    },
    {
      ...analyzeProductMetricDescriptorsMap['baseUOMWeight-pow'],
      titleCategory: t('Item (UOM)', { ns: 'simulation' }),
      title: t('Base UOM Weight (Relative)', { ns: 'simulation' }),
      subTitle: t('Item', { ns: 'simulation' }),
      format: v => formatter.formatWeight(v).fullString,
      icon: Icon.CongestionMap4,
    },
    {
      ...analyzeProductMetricDescriptorsMap['baseUOMVolume'],
      titleCategory: t('Item (UOM)', { ns: 'simulation' }),
      title: t('Base UOM Volume', { ns: 'simulation' }),
      subTitle: t('Item', { ns: 'simulation' }),
      format: v => formatter.formatVolume(v).fullString,
      icon: Icon.CongestionMap4,
    },
    {
      ...analyzeProductMetricDescriptorsMap['baseUOMVolume-pow'],
      titleCategory: t('Item (UOM)', { ns: 'simulation' }),
      title: t('Base UOM Volume (Relative)', { ns: 'simulation' }),
      subTitle: t('Item', { ns: 'simulation' }),
      format: v => formatter.formatVolume(v).fullString,
      icon: Icon.CongestionMap4,
    },
    // {
    //   ...analyzeProductMetricDescriptorsMap['baseUOMCapacity'],
    //   title: t('Base UOM Capacity', { ns: 'simulation' }),
    //   subTitle: t('Item', { ns: 'simulation' }),
    //   format: v => formatInteger(v),
    //   icon: Icon.CongestionMap4,
    // },
    // {
    //   ...analyzeProductMetricDescriptorsMap['baseUOMDensity'],
    //   title: t('Base UOM Density', { ns: 'simulation' }),
    //   subTitle: t('Item', { ns: 'simulation' }),
    //   format: v => formatToPrecision(v),
    //   icon: Icon.CongestionMap4,
    // },
    // {
    //   ...analyzeProductMetricDescriptorsMap['baseUOMProportionality'],
    //   title: t('Base UOM Proportionality', { ns: 'simulation' }),
    //   subTitle: t('Item', { ns: 'simulation' }),
    //   format: v => formatToPrecision(v),
    //   icon: Icon.CongestionMap4,
    // },

    // REORDER METRICS
    {
      ...analyzeProductMetricDescriptorsMap['triggered-reorder'],
      titleCategory: t('Re-Ordering', { ns: 'simulation' }),
      title: t('Direct re-order', { ns: 'simulation' }),
      subTitle: t('Item', { ns: 'simulation' }),
      actionTitle: t('show triggered reorders heatmap', { ns: 'simulation' }),
      icon: Icon.PalletStackingResortDirect,
    },
    {
      ...analyzeProductMetricDescriptorsMap['triggered-reorder-cost'],
      titleCategory: t('Re-Ordering', { ns: 'simulation' }),
      title: t('Direct re-order (cost)', { ns: 'simulation' }),
      subTitle: t('Item', { ns: 'simulation' }),
      format: v => formatter.formatCurrency(v, 2).fullString,
      icon: Icon.CongestionMap4,
    },
    {
      ...analyzeProductMetricDescriptorsMap['triggered-reorder-duration'],
      titleCategory: t('Re-Ordering', { ns: 'simulation' }),
      title: t('Direct re-order (duration)', { ns: 'simulation' }),
      subTitle: t('Item', { ns: 'simulation' }),
      format: v => formatter.formatTimeSpan(v).fullString,
      icon: Icon.CongestionMap4,
    },
    {
      ...analyzeProductMetricDescriptorsMap['triggered-reorder-weight'],
      titleCategory: t('Re-Ordering', { ns: 'simulation' }),
      title: t('Direct re-order (weight)', { ns: 'simulation' }),
      subTitle: t('Item', { ns: 'simulation' }),
      format: v => formatter.formatWeight(v).fullString,
      icon: Icon.CongestionMap4,
    },
    {
      ...analyzeProductMetricDescriptorsMap['applied-reorder'],
      titleCategory: t('Re-Ordering', { ns: 'simulation' }),
      title: t('Indirect re-order', { ns: 'simulation' }),
      subTitle: t('Item', { ns: 'simulation' }),
      actionTitle: t('show applied reorders heatmap', { ns: 'simulation' }),
      icon: Icon.PalletStackingResortHeatmapDirect,
    },
    {
      ...analyzeProductMetricDescriptorsMap['applied-reorder-cost'],
      titleCategory: t('Re-Ordering', { ns: 'simulation' }),
      title: t('Indirect re-order (cost)', { ns: 'simulation' }),
      subTitle: t('Item', { ns: 'simulation' }),
      format: v => formatter.formatCurrency(v, 2).fullString,
      icon: Icon.CongestionMap4,
    },
    {
      ...analyzeProductMetricDescriptorsMap['applied-reorder-duration'],
      titleCategory: t('Re-Ordering', { ns: 'simulation' }),
      title: t('Indirect re-order (duration)', { ns: 'simulation' }),
      subTitle: t('Item', { ns: 'simulation' }),
      format: v => formatter.formatTimeSpan(v).fullString,
      icon: Icon.CongestionMap4,
    },
    {
      ...analyzeProductMetricDescriptorsMap['applied-reorder-weight'],
      titleCategory: t('Re-Ordering', { ns: 'simulation' }),
      title: t('Indirect re-order (weight)', { ns: 'simulation' }),
      subTitle: t('Item', { ns: 'simulation' }),
      format: v => formatter.formatWeight(v).fullString,
      icon: Icon.CongestionMap4,
    },
    // REASSIGN METRICS
    {
      ...analyzeProductMetricDescriptorsMap['reassign-task-type'],
      titleCategory: t('Optimisation', { ns: 'simulation' }),
      title: t('Optimisation: Reassign', { ns: 'simulation' }),
      subTitle: t('Item', { ns: 'simulation' }),
      icon: Icon.CongestionMap4,
    },
    {
      ...analyzeProductMetricDescriptorsMap['unusedVolume'],
      titleCategory: t('Capacity and Occupancy', { ns: 'simulation' }),
      title: t('Unused Volume', { ns: 'simulation' }),
      subTitle: t('Item', { ns: 'simulation' }),
      icon: Icon.CongestionMap4,
    },
    {
      ...analyzeProductMetricDescriptorsMap['totalDaysWithOrders'],
      titleCategory: t('Frequency', { ns: 'simulation' }),
      title: t('Ordered Days (Total)', { ns: 'simulation' }),
      subTitle: t('Item', { ns: 'simulation' }),
      icon: Icon.CongestionMap4,
    },

    {
      ...analyzeProductMetricDescriptorsMap['totalWeeksWithOrders'],
      titleCategory: t('Frequency', { ns: 'simulation' }),
      title: t('Ordered Weeks (Total)', { ns: 'simulation' }),
      subTitle: t('Item', { ns: 'simulation' }),
      icon: Icon.CongestionMap4,
    },

    {
      ...analyzeProductMetricDescriptorsMap['dailyOrderLineCountAvg'],
      titleCategory: t('Frequency', { ns: 'simulation' }),
      title: t('Daily Order Lines (Average)', { ns: 'simulation' }),
      subTitle: t('Item', { ns: 'simulation' }),
      icon: Icon.CongestionMap4,
    },

    {
      ...analyzeProductMetricDescriptorsMap['dailyOrderLineCountMax'],
      titleCategory: t('Frequency', { ns: 'simulation' }),
      title: t('Daily Order Lines (Max)', { ns: 'simulation' }),
      subTitle: t('Item', { ns: 'simulation' }),
      icon: Icon.CongestionMap4,
    },

    {
      ...analyzeProductMetricDescriptorsMap['dailyDaysWithOrdersCv'],
      titleCategory: t('Frequency', { ns: 'simulation' }),
      title: t('Daily Correlation. (CV)', { ns: 'simulation' }),
      subTitle: t('Item', { ns: 'simulation' }),
      icon: Icon.CongestionMap4,
    },

    {
      ...analyzeProductMetricDescriptorsMap['weeklyOrderLineCountAvg'],
      titleCategory: t('Frequency', { ns: 'simulation' }),
      title: t('Weekly Order Lines (Average)', { ns: 'simulation' }),
      subTitle: t('Item', { ns: 'simulation' }),
      icon: Icon.CongestionMap4,
    },

    {
      ...analyzeProductMetricDescriptorsMap['weeklyOrderLineCountMax'],
      titleCategory: t('Frequency', { ns: 'simulation' }),
      title: t('Weekly Order Lines (Max)', { ns: 'simulation' }),
      subTitle: t('Item', { ns: 'simulation' }),
      icon: Icon.CongestionMap4,
    },

    {
      ...analyzeProductMetricDescriptorsMap['weeklyDaysWithOrdersAvg'],
      titleCategory: t('Frequency', { ns: 'simulation' }),
      title: t('Weekly Freq. (Avg)', { ns: 'simulation' }),
      subTitle: t('Item', { ns: 'simulation' }),
      icon: Icon.CongestionMap4,
    },

    {
      ...analyzeProductMetricDescriptorsMap['weeklyDaysWithOrdersMax'],
      titleCategory: t('Frequency', { ns: 'simulation' }),
      title: t('Weekly Freq. (Max)', { ns: 'simulation' }),
      subTitle: t('Item', { ns: 'simulation' }),
      icon: Icon.CongestionMap4,
    },

    {
      ...analyzeProductMetricDescriptorsMap['weeklyDaysWithOrdersCv'],
      titleCategory: t('Frequency', { ns: 'simulation' }),
      title: t('Weekly Correlation. (CV)', { ns: 'simulation' }),
      subTitle: t('Item', { ns: 'simulation' }),
      icon: Icon.CongestionMap4,
    },
  ];

  return _.keyBy(metrics, d => d.type);
}
