import classNames from 'classnames';
import _ from 'lodash';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { useRecoilState } from 'recoil';
import Tabs, { TabItem } from '../../components/actions/Tabs';
import * as Icon from '../../components/icons';
import PanelContainer from '../../containers/PanelContainer';
import useUserPermissionFeatures from '../../permissions/roles/hooks/useUserPermissionFeatures';
import StatManagementComponent from '../components/FeedFeatureManagement';
import FeedGlobalFilterPanel from '../components/FeedGlobalFilterPanel';
import ProcessTypeFilters from '../components/ProcessTypeFilters';
import ResourceFilters from '../components/ResourceFilters';
import { feedActivitySelectedTabId } from '../store/feed.state';
import { ActivityFeedTabId } from '../store/feed.types';
import ActualityExtraFieldsSettings from './ActualityExtraFieldsSettings';
import ActualityExtraSettingsEditor from './ActualityExtraSettingsEditor';
import ActualityFiltersSettings from './ActualityFiltersSettings';
import ActualityPipelineSettings from './ActualityPipelineSettings';
import FeedExtraSettingsPanel from './FeedExtraSettingsPanel';
import FeedResourcePolicyPanel from './FeedResourcePolicyPanel';
import FeedSettingsDataPanel from './FeedSettingsDataPanel';

const FeedSettingsPanel: React.FC = () => {
  const { t } = useTranslation('feed');
  const [selectedTab, setSelectedTab] = useRecoilState(
    feedActivitySelectedTabId,
  );
  const visibleFeatures = useUserPermissionFeatures();

  const tabIconStyle = classNames(
    'fill-current w-7 lg:w-8 xl:w-9 2xl:w-10 h-auto',
  );

  const tabItems: TabItem<ActivityFeedTabId>[] = [
    {
      id: 'tab-feed-settings-data',
      title: t`Datasets`,
      icon: <Icon.Settings3 className={tabIconStyle} />,
      content: (
        <>
          {/* <ScreenTitle
            title={t`Setup`}
            subtitle={t`Feed`}
            helpNavTo={'actuality/actuality-setup'}
            icon={Icon.Settings3}
          /> */}
          <FeedSettingsDataPanel />
        </>
      ),
    },
    {
      id: 'tab-feed-settings-filters',
      title: t`Filters`,
      icon: <Icon.FilterMenu className={tabIconStyle} />,
      content: (
        <>
          <ResourceFilters />
          <ProcessTypeFilters />
          <FeedGlobalFilterPanel />
        </>
      ),
    },
    {
      id: 'tab-feed-settings-policies',
      title: t`Policies`,
      icon: <Icon.Policy className={tabIconStyle} />,
      content: (
        <>
          <FeedResourcePolicyPanel />

          {/* <PanelContainer
            id="panel-feed-resource-policy"
            title={t`Resource Policy`}
            collapsible
          >
            <FeedResourcePolicyPanel />
          </PanelContainer> */}
        </>
      ),
    },
    {
      id: 'tab-feed-settings-general',
      title: t`Extras...`,
      icon: <Icon.MenuDotsHorizontal className={tabIconStyle} />,
      content: (
        <>
          <PanelContainer
            id="panel-feed-settings-kpi-stats"
            title={t`Feature Visibility`}
            collapsible
          >
            <StatManagementComponent />
          </PanelContainer>
          <FeedExtraSettingsPanel />
          <ActualityPipelineSettings />
          <ActualityExtraFieldsSettings />
          <ActualityFiltersSettings />
          <ActualityExtraSettingsEditor />
        </>
      ),
    },
  ];

  console.log('tabItems:', tabItems);
  const shouldHaveScroll = selectedTab !== 'tab-feed-activity';

  const allowedTabs = tabItems.filter(tab => true);

  console.log('visibleFeatures:', visibleFeatures);
  console.log('allowedTabs:', allowedTabs);

  return (
    <Tabs
      componentName="FeedSettingsPanel"
      items={allowedTabs}
      selectedIndex={_.findIndex(allowedTabs, i => i.id === selectedTab)}
      onChange={index => setSelectedTab(allowedTabs[index].id)}
      classNameTitle="uppercase"
      hasScroll={shouldHaveScroll}
      fullHeight
      hasActionbar={shouldHaveScroll ? false : true}
    />
  );
};

export default FeedSettingsPanel;
