import { useUpdateActualityMutation } from '@warebee/frontend/data-access-api-graphql';
import _ from 'lodash';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { useRecoilState, useRecoilValue } from 'recoil';
import PanelContainer from '../../containers/PanelContainer';
import DatasetExtraFieldsSettingsEditor from '../../datasetObject/panels/DatasetExtraFieldsSettingsEditor';
import { DatasetExtraFieldSettings } from '../../datasetObject/store/datasetObject.types';
import {
  actualityExtraSettings,
  actualitySelectedId,
} from '../store/actuality.state';
import { ActualityExtraSettings } from '../store/actuality.types';
import { feedDatasetObject } from '../store/feed.state';

/**
 *  Configure Extra Fields of datasets used in actuality,
 *  that can be shown in results and can be used in metrics and filters
 */

const ActualityExtraFieldsSettings: React.FC = () => {
  const { t } = useTranslation('app');
  const actualityId = useRecoilValue(actualitySelectedId);
  const activityFeedSummary = useRecoilValue(feedDatasetObject);

  const [extraSettings, setExtraSettings] = useRecoilState(
    actualityExtraSettings,
  );
  const [callUpdateActuality] = useUpdateActualityMutation();

  function updateExtraSettings(patch: Partial<ActualityExtraSettings>) {
    const newSettings = {
      ...extraSettings,
      ...patch,
    };
    setExtraSettings(newSettings);
    callUpdateActuality({
      variables: {
        input: {
          actualityId,
          extraSettings: newSettings,
        },
      },
    });
  }

  const activityDatasetType = 'activityFeed';

  const activityFilterOverrides = _.find(
    extraSettings.datasetExtraFieldsSettings,
    f => f.datasetType === activityDatasetType,
  )?.fieldsSettings as DatasetExtraFieldSettings[];

  const filterOverridesMap = _.keyBy(
    activityFilterOverrides,
    f => activityDatasetType,
  );

  return (
    <PanelContainer
      id="panel-extra-fields"
      title={t`Extra fields`}
      collapsible
      hasPadding
    >
      <DatasetExtraFieldsSettingsEditor
        sectionId="activity-extra-fields"
        sectionTitle={t`Activity feed`}
        extraFields={activityFeedSummary.summary?.rawDataFields}
        extraFieldsSettings={activityFilterOverrides}
        onChange={_.noop}
      />
    </PanelContainer>
  );
};

export default ActualityExtraFieldsSettings;
