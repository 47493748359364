import {
  ImportFormat,
  ImportJobFragment,
  ImportJobSourceCompression,
  ImportPipelineFragment,
} from '@warebee/frontend/data-access-api-graphql';
import _ from 'lodash';
import { atom, selector, selectorFamily } from 'recoil';

import { PanelFixedHeight } from '../../common/component.types';
import { IMPORTER_SCHEMA_VERSION } from '../../common/constants';
import { persistAtom } from '../../common/recoil/persistAtom';
import { AsyncLoadStatus } from '../../common/types';
import {
  warehouseSelected,
  warehouseSelectedId,
} from '../../store/warehouse.state';
import { getImportConfiguration } from './import.default';
import {
  AutoImportStage,
  DatasetType,
  ImportMenuItemId,
  ImportSettingsMap,
  ImportSourceSettings,
  MappingFieldSettings,
  MappingPreviewViewAs,
  MappingSettings,
  ParsedFilePreview,
  UploadChunkStatus,
  UploadStatus,
  ValidationError,
} from './import.types';

const getKey = (postfix: string) =>
  `warebee-import-csv-${postfix}-${IMPORTER_SCHEMA_VERSION}`;

export const importTypeCurrent = atom<DatasetType>({
  key: getKey('type-current'),
  default: null,
});

export const importSettingsAtomAll = atom<ImportSettingsMap>({
  key: getKey('schema-by-warehouse'),
  default: {},
});

export const importMappingSettingsByType = selectorFamily<
  MappingSettings<Object>,
  DatasetType
>({
  key: getKey('schema-by-type'),
  get:
    docType =>
    ({ get }) => {
      const wh = get(warehouseSelected);
      if (_.isNil(wh) || _.isNil(docType)) return null;
      const currentSetting = get(importSettingsAtomAll)[wh.id]?.[docType];
      const defaultSettings =
        getImportConfiguration(docType).mappingSettingsDefault;
      return currentSetting ?? defaultSettings;
    },

  set:
    docType =>
    ({ get, set }, value: MappingSettings<object>) => {
      const whId = get(warehouseSelectedId);
      if (_.isNil(whId)) return null;
      const current = get(importSettingsAtomAll);
      set(importSettingsAtomAll, {
        ...current,
        [whId]: {
          ...(current[whId] ?? {}),
          [docType]: value,
        },
      });
    },
});

export const importUnmappedFields = selectorFamily<
  MappingFieldSettings<Object>[],
  DatasetType
>({
  key: getKey('unmapped-fields'),
  get:
    type =>
    ({ get }) => {
      return _(get(importMappingSettingsByType(type)).fields)
        .filter(f => _.isNil(f.columnName))
        .value();
    },
});

export const importSelectedFile = atom<File>({
  key: getKey('file'),
  default: null,
});

export const importRawPreview = atom<ParsedFilePreview>({
  key: getKey('parsed-file-preview'),
  default: null,
});

export const importRawPreviewStatus = atom<AsyncLoadStatus>({
  key: getKey('parsed-file-preview-status'),
  default: AsyncLoadStatus.None,
});

export const importTransformedPreview = atom<ParsedFilePreview>({
  key: getKey('transformed-file-preview'),
  default: null,
});

export const importTransformedPreviewStatus = atom<AsyncLoadStatus>({
  key: getKey('transformation-status'),
  default: AsyncLoadStatus.None,
});

export const importParsedFile = selector<ParsedFilePreview>({
  key: getKey('parsed-file'),
  get: ({ get }) => {
    const raw = get(importRawPreview);
    const transformed = get(importTransformedPreview);
    return transformed ?? raw;
  },
});

export const importValidationStatus = atom<AsyncLoadStatus>({
  key: getKey('validation-status'),
  default: AsyncLoadStatus.None,
});

export const importValidationErrors = atom<ValidationError[]>({
  key: getKey('validation-errors'),
  default: [],
});

export const importValidationErrorsGrouped = selector<ValidationError[]>({
  key: getKey('validation-errors-grouped'),
  get: ({ get }) => {
    const errors = get(importValidationErrors);

    const aggregated = _(errors)
      .groupBy(
        e =>
          `${e.property}-${e.csvColumn}-${
            e.code === 'InvalidEnumValue' || e.code === 'InvalidBooleanValue'
              ? e.value
              : e.code
          }`,
      )
      .map((items, key) => ({
        ..._.head(items),
        count: _.size(items),
      }))
      .value();

    return aggregated;
  },
});

export const importCanUpload = selector<boolean>({
  key: getKey('can-upload'),
  get: ({ get }) => {
    const type = get(importTypeCurrent);
    const hasFile = !_.isNil(get(importRawPreview));
    if (type === 'dataset') {
      return hasFile;
    }
    const unmapped = get(importUnmappedFields(type));
    const allMapped =
      _(unmapped)
        .filter(f => !f.optional)
        .size() === 0;
    //const hasErrors = !_.isEmpty(get(importValidationErrors));
    return hasFile && allMapped;
  },
});

/*
 *  JOB progress  and upload progress
 */
export const importJob = atom<ImportJobFragment>({
  key: getKey('job'),
  default: null,
});

export const importUploadChunkStatusAtom = atom<
  Record<string, UploadChunkStatus>
>({
  key: getKey('chun-upload-status-atom'),
  default: {},
});

export const importUploadChunkStatus = selectorFamily<
  UploadChunkStatus,
  string
>({
  key: getKey('chun-upload-status'),
  get:
    key =>
    ({ get }) =>
      get(importUploadChunkStatusAtom)?.[key],
  set:
    key =>
    ({ get, set }, value) => {
      const current = get(importUploadChunkStatusAtom);
      set(importUploadChunkStatusAtom, {
        ...current,
        [key]: value,
      });
    },
});

export const importUploadProgress = selector<UploadStatus>({
  key: getKey('upload-progress-summary'),
  get: ({ get }) => {
    const allChunks = get(importUploadChunkStatusAtom);
    return _.reduce(
      _.values(allChunks),
      (acc, chunk) => ({
        jobId: chunk.jobId,
        status: Math.max(acc.status, chunk.status),
        loaded: acc.loaded + chunk.loaded,
        total: acc.total + chunk.total,
        errors: [...acc.errors, ...(chunk.errors ?? [])],
      }),
      {
        jobId: null,
        status: AsyncLoadStatus.None,
        loaded: 0,
        total: 0,
        errors: [],
      },
    );
  },
});

/*
 *  Importer wizard
 */
export const importSelectedStep = atom<ImportMenuItemId>({
  key: getKey('selected-step'),
  default: 'import-getting-started',
});

export const importMenuEnabledItemsIds = selector<Set<ImportMenuItemId>>({
  key: getKey('menu-items-enabled'),
  get: ({ get }) => {
    const type = get(importTypeCurrent);
    const mappingSettings = get(importMappingSettingsByType(type));

    const hasTransformation = mappingSettings?.transformation?.enabled ?? false;

    let enabledStepIds: Set<ImportMenuItemId> = new Set<ImportMenuItemId>([
      'import-getting-started',
      'import-select-csv',
    ]);
    const uploadStatus = get(importUploadStatus);
    const processStatus = get(importProcessStatus);
    if (
      uploadStatus === AsyncLoadStatus.Loading ||
      processStatus === AsyncLoadStatus.Loading
    ) {
      enabledStepIds = new Set<ImportMenuItemId>(['import-upload-data']);
    } else {
      const previewData = get(importRawPreview);
      const transformedData = get(importTransformedPreview);
      if (!_.isNil(previewData) && _.isEmpty(previewData.errors)) {
        enabledStepIds.add('import-transform');
        if (
          !hasTransformation ||
          (!_.isNil(transformedData) && _.isEmpty(transformedData.errors))
        ) {
          enabledStepIds.add('import-map-fields');
        }
      }
    }
    return enabledStepIds;
  },
});

export const importProcessStatus = atom<AsyncLoadStatus>({
  key: getKey('processing-status'),
  default: AsyncLoadStatus.None,
});

export const importUploadStatus = atom<AsyncLoadStatus>({
  key: getKey('upload-status'),
  default: AsyncLoadStatus.None,
});

export const importPreviewRowIndex = atom<number>({
  key: getKey('preview-row-index'),
  default: 0,
});

export const importMappingPreviewHeight = atom<PanelFixedHeight>({
  key: getKey('mapping-preview-height'),
  default: 'h-default',
});

export const importMappingPreviewViewAs = atom<MappingPreviewViewAs>({
  key: getKey('mapping-preview-view-as'),
  default: 'data',
});

export const importSourceSettings = atom<ImportSourceSettings>({
  key: getKey('source-settings'),
  default: {
    format: ImportFormat.CSV,
    compression: ImportJobSourceCompression.NO,
  },
});

export const importSourceShouldUploadFile = selector<boolean>({
  key: getKey('source-should-upload-file'),
  get: ({ get }) => get(importSourceSettings).format !== ImportFormat.DATASET,
});

export const importTargetDatasetId = atom<string>({
  key: getKey('target-dataset-id'),
  default: null,
});

export const showAdvancedImportSetup = persistAtom<boolean>({
  key: 'show-advanced-import-setup',
  default: false,
});

export const showTargetImportSetup = persistAtom<boolean>({
  key: 'show-target-import-setup',
  default: false,
});

// -- Auto import  with pipelines
const importAutoImportStageAll = atom<Record<DatasetType, AutoImportStage>>({
  key: getKey('auto-import-stage-all'),
  default: null,
});

export const importAutoImportStage = selectorFamily<
  AutoImportStage,
  DatasetType
>({
  key: getKey('auto-import-stage'),
  get:
    type =>
    ({ get }) => {
      return get(importAutoImportStageAll)?.[type] ?? 'initializing';
    },
  set:
    (type: DatasetType) =>
    ({ set }, value) => {
      set(importAutoImportStageAll, current => ({ ...current, [type]: value }));
    },
});

const importAutoImportPipelinesAll = atom<
  Record<DatasetType, ImportPipelineFragment>
>({
  key: getKey('auto-import-pipelines'),
  default: null,
});

export const importAutoImportPipeline = selectorFamily<
  ImportPipelineFragment,
  DatasetType
>({
  key: getKey('auto-import-stage'),
  get:
    type =>
    ({ get }) => {
      return get(importAutoImportPipelinesAll)?.[type];
    },
  set:
    (type: DatasetType) =>
    ({ set }, value) => {
      set(importAutoImportPipelinesAll, current => ({
        ...current,
        [type]: value,
      }));
    },
});

export const importPreviewSizeLimit = persistAtom<number>({
  key: 'import-preview-size-limit',
  default: 1024 * 1024, // 1 MB
});
