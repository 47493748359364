import _ from 'lodash';
import React, { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { InputGroupList } from '../../../../components/inputs/InputGroupList';
import { ContainerScroll } from '../../../../components/layout/ContainerScroll';
import { StatGroup } from '../../../../components/stats/StatGroup';
import { updateAllPolicyFilterValues } from '../../../../policyFilters/policyFilter.helper';
import useLoadLocationFilterValues from '../../../../policyFilters/useLoadLocationFilterValues';
import { getLocationFilterConfigCommon } from '../../../store/assignmentPolicy.default';
import AssignmentOccupancyFilterEditor from './AssignmentOccupancyFilterEditor';

const AssignmentOccupancyFilterPanel: React.FC = () => {
  const { t } = useTranslation('simulation');
  const [startLoadData, cancelLoadData] = useLoadLocationFilterValues();

  const locationsFilterConfig = getLocationFilterConfigCommon(t);
  useEffect(() => {
    updateAllPolicyFilterValues({
      filterConfig: locationsFilterConfig,
      selectedFilterId: null,
      policyFilterUnion: null, //rule.locationsMatch,
      load: startLoadData,
      cancel: cancelLoadData,
    });
  }, []);

  return (
    <ContainerScroll className="h-full">
      <StatGroup
        className="!my-0"
        classNameStatGroupItems="!mt-0 !pt-0"
        classNameTitle="!text-xs"
        title={t`Items filter`}
      />
      <InputGroupList>
        {_.map(locationsFilterConfig, filterConfig => {
          return (
            <AssignmentOccupancyFilterEditor
              key={`item-set-filter-${filterConfig.type}`}
              filterConfig={filterConfig}
              startLoadData={startLoadData}
              cancelLoadData={cancelLoadData}
            />
          );
        })}
      </InputGroupList>
    </ContainerScroll>
  );
};

export default AssignmentOccupancyFilterPanel;
