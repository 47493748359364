import _ from 'lodash';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { useRecoilState } from 'recoil';
import ButtonToolbar from '../../components/actions/ButtonToolbar';
import { ButtonToolbarGroup } from '../../components/actions/ButtonToolbarGroup';
import DropdownSelector from '../../components/actions/DropdownSelector';
import { getOccupancyCategories } from '../store/occupancy/assignmentOccupancy.helper';
import {
  assignmentOccupancyCategoryFilter,
  assignmentOccupancyMetricSelected,
  assignmentOccupancyShowHeatmap,
  assignmentOccupancyShowHeatmapLegend,
} from '../store/occupancy/assignmentOccupancy.state';
import { occupancyMetrics } from '../store/occupancy/assignmentOccupancy.types';
import useAssignmentOccupancyMetricDescriptors from '../store/occupancy/useAssignmentOccupancyMetricDescriptors';
import { LegendItem, LegendItemProps } from './LegendItem';

const AssignmentOccupancyToolbar: React.FC = () => {
  const { t } = useTranslation('simulation');

  const [showHeatmap, setShowHeatmap] = useRecoilState(
    assignmentOccupancyShowHeatmap,
  );
  const [showHeatmapLegend, setShowHeatmapLegend] = useRecoilState(
    assignmentOccupancyShowHeatmapLegend,
  );
  const [filters, setFilters] = useRecoilState(
    assignmentOccupancyCategoryFilter,
  );
  const [metricId, setMetricId] = useRecoilState(
    assignmentOccupancyMetricSelected,
  );
  const allMetrics = useAssignmentOccupancyMetricDescriptors();
  const allMetricsMap = _.keyBy(allMetrics, m => m.type);
  const hasFiltered = !_.isEmpty(filters);
  const toggleBucketFilter = (bucketKey: string) => {
    if (_.has(filters, bucketKey)) {
      setFilters(_.omit(filters, bucketKey));
    } else {
      setFilters({
        ...filters,
        [bucketKey]: true,
      });
    }
  };

  const getItems = () => {
    return _.map(getOccupancyCategories(t), config => {
      const legendProps: LegendItemProps = {
        color: config.color,
        text: config.title,
        isHidden: hasFiltered && !_.has(filters, config.key),
        onClick: () => toggleBucketFilter(config.key),
      };
      return (
        <ButtonToolbar key={`legend-item-${config.key}`}>
          <LegendItem {...legendProps} />
        </ButtonToolbar>
      );
    });
  };
  return (
    <ButtonToolbarGroup
      className={`absolute top-1 flex flex-col ltr:left-1 rtl:right-1`}
    >
      {/* <TitleToolbarContainer
        id="panel-toolbar-analytics-heatmap-occupancy"
        title={t`Analytics`}
        collapsible
      >
        <div className="flex items-start">
          <ButtonToolbar isSelected={showHeatmap} onChange={setShowHeatmap}>
            <Icon.HeatMap9
              className="h-8 w-8 text-white"
              alt={t`Show Occupancy heatmap`}
              title={t`Show Occupancy heatmap`}
            />
          </ButtonToolbar>
        </div>
      </TitleToolbarContainer> */}
      {showHeatmap && showHeatmapLegend && (
        <>
          {/* <ButtonToolbarWrapper className="p-0.5"> */}
          <DropdownSelector
            DropDown
            DropAlignRight
            buttonTransparent
            policyMode
            // classNameDropDownMenu="w-[5.5rem]"
            className="text-xs"
            values={occupancyMetrics}
            value={metricId}
            renderValue={m => allMetricsMap[m].title}
            onChange={m => setMetricId(m)}
          />
          {/* </ButtonToolbarWrapper> */}
          {/* <div> */}
          {getItems()}

          {/* </div> */}
        </>

        // <TitleToolbarContainer
        //   id="panel-toolbar-heatmap-occupancy"
        //   title={t`Occupancy %`}
        //   collapsible
        //   className="mt-2"
        // >
        //   <div>{getItems()}</div>
        // </TitleToolbarContainer>
      )}
    </ButtonToolbarGroup>
  );
};

export default AssignmentOccupancyToolbar;
