import { ReassignJobsDataRow } from '@warebee/shared/export-converter';
import { default as classNames } from 'classnames';
import { TFunction } from 'i18next';
import _ from 'lodash';
import { Formatter } from '../../../common/useFormatter';
import ButtonLocateLocation from '../../../components/ButtonLocateLocation';
import { ColumnConfig } from '../../../components/DatasetTable';
import * as Icon from '../../../components/icons';
import ItemCategoryTag from '../../../components/table/ItemCategoryTag';
import { getMoveTypeDescriptors } from '../../store/simulation.helper';
import EmptyValueTag from '../../tags/EmptyValueTag';
import ItemTag from '../../tags/ItemTag';
import UomTag from '../../tags/UomTag';

export function getAllocateReassignJobTableConfigDefault(
  t: TFunction<'simulation'>,
  formatter: Formatter,
): ColumnConfig<ReassignJobsDataRow>[] {
  const moveTypes = _.keyBy(getMoveTypeDescriptors(t), t => t.id);

  return [
    {
      field: 'stepId',
      title: t`Step ID`,
      isHeader: true,
      hasFilter: true,
      render: (value: string, row) => {
        return (
          <span className="flex w-full items-center justify-center rounded-full text-xs">
            <span className="bg-app-panel-dark/60 text-menu-active mx-0.5 flex h-6 w-full items-center justify-start rounded-full px-3 py-1 shadow-md">
              {value}
            </span>
          </span>
        );
      },
    },
    {
      field: 'consignee',
      title: t`Client (Consignee)`,
      isHeader: false,
      hasFilter: true,
    },
    {
      field: 'sku',
      title: t`Item (SKU)`,
      isHeader: false,
      hasFilter: true,
      render: (sku: string, row) => (
        <ItemTag title={sku} filters={{ consignee: row['consignee'], sku }} />
      ),
    },
    {
      field: 'item.cumulativePercentRank',
      title: t`Category`,
      hasSort: false,
      hiddenInExport: true,
      render: (v: number) => <ItemCategoryTag cmlPercent={v} />,
    },
    {
      field: 'categorySting',
      title: t`Category`,
      hiddenInBrowser: true,
    },

    // {
    //   field: 'srcLocationId',
    //   title: t`From`,
    //   render: (locationId: string, row) => (
    //     <ButtonLocateLocation locationId={locationId} />
    //   ),
    // },
    // {
    //   field: 'srcLocationId',
    //   title: t`From`,
    //   hasFilter: true,
    //   render: (locationId: string, row) => {
    //     // const colorIndex = (row.sequenceNumber - 1) % moveSequenceColors.length;
    //     // const bg = moveSequenceColors[colorIndex];
    //     return (
    //       <>
    //         <div
    //           className={classNames(
    //             // 'w-full',
    //             'w-4',
    //             'h-full',
    //             'absolute right-0 left-0- top-0 bottom-0 z-0',
    //             'opacity-50 rounded-l-lg',
    //           )}
    //           // className="w-full h-full absolute right-0 left-0 top-0 bottom-0 z-0 opacity-50 rounded-l-lg"
    //           // style={{ background: bg }}
    //         ></div>
    //         <ButtonLocateLocation
    //           locationId={locationId}
    //           // color={[bg, 'black']}
    //           // colorBorder={bg}
    //           // colorText={bg}
    //           classNameIcon={classNames(
    //             'bg-location-srcLocations text-menu-text rotate-90',
    //           )}
    //           className={classNames(
    //             'group/location',
    //             'w-full rounded-lg',
    //             'text-xs',
    //             'border border-location-srcLocations',
    //             'any-hover:group-hover/location:text-white',
    //             // 'border border-menu-400',
    //             'shadow-lg mr-4',
    //             'z-100',
    //             'bg-menu-500',
    //           )}
    //         />
    //       </>
    //     );
    //   },
    // },
    // {
    //   field: 'destAction',
    //   title: t``,
    //   render: (value: string, row, isSelected) => {
    //     const MoveTypeIcon = moveTypes[value]?.icon;
    //     if (_.isNil(MoveTypeIcon)) return value;
    //     return (
    //       <div
    //         className={classnames('flex items-center justify-center w-6 h-6', {
    //           'text-menu-active': isSelected,
    //           'text-menu-200 any-hover:group-hover:text-menu-active': !isSelected,
    //         })}
    //       >
    //         <MoveTypeIcon className="w-6 h-6 fill-current" />
    //       </div>
    //     );
    //   },
    // },
    {
      field: 'srcLocationId',
      title: t`From`,
      hasFilter: true,
      render: (locationId: string, row) => {
        if (!locationId) {
          return <EmptyValueTag />;
        }

        const borderClass =
          row.destAction === 'PUT'
            ? 'border-location-reassignSwap'
            : 'border-location-srcLocations';

        const bgClass =
          row.destAction === 'PUT'
            ? 'bg-location-reassignSwap'
            : 'bg-location-srcLocations';

        return (
          <ButtonLocateLocation
            locationId={locationId}
            icon={Icon.Show}
            classNameIcon={classNames(
              // 'rotate-90',
              'text-menu-active-text',
              bgClass,
            )}
            className={classNames(
              'group/location',
              'w-full',
              'text-xs',
              'border',
              'group-hover/location:text-menu-text',
              'shadow hover:shadow-lg',
              // 'ltr:mr-4 rtl:ml-4',
              '!px-1',
              'z-100',
              'bg-sidebar-header/50 hover:bg-sidebar-header/75',
              borderClass,
            )}
          />
        );
      },
    },
    {
      field: 'process',
      title: t`Process`,
      isHeader: false,
      hasFilter: true,
    },
    {
      field: 'destLocationId',
      title: t`To`,
      hasFilter: true,
      render: (locationId: string, row) => {
        if (!locationId) {
          return <EmptyValueTag />;
        }

        const borderClass =
          row.destAction === 'PUT'
            ? 'border-location-reassignSwap'
            : 'border-location-srcLocations';

        const iconClass =
          row.destAction === 'PUT'
            ? 'text-location-reassignSwap'
            : 'text-location-srcLocations';

        return (
          <>
            <div
              className={classNames(
                'w-4',
                'h-full',
                'absolute bottom-0 left-0 right-0 top-0 z-0',
                'rounded-r-lg opacity-50',
              )}
            ></div>
            <ButtonLocateLocation
              locationId={locationId}
              icon={Icon.Show}
              classNameIcon={classNames(iconClass)}
              className={classNames(
                'group/location',
                'w-full',
                'text-xs',
                'border',
                'group-hover/location:text-menu-text',
                'shadow hover:shadow-lg',
                // 'ltr:mr-4 rtl:ml-4',
                '!px-1',
                'z-100',
                'bg-sidebar-header/50 hover:bg-sidebar-header/75',
                borderClass,
              )}
            />
          </>
        );
      },
    },
    {
      field: 'movedQuantity',
      title: t`UOMs`,
      hiddenInExport: true,
      render: (value, row) => {
        const breakdownByUom =
          row?.movedQuantity?.breakdownByUom?.map(item => ({
            uom: item.uom || '',
            quantity: item.uomQuantity,
          })) || [];

        return (
          <div>
            {breakdownByUom.map((item, index) => (
              <div key={index}>
                <UomTag
                  uom={item.uom}
                  value={item.quantity}
                  // className="!bg-transparent"
                />
              </div>
            ))}
          </div>
        );
      },
    },
    {
      field: 'uomString',
      title: t`UOM`,
      hiddenInBrowser: true,
    },
    // {
    //   field: 'movedQuantity',
    //   title: t`Quantity`,
    //   hiddenInBrowser: true,
    //   hasSort: true,
    //   render: (value, row) => {
    //     const breakdownByUom = row.movedQuantity.breakdownByUom.map(item => ({
    //       uom: item.uom || '',
    //       quantity: item.uomQuantity,
    //     }));
    //     return breakdownByUom.map(item => item.quantity);
    //   },
    // },
    {
      field: 'jobDuration',
      title: t`Duration`,
      hasSort: true,
      render: (v: number) => formatter.formatTimeSpan(v).fullString,
    },
    {
      field: 'jobDistance',
      title: t`Distance`,
      hasSort: true,
      render: (v: number) => formatter.formatDistance(v).fullString,
    },
    {
      field: 'jobCost',
      title: t`Cost`,
      render: (v: number) => formatter.formatCurrency(v).raw.toFixed(2),
    },
    // {
    //   field: 'groupId',
    //   title: t`Group Index`,
    //   hiddenInBrowser: true,
    // },
    // {
    //   field: 'chainNumber',
    //   title: t`Chain Index`,
    //   hiddenInBrowser: true,
    // },
    // {
    //   field: 'sequenceNumber',
    //   title: t`Sequence`,
    //   hiddenInBrowser: true,
    // },
    {
      field: 'item.name',
      title: t`Item Name`,
      isHeader: false,
      hasFilter: true,
    },
    {
      field: 'item.description',
      title: t`Item Description`,
      isHeader: false,
      hasFilter: true,
    },
    {
      field: 'item.skuGroup',
      title: t`Item Group`,
      isHeader: false,
      hasFilter: true,
    },
    {
      field: 'item.subGroup',
      title: t`Item Sub Group`,
      isHeader: false,
      hasFilter: true,
    },
    {
      field: 'item.storageClass',
      title: t`Item Storage Class`,
      isHeader: false,
      hasFilter: true,
    },
    {
      field: 'item.stockCategory',
      title: t`Item Stock Category`,
      isHeader: false,
      hasFilter: true,
    },
    {
      field: 'item.transportClass',
      title: t`Item Transport Class`,
      isHeader: false,
      hasFilter: true,
    },
    {
      field: 'item.pollutionClass',
      title: t`Item Pollution Class`,
      isHeader: false,
      hasFilter: true,
    },
  ];
}
