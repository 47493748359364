import { QueryCreator, sql } from 'kysely';
import { getAnalyzeEventsFilteredQueryBuilder } from './analyzedEventsQueryBuilder';
import { AnalyzedFeedEventsQueryBuilderParams } from './analyzedFeedEventsQueryBuilder';
import { DatasetDatabase } from './datasetQueryBuilder';

export type AnalyzedFeedJobsQueryBuilderReturnType = ReturnType<
  typeof analyzedFeedJobsQueryBuilder
>;

export function analyzedFeedJobsQueryBuilder<T extends DatasetDatabase>(
  params: AnalyzedFeedEventsQueryBuilderParams,
  db: QueryCreator<T>,
) {
  const builder = db
    .with(
      cte => cte('aje'),
      db => {
        return getAnalyzeEventsFilteredQueryBuilder(
          params,
          db as unknown as QueryCreator<DatasetDatabase>,
        ).selectAll();
      },
    )
    .with(
      cte => cte('aj'),
      db => {
        return db
          .selectFrom('aje')
          .select(({ fn }) => [
            'jobId',
            'processType',
            'jobDate',
            fn.count<number>('jobLine').distinct().as('analyzedLines'),
            fn.sum<number>('cost').as('cost'),
            fn.sum<number>('duration').as('duration'),
            //TRAVELLING START/END
            fn
              .sum<number>('duration')
              .filterWhere('eventType', 'in', [
                'TRAVELLING_HORIZONTAL_START',
                'TRAVELLING_HORIZONTAL_END',
              ] as any)
              .as('durationTravellingStartEnd'),
            fn
              .sum<number>(
                sql`cast(json_query(${sql.ref('details')}, 'strict $.distance') as double)`,
              )
              .filterWhere('eventType', 'in', [
                'TRAVELLING_HORIZONTAL_START',
                'TRAVELLING_HORIZONTAL_END',
              ] as any)
              .as('distanceStartEnd'),

            //HANDLING
            fn
              .sum<number>('duration')
              .filterWhere('eventType', 'in', [
                'PRE_HANDLING',
                'HANDLING_PREPARATION',
                'HANDLING_ON_COMPLETION',
                'POST_HANDLING',
              ] as any)
              .as('durationHandling'),

            //PICKING
            fn
              .sum<number>('duration')
              .filterWhere('eventType', 'in', ['HANDLING_EXECUTION'] as any)
              .as('durationPicking'),

            //PICKING
            fn
              .sum<number>('duration')
              .filterWhere('eventType', 'in', ['TRAVELLING_VERTICAL'] as any)
              .as('durationTravellingVertical'),

            //TRAVELLING
            fn
              .sum<number>('duration')
              .filterWhere('eventType', 'in', ['TRAVELLING_HORIZONTAL'] as any)
              .as('durationTravelling'),

            //REORDERING
            fn
              .sum<number>('duration')
              .filterWhere('eventType', 'in', [
                'HANDLING_PALLET_REORDERING',
              ] as any)
              .as('durationReordering'),

            fn
              .sum<number>(
                sql`cast(json_query(${sql.ref('details')}, 'strict $.distance') as double)`,
              )
              .filterWhere('eventType', 'in', [
                'TRAVELLING_HORIZONTAL_START',
                'TRAVELLING_HORIZONTAL',
                'TRAVELLING_HORIZONTAL_END',
              ] as any)
              .as('distance'),
          ])
          .groupBy(['jobId', 'processType', 'jobDate']);
      },
    );
  return builder;
}

// [PicklistEventType.PRE_HANDLING]: 'handling',
// [PicklistEventType.HANDLING_PREPARATION]: 'handling',
// [PicklistEventType.HANDLING_ON_COMPLETION]: 'handling',
// [PicklistEventType.POST_HANDLING]: 'handling',
// [PicklistEventType.HANDLING_PALLET_REORDERING]: 'reordering',
// [PicklistEventType.HANDLING_EXECUTION]: 'picking',
// [PicklistEventType.TRAVELLING_HORIZONTAL]: 'travellingHorizontal',
// [PicklistEventType.TRAVELLING_VERTICAL]: 'travellingVertical',
// [PicklistEventType.TRAVELLING_HORIZONTAL_START]: 'travellingEndpoint',
// [PicklistEventType.TRAVELLING_HORIZONTAL_END]: 'travellingEndpoint',
