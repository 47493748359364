import { Field, ID, ObjectType } from '@warebee/shared/util-backend-only-types';

@ObjectType()
export class PicklistTerminalLocationDetails {
  @Field(() => ID)
  locationId: string;

  @Field()
  distance: number;

  @Field()
  duration: number;

  @Field()
  cost: number;
}

@ObjectType()
export class PicklistDetails {
  @Field(() => ID, { nullable: true })
  startLocationId?: string;

  @Field(() => ID, { nullable: true })
  endLocationId?: string;

  @Field(() => ID, { nullable: true })
  shipmentId?: string;

  @Field(() => [PicklistTerminalLocationDetails], { nullable: true })
  possibleEndLocations?: PicklistTerminalLocationDetails[];
}
