import classNames from 'classnames';
import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useRecoilState, useRecoilValue } from 'recoil';
import { formatShare } from '../common/formatHelper';
import { AsyncLoadStatus } from '../common/types';
import FileUpload from '../components/FileUpload';
import * as Icon from '../components/icons';
import { Container } from '../components/layout/ContainerFlex';
import { ProgressBar } from '../components/progressbar/ProgressBar';
import { getDatasetConfigurations } from '../import/store/import.default';
import { DatasetType } from '../import/store/import.types';
import useUploadPipelineFile from './hooks/useUploadPipelineFile';
import {
  importPipelineFilesUploadProgress,
  importPipelineFilesUploadStatus,
} from './store/importPipelines.state';

export type PipelineFileUploaderProps = {
  pipelineId: string;
  dataType?: DatasetType;
  className?: string;
  viewMode?: 'header' | 'table' | 'dashboard' | 'panel' | 'minimal';
};

const PipelineFileUploader: React.FC<PipelineFileUploaderProps> = props => {
  const { t } = useTranslation('importer');
  const importConfigurations = getDatasetConfigurations(t);
  const datasetConfig = importConfigurations.find(
    config => config.dataType === props.dataType,
  );
  const dataTypeTitle = datasetConfig?.title;
  const DataTypeIcon = datasetConfig?.icon ?? Icon.FiletypeAny;

  const [upload] = useUploadPipelineFile();
  const [status, setStatus] = useRecoilState(importPipelineFilesUploadStatus);
  const progress = useRecoilValue(importPipelineFilesUploadProgress);
  const inProgress = status === AsyncLoadStatus.Loading;
  const [filename, setFilename] = useState<string>('');

  useEffect(() => {
    if (status === AsyncLoadStatus.Ok) {
      setTimeout(() => setStatus(AsyncLoadStatus.None), 3000);
    }
  }, [status]);

  const isCompleted = status === AsyncLoadStatus.Ok;
  return (
    <div
      data-component="PipelineFileUploader"
      className={classNames('flex h-full w-full flex-col', props.className)}
    >
      <div className={classNames('max-h-20', inProgress ? 'p-4' : '')}>
        {inProgress && (
          <ProgressBar
            progress={formatShare(progress.loaded / progress.total, t)}
            action={t`Uploading...`}
            message={`${filename}`}
            viewMode="compact"
          />
        )}

        {isCompleted && (
          <div
            className={classNames(
              'flex items-center justify-between',
              'w-full',
              props.viewMode === 'header' ? 'px-4 py-2' : 'px-2 py-2',
              'text-center',
              'text-base',
              'rounded',
              'bg-app-panel-dark/60',
            )}
          >
            <Icon.CircleOk
              className={classNames(
                props.viewMode === 'header' ? 'h-8 w-8' : 'h-6 w-6',
                'mx-4',
                'fill-alerts-okay',
                'text-menu-active/70 group-hover:text-menu-active/30',
              )}
            />
            <span
              className={classNames(
                'text-menu-active/70 group-hover:text-menu-active/30',
                'flex flex-1 items-center',
              )}
            >
              <span className={classNames('ltr:mr-1 rtl:ml-1')}>
                {t(`Uploaded`)}
              </span>
            </span>
          </div>
        )}
        {!inProgress && !isCompleted && (
          <Container
            className={classNames(
              'group',
              'p-px',
              props.viewMode === 'panel'
                ? 'bg-barber-pole-xs bg-barber-size-xs rounded'
                : 'bg-barber-pole-sm bg-barber-size-sm',
              'bg-menu-active group-hover:bg-menu',
              'cursor-pointer',
            )}
          >
            <FileUpload
              accept="*"
              onChange={async file => {
                setFilename(file.name);
                upload({ pipelineId: props.pipelineId, file });
              }}
            >
              <div
                className={classNames(
                  // 'px-4',
                  'flex w-full flex-col items-center',
                )}
              >
                <div
                  className={classNames(
                    'flex items-center justify-between',
                    'w-full',
                    props.viewMode === 'header'
                      ? 'px-4 py-2'
                      : props.viewMode === 'panel'
                        ? 'px-2 py-4'
                        : 'px-2 py-2',
                    'text-center',
                    'text-sm',
                    'rounded',
                    'bg-app-panel-dark/60',
                  )}
                >
                  {props.viewMode !== 'panel' && (
                    <DataTypeIcon
                      className={classNames(
                        props.viewMode === 'header' ? 'h-8 w-8' : 'h-6 w-6',
                        'mx-4',
                        'fill-current',
                        'text-menu-active/70 group-hover:text-menu-active/30',
                      )}
                    />
                  )}
                  <span
                    className={classNames(
                      'text-menu-active/70 group-hover:text-menu-active/30',
                      'flex flex-1 items-center',
                    )}
                  >
                    <span className={classNames('ltr:mr-1 rtl:ml-1')}>
                      {t(`Drag or Click to upload the {{dataTypeTitle}}`, {
                        dataTypeTitle,
                      })}
                    </span>
                  </span>
                </div>
              </div>
            </FileUpload>
          </Container>
        )}
      </div>
    </div>
  );
};

export default PipelineFileUploader;
