import {
  AssignmentsMatchFragment,
  UnassignedLocationsMatch,
} from '@warebee/frontend/data-access-api-graphql';
import _ from 'lodash';
import { nanoid } from 'nanoid';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { useRecoilState, useRecoilValue } from 'recoil';
import { formatInteger } from '../common/formatHelper';
import { AsyncLoadStatus } from '../common/types';
import useFormatter from '../common/useFormatter';
import { PolicyFilter } from '../components/policies/PolicyFilter';
import PolicyFilterGroupList from '../components/policies/PolicyFilterGroupList';
import PolicyFilterItem from '../components/policies/PolicyFilterItem';
import { PolicyStatContainer } from '../components/policies/PolicyStatContainer';
import RuleSectionContainer from '../components/policies/RuleSectionContainer';
import { Stat } from '../components/stats/Stat';
import {
  getLocationFilterConfigCommon,
  getProductFilterConfigCommon,
} from '../simulation/store/assignmentPolicy.default';
import { optimisationIsEditable } from '../simulation/store/optimisation.state';
import { sidebarStateByType } from '../store/sidebar.state';
import { getSwapModeOptions } from './swapPolicy.helper';
import {
  swapPolicyCheckResult,
  swapPolicyRule,
  swapPolicySelectedIdentity,
} from './swapPolicy.state';
import {
  SwapPolicySelectedGroup,
  SwapPolicySelectedIdentity,
  SwapPolicySelectedType,
} from './swapPolicy.type';

export type SwapPolicyGroupProps = {
  title: string;
  ruleId: string;
  group: SwapPolicySelectedGroup;
};

/**
 * Part of SwapPolicy Rule related to FROM / TO filter group settings
 * @param props
 * @returns
 */
const SwapPolicyGroup: React.FC<SwapPolicyGroupProps> = props => {
  const { t } = useTranslation('simulation');
  const { ruleId, group } = props;
  const [rule, updateRule] = useRecoilState(swapPolicyRule(ruleId));
  const [selectedIdentity, setSelectedIdentity] = useRecoilState(
    swapPolicySelectedIdentity,
  );
  const [policyEditPanelState, setPolicyEditPanelState] = useRecoilState(
    sidebarStateByType('sidebar-policy-swap-editor'),
  );
  const isDisabled = !useRecoilValue(optimisationIsEditable);
  const checkResult = useRecoilValue(swapPolicyCheckResult);
  const formatter = useFormatter();

  const assignmentsMatch = group === 'source' ? rule.src : rule.dest;
  const isActiveRule = props.ruleId === selectedIdentity?.ruleId;

  function selectFilterIntersection(
    type: SwapPolicySelectedType,
    filterId: string,
  ) {
    const isClearing =
      type === 'mode'
        ? isActiveRule && selectedIdentity.type === 'mode'
        : _.isNil(filterId) || filterId === selectedIdentity?.filterId;

    const newSelectedIdentity: SwapPolicySelectedIdentity = isClearing
      ? null
      : {
          ruleId,
          filterId,
          type,
          group,
        };

    setSelectedIdentity(newSelectedIdentity);

    // trigger  toggle filter editor panel
    if (!policyEditPanelState.isPinned) {
      setPolicyEditPanelState({
        ...policyEditPanelState,
        isCollapsed: isClearing,
      });
    }
  }

  function addLocationIntersection() {
    const id = nanoid();
    const assignmentsMatch = group === 'source' ? rule.src : rule.dest;

    const newAssignmentsMatch: AssignmentsMatchFragment = {
      ...(assignmentsMatch ?? {}),
      locationsMatch: {
        anyOf: [
          ...(assignmentsMatch?.locationsMatch?.anyOf ?? []),
          { id, allOf: [] },
        ],
      },
    };
    updateRule({
      ...rule,
      src: group === 'source' ? newAssignmentsMatch : rule.src,
      dest: group === 'destination' ? newAssignmentsMatch : rule.dest,
    });
    selectFilterIntersection('locations', id);
  }

  function addProductIntersection() {
    const id = nanoid();
    const assignmentsMatch = group === 'source' ? rule.src : rule.dest;

    const newAssignmentsMatch: AssignmentsMatchFragment = {
      ...(assignmentsMatch ?? {}),
      itemsMatch: {
        anyOf: [
          ...(assignmentsMatch?.itemsMatch?.anyOf ?? []),
          { id, allOf: [] },
        ],
      },
    };
    updateRule({
      ...rule,
      src: group === 'source' ? newAssignmentsMatch : rule.src,
      dest: group === 'destination' ? newAssignmentsMatch : rule.dest,
    });
    selectFilterIntersection('items', id);
  }

  const removeField = (groupId: string, fieldId: string) => {
    const newAssignmentsMatch: AssignmentsMatchFragment = {
      ...(assignmentsMatch ?? {}),
      locationsMatch: {
        anyOf: _.map(assignmentsMatch.locationsMatch.anyOf, match => {
          if (match.id !== groupId) return match;
          return {
            id: groupId,
            allOf: match.allOf.filter(filter => filter.type !== fieldId),
          };
        }),
      },
      itemsMatch: {
        anyOf: _.map(assignmentsMatch.itemsMatch?.anyOf, match => {
          if (match.id !== groupId) return match;
          return {
            id: groupId,
            allOf: match.allOf.filter(filter => filter.type !== fieldId),
          };
        }),
      },
    };

    updateRule({
      ...rule,
      src: group === 'source' ? newAssignmentsMatch : rule.src,
      dest: group === 'destination' ? newAssignmentsMatch : rule.dest,
    });
  };

  function removeIntersection(groupId: string) {
    const newAssignmentsMatch: AssignmentsMatchFragment = {
      ...(assignmentsMatch ?? {}),
      locationsMatch: {
        anyOf: _.filter(
          assignmentsMatch.locationsMatch?.anyOf,
          fg => fg.id !== groupId,
        ),
      },
      itemsMatch: {
        anyOf: _.filter(
          assignmentsMatch.itemsMatch?.anyOf,
          fg => fg.id !== groupId,
        ),
      },
    };

    updateRule({
      ...rule,
      src: group === 'source' ? newAssignmentsMatch : rule.src,
      dest: group === 'destination' ? newAssignmentsMatch : rule.dest,
    });
    if (groupId === selectedIdentity?.filterId) {
      selectFilterIntersection('locations', null);
    }
  }
  const ruleCheckResult = _.find(
    checkResult?.result?.rules,
    r => r.id === ruleId,
  );
  const match =
    group === 'source' ? ruleCheckResult?.srcMatch : ruleCheckResult?.destMatch;

  const isSelectedGroup = selectedIdentity?.group === props.group;
  const isSelectedProduct =
    isSelectedGroup && selectedIdentity?.type === 'items';
  const isSelectedLocation =
    isSelectedGroup && selectedIdentity?.type === 'locations';
  const isSelectedMode = isSelectedGroup && selectedIdentity?.type === 'mode';
  const isLoading = checkResult?.status === AsyncLoadStatus.Loading;
  const hasIssue = ruleCheckResult?.hasValidSwaps === false;
  const filteredItemsCount = formatInteger(match?.itemCount ?? 0);
  const filteredLocationsCount = formatInteger(match?.locationCount ?? 0);
  const filteredAssignmentItemsCount = formatInteger(
    match?.assignmentItemCount ?? 0,
  );
  const filteredEmptyLocationCount = formatInteger(
    match?.emptyLocationCount ?? 0,
  );
  const modes = getSwapModeOptions(t);
  const selectedModeValue =
    assignmentsMatch?.unassignedLocationsMatch ??
    UnassignedLocationsMatch.EXCLUDE;
  const selectedMode = _.find(modes, m => m.id === selectedModeValue).title;
  const showEmptyLocationsStats =
    selectedModeValue === UnassignedLocationsMatch.UNASSIGNED_ONLY ||
    selectedModeValue === UnassignedLocationsMatch.INCLUDE;
  return (
    <div data-component="SwapPolicyGroup" className={'mx-1 mb-4'}>
      <div className="text-menu-active px-2 pb-2 pt-2 text-sm uppercase">
        {props.title}
      </div>
      <div
        className={
          'border-menu-500 bg-app-panel/70 rounded border p-2 shadow-xl'
        }
      >
        <div className="divide-menu-300/50 mb-2 flex flex-col divide-x lg:flex-row">
          <Stat
            hasNoPadding
            className={`bg-opacity-0 px-1 py-1 xl:px-2`}
            titleClassName="text-sm"
            //hasIssue={hasIssue}
            title={t`Item Stock`}
            value={filteredAssignmentItemsCount}
            inPanelMode
            inFilterStat
            toggleTable
            isCheckLoading={isLoading}
          />
          {showEmptyLocationsStats && (
            <Stat
              hasNoPadding
              className={`bg-opacity-0 px-1 py-1 xl:px-2`}
              titleClassName="text-sm"
              hasIssue={hasIssue}
              title={t`Empty Locations`}
              value={filteredEmptyLocationCount}
              inPanelMode
              inFilterStat
              toggleTable
              isCheckLoading={isLoading}
            />
          )}
        </div>

        {/**
         * ITEMS RELATED SECTION
         */}
        <div className="space-y-2">
          <RuleSectionContainer
            hasIssue={hasIssue}
            isSelected={isSelectedProduct}
          >
            <PolicyStatContainer>
              <Stat
                hasNoPadding
                className={`bg-opacity-0 px-1 py-1 xl:px-2`}
                hasIssue={hasIssue}
                title={t`Items`}
                value={filteredItemsCount}
                inPanelMode
                isActionable
                inFilterStat
                toggleTable
                isCheckLoading={isLoading}
                isSelected={isSelectedProduct}
                onClick={() =>
                  setSelectedIdentity(
                    isSelectedProduct
                      ? null
                      : {
                          ruleId,
                          filterId: null,
                          type: 'items',
                          group: props.group,
                        },
                  )
                }
              />
            </PolicyStatContainer>
            <PolicyFilterGroupList
              isDisabled={isDisabled}
              filterSets={_.map(
                assignmentsMatch?.itemsMatch?.anyOf,
                filterGroup => ({
                  id: filterGroup.id,
                  allOf: [...filterGroup.allOf],
                }),
              )}
              config={getProductFilterConfigCommon(t, formatter)}
              selectedId={selectedIdentity?.filterId}
              onAddGroup={addProductIntersection}
              onDeleteField={removeField}
              onSelectGroup={(id, isSelected) =>
                selectFilterIntersection('items', id)
              }
              onDeleteGroup={removeIntersection}
            />
          </RuleSectionContainer>

          {/**
           * Locations  RELATED SECTION
           */}
          <RuleSectionContainer
            hasIssue={hasIssue}
            isSelected={isSelectedLocation}
          >
            <PolicyStatContainer>
              <Stat
                hasNoPadding
                className={`bg-opacity-0 px-1 py-1 xl:px-2`}
                hasIssue={hasIssue}
                title={t`Locations`}
                // value={formatInteger(props.locationCount[1])}
                value={filteredLocationsCount}
                inPanelMode
                isActionable
                inFilterStat
                isCheckLoading={isLoading}
                //hasTooltip={t`Global Locations: ` + props.locationCount[0]}
                isSelected={isSelectedLocation}
                onClick={() =>
                  setSelectedIdentity(
                    isSelectedLocation
                      ? null
                      : {
                          ruleId,
                          filterId: null,
                          type: 'locations',
                          group: props.group,
                        },
                  )
                }
              />
            </PolicyStatContainer>
            <PolicyFilterGroupList
              isDisabled={isDisabled}
              filterSets={_.map(
                assignmentsMatch?.locationsMatch?.anyOf,
                filterGroup => ({
                  id: filterGroup.id,
                  allOf: [...filterGroup.allOf],
                }),
              )}
              config={getLocationFilterConfigCommon(t)}
              selectedId={selectedIdentity?.filterId}
              onAddGroup={addLocationIntersection}
              onDeleteField={removeField}
              onSelectGroup={(id, isSelected) =>
                selectFilterIntersection('locations', id)
              }
              onDeleteGroup={removeIntersection}
            />
          </RuleSectionContainer>

          <PolicyFilter
            label={t`Location selection:`}
            isActive={isSelectedMode}
            isDisabled={isDisabled}
            onClick={() => selectFilterIntersection('mode', null)}
          >
            <PolicyFilterItem
              name={t`Mode`}
              value={selectedMode}
              isActive={isSelectedMode}
              isDisabled={isDisabled}
              isRemovable={false}
            />
          </PolicyFilter>
        </div>
      </div>
    </div>
  );
};
export default SwapPolicyGroup;
