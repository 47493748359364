import { BatchJobStatus } from '@warebee/frontend/data-access-api-graphql';
import classNames from 'classnames';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { useRecoilValue } from 'recoil';
import AllocationRequirementGenerateButton from '../../allocation/AllocationRequirementGenerateButton';
import AllocationRequirementSummary from '../../allocation/AllocationRequirementSummary';
import AllocationRequirementTable from '../../allocation/AllocationRequirementTable';
import ErrorIndicator from '../../components/ErrorIndicator';
import InboxZero from '../../components/InboxZero';
import LoadingIndicator from '../../components/LoadingIndicator';
import { Container } from '../../components/layout/ContainerFlex';
import { allocationRequirement } from '../store/allocation/allocationRequirement.state';

const SimulationPickingRequirementView: React.FC = () => {
  const { t } = useTranslation('simulation');
  const requirement = useRecoilValue(allocationRequirement);

  const isLoading =
    requirement?.status === 'CALCULATING' ||
    requirement?.status === 'CREATED' ||
    requirement?.status === 'RESTARTED';
  const isReady = requirement?.status === BatchJobStatus.READY;
  const hasError = requirement?.status === BatchJobStatus.FAILED;

  return (
    <Container
      col
      overflow
      componentName={`SimulationAllocationViewContainer`}
      className={classNames(
        'border-app-panel-dark flex-1 ltr:border-l rtl:border-r',
      )}
    >
      {requirement === null ? (
        <InboxZero selfCenter message={t('No Requirement found')}>
          <AllocationRequirementGenerateButton />
        </InboxZero>
      ) : (
        <>
          {isLoading && (
            <LoadingIndicator
              message={t('Generating Requirement...')}
              selfCenter
            />
          )}
          {hasError && (
            <ErrorIndicator
              message={t('Error generating requirement...')}
              selfCenter
            />
          )}
          {isReady && (
            <>
              <AllocationRequirementSummary />
              <AllocationRequirementTable />
            </>
          )}
        </>
      )}
    </Container>
  );
};

export default SimulationPickingRequirementView;
