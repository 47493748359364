import {
  LayoutLocationsSummaryFragment,
  LayoutLocationsSummaryGroupBy,
  SimulationItemDataFragment,
} from '@warebee/frontend/data-access-api-graphql';
import { LocationsStatsDataColumn } from '@warebee/shared/export-converter';
import _ from 'lodash';
import { atom, selector, selectorFamily } from 'recoil';
import { persistAtom } from '../../common/recoil/persistAtom';
import { AsyncLoadStatus } from '../../common/types';
import { actualitySelectedDocument } from '../../feed/store/actuality.state';
import { LocationsStatsDimensionId } from '../tableViews/locationsStatsTable.config';
import {
  PolicyFilterData,
  PolicyFilterGroupType,
  PolicyFilteredItems,
} from './policyFilter.types';
import {
  simulationAssignmentId,
  simulationItemSetId,
  simulationLayoutId,
} from './simulation.state';

const getKey = (postfix: string) =>
  `warego-simulation-policy-filters-${postfix}`;

export const policyFilterEffectiveLayoutId = selector<string>({
  key: getKey('effective-layout-id'),
  get: ({ get }) => {
    return get(simulationLayoutId) ?? get(actualitySelectedDocument)?.layoutId;
  },
});

export const policyFilterEffectiveAssignmentId = selector<string>({
  key: getKey('effective-item-set-id'),
  get: ({ get }) => {
    return (
      get(simulationAssignmentId) ??
      get(actualitySelectedDocument)?.assignmentId
    );
  },
});

export const policyFilterEffectiveItemSetId = selector<string>({
  key: getKey('effective-item-set-id'),
  get: ({ get }) => {
    return (
      get(simulationItemSetId) ?? get(actualitySelectedDocument)?.itemSetId
    );
  },
});

export const policyFiltersSearchValues = atom<Record<string, string>>({
  key: getKey('search-values'),
  default: {},
});

export const policyFilterDataAtom = atom<Record<string, PolicyFilterData>>({
  key: getKey('filter-values-all-atom'),
  default: {},
});

export const policyFilterData = selectorFamily<PolicyFilterData, string>({
  key: getKey('filter-values-all'),
  get:
    filterKey =>
    ({ get }) =>
      get(policyFilterDataAtom)?.[filterKey] ?? null,
  set:
    (filterKey: string) =>
    ({ get, set }, value) => {
      if (_.isEmpty(filterKey)) {
        console.error('Filter key is not provided');
        return;
      }
      const current = get(policyFilterDataAtom);
      set(policyFilterDataAtom, { ...current, [filterKey]: value });
    },
});

export const policyFilterSearchByFilterKey = selectorFamily<string, string>({
  key: getKey('search-by-filter'),
  get:
    (key: string) =>
    ({ get }) =>
      get(policyFiltersSearchValues)[key],
});

export const policyFilteredProducts = atom<
  PolicyFilteredItems<SimulationItemDataFragment>
>({
  key: getKey('filtered-simulation-products'),
  default: null,
});

export const policyFilteredProductsSearchValues = atom<Record<string, string>>({
  key: getKey('filtered-simulation-products-search-values'),
  default: {},
});

export const policyLocationsStatsDimension =
  persistAtom<LocationsStatsDimensionId>({
    key: getKey('locations-stats-dimension'),
    default: LayoutLocationsSummaryGroupBy.LOCATION_RACKING_TYPE,
  });

export const policyFilteredLocationStats = atom<
  LayoutLocationsSummaryFragment[]
>({
  key: getKey('locations-stats-by-rule'),
  default: null,
});

export const policyFilteredLocationsStatsSearchValues = atom<
  Partial<Record<LocationsStatsDataColumn, any>>
>({
  key: getKey('locations-stats-by-rule-search-values'),
  default: {},
});

export const policyFilteredLocationsStatsLoadStatus = atom<AsyncLoadStatus>({
  key: getKey('locations-stats-by-rule-load-status'),
  default: AsyncLoadStatus.None,
});

export const policyFilterCopyPasteBufferAtom = persistAtom<
  Record<PolicyFilterGroupType, any>
>({
  key: getKey('copy-paste-buffer-atom'),
  default: {
    item: null,
    location: null,
  },
});

export const policyFilterCopyPasteBuffer = selectorFamily<
  any,
  PolicyFilterGroupType
>({
  key: getKey('copy-paste-buffer'),
  get:
    group =>
    ({ get }) =>
      get(policyFilterCopyPasteBufferAtom)[group],
  set:
    group =>
    ({ set }, value) =>
      set(policyFilterCopyPasteBufferAtom, current => ({
        ...current,
        [group]: value,
      })),
});
