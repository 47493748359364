import { ImportItemSetLineInput } from '@warebee/shared/data-access-api-dto';
import { ITEM_SET_MAPPING_SCHEMA } from '@warebee/shared/import-converter';
import { TFunction } from 'i18next';
import { keyBy } from 'lodash';
import { MappingSettings } from '../../store/import.types';
import {
  consigneeAliases,
  skuAliases,
  uomAliases,
} from '../../store/sharedAliases';

export function getItemSetSchema(
  t: TFunction<'importer'>,
): MappingSettings<ImportItemSetLineInput> {
  const baseFields = keyBy(ITEM_SET_MAPPING_SCHEMA.fields, f => f.name);

  return {
    fields: [
      {
        ...baseFields['consignee'],
        title: t(`Client (Consignee)`, { ns: 'importer' }),
        description: t(
          `Client (consignee) — Name of your company (can be generated) or your client (For 3PLs)`,
          { ns: 'importer' },
        ),
        example: 'Client A',
        aliases: consigneeAliases,
      },
      {
        ...baseFields['sku'],
        title: t(`Item`, { ns: 'importer' }),
        description: t(`Item code (SKU)`, { ns: 'importer' }),
        example: 'SKU1234567890',
        aliases: skuAliases,
      },
      {
        ...baseFields['uom'],
        title: t(`UOM`, { ns: 'importer' }),
        description: t(
          `UOM — Unit of Measure, such as Each/Piece, Inner, Case, Layer or Pallet`,
          { ns: 'importer' },
        ),
        example: 'PALLET',
        aliases: uomAliases,
      },
      {
        ...baseFields['skuGroup'],
        title: t(`Item Group`, { ns: 'importer' }),
        description: t(`Item Group name or group id`, { ns: 'importer' }),
        example: 'Group A',
        aliases: [
          'skugroup',
          'itemgroup',
          'itmgroup',
          'productgroup',
          'producttype',
          'group',
          'series',
          'groupid',
          'productgroupid',
          'itmtype',
          'itm_type',
          'type',
          'division',
          'category',
          'commodity',
          'family_group',
          'familygroup',
          'articlegroup',
          'article_group',
        ],
      },
      {
        ...baseFields['lowerUom'],
        title: t(`Lowest UOM`, { ns: 'importer' }),
        description: t(
          `Lowest unit of measure of the item that stock balance is managed in ("Each"), Pallet > Case > Inner > Each`,
          { ns: 'importer' },
        ),
        example: 'EACH',
        aliases: [
          'loweruom',
          'lowestuom',
          'lowuom',
          'lowlowunit',
          'lowunit',
          'isbaseunit',
          'baseunit',
          'baseuom',
          'basearticle',
          'minuom',
          'subuom',
        ],
      },
      {
        ...baseFields['unitsPerLowestUom'],
        title: t(`Units Per Lowest UOM`, { ns: 'importer' }),
        description: t(
          `Quantity expressed in the smallest Unit of Measure (UOM). For instance, the number of individual items within a Case or Pallet.`,
          { ns: 'importer' },
        ),
        example: '300',
        aliases: [
          'unitsperlowestuom',
          'unitsperlowuom',
          'unitsperloweruom',
          'uplom',
          'cpp',
          'upu',
          'packsize',
        ],
      },
      {
        ...baseFields['netWeight'],
        title: t(`Weight`, { ns: 'importer' }),
        description: t(`Weight of the UOM`, { ns: 'importer' }),
        example: '10',
        aliases: [
          'netWeight',
          'weight',
          'weight(g)',
          'weight(kg)',
          'weightgrams',
          'weightg',
          'weightkilograms',
          'weightkg',
          'weightkgs',
          'itmwgt',
          'itemwgt',
          'wgt',
          'weight(kgs)',
          'weight(lb)',
          'weight(lbs)',
          'uomweight',
          'uomweight(g)',
          'uomweight(kg)',
          'uomweight(kgs)',
          'uomweight(lb)',
          'uomweight(lbs)',
          'palletweight',
          'palletweight(g)',
          'palletweight(kg)',
          'palletweight(kgs)',
          'palletweight(lb)',
          'palletweight(lbs)',
          'caseweight',
          'caseweight(g)',
          'caseweight(kg)',
          'caseweight(kgs)',
          'caseweight(lb)',
          'caseweight(lbs)',
          'itemweight',
          'itemweight(g)',
          'itemweight(kg)',
          'itemweight(kgs)',
          'itemweight(lb)',
          'itemweight(lbs)',
          'eachweight',
          'eachweight(g)',
          'eachweight(kg)',
          'eachweight(kgs)',
          'eachweight(lb)',
          'eachweight(lbs)',
        ],
      },
      {
        ...baseFields['length'],
        title: t(`Length (Depth)`, { ns: 'importer' }),
        description: t(`Length of the UOM`, { ns: 'importer' }),
        example: '10',
        aliases: [
          'l',
          'length',
          'lenght',
          'lenght',
          'length(mm)',
          'length(cm)',
          'length(m)',
          'length(in)',
          'length(ft)',
          'lengthmm',
          'lengthcm',
          'lengthm',
          'lengthin',
          'lengthft',
          'uomlength',
          'uomlength(mm)',
          'uomlength(cm)',
          'uomlength(m)',
          'uomlength(in)',
          'uomlength(ft)',
          'palletlength',
          'palletlength(mm)',
          'palletlength(cm)',
          'palletlength(m)',
          'palletlength(in)',
          'palletlength(ft)',
          'caselength',
          'caselength(mm)',
          'caselength(cm)',
          'caselength(m)',
          'caselength(in)',
          'caselength(ft)',
          'itemlength',
          'itemlength(mm)',
          'itemlength(cm)',
          'itemlength(m)',
          'itemlength(in)',
          'itemlength(ft)',
          'eachlength',
          'eachlength(mm)',
          'eachlength(cm)',
          'eachlength(m)',
          'eachlength(in)',
          'eachlength(ft)',
          'itmlgt',
          'itmlen',
          'len',
          'lgt',
          'lengtheach',
          'lengthcase',
          'lengthpallet',
          'depth',
          'uomdepth',
          'uomdepth(mm)',
          'uomdepth(cm)',
          'uomdepth(m)',
          'uomdepth(in)',
          'uomdepth(ft)',
          'palletdepth',
          'palletdepth(mm)',
          'palletdepth(cm)',
          'palletdepth(m)',
          'palletdepth(in)',
          'palletdepth(ft)',
          'casedepth',
          'casedepth(mm)',
          'casedepth(cm)',
          'casedepth(m)',
          'casedepth(in)',
          'casedepth(ft)',
          'itemdepth',
          'itemdepth(mm)',
          'itemdepth(cm)',
          'itemdepth(m)',
          'itemdepth(in)',
          'itemdepth(ft)',
          'eachdepth',
          'eachdepth(mm)',
          'eachdepth(cm)',
          'eachdepth(m)',
          'eachdepth(in)',
          'eachdepth(ft)',
          'skueachdepth',
          'skueachlength',
          'deptheach',
          'depthitem',
          'depthcase',
          'depthpallet',
          'sku_each_depth',
          'd',
          'y',
          'sizey',
        ],
      },
      {
        ...baseFields['width'],
        title: t(`Width`, { ns: 'importer' }),
        description: t(`Width of the UOM`, { ns: 'importer' }),
        example: '10',
        aliases: [
          'w',
          'width',
          'width(mm)',
          'width(cm)',
          'width(m)',
          'width(ft)',
          'width(in)',
          'widthmm',
          'widthcm',
          'widthm',
          'widthft',
          'widthin',
          'uomwidth',
          'uomwidth(mm)',
          'uomwidth(cm)',
          'uomwidth(m)',
          'uomwidth(ft)',
          'uomwidth(in)',
          'palletwidth',
          'palletwidth(mm)',
          'palletwidth(cm)',
          'palletwidth(m)',
          'palletwidth(ft)',
          'palletwidth(in)',
          'casewidth',
          'casewidth(mm)',
          'casewidth(cm)',
          'casewidth(m)',
          'casewidth(ft)',
          'casewidth(in)',
          'itemwidth',
          'itemwidth(mm)',
          'itemwidth(cm)',
          'itemwidth(m)',
          'itemwidth(ft)',
          'itemwidth(in)',
          'eachwidth',
          'eachwidth(mm)',
          'eachwidth(cm)',
          'eachwidth(m)',
          'eachwidth(ft)',
          'eachwidth(in)',
          'itmwid',
          'wdth',
          'widthuom',
          'widtheach',
          'widthitem',
          'widthcase',
          'widthpallet',
          'skueachwidth',
          'wid',
          'w',
          'szwdth',
          'x',
          'sizex',
        ],
      },
      {
        ...baseFields['height'],
        title: t(`Height`, { ns: 'importer' }),
        description: t(`Height of the UOM`, { ns: 'importer' }),
        example: '10',
        aliases: [
          'h',
          'height',
          'height(mm)',
          'height(cm)',
          'height(m)',
          'height(in)',
          'height(ft)',
          'heightmm',
          'heightcm',
          'heightm',
          'heightin',
          'heightft',
          'uomheight',
          'uomheight(mm)',
          'uomheight(cm)',
          'uomheight(m)',
          'uomheight(in)',
          'uomheight(ft)',
          'palletheight',
          'palletheight(mm)',
          'palletheight(cm)',
          'palletheight(m)',
          'palletheight(ft)',
          'palletheight(in)',
          'caseheight',
          'caseheight(mm)',
          'caseheight(cm)',
          'caseheight(m)',
          'caseheight(in)',
          'caseheight(ft)',
          'itemheight',
          'itemheight(mm)',
          'itemheight(cm)',
          'itemheight(m)',
          'itemheight(in)',
          'itemheight(ft)',
          'eachheight',
          'eachheight(mm)',
          'eachheight(cm)',
          'eachheight(m)',
          'eachheight(in)',
          'eachheight(ft)',
          'itmhgt',
          'hgt',
          'caseheight',
          'palletheight',
          'heightuom',
          'heighteach',
          'heightitem',
          'heightcase',
          'heightpallet',
          'skueachheight',
          'hght',
          'szhght',
          'z',
          'sizez',
        ],
      },
      {
        ...baseFields['volume'],
        title: t(`Volume`, { ns: 'importer' }),
        description: t(`Volume of the UOM`, { ns: 'importer' }),
        example: '1000',
        aliases: [
          'v',
          'vol',
          'vl',
          'volume',
          'volumemmcubed',
          'volumecmcubed',
          'volume(mm3)',
          'volume(cm3)',
          'volume(m3)',
          'volume(in3)',
          'volume(ft3)',
          'volumemm3',
          'volumecm3',
          'volumem3',
          'volumein3',
          'volumeft3',
          'volumemm',
          'volumecm',
          'volumem',
          'volumein',
          'volumeft',
          'uomvolume',
          'uomvolume(mm3)',
          'uomvolume(cm3)',
          'uomvolume(m3)',
          'uomvolume(in3)',
          'uomvolume(ft3)',
          'palletvolume',
          'palletvolume(mm3)',
          'palletvolume(cm3)',
          'palletvolume(m3)',
          'palletvolume(in3)',
          'palletvolume(ft3)',
          'casevolume',
          'casevolume(mm3)',
          'casevolume(cm3)',
          'casevolume(m3)',
          'casevolume(in3)',
          'casevolume(ft3)',
          'itemvolume',
          'itemvolume(mm3)',
          'itemvolume(cm3)',
          'itemvolume(m3)',
          'itemvolume(in3)',
          'itemvolume(ft3)',
          'eachvolume',
          'eachvolume(mm3)',
          'eachvolume(cm3)',
          'eachvolume(m3)',
          'eachvolume(in3)',
          'eachvolume(ft3)',
          'skueachvolume',
          'volumeuom',
          'volumeeach',
          'volumeitem',
          'volumecase',
          'volumepallet',
          'cubic',
          'mm3',
          'cm3',
          'dm3',
          'm3',
          'in3',
          'ft3',
        ],
      },
      {
        ...baseFields['ean'],
        title: t('Scan Code: EAN', { ns: 'importer' }),
        description: t(
          'EAN: The EAN is similar to the UPC but contains 13 digits, more common internationally. It includes a country code, manufacturer code, product code, and a check digit. An example of an EAN could be 5001234567890.',
          { ns: 'importer' },
        ),
        aliases: ['ean'],
      },
      {
        ...baseFields['upc'],
        title: t('Scan Code: UPC', { ns: 'importer' }),
        description: t(
          'UPC: The UPC is a 12-digit code primarily used in North America. It typically consists of a manufacturer code, a product code, and a check digit. For example, a UPC might look like 012345678905., or any other QR or Barcode Type',
          {
            ns: 'importer',
          },
        ),
        aliases: ['upc'],
      },

      {
        ...baseFields['name'],
        title: t('Item Name', { ns: 'importer' }),
        description: t('Item Name - Item Title', { ns: 'importer' }),
        example: 'Item A',
        aliases: [
          'name',
          'title',
          'item_name',
          'itemname',
          'product_name',
          'productname',
          'article_name',
          'articlename',
          'warehouse_sku_description',
        ],
      },

      {
        ...baseFields['description'],
        title: t('Item Description', { ns: 'importer' }),
        description: t('Item Description - Item description metadata', {
          ns: 'importer',
        }),
        example: 'Item A',
        aliases: [
          'description',
          'desc',
          'dsc',
          'item_desc',
          'itm_desc',
          'itm_dsc',
          'itemdesc',
          'itmdesc',
          'product_desc',
          'productdesc',
          'sku_desc',
          'sku_label',
          'sku_description',
          'article_description',
          'articledescription',
          'articledesc',
        ],
      },

      {
        ...baseFields['subGroup'],
        title: t('Item Sub-Group', { ns: 'importer' }),
        description: t(
          'Item Sub Group, or any additional product category, or classification',
          { ns: 'importer' },
        ),
        example: 'Sub Group A',
        aliases: [
          'subGroup',
          'sub_group',
          'item_sub_group',
          'inventory_sub_group',
          'label_group',
          'additionalarticlegroups',
        ],
      },

      {
        ...baseFields['transportClass'],
        title: t('Item Transport Class', { ns: 'importer' }),
        description: t(
          'Item Transport Class, can be used as Sort by in Policies',
          { ns: 'importer' },
        ),
        example: '01-A',
        aliases: [
          'transportClass',
          'transport_class',
          'transport',
          'transport_type',
          'transporttype',
          'transport_id',
          'transportid',
          'trans',
          'trans_id',
          'transid',
          'pick_sequence_sku',
          'picksequencesku',
          'picksequence',
        ],
      },
      {
        ...baseFields['stockCategory'],
        title: t('Item Stock category', { ns: 'importer' }),
        description: t(
          'Special stock indicator and number, can be used as Sort by in Policies',
          {
            ns: 'importer',
          },
        ),
        example: '1-LIGHT',
        aliases: [
          'stockCategory',
          'cat',
          'stock_cat',
          'stock_category',
          'stock_id',
          'packingCategory',
        ],
      },
      {
        ...baseFields['storageClass'],
        title: t('Storage class', { ns: 'importer' }),
        description: t('Storage Class/type, Packing Group, DOT Code, etc.', {
          ns: 'importer',
        }),
        example: '1-CLASS',
        aliases: [
          'storageClass',
          'class',
          'storage_class',
          'warehouse_sku_storage_type',
          'sku_storage_type',
          'storage_type',
          'handlingclass',
          'storage',
          'storage_id',
          'type',
        ],
      },
      {
        ...baseFields['pollutionClass'],
        title: t('Pollution class', { ns: 'importer' }),
        description: t(
          'Hazardous Material Classifications or Water pollution class (Hazmat SKU Label)',
          {
            ns: 'importer',
          },
        ),
        example: '3 Class 3: Flammable Liquid',
        aliases: [
          'pollutionClass',
          'pollution_class',
          'pollution',
          'hazard_class',
          'hazardclass',
          'hazmat',
          'material_class',
          'dot',
          'dot_code',
          'dot_class',
        ],
      },
      {
        ...baseFields['maxStack'],
        title: t('Max Stackibility', { ns: 'importer' }),
        description: t('Max item Stackibility', {
          ns: 'importer',
        }),
        example: '0 or no value - Unlimited,1 level, 2 - levels, etc.',
        aliases: [
          // Generic/Common terms
          'maxStack',
          'minStack',
          'stack_class',
          'stack',
          'stack_code',
          'stack_type',
          'stack_id',
          'stack_seq',
          'stack_sequence',
          'stackability_code',
          'itemstackability',
          'stackability',
          'stackingorder',
          'stackid',

          // Blue Yonder (JDA) specific
          'stacking_height',
          'stackingheight',
          'max_stacking_height',
          'maxstackingheight',
          'stacking_limit_factor',
          'stackinglimitfactor',
          'stacking_sequence',
          'stackingsequence',
          
          // Manhattan SCALE specific
          'stack_qty',
          'stackqty',
          'stack_quantity',
          'stackquantity',
          'max_stack_qty',
          'maxstackqty',
          'stacking_rule',
          'stackingrule',

          // SAP EWM/WM specific (uses abbreviated terms)
          'stck_max',
          'max_stck',
          'stack_spec',
          'stackspec',
          'stacking_indicator',
          'stackingindicator',
          'stacking_group',
          'stackinggroup',

          // Oracle WMS Cloud specific
          'stacking_tolerance',
          'stackingtolerance',
          'stack_tolerance_factor',
          'stacktolerancefactor',
          'max_stack_count',
          'maxstackcount',

          // Infor WMS specific
          'stack_height_max',
          'stackheightmax',
          'stack_limit_code',
          'stacklimitcode',
          'stacking_pattern',
          'stackingpattern',

          // HighJump/Körber specific
          'stack_max_qty',
          'stackmaxqty',
          'stack_max_height',
          'stackmaxheight',
          'stack_constraint',
          'stackconstraint',
          'stack_rule_id',
          'stackruleid',

          // Deposco specific
          'max_stack_level',
          'maxstacklevel',
          'stack_tier_max',
          'stacktiermax',

          // 3PL Central specific
          'pallet_stack_max',
          'palletstackmax',
          'stack_limit_max',
          'stacklimitmax'
        ],
      },
    ],
  };
}
