import {
  ImportFormat,
  ImportJobSourceCompression,
  ImportSource,
  useCreateImportJobMutation,
  useGetImportPreviewColumnsMutation,
  useGetImportTransformedPreviewLazyQuery,
  useLoadImportPreviewUrlLazyQuery,
} from '@warebee/frontend/data-access-api-graphql';
import { format } from 'date-fns';
import { GraphQLFormattedError } from 'graphql';
import _ from 'lodash';
import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useRecoilCallback, useRecoilValue } from 'recoil';
import { AsyncLoadStatus } from '../common/types';
import { cloneWithoutTypename } from '../common/utils';
import { importTriggeredBySim } from '../store/global.state';
import { warehouseSelectedId } from '../store/warehouse.state';
import {
  IMPORT_PREVIEW_DUMMY_QUERY,
  IMPORT_PREVIEW_QUERY,
  getImportConfiguration,
} from './store/import.default';
import {
  createDefaultMapping,
  getChunkKey,
  uploadFile,
} from './store/import.helper';
import {
  importJob,
  importMappingSettingsByType,
  importPreviewSizeLimit,
  importRawPreview,
  importRawPreviewStatus,
  importSourceSettings,
  importSourceShouldUploadFile,
  importTypeCurrent,
  importUploadChunkStatus,
} from './store/import.state';
import useApplyImportTransformation from './useApplyImportTransformation';
import useApplyMappingSettings from './useApplyMappingSettings';

export type UploadPreviewFileParams = {
  file: File;
};

function useUploadPreviewFile() {
  const { t } = useTranslation('errors');
  const [createJob] = useCreateImportJobMutation();
  const [loadImportPreviewUrl] = useLoadImportPreviewUrlLazyQuery();
  const [getTransformed] = useGetImportTransformedPreviewLazyQuery();
  const [preparePreview] = useGetImportPreviewColumnsMutation();
  const [applyTransformation] = useApplyImportTransformation();
  const applyMappings = useApplyMappingSettings();
  const errorTitle = t`Cannot load Preview.`;
  const [errorDetails, setErrorDetails] = useState<string | null>(null);

  const previewSize = useRecoilValue(importPreviewSizeLimit);

  const initLoading = useRecoilCallback(
    ({ snapshot, set }) =>
      async (params: UploadPreviewFileParams) => {
        set(importRawPreviewStatus, AsyncLoadStatus.Loading);
      },
  );

  const callLoad = useRecoilCallback(
    ({ snapshot, set }) =>
      async (params: UploadPreviewFileParams) => {
        const warehouseId = await snapshot.getPromise(warehouseSelectedId);
        const type = await snapshot.getPromise(importTypeCurrent);
        const targetSimulationId =
          await snapshot.getPromise(importTriggeredBySim);
        const importType = getImportConfiguration(type).jobType;
        const mappingSettings = await snapshot.getPromise(
          importMappingSettingsByType(type),
        );
        const sourceSettings = await snapshot.getPromise(importSourceSettings);
        const shouldUpload = await snapshot.getPromise(
          importSourceShouldUploadFile,
        );

        function handleApolloError(errors: readonly GraphQLFormattedError[]) {
          if (!_.isEmpty(errors)) {
            let details = '';
            if (_.isArray(errors)) {
              details = _(errors)
                .map(j => j?.message)
                .join(';');
            } else {
              details = (errors as any as GraphQLFormattedError).message;
            }
            handleError(details);
            return;
          }
        }

        function handleError(details?: string, stack?: string) {
          console.error(errorTitle, details, stack);
          set(importRawPreviewStatus, AsyncLoadStatus.Error);
          setErrorDetails(details);
          console.log('Error handled:', details);
        }
        if (shouldUpload && _.isNil(params.file)) {
          handleError(t`File is not selected`);
          return;
        }
        const chunkCount = 1;
        const filename = params.file?.name ?? 'dataset';

        const day = format(new Date(), 'yyyy-MM-dd');
        const targetTitle = `${filename}`;

        let blob: Blob;
        let targetSize = 0;
        let skipLastLine = false;

        try {
          const { data: jobData, errors: jobDataErrors } = await createJob({
            variables: {
              input: {
                warehouseId,
                importType,
                filename,
                targetTitle,
                targetId: targetSimulationId,
                sourceSettings: {
                  ...sourceSettings,
                  source:
                    sourceSettings.format === ImportFormat.DATASET
                      ? ImportSource.DATASET
                      : ImportSource.UPLOAD,
                },
                settings: {
                  csvOptions: cloneWithoutTypename(mappingSettings.csvOptions),
                  hasHeaders: mappingSettings.hasHeaders,
                },
              },
            },
          });
          handleApolloError(jobDataErrors);
          const job = jobData.createImportJob;
          const jobId = job.id;

          if (shouldUpload) {
            if (
              sourceSettings.compression === ImportJobSourceCompression.GZIP
            ) {
              try {
                const ds = new DecompressionStream('gzip');
                const reader = await params.file
                  .stream()
                  .pipeThrough(ds)
                  .getReader();
                const { done, value } = await reader.read();
                targetSize = Math.min(value?.length, previewSize);
                skipLastLine = value?.length > previewSize;
                blob = value.slice(0, targetSize);
              } catch (ex) {
                console.error(ex);
                handleError(t`Can't decompress source file`);
                return;
              }
            } else {
              targetSize = Math.min(params.file.size, previewSize);
              skipLastLine = params.file.size > previewSize;
              blob = params.file.slice(0, targetSize);
            }

            const { data: startUploadData, error: startUploadError } =
              await loadImportPreviewUrl({
                variables: {
                  jobId,
                  contentLength: targetSize,
                },
              });

            if (!_.isEmpty(startUploadError)) {
              handleError(startUploadError.message);
              return;
            }

            const uploadUrls = [startUploadData?.importJob?.previewUploadUrl];
            const etags: string[] = [];
            const chunkErrors = [];
            await Promise.all(
              _.range(chunkCount).map(i => {
                return new Promise<void>((resolve, reject) => {
                  const chunkId = getChunkKey(jobId, i);
                  uploadFile({
                    blob,
                    url: uploadUrls[i],

                    onComplete: (e, etag) => {
                      etags[i] = etag;
                      resolve();
                    },
                    onError: e => {
                      chunkErrors.push[chunkId];
                      reject();
                    },

                    onProgress: e => {
                      set(importUploadChunkStatus(chunkId), {
                        id: chunkId,
                        jobId,
                        status: AsyncLoadStatus.Loading,
                        loaded: e.loaded,
                        total: e.total,
                      });
                    },
                  });
                });
              }),
            );

            if (!_.isEmpty(chunkErrors)) {
              handleError('Chunk upload failed: ' + _.size(chunkErrors));
              return;
            }
          }

          const { data: completeJobData, errors: completeJobErrors } =
            await preparePreview({
              variables: {
                jobId,
                options: {
                  csvOptions: {
                    ...cloneWithoutTypename(mappingSettings.csvOptions),
                    skipFooterLines: skipLastLine ? 1 : 0,
                  },
                  hasHeader: mappingSettings.hasHeaders,
                },
              },
            });

          if (!_.isEmpty(completeJobErrors)) {
            const details = _(completeJobErrors)
              .map(j => j?.message)
              .join(';');
            handleError(details);
            return;
          }

          const columnNames = completeJobData.prepareImportJobPreviewTable;

          const { data: previewData, error: previewDataError } =
            await getTransformed({
              variables: {
                jobId,
                query:
                  job?.sourceSettings?.format === 'DATASET'
                    ? IMPORT_PREVIEW_DUMMY_QUERY
                    : IMPORT_PREVIEW_QUERY,
              },
            });

          set(importRawPreview, {
            data: previewData?.importJob?.previewQuery?.result,
            fields: columnNames,
            errors: _.isNil(previewDataError)
              ? null
              : [previewDataError?.message],
          });

          let mappedSettings = {
            ...mappingSettings,
            columnNames: columnNames,
          };
          set(importMappingSettingsByType(type), mappedSettings);

          if (mappingSettings?.transformation?.enabled) {
            applyTransformation({
              jobId,
              query: mappingSettings?.transformation?.query,
            });
          } else {
            const mappedSettingsDef = createDefaultMapping(
              mappedSettings,
              columnNames,
            );
            applyMappings({
              mappingSettings: mappedSettingsDef,
            });
          }

          set(importJob, jobData.createImportJob);
          set(importRawPreviewStatus, AsyncLoadStatus.Ok);
        } catch (ex) {
          handleError(ex?.message ?? ex);
        }
      },
  );

  async function call(params: UploadPreviewFileParams) {
    await initLoading(params);
    await callLoad(params);
  }

  function cancel() {
    // console.log('cancel handled:');
  }

  return [call, cancel, errorDetails] as const;
}
export default useUploadPreviewFile;
