import { ImportJobImportType } from '@warebee/frontend/data-access-api-graphql';
import { t } from 'i18next';
import _ from 'lodash';
import React, { useEffect } from 'react';
import { Navigate, RouteObject, useRoutes } from 'react-router-dom';
import { useRecoilValue } from 'recoil';
// import { APIPublic } from './API';
import { NoMatch404 } from './NoMatch404';
import Pricing from './Pricing';
import PricingPublic from './PricingPublic';
import AnalyzeCompareRoot from './analyzeCompare/AnalyzeCompareRoot';
import AppBillingPaymentStatus from './appBilling/AppBillingPaymentStatus';
import AppBillingPricing from './appBilling/AppBillingPricing';
import AppBillingPurchase from './appBilling/AppBillingPurchase';
import AssignmentView from './assignment/AssignmentView';
import { AsyncLoadStatus } from './common/types';
import { AppFooter } from './components/AppFooter';
import { AppHeader } from './components/AppHeader';
import ErrorIndicator from './components/ErrorIndicator';
import LoadingIndicator from './components/LoadingIndicator';
import { ContainerAppView } from './components/layout/ContainerAppView';
import DataDashboard from './dashboard/DataDashboard';
import { NotificationDashboard } from './dashboard/NotificationDashboard';
import SimulationsDashboard from './dashboard/SimulationsDashboard';
import DatasetAutomationRoot from './datasetAutomation/DatasetAutomationRoot';
import DatasetObjectRoot from './datasetObject/DatasetObjectRoot';
import ActualityDashboard from './feed/ActualityDashboard';
import FeedRoot from './feed/FeedRoot';
import ImportRoot from './import/ImportRoot';
import ConverterRoot from './import/layout/converter/ConverterRoot';
import ItemSetRoot from './itemSet/ItemSetRoot';
import LayoutRoot from './layout/LayoutRoot';
import OptimizationImplement from './optimization/OptimizationImplement';
import OrderSetView from './orders/OrderSetView';
import { Login } from './public/Login';
import { PasswordRecovery } from './public/PasswordRecovery';
import { Register } from './public/Register';
import { Registration } from './public/Registration';
import { Legal } from './public/legal/Legal';
import { LegalAgreements } from './public/legal/LegalAgreements';
import { LegalCompliance } from './public/legal/LegalCompliance';
import { LegalCookiePolicy } from './public/legal/LegalCookiePolicy';
import { LegalDisclaimer } from './public/legal/LegalDisclaimer';
import { LegalPrivacyPolicy } from './public/legal/LegalPrivacyPolicy';
import { LegalTermsPolicy } from './public/legal/LegalTermsPolicy';
import SidebarGlobalMenu from './screens/SidebarGlobalMenu';
import SidebarMenuWarehouses from './screens/SidebarMenuWarehouses';
import { About } from './screens/about/about';
import { Help } from './screens/help/Help';
import SidebarHelp from './screens/help/SidebarHelp';
import SidebarNotifications from './screens/notifications/SidebarNotifications';
import SidebarSettings from './screens/settings/SidebarSettings';
import Settings from './settings/Settings';
import SimulationRoot from './simulation/SimulationRoot';
import { authState } from './store/auth.state';
import {
  userSettings,
  userSettingsBase,
  warehouseDefaultRoute,
  warehouseList,
  warehouseListLoadStatus,
  warehouseSelected,
} from './store/warehouse.state';
import WarehouseContainer from './warehouse/WarehouseContainer';
import WarehouseRoot from './warehouse/WarehouseRoot';
import WarehouseSettings from './warehouse/WarehouseSettings';
import WarehouseDashboardBeta from './warehouse/beta/WarehouseDashboardBeta';
import WarehouseDashboardBilling from './warehouse/beta/WarehouseDashboardBilling';
import WarehouseDashboardContracts from './warehouse/beta/WarehouseDashboardContracts';
import WarehouseDashboardMarketplace from './warehouse/beta/WarehouseDashboardMarketplace';
import WarehouseDashboardSales from './warehouse/beta/WarehouseDashboardSales';
import WarehouseDesignerDashboard from './warehouse/designer/WarehouseDesignerDashboard';
import useLoadUserSettings from './warehouse/hooks/useLoadUserSettings';
import useLoadWarehouses from './warehouse/hooks/useLoadWarehouses';
import Welcome from './welcome/Welcome';

const PATH_WELCOME = 'welcome';
const PATH_LOGIN = '/login';

const Custom404 = () => (
  <>
    <AppHeader hasMenuGlobal hasUser hasHelp />
    <ContainerAppView>
      <NoMatch404
        subtitle={t`Not Found`}
        title={t`We couldn't find the page you are looking for`}
      />
    </ContainerAppView>
    <AppFooter />
  </>
);
interface WithPageTitleAndFaviconProps {
  title?: string;
  favicon?: string;
  element: React.ReactElement;
  includeWarehouseTitle?: boolean;
}

const usePageTitleAndFavicon = (
  title: string = t`WareBee`,
  faviconPath: string = 'app/assets/theme/img/favicon.ico',
  includeWarehouseTitle: boolean = false,
) => {
  const warehouse = useRecoilValue(warehouseSelected);

  useEffect(() => {
    const pageTitle =
      includeWarehouseTitle && warehouse?.title
        ? `${warehouse.title} | ${title}`
        : title;

    document.title = pageTitle;

    const link: HTMLLinkElement =
      document.querySelector("link[rel*='icon']") ||
      document.createElement('link');
    link.type = 'image/x-icon';
    link.rel = 'shortcut icon';
    link.href = faviconPath;
    document.getElementsByTagName('head')[0].appendChild(link);
  }, [title, faviconPath, warehouse, includeWarehouseTitle]);
};

const PageElement: React.FC<WithPageTitleAndFaviconProps> = ({
  title,
  favicon,
  element,
  includeWarehouseTitle,
  ...props
}) => {
  usePageTitleAndFavicon(title, favicon, includeWarehouseTitle);
  return React.cloneElement(element, props);
};

const UserCurrentRoute: React.FC = () => {
  const warehouseUserRoute = useRecoilValue(warehouseDefaultRoute);

  return <Navigate to={`${warehouseUserRoute}`} replace={true} />;
};

const warehouseRoutes: RouteObject[] = [
  {
    path: `/`,
    element: <UserCurrentRoute />,
  },
  {
    path: `simulations/*`,
    children: [
      {
        path: ``,
        element: (
          <PageElement
            title={t`Simulations`}
            includeWarehouseTitle={true}
            element={<SimulationsDashboard />}
          />
        ),
      },
      {
        path: 'compare',
        element: (
          <PageElement
            title={t`Compare`}
            includeWarehouseTitle={true}
            element={<AnalyzeCompareRoot />}
          />
        ),
      },
      {
        path: ':simulationId',
        element: (
          <PageElement
            title={t`Simulation`}
            includeWarehouseTitle={true}
            element={<SimulationRoot />}
          />
        ),
      },
      {
        path: `:simulationId/implement`,
        element: (
          <PageElement
            title={t`Implement`}
            includeWarehouseTitle={true}
            element={<OptimizationImplement hasControls={false} />}
          />
        ),
      },
    ],
  },
  {
    path: `slotting/*`,
    children: [
      {
        path: ``,
        element: <SimulationsDashboard />,
      },
    ],
  },
  {
    path: `simulation/slotting/*`,
    children: [
      {
        path: ``,
        element: <SimulationsDashboard />,
      },
      {
        path: 'compare',
        element: <AnalyzeCompareRoot />,
      },
      {
        path: ':simulationId',
        element: <SimulationRoot />,
      },
      {
        path: `:simulationId/implement`,
        element: <OptimizationImplement hasControls={false} />,
      },
    ],
  },

  {
    path: `feed/:actualityId/:isEmbedded`,
    element: (
      <PageElement
        title={t`Activity Feed`}
        includeWarehouseTitle={true}
        element={<FeedRoot />}
      />
    ),
  },
  {
    path: `feed/:actualityId`,
    element: (
      <PageElement
        title={t`Activity Feed`}
        includeWarehouseTitle={true}
        element={<FeedRoot />}
      />
    ),
  },
  {
    path: `feed/*`,
    element: (
      <PageElement
        title={t`Activity Feed`}
        includeWarehouseTitle={true}
        element={<ActualityDashboard />}
      />
    ),
  },
  {
    path: `data/*`,
    children: [
      {
        path: '',
        element: (
          <PageElement
            title={t`Data`}
            includeWarehouseTitle
            element={<DataDashboard selectedMenuId={'data-dash-data-all'} />}
          />
        ),
      },
      {
        path: 'layouts',
        element: (
          <PageElement
            title={t`Data | Layout`}
            includeWarehouseTitle
            element={<WarehouseDesignerDashboard />}
            // element={<DataDashboard selectedMenuId={'data-dash-layout'} />}
          />
        ),
      },
      {
        path: 'locations/integration',
        element: (
          <PageElement
            title={t`Data Integration | Locations`}
            includeWarehouseTitle
            element={
              <DatasetAutomationRoot datasetType={ImportJobImportType.LAYOUT} />
            }
          />
        ),
      },
      {
        path: 'assignments/integration',
        element: (
          <PageElement
            title={t`Data Integration | Assignment`}
            includeWarehouseTitle
            element={
              <DatasetAutomationRoot
                datasetType={ImportJobImportType.ASSIGNMENT}
              />
            }
          />
        ),
      },
      {
        path: 'assignments',
        element: (
          <PageElement
            title={t`Data | Assignment`}
            includeWarehouseTitle
            element={<DataDashboard selectedMenuId={'data-dash-assignment'} />}
          />
        ),
      },
      {
        path: 'orders/integration',
        element: (
          <PageElement
            title={t`Data Integration | Order`}
            includeWarehouseTitle
            element={
              <DatasetAutomationRoot
                datasetType={ImportJobImportType.ORDER_SET}
              />
            }
          />
        ),
      },
      {
        path: 'orders',
        element: (
          <PageElement
            title={t`Data | Order`}
            includeWarehouseTitle
            element={<DataDashboard selectedMenuId={'data-dash-order'} />}
          />
        ),
      },
      {
        path: 'items/integration',
        element: (
          <PageElement
            title={t`Data Integration | Items`}
            includeWarehouseTitle
            element={
              <DatasetAutomationRoot
                datasetType={ImportJobImportType.ITEM_SET}
              />
            }
          />
        ),
      },
      {
        path: 'items',
        element: (
          <PageElement
            title={t`Data | Items`}
            includeWarehouseTitle
            element={<DataDashboard selectedMenuId={'data-dash-items'} />}
          />
        ),
      },
      {
        path: 'feed/integration',
        element: (
          <PageElement
            title={t`Data Integration | Feed`}
            includeWarehouseTitle
            element={
              <DatasetAutomationRoot
                datasetType={ImportJobImportType.ACTIVITY_FEED}
              />
            }
          />
        ),
      },
      {
        path: 'activity/integration',
        element: (
          <PageElement
            title={t`Data Integration | Feed`}
            includeWarehouseTitle
            element={
              <DatasetAutomationRoot
                datasetType={ImportJobImportType.ACTIVITY_FEED}
              />
            }
          />
        ),
      },
      {
        path: 'dataset/integration',
        element: (
          <PageElement
            title={t`Data Integration | Dataset`}
            includeWarehouseTitle
            element={
              <DatasetAutomationRoot
                datasetType={ImportJobImportType.GENERIC}
              />
            }
          />
        ),
      },
      {
        path: 'policy/integration',
        element: (
          <PageElement
            title={t`Data Integration | Policies`}
            includeWarehouseTitle
            element={
              <DatasetAutomationRoot
                datasetType={ImportJobImportType.ASSIGNMENT_POLICY}
              />
            }
          />
        ),
      },
      {
        path: 'policy/ap/integration',
        element: (
          <PageElement
            title={t`Data Integration | Policy | Storage Policy`}
            includeWarehouseTitle
            element={
              <DatasetAutomationRoot
                datasetType={ImportJobImportType.ASSIGNMENT_POLICY}
              />
            }
          />
        ),
      },
      // temp
      {
        path: 'dataset',
        element: (
          <PageElement
            title={t`Data | Dataset`}
            includeWarehouseTitle
            element={
              <DataDashboard selectedMenuId={'data-dash-dataset-object'} />
            }
          />
        ),
      },
      {
        path: 'activity',
        element: (
          <PageElement
            title={t`Data | Activity Feed`}
            includeWarehouseTitle
            element={
              <DataDashboard
                selectedMenuId={'data-dash-dataset-activityFeed'}
              />
            }
          />
        ),
      },
      {
        path: 'datasets',
        element: (
          <PageElement
            title={t`Data | Dataset`}
            includeWarehouseTitle
            element={
              <DataDashboard selectedMenuId={'data-dash-dataset-object'} />
            }
          />
        ),
      },
      {
        path: 'policies',
        element: (
          <PageElement
            title={t`Data | Policies`}
            includeWarehouseTitle
            element={<DataDashboard selectedMenuId={'data-dash-policies'} />}
          />
        ),
      },
      {
        path: 'files',
        element: (
          <PageElement
            title={t`Data | Files`}
            includeWarehouseTitle
            element={<DataDashboard selectedMenuId={'data-dash-files'} />}
          />
        ),
      },
    ],
  },
  {
    path: 'layouts/:layoutId',
    element: (
      <PageElement
        title={t`Data | Layout`}
        includeWarehouseTitle
        element={<LayoutRoot />}
      />
    ),
  },
  {
    path: 'assignments/:assignmentId',
    element: (
      <PageElement
        title={t`Data | Assignment`}
        includeWarehouseTitle
        element={<AssignmentView />}
      />
    ),
  },
  {
    path: `orders/:orderSetId`,
    element: (
      <PageElement
        title={t`Data | Order`}
        includeWarehouseTitle
        element={<OrderSetView />}
      />
    ),
  },
  {
    path: `items/:itemSetId`,
    element: (
      <PageElement
        title={t`Data | Items`}
        includeWarehouseTitle
        element={<ItemSetRoot />}
      />
    ),
  },
  {
    path: 'dataset/:datasetObjectId',
    element: (
      <PageElement
        title={t`Data | Dataset`}
        includeWarehouseTitle
        element={<DatasetObjectRoot />}
      />
    ),
  },

  {
    path: `designer`,
    element: (
      <PageElement
        title={t`Designer`}
        includeWarehouseTitle
        element={<WarehouseDesignerDashboard />}
      />
    ),
  },
  {
    path: `beta`,
    element: (
      <PageElement
        title={t`Beta`}
        includeWarehouseTitle
        element={<WarehouseDashboardBeta />}
      />
    ),
  },
  {
    path: `billing`,
    element: (
      <PageElement
        title={t`Billing`}
        includeWarehouseTitle
        element={<WarehouseDashboardBilling />}
      />
    ),
  },
  {
    path: `contracts`,
    element: (
      <PageElement
        title={t`Billing`}
        includeWarehouseTitle
        element={<WarehouseDashboardContracts />}
      />
    ),
  },
  {
    path: `marketplace`,
    element: (
      <PageElement
        title={t`Marketplace`}
        includeWarehouseTitle
        element={<WarehouseDashboardMarketplace />}
      />
    ),
  },
  {
    path: `sales`,
    element: (
      <PageElement
        title={t`Sales`}
        includeWarehouseTitle
        element={<WarehouseDashboardSales />}
      />
    ),
  },
  {
    path: `import/*`,
    children: [
      {
        path: `layout/*`,
        children: [
          {
            path: ':layoutImportId/convert',
            element: (
              <PageElement
                title={t`Designer | Layout`}
                includeWarehouseTitle
                element={<ConverterRoot />}
              />
            ),
          },
          {
            path: ':jobId?',
            element: (
              <PageElement
                title={t`Import | Layout`}
                includeWarehouseTitle
                element={<ImportRoot type={'layout'} />}
              />
            ),
          },
        ],
      },
      {
        path: `assignment/:jobId?`,
        element: (
          <PageElement
            title={t`Import | Assignment`}
            includeWarehouseTitle
            element={<ImportRoot type={'assignment'} />}
          />
        ),
      },
      {
        path: `orders/:jobId?`,
        element: (
          <PageElement
            title={t`Import | Orders`}
            includeWarehouseTitle
            element={<ImportRoot type={'orders'} />}
          />
        ),
      },
      {
        path: `items/:jobId?`,
        element: (
          <PageElement
            title={t`Import | Items`}
            includeWarehouseTitle
            element={<ImportRoot type={'items'} />}
          />
        ),
      },
      {
        path: `ap/:jobId?`,
        element: (
          <PageElement
            title={t`Import | Policy`}
            includeWarehouseTitle
            element={<ImportRoot type={'assignmentPolicy'} />}
          />
        ),
      },
      {
        path: `activity/:jobId?`,
        element: (
          <PageElement
            title={t`Import | Activity Feed`}
            includeWarehouseTitle
            element={<ImportRoot type={'activityFeed'} />}
          />
        ),
      },
      {
        path: `dataset/:jobId?`,
        element: (
          <PageElement
            title={t`Import | Dataset`}
            includeWarehouseTitle
            element={<ImportRoot type={'dataset'} />}
          />
        ),
      },
    ],
  },
  {
    path: '/settings/*',
    children: [
      {
        path: ':sectionId',
        element: (
          <PageElement
            title={t`Settings`}
            includeWarehouseTitle
            element={<Settings />}
          />
        ),
      },
      {
        path: '*',
        element: (
          <PageElement
            title={t`Settings`}
            includeWarehouseTitle
            element={<Settings />}
          />
        ),
      },
    ],
  },

  /*
   * BILLING
   */
  {
    path: '/pricing/*',
    children: [
      {
        path: ':priceId',
        element: (
          <PageElement
            title={t`Pricing`}
            element={
              <WarehouseContainer fullHeaders={true}>
                <AppBillingPricing />
              </WarehouseContainer>
            }
          />
        ),
      },
      {
        path: '*',
        element: (
          <PageElement
            title={t`Pricing`}
            element={
              <WarehouseContainer fullHeaders={true}>
                <AppBillingPricing />
              </WarehouseContainer>
            }
          />
        ),
      },
    ],
  },

  {
    path: '/purchase/:priceId',
    element: (
      <>
        <AppHeader
          hasMenuGlobal
          hasHelp
          hasUser
          hasSetting
          hasMenuApps
          isPurchasing
        />
        <ContainerAppView>
          {/* <SidebarMenuWarehouses /> */}
          <AppBillingPurchase />
        </ContainerAppView>
        <AppFooter />
      </>
    ),
  },
  {
    path: '/payment/',
    element: (
      <>
        <AppHeader hasMenuGlobal hasSubscription hasHelp hasUser />
        <ContainerAppView>
          <SidebarMenuWarehouses />
          <AppBillingPaymentStatus hasStepper />
        </ContainerAppView>
        <AppFooter />
      </>
    ),
  },
  { path: '*', element: <NoMatch404 /> },
];

/**
 * Routes for non-authenticated users
 */
const publicRoutes: RouteObject[] = [
  { path: '/assets/docs/*', element: <Navigate to={PATH_LOGIN} /> },
  {
    path: PATH_LOGIN,
    element: <PageElement title={t`Login`} element={<Login />} />,
  },
  {
    path: '/register',
    element: <PageElement title={t`Register`} element={<Register />} />,
  },
  {
    path: '/verify',
    element: <PageElement title={t`Verify`} element={<PasswordRecovery />} />,
  },
  {
    path: '/forgot',
    element: (
      <PageElement title={t`Forgot Password`} element={<PasswordRecovery />} />
    ),
  },
  { path: '*', element: <Navigate to={PATH_LOGIN} /> },
];

/**
 * Routes for authenticated  but not configured users
 */
const restrictedRoutes: RouteObject[] = [
  { path: PATH_WELCOME, element: <Welcome /> },
  { path: '*', element: <Navigate to={PATH_WELCOME} /> },
];

/**
 * Routes for authenticated anc configured users
 */
const privateRoutes: RouteObject[] = [
  { path: '', element: <Navigate to="/wh/i" replace /> },
  { path: 'login', element: <Navigate to="/wh/i" replace /> },
  { path: 'welcome', element: <Navigate to="/wh/i" replace /> },
  {
    path: 'wh/*',
    children: [
      {
        path: 'new',
        element: (
          <PageElement
            title={t`New Warehouse`}
            element={<WarehouseSettings />}
          />
        ),
      },
      {
        path: 'i/*',
        children: [
          {
            path: ':warehouseId/edit',
            element: (
              <PageElement
                title={t`Edit Warehouse`}
                element={<WarehouseSettings />}
              />
            ),
          },
          {
            path: ':warehouseId/*',
            element: <WarehouseRoot routes={warehouseRoutes} />,
          },
          {
            path: '*',
            element: <WarehouseRoot routes={warehouseRoutes} />,
          },
        ],
      },
      { path: '', element: <Navigate to={'i'} replace={true} /> },
      { path: 'i/new', element: <>{t`Please contact Support`} </> },
    ],
  },
  {
    path: '/notifications',
    element: (
      <>
        <AppHeader hasMenuGlobal hasUser hasHelp />
        <ContainerAppView>
          <NotificationDashboard />
        </ContainerAppView>
        <AppFooter />
      </>
    ),
  },
  { path: '/help', element: <Help /> },
];

/**
 * Routes both for auth and no-auth users
 */
const commonRoutes: RouteObject[] = [
  {
    path: '/registration',
    element: (
      <PageElement title={t`WareBee Registration`} element={<Registration />} />
    ),
  },
  {
    path: '/pricing',
    element: (
      <PricingPublic
        modePreview={'FULL'}
        modeCategory={'SUBSCRIPTION'}
        modePrice={'PRICING_HIDE'}
        modeLink={'WEBSITE'}
      />
    ),
  },
  {
    path: '/pricing/new',
    element: (
      <Pricing
        modePreview={'FULL'}
        modeCategory={'SUBSCRIPTION'}
        modePrice={'PRICING_HIDE'}
        modeLink={'WEBSITE'}
      />
    ),
  },
  {
    path: '/pricing/bundle',
    element: (
      <PricingPublic
        modePreview={'FULL'}
        modeCategory={'BUNDLE'}
        modePrice={'PRICING_HIDE'}
        modeLink={'WEBSITE'}
      />
    ),
  },
  {
    path: '/pricing/onetime',
    element: (
      <PricingPublic
        modePreview={'FULL'}
        modeCategory={'BUNDLE'}
        modePrice={'PRICING_SHOW'}
        modeLink={'WEBSITE'}
      />
    ),
  },
  {
    path: '/pricing/subscription',
    element: (
      <PricingPublic
        modePreview={'FULL'}
        modeCategory={'SUBSCRIPTION'}
        modePrice={'PRICING_HIDE'}
        modeLink={'WEBSITE'}
      />
    ),
  },
  {
    path: '/pricing/subscribe',
    element: (
      <PricingPublic
        modePreview={'FULL'}
        modeCategory={'SUBSCRIPTION'}
        modePrice={'PRICING_SHOW'}
        modeLink={'WEBSITE'}
      />
    ),
  },

  // { path: '/api/import', element: <APIPublic /> },
  {
    path: '/legal',
    element: <PageElement title={t`WareBee | Legal`} element={<Legal />} />,
  },
  {
    path: '/legal/privacy',
    element: (
      <PageElement
        title={t`WareBee | Privacy Policy`}
        element={<LegalPrivacyPolicy />}
      />
    ),
  },
  {
    path: '/legal/policy-privacy',
    element: (
      <PageElement
        title={t`WareBee | Privacy Policy`}
        element={<LegalPrivacyPolicy />}
      />
    ),
  },
  {
    path: '/legal/cookie',
    element: (
      <PageElement
        title={t`WareBee | Cookie Policy`}
        element={<LegalCookiePolicy />}
      />
    ),
  },
  { path: '/legal/policy-cookie', element: <LegalCookiePolicy /> },
  { path: '/legal/terms', element: <LegalTermsPolicy /> },
  { path: '/legal/policy-terms', element: <LegalTermsPolicy /> },
  { path: '/legal/compliance', element: <LegalCompliance /> },
  { path: '/legal/agreement', element: <LegalAgreements /> },
  {
    path: '/legal/disclaimer',
    element: (
      <PageElement title={t`WareBee | Legal`} element={<LegalDisclaimer />} />
    ),
  },
  {
    path: '/legal/*',
    element: <PageElement title={t`WareBee | Legal`} element={<Legal />} />,
  },
  { path: '/about', element: <About /> },

  // {
  //   path: '/register',
  //   element: (
  //     <>
  //       <AppHeader hasMenuGlobal hasUser hasHelp />
  //       <ContainerAppView>
  //         <NoMatch404
  //           subtitle={t`Invite Link`}
  //           title={t`You must be logged out to register new account`}
  //         />
  //       </ContainerAppView>
  //       <AppFooter />
  //     </>
  //   ),
  // },
  // {
  //   path: '*',
  //   element: <Custom404 />,
  // },
];

type RoutesElements = {
  routes: RouteObject[];
};

const RoutesElements: React.FC<{ routes: RouteObject[] }> = ({ routes }) => {
  const routeElement = useRoutes(routes);
  return routeElement;
};

const AppRoutes: React.FC = () => {
  const auth = useRecoilValue(authState);
  const settingsLogin = useRecoilValue(userSettingsBase);
  const settings = useRecoilValue(userSettings);
  const loadWarehouseList = useLoadWarehouses();
  const whList = useRecoilValue(warehouseList);
  const warehouseListStatus = useRecoilValue(warehouseListLoadStatus);
  const [loadSettings] = useLoadUserSettings();

  useEffect(() => {
    if (auth.status === 'authorized') {
      loadWarehouseList();
      loadSettings();
    }
  }, [auth]);

  const hasAnyWarehouse =
    warehouseListStatus === AsyncLoadStatus.Ok && _.size(whList) > 0;

  let showSidebars = false;
  const routes = [...commonRoutes];
  if (auth.status === 'unauthorized') {
    routes.push(...publicRoutes);
  }
  if (auth.status === 'authorized') {
    showSidebars = true;
    if (settings?.configured || settingsLogin?.configured || hasAnyWarehouse) {
      routes.push(...privateRoutes);
    } else {
      routes.push(...restrictedRoutes);
    }
  }

  if (warehouseListStatus === AsyncLoadStatus.Loading || AsyncLoadStatus.None) {
    return <LoadingIndicator selfCenter message={t`Loading Warehouse`} />;
  }

  if (warehouseListStatus === AsyncLoadStatus.Error) {
    return <ErrorIndicator selfCenter message={t`Error Loading Warehouse`} />;
  }

  return (
    <>
      <RoutesElements routes={routes} />
      {showSidebars && (
        <>
          <SidebarGlobalMenu />
          <SidebarHelp />
          <SidebarNotifications />
          <SidebarSettings />
        </>
      )}
    </>
  );
};

export default AppRoutes;
