import classNames from 'classnames';
import { useTranslation } from 'react-i18next';
import { useRecoilValue } from 'recoil';
import { cn } from '../../../common/utils';
import { Button } from '../../../components/actions/Button';
import * as Icon from '../../../components/icons';
import { TagFilename } from '../../../helpContext/TagFilename';
import { importSelectedFile } from '../../store/import.state';
import useResetImportState from '../../useResetImportState';

const ImporterSelectedFileHeader: React.FC = () => {
  const { t } = useTranslation('importer');
  const file = useRecoilValue(importSelectedFile);
  const reset = useResetImportState();

  return (
    <div
      data-component="HeaderSelectedFile"
      className={classNames(
        'flex items-center',
        'w-full',
        'space-x-4',
        // 'p-2 bg-app-panel-dark/60'
      )}
    >
      {file ? (
        <TagFilename
          canCopy={false}
          value={file?.name}
          className={cn(
            'h-7',
            // 'ltr:mr-4 rtl:ml-4',
            'flex-1',
            'text-menu-active',
            'bg-app-panel-dark',
          )}
        />
      ) : (
        <>
          <span className={classNames('flex-1')}>{t`Dataset`}</span>
        </>
      )}
      <Button
        className="rounded"
        buttonSize="xs"
        buttonType="primary"
        label={file ? t`Cancel Upload` : t`Cancel`}
        hasIconAfter
        buttonIcon={
          <Icon.CircleX className={classNames('h-5 w-5 fill-current')} />
        }
        onPress={() => reset({ step: 'import-select-csv' })}
      />
    </div>
  );
};

export default ImporterSelectedFileHeader;
