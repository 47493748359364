import { InferResult, sql } from 'kysely';
import { getAnalyzeEventsFilteredQueryBuilder } from './queryBuilder/analyzedEventsQueryBuilder';
import { datasetQueryBuilder } from './queryBuilder/datasetQueryBuilder';

export type AnalyzeQueryBuilderParams = {
  analyzeId: string;
};

export type AnalyzeGatesStatsResults = InferResult<
  ReturnType<typeof getAnalyzeGatesStatsQuery>
>;
export type AnalyzeGatesStatsRow = AnalyzeGatesStatsResults[number];
export type AnalyzeGatesStatsColumn = keyof AnalyzeGatesStatsRow;

export function getAnalyzeGatesStatsQuery(params: AnalyzeQueryBuilderParams) {
  return getAnalyzeEventsFilteredQueryBuilder(
    { actualityId: params.analyzeId },
    datasetQueryBuilder,
  )
    .select(({ fn }) => [
      'locationId',
      'eventType',
      fn.sum<number>('duration').as('duration'),
      fn.sum<number>('cost').as('cost'),
      fn
        .sum<number>(
          sql<number>`json_value(${sql.ref('details')}, 'strict $.distance' returning double)`,
        )
        .as('distance'),
      fn
        .sum<number>(
          sql<number>`json_value(${sql.ref('details')}, 'strict $.volume' returning double)`,
        )
        .as('volume'),
      fn
        .sum<number>(
          sql<number>`json_value(${sql.ref('details')}, 'strict $.weight' returning double)`,
        )
        .as('weight'),
      fn.countAll<number>().as('jobsCount'),
    ])
    .where('eventType', 'in', [
      'PRE_HANDLING',
      // 'TRAVELLING_HORIZONTAL',
      'TRAVELLING_HORIZONTAL_END',
    ])
    .where('processType', '=', 'PICKING')
    .groupBy(['locationId', 'eventType']);
}
